import { deleteProperty } from "dot-prop";
import { DownloadIconAlign } from "../../validators/styleValidator.js";
export const defaultDownloadButton = {
    properties: {
        style: {
            background: 'black',
            color: 'white',
            fontFamily: 'Arial',
            fontSize: '16px',
            fontWeight: 800,
        },
        component: {
            label: 'Download',
            loadingLabel: 'Downloading...',
            showIcon: true,
        },
        downloadIconStyle: {
            color: 'white'
        },
        loadingStyle: {
            background: 'black',
            color: 'white'
        },
        downloadIconAlign: DownloadIconAlign.Enum.left
    }
};
export const copyDownloadButtonStyles = (properties) => {
    const _properties = { ...properties };
    deleteProperty(_properties, 'component.label');
    deleteProperty(_properties, 'component.loadingLabel');
    return _properties;
};
