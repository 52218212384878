import { Box } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { reportPageValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { deepMerge } from "src/utils/theme";
import { z } from "zod";
import ColorForm from "../../forms/ColorForm";
import PositionForm from "../../forms/PositionForm";

const formValidator = reportPageValidator.baseComponent;

type FormData = z.infer<typeof formValidator>

const defaultValues: Pick<FormData, 'properties'> = {
    properties: {
        style: {
        }
    }
}

const isSVG = (url: string) => {
    return url?.endsWith('.svg');
}

const ImageStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const isSVGImage = isSVG(componentToEdit?.properties.src);

    const methods = useForm<FormData>({
        defaultValues: defaultValues,
        values: componentToEdit as any,
        resolver: zodResolver(formValidator),
        mode: 'onBlur',
    })

    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                {isSVGImage && <ColorForm<FormData> title="Color" name={"properties.style.backgroundColor"} />}
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={deepMerge(defaultValues, componentToEdit)} />
            </form>
        </FormProvider>
    </Box>
}

export default ImageStyle;