import {
    Flex,
    FormControl,
    Input,
    FormLabel,
    ModalBody,
    Button,
    FormErrorMessage
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { sourceAuthValidator } from 'common/validators';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useModalStepList } from '../../ModalStepList/ModalStepList';
import { useCreateSourceAuthFromCredentialsMutation } from '@api';
import { toast } from 'react-toastify';
import { StepKeys } from '../enums';

type FormModel = z.infer<typeof sourceAuthValidator.createFromCredentials>;

interface Props {
    withAccountSelection?: boolean;
}

export default function LoginStep({ withAccountSelection }: Props) {
    const { state, setState, setStepByKey } = useModalStepList();
    const [createSourceAuth, { isLoading }] =
        useCreateSourceAuthFromCredentialsMutation();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormModel>({
        resolver: zodResolver(sourceAuthValidator.createFromCredentials),
        defaultValues: {
            sourceIdentifier: state.sourceIdentifier
        },
        mode: 'onSubmit'
    });

    const onSubmit = handleSubmit(async (data) => {
        try {
            const response = await createSourceAuth({
                password: data.password,
                username: data.username,
                sourceIdentifier: data.sourceIdentifier
            }).unwrap();

            setState({
                ...state,
                sourceAuth: response
            });

            if (withAccountSelection) {
                setStepByKey(StepKeys.SelectAccount);
            } else {
                setStepByKey(StepKeys.SourceAuthDetail);
            }
        } catch (e: any) {
            if ('status' in e && e.status === 400) {
                toast.error('Invalid credentials.');
            } else {
                toast.error('An error occurred. Please try again.');
                console.error(e);
            }
        }
    });

    return (
        <ModalBody>
            <form onSubmit={onSubmit}>
                <Flex flexDir="column" gap="2rem">
                    <Flex flexDir="column" gap="1rem">
                        <FormControl isRequired isInvalid={Boolean(errors.username)}>
                            <FormLabel>Username</FormLabel>
                            <Input
                                type="text"
                                placeholder="Enter username"
                                {...register('username')}
                            />
                            {errors.username && (
                                <FormErrorMessage>
                                    {errors.username.message}
                                </FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl isRequired isInvalid={Boolean(errors.password)}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                type="password"
                                placeholder="Enter password"
                                {...register('password')}
                            />
                            {errors.password && (
                                <FormErrorMessage>
                                    {errors.password.message}
                                </FormErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                    <Flex gap="1rem" justifyContent="right">
                        <Button variant="outline">Cancel</Button>
                        <Button variant="solid" type="submit" isLoading={isLoading}>
                            Submit
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </ModalBody>
    );
}
