import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { ClientIdParams, ReportSlugParams, ViewSlugParams } from '@router';
import React, { useCallback, useEffect, useRef } from 'react';
import {
    BlockerFunction,
    generatePath,
    matchPath,
    useBlocker,
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import CanvasProvider from 'src/blueprint/pages/editor/EditorContext';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useLazyGetReportBySlugQuery } from 'src/redux/api';
import {
    selectActiveReport,
    selectActiveReportPage,
    setActiveReportPageId
} from 'src/redux/features/blueprint/bluePrintSlice';
import { useEditorNavigation } from 'src/router/hooks';
import { PathNames } from 'src/router/router';
import CanvasReport from './layout/CanvasReport';
import PropertySidebar from './layout/PropertySidebar';
import Sidebar from './layout/Sidebar/Sidebar';
import TopBar from './layout/TopBar';
import { PopoverProvider } from './contexts/PopoverProvider';
import { HappyModal } from '@tasklogy/zircon-ui-components';
import { findNonEditorPathname } from './layout/Sidebar/utils';

const ProgressiveEditor: React.FC = () => {
    const goToEditor = useEditorNavigation();
    const activeReport = useAppSelector(selectActiveReport);
    const activePage = useAppSelector(selectActiveReportPage);
    const params = useParams<ReportSlugParams & ViewSlugParams & ClientIdParams>();
    const [fetchReport] = useLazyGetReportBySlugQuery();
    const dispatch = useAppDispatch();
    const isMountedRef = useRef<boolean>();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const shouldBlock = useCallback<BlockerFunction>(
        ({ currentLocation, nextLocation }) => {
            if (
                isMountedRef.current &&
                currentLocation.pathname !== nextLocation.pathname
            ) {
                if (nextLocation.pathname === PathNames.TEMPLATES) {
                    return false;
                }
                const nextLocationReportSlug = matchPath(
                    PathNames.EDITOR,
                    nextLocation.pathname
                )?.params.reportSlug;
                if (nextLocationReportSlug !== params.reportSlug) {
                    return false;
                }
                const nextLocationViewSlug = matchPath(
                    PathNames.EDITOR,
                    nextLocation.pathname
                )?.params.viewSlug;
                // viewSlug is optional, so if it's undefined, we cannot navigate
                if (nextLocationViewSlug === undefined) {
                    return true;
                }
                return false;
            }
            return true;
        },
        []
    );

    let blocker = useBlocker(shouldBlock);

    useEffect(() => {
        if (activeReport?.slug !== params.reportSlug || !activeReport) {
            fetchReport({ slug: params.reportSlug, clientId: +params.clientId! });
        }
    }, [fetchReport, params.reportSlug]);

    useEffect(() => {
        if (params.viewSlug && activeReport?.pages) {
            const page = activeReport?.pages?.find(
                (p: any) => p.slug === params.viewSlug
            );

            if (page) {
                dispatch(setActiveReportPageId(page.id));
            } else {
                dispatch(setActiveReportPageId(activeReport.pages[0].id));
            }
        }
        if (!params.viewSlug) {
            goToEditor(
                params.clientId!,
                params.reportSlug!,
                activeReport?.pages?.[0].slug
            );
        }
    }, [activeReport?.pages, dispatch, params.viewSlug]);

    if (!params.clientId) {
        return null;
    }

    if (!activeReport) {
        return null;
    }

    const handleNavigateBack = () => {
        const nonEditorPathname = findNonEditorPathname(location);
        if (nonEditorPathname) {
            navigate(nonEditorPathname);
        } else {
            navigate('/');
        }
    };

    return (
        <Box>
            <TopBar />
            <CanvasProvider
                useEditorReport={true}
                useSharedReportAPI={false}
                reportBasePathName={generatePath(PathNames.BASE_SHARED_REPORT, {
                    clientId: params.clientId!
                })}
                containerStyle={{
                    display: 'flex',
                    maxHeight: '100vh',
                    maxWidth: '100vw'
                }}
                height={activePage?.layout?.pageHeight}
                width={activePage?.layout?.pageWidth}
            >
                <PopoverProvider>
                    <Sidebar />
                    <Box
                        id="editor-canvas"
                        width="80%"
                        height="calc( 100vh - 48px)"
                        overflow="scroll"
                        position="relative"
                        zIndex={0}
                    >
                        <CanvasReport />
                    </Box>
                    <PropertySidebar />
                </PopoverProvider>
            </CanvasProvider>

            <HappyModal isOpen={blocker.state === "blocked"} onClose={() =>
                // @ts-ignore
                blocker.reset()}>
                <HappyModal.Body>
                    Are you sure you want to leave the editor?
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={() =>
                        // @ts-ignore
                        blocker.reset()}>
                        No
                    </Button>
                    <Button
                        variant="solid"
                        onClick={() => handleNavigateBack()}
                    >
                        Yes
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
        </Box>
    );
};

export default ProgressiveEditor;
