import { Icon, IconButton, Text } from "@chakra-ui/react";
import { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import FormPropertyRow from "../../forms/layout/FormPropertyRow";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";

const ShowGrid = () => {
    const {state, actions} = useCanvas();

    return <FormPropertyRow>
        <Text textStyle='_h2'>
            Toggle grid visibility
        </Text>
        <IconButton
            aria-label="Show grid"
            icon={<Icon as={state.showGrid ? IoMdEye : IoMdEyeOff} />}
            onClick={() => actions.setShowGrid(!state.showGrid)}
        />
    </FormPropertyRow>
}

export default ShowGrid;
