import { deleteProperty } from 'dot-prop';
import { cardStyle, pallet } from './common.js';
const defaultDonutChart = {
    properties: {
        style: {},
        component: {
            legend: {
                align: 'right',
                height: 20,
                layout: 'vertical',
                symbol: 'square',
                fontSize: 14,
                itemsAlign: 'left',
                verticalAlign: 'middle'
            },
            pallet: pallet,
            label: 'Donut Chart'
        },
        labelStyle: {
            left: '30%',
            color: 'black',
            display: 'none',
            fontSize: '16px',
            textAlign: 'left',
            fontWeight: 400,
            lineHeight: '20px',
            fontFamily: 'Arial'
        },
        cardStyle: cardStyle
    }
};
export const copyDonutChartStyles = (properties) => {
    const _properties = { ...properties };
    deleteProperty(_properties, 'component.label');
    return _properties;
};
export default defaultDonutChart;
