import { Box, Button, Flex, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { HEADER_HEIGHT } from '@constants';
import { ClientTemplateParams } from '@router';
import { Message } from '@tasklogy/zircon-ui-components';
import { TbCopyMinus } from 'react-icons/tb';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import ExternalLink from 'src/components/ExternalLink';
import { PathNames } from 'src/router/router';

const ReportCreated = () => {
    const params = useParams<ClientTemplateParams>();
    const navigate = useNavigate();
    const reportLink =
        window.location.origin +
        generatePath(PathNames.SHARED_REPORT, {
            clientId: params.clientId!,
            reportSlug: params.reportSlug!,
            viewSlug: null
        });
    return (
        <Message
            style={{
                height: `calc(100vh - ${HEADER_HEIGHT}px)`,
                marginTop: '-2rem'
            }}
        >
            <Message.Content>
                <Flex
                    maxW={'40rem'}
                    alignItems="center"
                    flexDirection="column"
                    gap={'1.5rem'}
                >
                    <Text>
                        Congratulations, your report has been created successfully. Now
                        you can visit or share your report.
                    </Text>
                    <HStack spacing="0.5rem" alignItems="center">
                        <ExternalLink to={reportLink} label={reportLink} />
                        <Tooltip label="Copy" aria-label="Copy" placement="top">
                            <Flex>
                                <Icon
                                    as={TbCopyMinus}
                                    fontSize="1.125rem"
                                    transition="color 0.2s"
                                    _hover={{ color: 'brand.primary', cursor: 'pointer' }}
                                    onClick={() =>
                                        navigator.clipboard.writeText(reportLink)
                                    }
                                    justifyContent="left"
                                />
                            </Flex>
                        </Tooltip>
                    </HStack>
                    <Button
                        variant="solid"
                        onClick={() => {
                            navigate(PathNames.DASHBOARD_REPORTS);
                        }}
                    >
                        Go back to reports
                    </Button>
                </Flex>
            </Message.Content>
        </Message>
    );
};

export default ReportCreated;
