import { useListReportsQuery } from '@api';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, SimpleGrid } from '@chakra-ui/react';
import { usePagination } from 'ahooks';
import React from 'react';
import CardTemplate from './components/CardTemplate';
import Pagination from './components/Pagination';
import { Report, Report as ReportType } from 'src/redux/types/api';
import { useHasScopePermissions } from 'src/auth/useHasScopePermissions';

const getCards = (
    current: number,
    pageSize: number,
    templates: ReportType[]
): Promise<{ total: number; list: ReportType[] }> => {
    const start = (current - 1) * pageSize;
    const end = current * pageSize;
    return Promise.resolve({
        total: templates.length,
        list: templates.slice(start, end)
    });
};

const TemplatesList = () => {
    const { data: templates } = useListReportsQuery({ isTemplate: true });

    const { data, pagination } = usePagination(
        ({ current, pageSize }) => getCards(current, pageSize, templates ?? []),
        {
            defaultPageSize: 15,
            refreshDeps: [templates]
        }
    );
    const [showMore, setShowMore] = React.useState(false);

    const handleShowMore = () => {
        setShowMore(true);
    };

    const cards = showMore ? data?.list : data?.list?.slice(0, 8);

    const hasEditorPermission = useHasScopePermissions({
        any: ['editor']
    });

    return (
        <Box pb="4rem">
            <SimpleGrid columns={[1, 1, 1, 2, 3, 4]} gap={6}>
                {hasEditorPermission && (
                    <CardTemplate
                        template={{
                            id: 1,
                            title: 'Blank Report',
                            usedDataSources: [],
                            previewImageUrl: 'https://via.placeholder.com/150',
                            slug: 'blank-report',
                            isBlank: true
                        }}
                    />
                )}
                {cards?.map((template: Report, index: number) => (
                    <CardTemplate key={index} template={template} />
                ))}
            </SimpleGrid>
            {!showMore && data?.list && data.list.length > 8 && (
                <Button
                    onClick={handleShowMore}
                    leftIcon={<ChevronDownIcon h={6} w={6} />}
                    display={'flex'}
                    alignItems={'center'}
                    textDecoration={'underline'}
                    width="fit-content"
                    border={'none'}
                    outline={'none'}
                    _hover={{
                        color: 'brand.primary',
                        border: 'none'
                    }}
                >
                    Show all templates
                </Button>
            )}
            {templates && showMore && (
                <Flex justifyContent="end">
                    <Pagination pagination={pagination} />
                </Flex>
            )}
        </Box>
    );
};

export default TemplatesList;
