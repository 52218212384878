
import { Flex } from "@chakra-ui/react";
import { DateTime } from "luxon";
import MinMaxDate from "../../inputs/MinMaxDate";
import FormTitle from "../../layout/FormTitle";

const DateRangeForm = () => {
    return <Flex flexDir='column'>
        <Flex mt={4} flexDir='column'>
            <FormTitle title='Min date' />
            <MinMaxDate title='Select minimum date' name='minDate' defaultValue='2023-01-01' />
        </Flex>
        <Flex mt={4} flexDir='column'>
            <FormTitle title='Max date' />
            <MinMaxDate title='Select maximum date' name='maxDate' defaultValue={DateTime.now().toFormat('yyyy-MM-dd')} />
        </Flex>
    </Flex>;
}

export default DateRangeForm;