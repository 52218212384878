import { Button } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { BaseTable } from '@tasklogy/zircon-ui-components';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ClientConnectedSource } from 'src/redux/types/api';

type ClientConnectedSourceColumn = ClientConnectedSource & { action: string };
interface DataSourcesTableProps {
    clientConnectedSources: ClientConnectedSource[];
}

const columnHelper = createColumnHelper<ClientConnectedSourceColumn>();
const columns: ColumnDef<ClientConnectedSourceColumn, any>[] = [
    columnHelper.accessor('sourceIdentifier', {
        id: 'sourceIdentifier',
        cell: (info) => {
            return info.getValue();
        },
        header: () => 'Source type'
    }),
    columnHelper.accessor('advertiser.advertiserName', {
        id: 'advertiser.advertiserName',
        cell: (info) => {
            return info.getValue();
        },
        header: () => 'Advertiser account'
    }),
    columnHelper.accessor('advertiser.advertiserId', {
        id: 'advertiser.advertiserId',
        cell: (info) => {
            return info.getValue();
        },
        header: () => 'Account ID'
    }),
    columnHelper.accessor('action', {
        id: 'action',
        cell: (info) => {
            const { control } = useFormContext();
            return (
                <Controller
                    control={control}
                    name="connectedSources"
                    render={({ field: { value, onChange } }) => {
                        const isSelected =
                            value[info.row.original.sourceIdentifier] &&
                            value[info.row.original.sourceIdentifier] ===
                                info.row.original.id;
                        return (
                            <>
                                {isSelected ? (
                                    <Button
                                        fontWeight={500}
                                        paddingY={0}
                                        textDecoration={'underline'}
                                        color={'#F13939'}
                                        outline={'none'}
                                        border={'none'}
                                        _hover={{
                                            color: '#c93434',
                                            backgroundColor: 'transparent'
                                        }}
                                        onClick={() => {
                                            const newConnectedSources = value;
                                            delete newConnectedSources[
                                                info.row.original.sourceIdentifier
                                            ];
                                            onChange(newConnectedSources);
                                        }}
                                    >
                                        Remove
                                    </Button>
                                ) : (
                                    <Button
                                        fontWeight={500}
                                        paddingY={0}
                                        textDecoration={'underline'}
                                        color={'#23E1EF'}
                                        outline={'none'}
                                        border={'none'}
                                        _hover={{
                                            color: '#1C73E8',
                                            backgroundColor: 'transparent'
                                        }}
                                        onClick={() =>
                                            onChange({
                                                ...value,
                                                [info.row.original.sourceIdentifier]:
                                                    info.row.original.id
                                            })
                                        }
                                    >
                                        + Select
                                    </Button>
                                )}
                            </>
                        );
                    }}
                />
            );
        },
        header: () => ''
    })
];

const DataSourcesTable: React.FC<DataSourcesTableProps> = ({
    clientConnectedSources
}) => {
    return (
        <BaseTable
            variant="ghost"
            columns={columns}
            withPagination={false}
            data={clientConnectedSources as any}
        />
    );
};

export default DataSourcesTable;
