import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Icon,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Text
} from '@chakra-ui/react';
import { ClientTemplateParams } from '@router';
import { SourceIcon } from '@tasklogy/zircon-ui-components';
import { DataSourceIdentifier } from 'common/enums';
import { useFormContext } from 'react-hook-form';
import { FaCirclePlus } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import { ClientConnectedSource } from 'src/redux/types/api';
import DataSourcesTable from '../DataSourcesTable';
import { useModal } from 'src/hooks/useModal';
import PairDataSourceWizard from 'src/blueprint/components/PairDataSources/PairDataSourceWizard';
import { useAppDispatch } from 'src/hooks/redux';
import { api, useListUserSourceAuthsQuery } from '@api';
import ModalStepList from 'src/blueprint/components/ModalStepList/ModalStepList';
import SourceAuthListStep from 'src/blueprint/components/SourceAuths/SourceAuthListStep';
import { SelectAccount } from 'src/blueprint/components/PairDataSources/Steps/SelectAccount';

const DataSource = ({
    ds,
    clientConnectedSources,
    focusedSource,
    setFocused
}: {
    ds: { dataSource: DataSourceIdentifier; isSelected: boolean };
    clientConnectedSources: ClientConnectedSource[];
    focusedSource: DataSourceIdentifier | undefined;
    setFocused: (dataSource: DataSourceIdentifier | undefined) => void;
}) => {
    const { formState, watch } = useFormContext();
    const { errors } = formState;
    const dataSource = ds.dataSource;
    const isSelected = ds.isSelected;
    const isFocused = focusedSource === dataSource;
    const existClientConnectedSources = clientConnectedSources.length > 0;
    const {
        isOpen: isSourceAuthsModalOpen,
        open: openSourceAuthsModal,
        close: closeSourceAuthsModal
    } = useModal();
    const dispatch = useAppDispatch();
    const { data: sourceAuths } = useListUserSourceAuthsQuery(undefined);
    const {
        isOpen: isPairDataSourceWizardOpen,
        open: openPairDataSourceWizard,
        close: closePairDataSourceWizard
    } = useModal();

    const connectedSourcesErrors: Record<string, any> = errors?.connectedSources ?? {};

    const selectedConnectedSources = watch('connectedSources');

    const getBorder = () => {
        if (connectedSourcesErrors[dataSource]) {
            return '1px solid #E53E3E';
        } else {
            if (selectedConnectedSources[dataSource]) {
                return '2px solid #1BC30C';
            }

            return '1px solid #DFDFDF';
        }
    };

    const backgroundColor = connectedSourcesErrors[dataSource]
        ? '#F7EEED'
        : 'transparent';

    return (
        <Box zIndex={1000}>
            <Popover isOpen={isFocused} onClose={() => setFocused(undefined)}>
                <PopoverTrigger>
                    <Button
                        isDisabled={!isSelected}
                        aria-label={dataSource}
                        iconSpacing={isFocused ? 2 : 0}
                        leftIcon={<SourceIcon selectedIcons={[dataSource]} />}
                        variant={'ghost'}
                        border={getBorder()}
                        _hover={{
                            backgroundColor: 'transparent',
                            borderColor: '#23E1EF',
                            color: '#23E1EF'
                        }}
                        color="#878790"
                        backgroundColor={backgroundColor}
                        padding={!isFocused ? 0 : undefined}
                        rightIcon={
                            isFocused ? <ChevronDownIcon color="#878790" /> : undefined
                        }
                        onClick={() => setFocused(dataSource)}
                    >
                        {isFocused ? 'Select data source' : ''}
                    </Button>
                </PopoverTrigger>
                <PopoverContent w={'fit-content'}>
                    <>
                        {existClientConnectedSources && (
                            <>
                                <DataSourcesTable
                                    clientConnectedSources={clientConnectedSources}
                                />
                                <Button
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    paddingInline="10px"
                                    backgroundColor="#E5EEFC"
                                    color="#1C73E8"
                                    fontWeight={500}
                                    leftIcon={<Icon as={FaCirclePlus} h={4} w={4} />}
                                    onClick={() => {
                                        openSourceAuthsModal();
                                    }}
                                >
                                    Connect new data source
                                </Button>
                            </>
                        )}
                        {!existClientConnectedSources && (
                            <Flex alignItems="center">
                                <Text px="10px" fontSize={13}>
                                    This client does not have this source connected.
                                </Text>
                                <Button
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    paddingInline="10px"
                                    backgroundColor="#E5EEFC"
                                    color="#1C73E8"
                                    fontWeight={500}
                                    leftIcon={<Icon as={FaCirclePlus} h={4} w={4} />}
                                    onClick={openSourceAuthsModal}
                                >
                                    Connnect data source
                                </Button>
                            </Flex>
                        )}
                    </>
                </PopoverContent>
            </Popover>
            {sourceAuths && (
                <ModalStepList
                    isOpen={isSourceAuthsModalOpen}
                    onClose={closeSourceAuthsModal}
                >
                    <ModalStepList.Step modalProps={{ isCentered: true, size: '5xl' }}>
                        <SourceAuthListStep
                            sourceAuths={sourceAuths}
                            onAddNewSourceAuth={() => {
                                closeSourceAuthsModal();
                                openPairDataSourceWizard();
                            }}
                        />
                    </ModalStepList.Step>
                    <ModalStepList.Step modalProps={{ size: '5xl', isCentered: true }}>
                        <SelectAccount />
                    </ModalStepList.Step>
                </ModalStepList>
            )}
            <PairDataSourceWizard
                isOpen={isPairDataSourceWizardOpen}
                onClose={closePairDataSourceWizard}
                onSuccess={() => {
                    dispatch(api.util.invalidateTags(['SourceAuths']));
                }}
                withAccountSelection
            />
        </Box>
    );
};

export default DataSource;
