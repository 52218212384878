import {
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tooltip
} from '@chakra-ui/react';
import { ComponentType } from 'common/enums';
import { JSXElementConstructor, ReactElement } from 'react';
import { BiSelectMultiple } from "react-icons/bi";
import { GoSingleSelect } from 'react-icons/go';
import { IoIosMenu, IoMdLink } from 'react-icons/io';
import { LuComponent } from 'react-icons/lu';
import { MdDateRange, MdFileDownload, MdOutlineRectangle } from 'react-icons/md';
import { RiSortNumberDesc } from 'react-icons/ri';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useCanvasScroll } from './utils';

interface MenuItemProps {
    icon: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
    onClick: () => void;
    text: string;
}

const MenuItemComponent: React.FC<MenuItemProps> = ({ icon, onClick, text }) => {
    return (
        <MenuItem
            _hover={{
                borderColor: 'transparent',
                backgroundColor: 'gray.200'
            }}
            _focus={{
                outline: 'none'
            }}
            icon={icon}
            onClick={onClick}
        >
            {text}
        </MenuItem>
    );
};

interface AddComponentMenuProps {
    isOpen: boolean;
    onToggle: () => void;
}

const AddComponentMenu: React.FC<AddComponentMenuProps> = ({ isOpen, onToggle }) => {
    const { actions, state } = useCanvas();
    const { scrollX, scrollY, canvasHeight } = useCanvasScroll();
    const x = state.width ? state.width / 2 : scrollX;
    const y = scrollY + canvasHeight / 2;
    return (
        <Menu
            placement="right-start"
            id="editor-sidebar-component-menu"
            isOpen={isOpen}
            onClose={onToggle}
        >
            <Tooltip
                label="Add a component"
                aria-label="Add a component"
                placement="right"
            >
                <MenuButton
                    onClick={onToggle}
                    as={IconButton}
                    aria-label="Options"
                    icon={<LuComponent />}
                    variant="icon"
                    _hover={{
                        borderColor: 'transparent'
                    }}
                    _focus={{
                        outline: 'none'
                    }}
                />
            </Tooltip>
            <MenuList>
                <MenuItemComponent
                    icon={<RiSortNumberDesc />}
                    onClick={() => actions.addComponent(ComponentType.SINGLE_VALUE, x, y)}
                    text="Single value"
                />
                <MenuItemComponent
                    icon={<GoSingleSelect />}
                    onClick={() =>
                        actions.addComponent(ComponentType.SINGLE_SELECT, x, y)
                    }
                    text="Single select"
                />
                <MenuItemComponent
                    icon={<BiSelectMultiple />}
                    onClick={() => actions.addComponent(ComponentType.MULTI_SELECT, x, y)}
                    text="Multi select"
                />
                <MenuItemComponent
                    icon={<MdDateRange />}
                    onClick={() => actions.addComponent(ComponentType.DATE_RANGE, x, y)}
                    text="Date range"
                />
                <MenuItemComponent
                    icon={<MdFileDownload />}
                    onClick={() =>
                        actions.addComponent(ComponentType.DOWNLOAD_BUTTON, x, y)
                    }
                    text="Download button"
                />
                <MenuItemComponent
                    icon={<IoMdLink />}
                    onClick={() =>
                        actions.addComponent(ComponentType.NAVIGATION_BUTTON, x, y)
                    }
                    text="Navigation button"
                />
                <MenuItemComponent
                    icon={<MdOutlineRectangle />}
                    onClick={() => actions.addComponent(ComponentType.RECTANGLE, x, y)}
                    text="Ractangle"
                />
                {/* <MenuItemComponent
                    icon={<CiImageOn />}
                    onClick={() => actions.addComponent(ComponentType.LOGO, x, y)}
                    text="Logo"
                /> */}
                <MenuItemComponent
                    icon={<IoIosMenu />}
                    onClick={() => actions.addComponent(ComponentType.NAVBAR, x, y)}
                    text="Navbar"
                />
            </MenuList>
        </Menu>
    );
};

export default AddComponentMenu;
