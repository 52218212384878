import { useGetSourceAuthStatusQuery } from '@api';
import { Button, Flex, Img, ModalBody, ModalFooter } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import IconGroup from '../../../assets/svg/icons-group.svg';

import { SourceAuthAttemptStatus } from 'common/enums';
import { SourceAuth } from 'src/redux/types/api';

interface Props {
    attemptId?: string;
    onSuccess: () => void;
    onCancel: () => void;
    onPoll: (data: { status: SourceAuthAttemptStatus; sourceAuth: SourceAuth }) => void;
}

export const AuthInProgress = ({ onSuccess, onCancel, attemptId, onPoll }: Props) => {
    const [pollingInterval, setPollingInterval] = useState(3000);

    const { data: authStatusData } = useGetSourceAuthStatusQuery(
        {
            attemptId: attemptId
        },
        {
            pollingInterval,
            skip: attemptId === undefined
        }
    );

    useEffect(() => {
        if (authStatusData) {
            onPoll({
                status: authStatusData.status,
                sourceAuth: authStatusData.sourceAuth
            });
        }
        if (authStatusData?.status === SourceAuthAttemptStatus.FINISHED) {
            onSuccess?.();
        } else if (
            authStatusData?.status === SourceAuthAttemptStatus.REAUTH_NOT_SAME ||
            authStatusData?.status === SourceAuthAttemptStatus.FAILED
        ) {
            toast.error('Authentication failed. Please try again.');
            setPollingInterval(0);
        }
    }, [authStatusData]);

    return (
        <>
            <ModalBody px="0" pt="1rem" pb="2rem" textAlign="center">
                {authStatusData?.status && (
                    <Img src={IconGroup} ml="auto" mr="auto" mb="30px" mt="20px" />
                )}
                {authStatusData?.status === SourceAuthAttemptStatus.REAUTH_NOT_SAME && (
                    <>
                        Re-authentication failed. <br />
                        You have not logged in with the same account as before.
                    </>
                )}
                {authStatusData?.status === SourceAuthAttemptStatus.FAILED && (
                    <>
                        Authentication failed. <br />
                        Try again.
                    </>
                )}
                {authStatusData?.status === SourceAuthAttemptStatus.STARTED && (
                    <>
                        Authentication is in progress. <br />
                    </>
                )}
            </ModalBody>
            <ModalFooter p="0" justifyContent="center" gap={'1rem'}>
                <Flex justifyContent={'end'} width={'100%'}>
                    {authStatusData?.status === SourceAuthAttemptStatus.STARTED && (
                        <Button variant="solid" onClick={onCancel}>
                            Cancel authentication
                        </Button>
                    )}
                    {authStatusData?.status === SourceAuthAttemptStatus.FAILED && (
                        <Button variant="solid" onClick={onCancel}>
                            Try again
                        </Button>
                    )}
                </Flex>
            </ModalFooter>
        </>
    );
};
