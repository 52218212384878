import { Accordion, Button, Card, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useCreateReportPageMutation } from 'src/redux/api';

import { useAppSelector } from 'src/hooks/redux';
import { selectActiveReport } from 'src/redux/features/blueprint/bluePrintSlice';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { PageCard } from './PageCard';
import { PageForm } from './PageForm';

export interface Page {
    displayName: string;
    slug: string;
}

const Pages = () => {
    const activeReport = useAppSelector(selectActiveReport);

    const [createPage] = useCreateReportPageMutation();

    const [newPage, setNewPage] = useState<Page | undefined>(undefined);
    const handleAddPage = () => {
        setNewPage({ displayName: '', slug: '' });
    };

    assertIsDefined(activeReport);

    const handleCreateReportPage = (values: Page) => {
        createPage({
            displayName: values.displayName,
            slug: values.slug,
            reportId: activeReport.id
        });
        setNewPage(undefined);
    };

    return (
        <Card padding={2} gap={2}>
            <Flex alignItems="center">
                <Text fontSize={18} fontWeight={600} textTransform="uppercase">
                    Pages
                </Text>
                <IconButton
                    aria-label="addPage"
                    ml="auto"
                    mr={2}
                    icon={<Icon as={FiPlus} />}
                    onClick={() => handleAddPage()}
                />
            </Flex>
            {newPage && (
                <PageForm
                    page={newPage}
                    onSave={(values) => handleCreateReportPage(values)}
                />
            )}
            <Accordion allowToggle>
                <Flex flexDirection={'column'} gap={4}>
                    {activeReport?.pages?.map((page) => (
                        <PageCard key={page.id} item={page} />
                    ))}
                </Flex>
            </Accordion>
        </Card>
    );
};

export default Pages;
