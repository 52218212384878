import { ComponentType } from 'common/enums';
import { ColumnFormatType } from 'common/enums';

export const useDefaultData = (componentType: ComponentType) => {
    switch (componentType) {
        case ComponentType.SINGLE_VALUE:
            return {
                label: 'Test Value',
                value: 8376,
                type: ColumnFormatType.CURRENCY
            };
        case ComponentType.DONUT_CHART:
            return {
                label: 'Average open rate:',
                data: [
                    { name: 'Vracajúci sa', value: 4678 },
                    { name: 'Noví používatelia', value: 3900 }
                ],
                type: ColumnFormatType.PERCENTAGE
            };
        case ComponentType.TABLE || ComponentType.TABLE_PROGRESS_BAR_CHART:
            return {
                label: 'Post performance overview',
                columns: [
                    {
                        title: 'Name',
                        accessor: 'name',
                        type: ColumnFormatType.STRING
                    },
                    {
                        title: 'Age',
                        accessor: 'age',
                        type: ColumnFormatType.NUMBER
                    },
                    {
                        title: 'Since',
                        accessor: 'since',
                        type: ColumnFormatType.NUMBER
                    },
                    {
                        title: 'Balance',
                        accessor: 'balance',
                        type: ColumnFormatType.CURRENCY,
                        format: 'EUR'
                    }
                ],
                rows: [
                    {
                        name: 'Paul lorem ipsum dolor sit amet',
                        age: 32,
                        since: '2018-01-01',
                        balance: 1000
                    },
                    {
                        name: 'John lorem ipsum dolor sit amet',
                        age: 16,
                        since: '2018-01-01',
                        balance: 2000
                    },
                    {
                        name: 'Peter lorem ipsum dolor sit amet',
                        age: 18,
                        since: '2018-01-01',
                        balance: 3000
                    },
                    {
                        name: 'Ivan lorem ipsum dolor sit amet',
                        age: 22,
                        since: '2018-01-01',
                        balance: 4000
                    }
                ],
                summary: [
                    {
                        accessor: 'name',
                        value: 'Total'
                    },
                    {
                        accessor: 'balance',
                        value: 10000
                    }
                ]
            };
        case ComponentType.LINE_CHART:
            return {
                data: [
                    {
                        name: 'Page A',
                        uv: 4000,
                        pv: 2400,
                        amt: 2400
                    },
                    {
                        name: 'Page B',
                        uv: 3000,
                        pv: 1398,
                        amt: 2210
                    },
                    {
                        name: 'Page C',
                        uv: 2000,
                        pv: 9800,
                        amt: 2290
                    },
                    {
                        name: 'Page D',
                        uv: 2780,
                        pv: 3908,
                        amt: 2000
                    },
                    {
                        name: 'Page E',
                        uv: 1890,
                        pv: 4800,
                        amt: 2181
                    },
                    {
                        name: 'Page F',
                        uv: 2390,
                        pv: 3800,
                        amt: 2500
                    },
                    {
                        name: 'Page G',
                        uv: 3490,
                        pv: 4300,
                        amt: 2100
                    }
                ],
                xAxisKey: 'name',
                yAxisKeys: ['uv', 'pv', 'amt']
            };
        case ComponentType.AREA_CHART || ComponentType.BIAXIAL_BAR_CHART:
            return {
                data: [
                    {
                        name: 'Page A',
                        uv: 4000,
                        pv: 2400,
                        amt: 2400
                    },
                    {
                        name: 'Page B',
                        uv: 3000,
                        pv: 1398,
                        amt: 2210
                    },
                    {
                        name: 'Page C',
                        uv: 2000,
                        pv: 9800,
                        amt: 2290
                    },
                    {
                        name: 'Page D',
                        uv: 2780,
                        pv: 3908,
                        amt: 2000
                    },
                    {
                        name: 'Page E',
                        uv: 1890,
                        pv: 4800,
                        amt: 2181
                    },
                    {
                        name: 'Page F',
                        uv: 2390,
                        pv: 3800,
                        amt: 2500
                    },
                    {
                        name: 'Page G',
                        uv: 3490,
                        pv: 4300,
                        amt: 2100
                    }
                ],
                xAxisKey: 'name',
                yAxisKeys: ['uv', 'pv', 'amt']
            };
    }
};
