import { styleValidator } from '../../validators/index.js';
import { borderAll } from './common.js';
export const defaultNavbar = {
    properties: {
        style: {
            background: 'white'
        },
        buttonStyle: {
            color: 'white',
            fontSize: '16px',
            fontWeight: 800,
            fontFamily: 'Arial',
            transition: 'color 300ms ease-in-out',
            padding: '0px',
            _active: {
                color: 'blue',
            },
            _hover: {
                color: 'red',
            }
        },
        containerStyle: {
            background: 'white'
        },
        buttonContainer: {
            background: 'black',
            ...borderAll,
            verticalAlign: styleValidator.verticalAlign.Enum.middle,
            horizontalAlign: styleValidator.horizontalAlign.Enum.center,
            transition: 'background 300ms ease-in-out',
            padding: '0px',
            _active: {
                background: 'black',
                ...borderAll,
            },
            _hover: {
                background: 'blue',
                ...borderAll,
            }
        }
    }
};
export const copyNavbarStyles = (properties) => {
    const _properties = { ...properties };
    return _properties;
};
