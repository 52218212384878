import { useMatches } from 'react-router-dom';
import { Route } from '../types';

export const useNavigationRoutes = ({ routes }: { routes: Route[] }) => {
    const matches = useMatches();
    const matchesPaths = matches.map((match) => match.pathname);
    if (matchesPaths.length > 1) {
        matchesPaths.shift();
    }

    let navigationRoutes = routes
        .reduce((acc: Route[], route: Route) => {
            return acc.concat(route?.children ?? []);
        }, [] as Route[])
        .filter((route: Route) => route?.handle?.navItem);


    navigationRoutes = navigationRoutes.map((route: Route) => {
        const isActive = matchesPaths.includes(route.path);
        return { ...route, isActive };
    });

    return navigationRoutes;
};
