import { Box, chakra, Flex, FlexProps, Text, TextProps, useMultiStyleConfig } from "@chakra-ui/react";

interface PingStatusProps extends FlexProps {
    colorScheme?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    children?: React.ReactNode;
    statusStyles?: TextProps;
}

const _PingStatus = ({ colorScheme = 'green', size = 'md', children, statusStyles, ...rest }: PingStatusProps) => {
    const styles = useMultiStyleConfig('PingStatus', { colorScheme, size });
    const _statusStyles = { ...styles.status, ...statusStyles };
    return <Flex alignItems='center' gap='0.3rem' {...rest}>
        <Box __css={styles.ping} />
        <Text sx={_statusStyles}>{children}</Text>
    </Flex>
}

const PingStatus = chakra(_PingStatus, { themeKey: "PingStatus" });

export default PingStatus