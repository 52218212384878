import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { getDefaults } from './input'

const editor = defineStyle((props) => {
    const { errorBorderColor: ec } = getDefaults(props)
    return {
        border: '1px solid #F2F3F4',
        fontSize: '12px',
        borderRadius: '6px',
        boxShadow: 'none',
        backgroundColor: '#F2F3F4',
        color: '#242424',
        _focusVisible: {
            border: '1px solid #DFDFDF',
            boxShadow: 'none'
        },
        _invalid: {
            borderColor: ec,
        }
    }
})

export const textareaTheme = defineStyleConfig({
    variants: { editor },
})