import { Box, Flex } from "@chakra-ui/react";
import { SourceIcon } from "@tasklogy/zircon-ui-components";
import { components, OptionProps } from "react-select";

const Option = ({ children, ...props }: OptionProps<any>) => {
    const value = props.data?.value?.dataSource?.identifier;
    return (
        <components.Option {...props}>
            <Flex role="group" gap={2} alignItems='center'>
                <Box
                    filter={'grayscale(0.7)'}
                    _groupHover={{ filter: 'none' }}
                    transition={'filter 0.3s ease'}
                >
                    <SourceIcon selectedIcons={[value]} />
                </Box>
                {children}
            </Flex>
        </components.Option>
    );
};

export default Option;