import useResizeObserver from '@react-hook/resize-observer';
import React from 'react';

export const useSize = (target: React.MutableRefObject<any>) => {
    const [size, setSize] = React.useState<DOMRectReadOnly | undefined>();

    React.useLayoutEffect(() => {
        if (!target.current) {
            return;
        }
        setSize(target.current.getBoundingClientRect());
    }, [target]);

    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
};
