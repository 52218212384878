import { Box } from "@chakra-ui/react"
import PropertySidebarTabs from "./Tabs"


const PropertySidebar: React.FC = () => {
    return <Box height='calc( 100vh - 48px )' minW='320px' width='20%' bg='white' overflowY='scroll'>
        <PropertySidebarTabs />
    </Box>
}

export default PropertySidebar