import { Box } from '@chakra-ui/react';

interface PlayerIconProps {
    url?: string;
}

const YOUTUBE_REGEX =
    '^((?:https?:)?//)?((?:www|m).)?((?:youtube(?:-nocookie)?.com|youtu.be))';

const PlayerIcon: React.FC<PlayerIconProps> = ({ url }) => {
    if (url && url.match(YOUTUBE_REGEX)) {
        return (
            <Box
                my="0.25rem"
                position="absolute"
                h="30px"
                w="30px"
                top="calc(50% - 15px)"
                left="calc(50% - 15px)"
            >
                <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
                    <path
                        className="ytp-large-play-button-bg"
                        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                        fill="#f00"
                    ></path>
                    <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                </svg>
            </Box>
        );
    }
    return (
        <Box
            my="0.25rem"
            position="absolute"
            h="30px"
            w="30px"
            top="calc(50% - 15px)"
            left="calc(50% - 15px)"
        >
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z"
                    fill="black"
                />
                <path d="M14 10L8 5.5V14.5L14 10Z" fill="white" />
            </svg>
        </Box>
    );
};

export default PlayerIcon;
