import { useGetClientQuery } from '@api';
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { ActionEdit, OutletTitle } from '@tasklogy/zircon-ui-components';
import { useParams, useSearchParams } from 'react-router-dom';
import { ClientIdParams } from 'src/router/types';
import ConnectedSourcesTable from './ConnectedSourcesTable';
import ReportsTable from 'src/blueprint/components/ReportsTable';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import AuthorizedWithScopePermissions from 'src/auth/AuthorizedWithScopePermissions';
import UpdateClientModal from '../Clients/UpdateClientModal';
import { useModal } from 'src/hooks/useModal';

const ClientDetail: React.FC = () => {
    const params = useParams<ClientIdParams>();
    const [searchParams, setSearchParams] = useSearchParams();
    const { isOpen, open, close } = useModal();

    assertIsDefined(params.clientId, 'Client id is not defined');
    const { data: existingClientData } = useGetClientQuery(
        {
            id: Number.parseInt(params.clientId)
        },
        { pollingInterval: 1000 * 30 }
    );

    const tab = searchParams.get('tab');
    const initialTabIndex = tab === 'reports' ? 1 : 0;

    const handleTabChange = (index: number) => {
        if (index === 1) {
            setSearchParams({ tab: 'reports' });
        } else {
            setSearchParams({ tab: 'connected-accounts' });
        }
    };

    if (!existingClientData) {
        return null;
    }

    return (
        <Flex flexDir={'column'} height={'100%'} justifyContent={'space-between'}>
            <Box>
                <Flex alignItems="center">
                    <OutletTitle>{existingClientData?.name}</OutletTitle>
                    <AuthorizedWithScopePermissions
                        scopePermissions={{
                            any: ['edit-client-name']
                        }}
                    >
                        <Flex h="100%" alignItems="center" mb="1rem" ml="0.25rem">
                            <ActionEdit onClick={open} />
                            {existingClientData && (
                                <UpdateClientModal
                                    onClose={close}
                                    isOpen={isOpen}
                                    client={existingClientData}
                                />
                            )}
                        </Flex>
                    </AuthorizedWithScopePermissions>
                </Flex>

                <Tabs
                    variant="enclosed"
                    colorScheme='lake'
                    mt="1rem"
                    index={initialTabIndex}
                    onChange={handleTabChange}
                >
                    <TabList>
                        <Tab>Connected accounts</Tab>
                        <Tab px="3rem">Reports</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <ConnectedSourcesTable client={existingClientData} />
                        </TabPanel>
                        <TabPanel>
                            <ReportsTable client={existingClientData} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Flex>
    );
};

export default ClientDetail;
