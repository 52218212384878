import { ComponentStyleConfig } from "@chakra-ui/react";

const PingStatus: ComponentStyleConfig = {
  parts: ['ping', 'status'],
  baseStyle: (props) => {
    const { colorScheme: c } = props;
    return {
      ping: {
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: `${c}.500`,
      },
      status: {
        fontSize: '8px',
        fontWeight: 600,
        color: `${c}.500`,
        fontFamily: 'Noto Sans, sans-serif'
      },
    }
  },
  sizes: {
    xs: {
      ping: {
        width: '6px',
        height: '6px',
      },
      status: {
        fontSize: '10px',
      }
    },
    sm: {
      ping: {
        width: '8px',
        height: '8px',
      },
      status: {
        fontSize: '12px',
      }
    },
    md: {
      ping: {
        width: '10px',
        height: '10px',
      },
      status: {
        fontSize: '14px',
      }
    },
    lg: {
      ping: {
        width: '12px',
        height: '12px',
      },
      status: {
        fontSize: '16px',
      }
    },
    xl: {
      ping: {
        width: '14px',
        height: '14px',
      },
      status: {
        fontSize: '18px',
      }
    },
  },
  defaultProps: {
    colorScheme: 'green',
    size: 'md',
  }
}

export const pingTheme = PingStatus;