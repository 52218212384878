import { Flex, FormControl, FormErrorMessage, InputProps, Radio, RadioGroup, RadioProps, VStack } from "@chakra-ui/react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import FormTitle from "../../forms/layout/FormTitle";
import FormPropertyRow from "../../forms/layout/FormPropertyRow";
import { LayoutScale } from "common/enums";

import { forwardRef, LegacyRef } from "react";
import { FieldError } from "react-hook-form";
import { getProperty } from "dot-prop";
import FormPropertyTitle from "../../forms/layout/FormPropertyTitle";
import FormNumberInput from "../../forms/inputs/Number";

const PageSizeForm = () => {
    const methods = useFormContext();
    const { errors } = methods.formState;
    return <Flex flexDir='column'>
        <FormPropertyRow>
                <FormPropertyTitle title='Page size' />
                <Flex gap='1rem'>
                    <Controller
                        control={methods.control}
                        name='pageWidth'
                        render={({ field: { value, onChange, ...rest } }) => (
                            <FormNumberInput label="W" value={value} onChange={(e) => onChange(e.target.value ? +e.target.value : '')} {...rest} error={getProperty(errors, 'pageWidth') as FieldError | undefined} width='80px' type="number" />
                        )}
                    />
                    <Controller
                        name='pageHeight'
                        render={({ field: { value, onChange, ...rest } }) => (
                            <FormNumberInput label="H" value={value} onChange={(e) => onChange(e.target.value ? +e.target.value : '')} {...rest} error={getProperty(errors, 'pageHeight') as FieldError | undefined} width='80px' type="number" />
                        )}
                    />
                </Flex>
            </FormPropertyRow>
    </Flex>
}

export default PageSizeForm;