import { Text } from '@chakra-ui/react';
import { format } from 'date-fns';

export interface DateTimeCellProps {
    value: Date;
}

const DateTimeCell: React.FC<DateTimeCellProps> = ({ value }) => {
    return <Text>{format(value, 'dd-MM-yyyy')}</Text>;
};

export default DateTimeCell;
