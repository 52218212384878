import { FieldValues, Path } from "react-hook-form";
import { TbBorderCorners, TbBoxPadding, TbRadiusBottomLeft, TbRadiusBottomRight, TbRadiusTopLeft, TbRadiusTopRight } from "react-icons/tb";
import CollapseForm from "../CollapseForm";

const config = {
    title: 'Radius',
    typePath: '_borderRadiusType',
    all: {
        keyPath: 'borderRadius',
        defaultValue: '0px',
        icon: <TbBorderCorners />,
        max: 100
    },
    mixed: [
        {
            keyPath: 'borderTopLeftRadius',
            defaultValue: '0px',
            icon: <TbRadiusTopLeft />,
            max: 100
        },
        {
            keyPath: 'borderTopRightRadius',
            defaultValue: '0px',
            icon: <TbRadiusTopRight />,
            max: 100
        },
        {
            keyPath: 'borderBottomLeftRadius',
            defaultValue: '0px',
            icon: <TbRadiusBottomLeft />,
            max: 100
        },
        {
            keyPath: 'borderBottomRightRadius',
            defaultValue: '0px',
            icon: <TbRadiusBottomRight />,
            max: 100
        }
    ],
    mixedIcon: <TbBoxPadding />
}


const RadiusForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    return <CollapseForm<T> objPath={objPath} config={config} />

}

export default RadiusForm;