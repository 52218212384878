import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    formAnatomy.keys
);

const baseStyle = definePartsStyle({
    container: {
        color: 'text.primary'
    }
});

const variantRow = definePartsStyle(() => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1rem'
        }
    };
});

const variants = {
    row: variantRow
};

export const formTheme = defineMultiStyleConfig({
    baseStyle,
    variants
});
