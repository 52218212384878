import { Flex, Text } from '@chakra-ui/react';

import ModalStepList from 'src/blueprint/components/ModalStepList/ModalStepList';
import { AuthInProgressStep } from 'src/blueprint/components/PairDataSources/Steps/AuthInProgressStep';
import ListDataSources from 'src/blueprint/components/PairDataSources/Steps/ListDataSources';
import { useModalStepList } from 'src/blueprint/components/ModalStepList/ModalStepList';
import { SourceAuth } from 'src/redux/types/api';

import { SourceAuthDetailModalContent } from 'src/blueprint/components/SourceAuths/SourceAuthDetailModal';
import { StepKeys } from './enums';
import { SourceIcon } from '@tasklogy/zircon-ui-components';
import LoginStep from './Steps/LoginStep';
import { DataSourceIdentifier } from 'common/enums';
import { SelectAccount } from './Steps/SelectAccount';
import LoginWithFacebook from './Steps/LoginWithFacebook';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    withAccountSelection?: boolean;
}

function SourceAuthDetail() {
    const { state, onClose } = useModalStepList();
    const sourceAuth = state.sourceAuth as SourceAuth;

    return <SourceAuthDetailModalContent onClose={onClose} sourceAuth={sourceAuth} />;
}

function SourceAuthDetailHeader() {
    const { state } = useModalStepList();
    const sourceAuth = state.sourceAuth as SourceAuth;

    return (
        <Flex gap="1rem">
            <Text>{sourceAuth?.source?.displayName}</Text>
            <SourceIcon selectedIcons={[sourceAuth.source.identifier]} />
        </Flex>
    );
}

function LoginStepHeader() {
    const { state } = useModalStepList();
    const sourceIdentifier = state.sourceIdentifier as DataSourceIdentifier;

    return (
        <Flex gap="1rem">
            <Text>Enter credentials</Text>
            {sourceIdentifier && <SourceIcon selectedIcons={[sourceIdentifier]} />}
        </Flex>
    );
}

export default function PairDataSourceWizard({
    isOpen,
    onClose,
    onSuccess,
    withAccountSelection
}: Props) {
    return (
        <ModalStepList isOpen={isOpen} onClose={onClose}>
            <ModalStepList.Step
                modalProps={{ size: '4xl', isCentered: true }}
                stepKey={StepKeys.ListDataSources}
                header="Select a data source"
            >
                <ListDataSources connectedSources={[]} />
            </ModalStepList.Step>
            <ModalStepList.Step
                stepKey={StepKeys.AuthInProgress}
                modalProps={{ size: 'xl', isCentered: true }}
                header="Authentication in progress"
            >
                <AuthInProgressStep
                    onSuccess={onSuccess}
                    withAccountSelection={withAccountSelection}
                />
            </ModalStepList.Step>
            <ModalStepList.Step
                stepKey={StepKeys.LoginWindow}
                modalProps={{ size: 'lg', isCentered: true }}
                header={<LoginStepHeader />}
            >
                <LoginStep withAccountSelection={withAccountSelection} />
            </ModalStepList.Step>
            <ModalStepList.Step
                stepKey={StepKeys.LoginWithFacebook}
                modalProps={{ size: 'lg', isCentered: true }}
                header="Login with Facebook"
            >
                <LoginWithFacebook />
            </ModalStepList.Step>
            <ModalStepList.Step
                stepKey={StepKeys.SourceAuthDetail}
                modalProps={{ size: '4xl', isCentered: true }}
                header={<SourceAuthDetailHeader />}
            >
                <SourceAuthDetail />
            </ModalStepList.Step>

            <ModalStepList.Step
                modalProps={{ size: '4xl', isCentered: true }}
                stepKey={StepKeys.SelectAccount}
            >
                <SelectAccount />
            </ModalStepList.Step>
        </ModalStepList>
    );
}
