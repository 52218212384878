import { Component } from 'common/types';
import { useEffect, useRef, useState } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';

interface CanvasObjectWrapperProps {
    children: React.ReactNode;
    component: Component;
}

const CanvasObjectWrapper: React.FC<CanvasObjectWrapperProps> = ({
    children,
    component
}) => {
    const { state, actions } = useCanvas();
    const isEditorReport = state.useEditorReport;
    const [showResizeBorder, setShowResizeBorder] = useState(false);
    const objectRef = useRef<HTMLDivElement>(null);

    const onMouseEnter = () => {
        if (!isEditorReport) return;
        setShowResizeBorder(true);
    };

    const onMouseLeave = () => {
        if (!isEditorReport) return;
        setShowResizeBorder(false);
    };

    const onClick = () => {
        if (component.id) {
            actions.setActiveSelection(new Set(state.activeSelection.add(component.id)));
        }
    };

    useEffect(() => {
        const onClick = (event: MouseEvent) => {
            if (objectRef.current && !objectRef.current.contains(event.target as Node)) {
                return;
            }
            const canvasComponentMenu = document.getElementById('canvas-component-menu');
            const canvasToolbar = document.getElementById('canvas-toolbar');
            const canvasAlignToolbar = document.getElementById('canvas-align-toolbar');
            // If the user is clicking on the toolbar, don't allow the component to be deselected
            if (
                canvasComponentMenu?.contains(event.target as Element) ||
                canvasToolbar?.contains(event.target as Element) ||
                canvasAlignToolbar?.contains(event.target as Element)
            ) {
                return;
            }
            // If the user is holding the meta key, don't allow the component to be deselected
            // This is to allow for multiple selection
            if (isHotkeyPressed('shift')) {
                return;
            }
            actions.setIsReadOnly(true);
            actions?.setEnabledInlineEditor(false);
            if (component.id && state.activeSelection) {
                state.activeSelection.clear();
                actions?.setActiveSelection(state.activeSelection.add(component.id));
            }
        };

        document.addEventListener('pointerdown', onClick);

        return () => {
            document.removeEventListener('pointerdown', onClick);
        };
    }, []);

    return (
        <div
            ref={objectRef}
            style={{
                position: 'absolute',
                width: component.w,
                height: component.h,
                transform: `translate(${component.x}px, ${component.y}px)`,
                outline: 'none',
                boxShadow: `0px 0px 0px 3px ${(component.id && state?.activeSelection.has(component.id)) || showResizeBorder ? '#5592E8' : 'transparent'}`,
                cursor: state?.activeSelection.has(component.id) ? 'move' : 'pointer',
                zIndex: component.zIndex,
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default CanvasObjectWrapper;
