import { Box, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { StyledSelect } from "@tasklogy/zircon-ui-components";
import { defaultTheme } from "common/constants";
import { styleValidator } from "common/validators";
import { Controller, FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { deepMerge } from "src/utils/theme";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import BorderForm from "../../forms/BorderForm";
import ColorForm from "../../forms/ColorForm";
import FormPropertyRow from "../../forms/layout/FormPropertyRow";
import FormTitle from "../../forms/layout/FormTitle";
import PositionForm from "../../forms/PositionForm";
import CardForm from "../../forms/CardForm";
import StyleDivider from "../../StyleDivider";

type FormData = z.infer<typeof styleValidator.table>
const defaultValues: Pick<FormData, 'properties'> = defaultTheme.components.TABLE;

const PAGINATION_LAYOUT_OPTIONS = [
    { label: 'Top', value: 'top' },
    { label: 'Bottom', value: 'bottom' },
]

const TableStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultValues,
        values: componentToEdit as any,
        resolver: zodResolver(styleValidator.table),
        mode: 'onBlur',
    })
    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <StyleDivider />
                <FormPropertyRow>
                    <FormTitle title="Pagination Layout" />
                    <Controller
                        name="properties.component.paginationLayout"
                        render={({ field }) => <StyledSelect
                            variant='editor'
                            value={PAGINATION_LAYOUT_OPTIONS.find(option => option.value === field.value)}
                            onChange={(option: any) => field.onChange(option.value)}
                            options={PAGINATION_LAYOUT_OPTIONS}
                            placeholder="Select Pagination Layout"
                        />
                        }
                    />
                </FormPropertyRow>
                <StyleDivider />
                <FormAccordion>
                    <FormAccordionItem title="Header">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={"properties.headerCell.color"} title="Color" />
                            <ColorForm<FormData> name={"properties.headerStyle.tr.background"} title="Background Color" withGradient />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Body Row">
                        <ColorForm<FormData> name={"properties.cell.color"} title="Color" />
                        <BorderForm<any> objPath={'properties.bodyStyle.tr'} type='bottom' />
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Body Odd Row">
                        <ColorForm<FormData> name={"properties.bodyStyle.tr._odd.background"} title="Background Color" withGradient />
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Body Even Row">
                        <ColorForm<FormData> name={"properties.bodyStyle.tr._even.background"} title="Background Color" withGradient />
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Footer Row">
                        <ColorForm<FormData> name={"properties.footerStyle.tr.background"} title="Background Color" withGradient />
                        <ColorForm<FormData> name={"properties.footerStyle.cell.color"} title="Color" />
                        <BorderForm<any> objPath={'properties.footerStyle.tr'} type='bottom' />
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Pagination Rows Count">
                        <ColorForm<FormData> name={"properties.paginationButtonsStyle.rowsCount.color"} title="Color" />
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Pagination Back Button">
                        <ColorForm<FormData> name={"properties.paginationButtonsStyle.backButton.backgroundColor"} title="Background Color" />
                        <ColorForm<FormData> name={"properties.paginationButtonsStyle.iconBackButton.color"} title="Icon Color" />
                        <ColorForm<FormData> name={"properties.paginationButtonsStyle.backButton._hover.backgroundColor"} title="Hover Background Color" />
                        <ColorForm<FormData> name={"properties.paginationButtonsStyle.iconBackButton._hover.color"} title="Hover Icon Color" />
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Pagination Forward Button">
                        <ColorForm<FormData> name={"properties.paginationButtonsStyle.forwardButton.backgroundColor"} title="Background Color" />
                        <ColorForm<FormData> name={"properties.paginationButtonsStyle.iconForwardButton.color"} title="Icon Color" />
                        <ColorForm<FormData> name={"properties.paginationButtonsStyle.forwardButton._hover.backgroundColor"} title="Hover Background Color" />
                        <ColorForm<FormData> name={"properties.paginationButtonsStyle.iconForwardButton._hover.color"} title="Hover Icon Color" />
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Pagination Rows Per Page">
                        <ColorForm<FormData> name={"properties.paginationRowsPerPageStyle.labelStyle.color"} title="Label Color" />
                    </FormAccordionItem>
                </FormAccordion>
                <StyleDivider />
                <CardForm<FormData> objPath={"properties.cardStyle"} />
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={deepMerge(defaultValues, componentToEdit)} />
            </form>
        </FormProvider>
    </Box>
}

export default TableStyle;