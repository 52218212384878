const defaultMultiSelect = {
    properties: {
        style: {},
        labelStyle: {
            color: 'black',
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Arial'
        },
        controlStyle: {
            color: '#2b3674',
            _hover: {
                borderColor: 'transparent'
            },
            flexWrap: 'nowrap',
            textWrap: 'nowrap',
            backgroundColor: '#ffffff'
        },
        dropdownIndicatorStyle: {
            color: '#2b3674'
        },
        indicatorSeparatorStyle: {
            backgroundColor: 'transparent'
        },
        placeholderStyle: {
            color: '#2b3674'
        }
    }
};
export const copyMultiSelectStyles = (properties) => {
    const _properties = { ...properties };
    return _properties;
};
export default defaultMultiSelect;
