import React, { createContext, useEffect, useState } from 'react';
import {
    ModalStepListContextProps,
    ModalStepListProps,
    ModalStepProps
} from './ModalStepList.types';
import { ModalStep } from './ModalStep';
import { Modal } from '@chakra-ui/react';

export const StepListContext = createContext<ModalStepListContextProps>({
    currentStep: 0,
    totalSteps: 0,
    goToPreviousStep: () => {},
    goToNextStep: () => {},
    isOpen: false,
    onClose: () => {},
    state: {},
    setState: () => {},
    setModalProps: () => {}
});

export const useModalStepList = () => React.useContext(StepListContext);

const ModalStepList: React.FC<ModalStepListProps> & { Step: React.FC<ModalStepProps> } = (
    props
) => {
    const [modalProps, setModalProps] = useState<Record<string, any>>({
        isCentered: true
    });
    const [state, setState] = useState({});
    const [currentStep, setCurrentStep] = useState(0);
    const [totalSteps, _setTotalSteps] = useState(React.Children.count(props.children));
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(props.isOpen || false);
    }, [props.isOpen]);

    const onClose = () => {
        setIsOpen(false);
        setCurrentStep(0);
        if (props.onClose) {
            props.onClose();
        }
    };

    const goToPreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const goToNextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    const children = React.Children.map(
        props.children as any,
        (child: React.ReactElement<ModalStepProps>, index) => {
            return React.cloneElement(child as React.ReactElement, {
                isActive: index === currentStep
            });
        }
    );

    return (
        <StepListContext.Provider
            value={{
                currentStep,
                totalSteps,
                goToPreviousStep,
                goToNextStep,
                isOpen,
                onClose,
                state,
                setState,
                setModalProps
            }}
        >
            <Modal isOpen={isOpen} onClose={() => {}} {...modalProps}>
                {children}
            </Modal>
        </StepListContext.Provider>
    );
};

ModalStepList.Step = ModalStep;

export default ModalStepList;
