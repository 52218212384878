import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

const MissingImage: React.FC = () => {
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            height="250px"
            bg="gray.100"
            borderRadius="13.67px 13.67px 0px 0px"
        >
            <Text fontSize="lg" color="gray.400" textAlign="center">
                Generate preview image in <br /> template editor
            </Text>
        </Flex>
    );
};

export default MissingImage;
