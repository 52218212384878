import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SharedReport from '../pages/SharedReport';
import { PathNames } from './router';
import * as authService from 'src/auth/authService';
import { ErrorBoundary } from '@sentry/react';
import ErrorFallback from 'src/ErrorFallback';
import { useAuth } from 'src/auth/useAuth';

declare global {
    interface Window {
        hasSignedIn: boolean;
        hasLoggedOut: boolean;
    }
}

window.hasSignedIn = false;
window.hasLoggedOut = false;

const AuthCallback = () => {
    const navigate = useNavigate();
    const { handleCallback } = useAuth();

    const handleCallback_ = async (code: string) => {
        if (!window.hasSignedIn) {
            window.hasSignedIn = true;
            await handleCallback(code);

            navigate(PathNames.DASHBOARD_CLIENTS);
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        handleCallback_(code ?? '');
    }, []);

    return null;
};

const LogoutCallback = () => {
    useEffect(() => {
        if (!window.hasLoggedOut) {
            window.hasLoggedOut = true;
            authService.removeTokenResponseFromStorage();
            authService.removeUserFromStorage();
            authService.redirectToLogin();
        }
    }, []);

    return null;
};

const publicRoutes = () => {
    return [
        { path: '/cb', element: <AuthCallback /> },
        { path: '/cb-logout', element: <LogoutCallback /> },
        {
            path: PathNames.SHARED_REPORT,
            element: (
                <ErrorBoundary fallback={<ErrorFallback />}>
                    <SharedReport />
                </ErrorBoundary>
            )
        }
    ];
};

export default publicRoutes;
