import { useMemo } from 'react';

import { ScopePermissions } from 'src/router/types';
import { useAuth } from './useAuth';

export function useHasScopePermissions(scopePermissions: ScopePermissions) {
    const { hasScopePermissions } = useAuth();

    const has = useMemo(() => {
        return hasScopePermissions(scopePermissions);
    }, [hasScopePermissions, scopePermissions]);

    return has;
}
