import { ScopePermissions } from '../router/types';
import { useHasScopePermissions } from './useHasScopePermissions';

interface AuthorizedWithScopePermissionsProps {
    children: React.ReactNode;
    scopePermissions: ScopePermissions;
    display?: boolean;
}

const AuthorizedWithScopePermissions: React.FC<AuthorizedWithScopePermissionsProps> = ({
    children,
    scopePermissions,
    display = true
}) => {
    const hasPermissions = useHasScopePermissions(scopePermissions);
    if (!hasPermissions) {
        return (
            <div style={{ visibility: 'hidden', display: display ? 'none' : 'unset' }}>
                {children}
            </div>
        );
    }
    return <>{children}</>;
};

export default AuthorizedWithScopePermissions;
