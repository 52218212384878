import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { UpdateReportImageArgs } from 'common/types';
import {
    clientValidator,
    notificationValidator,
    reportPageValidator,
    reportValidator,
    sourceAuthValidator,
    teamValidator
} from 'common/validators';
import * as authService from 'src/auth/authService';
import { z } from 'zod';
import { RootState } from './store';
import {
    Client,
    ClientConnectedSource,
    DefaultFilter,
    GetAuthUriArgs,
    GetAuthUriResponse,
    GetClientArgs,
    GetClientResponse,
    Notification,
    Report,
    SelectAdvertisersArgs,
    SelectAdvertisersResponse,
    SourceAuth,
    Team
} from './types/api';
import * as Sentry from '@sentry/react';

type ListReportsArgs = z.infer<typeof reportValidator.list>;
type CreateReportTemplateArgs = z.infer<typeof reportValidator.create>;
type ListClientsArgs = z.infer<typeof clientValidator.list>;
type CreateDefaultFilterargs = z.infer<typeof reportValidator.createDefaultFilter>;
type updateDefaultFilterArgs = z.infer<typeof reportValidator.updateDefaultFilter>;
type CreateFromTemplateArgs = z.infer<typeof reportValidator.createFromTemplate>;
type SeenNotificationsArgs = z.infer<typeof notificationValidator.seen>;
type DeleteTeamArgs = z.infer<typeof teamValidator.remove>;
type CreateSourceAuthFromCredentials = z.infer<
    typeof sourceAuthValidator.createFromCredentials
>;
type DuplicateReportArgs = z.infer<typeof reportValidator.duplicate>;

export type GetReportPageDataArgs = {
    isSharedReport?: boolean;
} & z.infer<typeof reportPageValidator.getData>;

export interface ErrorResponse {
    status: number;
    message: string;
}

const is401Error = (error: FetchBaseQueryError) => {
    if ('originalStatus' in error && error.originalStatus === 401) {
        return true;
    }
};

const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL as string,
    prepareHeaders: (headers, { getState, endpoint }) => {
        const matcher = new RegExp('^/shared-report/');
        const isShareReport = window.location.pathname.match(matcher);
        if (endpoint === 'login') {
            return headers;
        }
        if (isShareReport && endpoint !== 'getUserMe') {
            const sharedReportToken = (getState() as RootState).auth.token;
            headers.set('Authorization', `Bearer ${sharedReportToken}`);
            return headers;
        }
        const auth = authService.getTokenResponseFromStorage();
        if (auth?.access_token) {
            headers.set('Authorization', `Bearer ${auth.access_token}`);
        }
        return headers;
    }
});

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    const matcher = new RegExp('^/shared-report/');

    const isShareReport = window.location.pathname.match(matcher);

    if (result.error && !is401Error(result.error)) {
        Sentry.captureException(result.error);
    }

    if (result.error && is401Error(result.error)) {
        const auth = authService.getTokenResponseFromStorage();

        if (isShareReport) {
            if (result.error && is401Error(result.error)) {
                return result;
            }
        } else {
            if (auth?.refresh_token) {
                try {
                    await authService.refreshToken();
                } catch (e) {
                    authService.redirectToLogin();
                    return result;
                }

                result = await baseQuery(args, api, extraOptions);

                if (result.error && is401Error(result.error)) {
                    authService.redirectToLogin();
                    return result;
                }
            } else {
                authService.redirectToLogin();
                return result;
            }
        }
    }

    return result;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Report',
        'RReport',
        'Client',
        'Teams',
        'TeamClients',
        'SourceAuths',
        'DefaultFilters',
        'Notification'
    ],
    endpoints: (builder) => ({
        sharedReportLogin: builder.mutation<
            { accessToken: string },
            { slug: string; clientId: number; password: string }
        >({
            query: ({ slug, clientId, password }) => ({
                url: '/api/sharedReports.login',
                method: 'POST',
                body: { slug, clientId, password }
            })
        }),
        getUserMe: builder.query({
            query: () => ({
                url: '/api/users.me',
                method: 'POST'
            })
        }),
        listUserSourceAuths: builder.query<SourceAuth[], undefined>({
            query: () => ({
                url: '/api/users.listSourceAuths',
                method: 'POST'
            }),
            providesTags: ['SourceAuths']
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: '/api/users.update',
                method: 'POST',
                body: data
            })
        }),

        // Data Sources
        listDataSources: builder.mutation<{ id: number; displayName: string }[], void>({
            query: () => ({
                url: '/api/dataSources.list',
                method: 'POST'
            })
        }),
        createDataSources: builder.mutation({
            query: (body) => ({
                url: '/api/dataSources.create',
                method: 'POST',
                body
            })
        }),

        // Report
        createReport: builder.mutation<Report, CreateReportTemplateArgs>({
            query: (args) => ({
                url: '/api/reports.create',
                method: 'POST',
                body: { ...args }
            }),
            invalidatesTags: ['Report']
        }),
        createReportDefaultFilter: builder.mutation<
            { id: number },
            CreateDefaultFilterargs
        >({
            query: (body) => ({
                url: '/api/reports.createDefaultFilter',
                method: 'POST',
                body
            }),
            invalidatesTags: ['DefaultFilters']
        }),
        updateReportDefaultFilter: builder.mutation<
            { id: number },
            updateDefaultFilterArgs
        >({
            query: (body) => ({
                url: '/api/reports.updateDefaultFilter',
                method: 'POST',
                body
            }),
            invalidatesTags: ['DefaultFilters']
        }),
        deleteReportDefaultFilter: builder.mutation<{ id: number }, { id: number }>({
            query: ({ id }) => ({
                url: '/api/reports.deleteDefaultFilter',
                method: 'POST',
                body: { id }
            }),
            invalidatesTags: ['DefaultFilters']
        }),
        listReportDefaultFilters: builder.query<DefaultFilter[], { reportId: number }>({
            query: ({ reportId }) => ({
                url: '/api/reports.listDefaultFilters',
                method: 'POST',
                body: { reportId }
            }),
            providesTags: ['DefaultFilters']
        }),
        deleteReport: builder.mutation({
            query: ({ id }) => ({
                url: '/api/reports.delete',
                method: 'POST',
                body: { id }
            }),
            invalidatesTags: ['Report']
        }),
        updateReport: builder.mutation({
            query: (body) => ({
                url: '/api/reports.update',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Report']
        }),
        createFromTemplate: builder.mutation<Report, CreateFromTemplateArgs>({
            query: (body) => ({
                url: '/api/reports.createFromTemplate',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Report']
        }),
        listReports: builder.query<Report[], ListReportsArgs>({
            query: (args) => ({
                url: '/api/reports.list',
                method: 'POST',
                body: { ...args }
            }),
            providesTags: ['Report']
        }),
        getReportById: builder.query({
            query: ({ id }) => ({
                url: '/api/reports.get',
                method: 'POST',
                body: { id }
            })
        }),
        getReportBySlug: builder.query({
            query: ({ slug, clientId, isSharedReport = false }) => ({
                url: isSharedReport
                    ? '/api/sharedReports.getBySlug'
                    : '/api/reports.getBySlug',
                method: 'POST',
                body: { slug, clientId }
            })
        }),
        updateReportImage: builder.mutation<null, UpdateReportImageArgs>({
            query: ({ reportId }) => ({
                url: '/api/reports.updateImage',
                method: 'POST',
                body: { reportId }
            }),
            invalidatesTags: ['Report']
        }),
        uploadReportImage: builder.mutation({
            query: (body) => ({
                url: '/api/reports.uploadImage',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Report']
        }),

        // Report Pages
        createReportPage: builder.mutation({
            query: ({ displayName, slug, reportId }) => ({
                url: '/api/reportPages.create',
                method: 'POST',
                body: { displayName, slug, reportId }
            }),
            invalidatesTags: ['Report']
        }),
        updateReportPage: builder.mutation({
            query: (body) => {
                reportPageValidator.update.parse(body);

                return {
                    url: '/api/reportPages.update',
                    method: 'POST',
                    body: body
                };
            },
            invalidatesTags: ['Report']
        }),
        updatePageComponents: builder.mutation({
            query: (body) => {
                reportPageValidator.update.parse(body);

                return {
                    url: '/api/reportPages.update',
                    method: 'POST',
                    body: body
                };
            }
        }),
        deleteReportPage: builder.mutation({
            query: ({ id }) => ({
                url: '/api/reportPages.delete',
                method: 'POST',
                body: { id }
            }),
            invalidatesTags: ['Report']
        }),
        duplicateReportPage: builder.mutation({
            query: ({ id }) => ({
                url: '/api/reportPages.duplicate',
                method: 'POST',
                body: { id }
            }),
            invalidatesTags: ['Report']
        }),
        getReportPageData: builder.mutation({
            query: ({
                reportPageId,
                componentIds,
                filterValues,
                isSharedReport = false
            }: {
                reportPageId: number;
                componentIds: string[];
                filterValues: any[];
                isSharedReport?: boolean;
            }) => ({
                url: isSharedReport
                    ? '/api/sharedReports.getDataV2'
                    : '/api/reportPages.getDataV2',
                method: 'POST',
                body: { reportPageId, componentIds, filterValues }
            })
        }),
        getReportPageFilters: builder.mutation({
            query: ({
                reportPageId,
                filterIds,
                dateRange,
                isSharedReport = false
            }: {
                reportPageId: number;
                filterIds: any[];
                dateRange: { start: string; end: string };
                isSharedReport?: boolean;
            }) => ({
                url: isSharedReport
                    ? '/api/sharedReports.getFilterOptionsV2'
                    : '/api/reportPages.getFilterOptionsV2',
                method: 'POST',
                body: { reportPageId, filterIds, dateRange }
            })
        }),

        // Clients
        listClients: builder.query<Client[], ListClientsArgs>({
            query: (body) => ({
                url: '/api/clients.list',
                method: 'POST',
                body
            }),
            providesTags: ['Client']
        }),
        getClient: builder.query<GetClientResponse, GetClientArgs>({
            query: ({ id }) => ({
                url: '/api/clients.get',
                method: 'POST',
                body: { id }
            }),
            providesTags: (result) => [{ type: 'Client', id: result?.id }]
        }),
        createClient: builder.mutation({
            query: ({ name, teamId }) => ({
                url: '/api/clients.create',
                method: 'POST',
                body: { name, teamId }
            }),
            invalidatesTags: ['Client']
        }),
        updateClient: builder.mutation({
            query: (props: any) => ({
                url: '/api/clients.update',
                method: 'POST',
                body: props
            }),
            invalidatesTags: ['Client']
        }),
        listSourceAuth: builder.query({
            query: ({ attemptId }) => ({
                url: '/api/sourceAuth.list',
                method: 'POST',
                body: { attemptId }
            })
        }),
        deleteSourceAuth: builder.mutation<undefined, { id: number }>({
            query: ({ id }) => ({
                url: '/api/sourceAuth.delete',
                method: 'POST',
                body: { id }
            }),
            invalidatesTags: ['SourceAuths', 'Client', 'RReport', 'Report']
        }),
        createSourceAuthFromCredentials: builder.mutation<
            SourceAuth,
            CreateSourceAuthFromCredentials
        >({
            query: (body) => ({
                url: '/api/sourceAuth.createFromCredentials',
                method: 'POST',
                body
            }),
            invalidatesTags: ['SourceAuths']
        }),
        deleteClient: builder.mutation<{ id: number }, { id: number }>({
            query: ({ id }) => ({
                url: '/api/clients.delete',
                method: 'POST',
                body: { id }
            }),
            invalidatesTags: ['Client']
        }),
        getSourceAuthUri: builder.mutation<GetAuthUriResponse, GetAuthUriArgs>({
            query: ({ clientId, sourceIdentifier, reauthId }) => ({
                url: '/api/sourceAuth.getAuthUri',
                method: 'POST',
                body: { clientId, sourceIdentifier, reauthId }
            })
        }),
        getSourceAuthStatus: builder.query({
            query: ({ attemptId }) => ({
                url: '/api/sourceAuth.getStatus',
                method: 'POST',
                body: { attemptId }
            })
        }),
        refreshSourceAuth: builder.mutation<SourceAuth, { sourceAuthId: number }>({
            query: (body) => ({
                url: '/api/sourceAuth.refresh',
                method: 'POST',
                body
            }),
            invalidatesTags: ['SourceAuths']
        }),
        createConnectedSource: builder.mutation<
            SelectAdvertisersResponse,
            SelectAdvertisersArgs
        >({
            query: ({ clientId, advertiserId }) => ({
                url: '/api/clients.createConnectedSource',
                method: 'POST',
                body: { clientId, advertiserId }
            }),
            invalidatesTags: (result) => [
                { type: 'Client', id: result?.clientId },
                'SourceAuths'
            ]
        }),
        deleteConnectedSource: builder.mutation<
            ClientConnectedSource,
            { connectedSourceId: number }
        >({
            query: (body) => ({
                url: '/api/clients.deleteConnectedSource',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Client', 'SourceAuths']
        }),

        // teams
        createMember: builder.mutation({
            query: (data) => ({
                url: '/api/teams.createMember',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Teams']
        }),
        updateMember: builder.mutation({
            query: (data) => ({
                url: '/api/teams.updateMember',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Teams']
        }),
        deleteMember: builder.mutation<{ id: number }, { id: number }>({
            query: (data) => ({
                url: '/api/teams.deleteMember',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Teams']
        }),

        listTeams: builder.query<Team[], undefined>({
            query: () => ({
                url: '/api/teams.list',
                method: 'POST'
            }),
            providesTags: ['Teams']
        }),
        getTeam: builder.query<Team, { teamId: number }>({
            query: (data: { teamId: number | string | undefined }) => ({
                url: '/api/teams.get',
                method: 'POST',
                body: { id: data.teamId ? +data.teamId : null }
            }),
            providesTags: ['Teams']
        }),
        deleteTeam: builder.mutation<undefined, DeleteTeamArgs>({
            query: (data) => ({
                url: '/api/teams.delete',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Teams']
        }),
        createTeam: builder.mutation({
            query: (data) => ({
                url: '/api/teams.create',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Teams']
        }),
        updateTeam: builder.mutation({
            query: (data) => ({
                url: '/api/teams.update',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Teams']
        }),
        // Notifications
        listNotifications: builder.query<Notification[], undefined>({
            query: () => ({
                url: '/api/notifications.list',
                method: 'POST'
            }),
            providesTags: ['Notification']
        }),
        seenNotifications: builder.mutation<undefined, SeenNotificationsArgs>({
            query: (body) => ({
                url: '/api/notifications.seen',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Notification']
        }),
        duplicateReport: builder.mutation<Report, DuplicateReportArgs>({
            query: (body) => ({
                url: '/api/reports.duplicate',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Report']
        })
    })
});

export const {
    // User
    useLazyGetUserMeQuery,
    useGetUserMeQuery,
    useListUserSourceAuthsQuery,
    useUpdateUserMutation,

    // Reports
    useLazyGetReportBySlugQuery,
    useCreateReportPageMutation,
    useUpdateReportPageMutation,
    useUpdatePageComponentsMutation,
    useDeleteReportPageMutation,
    useDuplicateReportPageMutation,
    useUpdateReportMutation,
    useGetReportPageDataMutation,
    useGetReportPageFiltersMutation,
    useCreateReportMutation,
    useCreateFromTemplateMutation,
    useDeleteReportMutation,
    useSharedReportLoginMutation,
    useListReportsQuery,
    useLazyListReportsQuery,
    useGetReportByIdQuery,
    useUpdateReportImageMutation,
    useDuplicateReportMutation,
    useUploadReportImageMutation,

    // Default filters
    useCreateReportDefaultFilterMutation,
    useUpdateReportDefaultFilterMutation,
    useDeleteReportDefaultFilterMutation,
    useListReportDefaultFiltersQuery,

    // Clients
    useListClientsQuery,
    useGetClientQuery,
    useDeleteClientMutation,
    useCreateClientMutation,
    useUpdateClientMutation,
    useCreateConnectedSourceMutation,
    useDeleteConnectedSourceMutation,

    // Source Auth
    useDeleteSourceAuthMutation,
    useListSourceAuthQuery,
    useGetSourceAuthUriMutation,
    useGetSourceAuthStatusQuery,
    useCreateSourceAuthFromCredentialsMutation,
    useRefreshSourceAuthMutation,

    // teams
    useCreateMemberMutation,
    useUpdateMemberMutation,
    useDeleteMemberMutation,
    useListTeamsQuery,
    useGetTeamQuery,
    useCreateTeamMutation,
    useUpdateTeamMutation,
    useDeleteTeamMutation,

    // Notifications
    useListNotificationsQuery,
    useSeenNotificationsMutation
} = api;
