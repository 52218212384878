import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { TbCopyMinus } from 'react-icons/tb';

export interface PasswordCellProps {
    password: string;
}

const PasswordCell: React.FC<PasswordCellProps> = ({ password }) => {
    const [eyeOpen, setEyeOpen] = useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(password);
    };

    return (
        <Flex alignItems="center" justifyContent="left">
            <Text mr="0.75rem">{eyeOpen ? password : '********'}</Text>
            <Tooltip
                label={eyeOpen ? 'Hide' : 'Show'}
                aria-label={eyeOpen ? 'Hide' : 'Show'}
                placement="top"
            >
                <Box as="span">
                    <Icon
                        as={eyeOpen ? FiEyeOff : FiEye}
                        onClick={() => setEyeOpen(!eyeOpen)}
                        aria-label="show password"
                        fontSize="1.125rem"
                        transition="color 0.2s"
                        _hover={{ color: 'brand.primary', cursor: 'pointer' }}
                        mr="0.25rem"
                    />
                </Box>
            </Tooltip>
            <Tooltip label="Copy" aria-label="Copy" placement="top">
                <Box as="span">
                    <Icon
                        as={TbCopyMinus}
                        ml={1}
                        fontSize="1.125rem"
                        transition="color 0.2s"
                        _hover={{ color: 'brand.primary', cursor: 'pointer' }}
                        onClick={() => copyToClipboard()}
                        justifyContent="left"
                    />
                </Box>
            </Tooltip>
        </Flex>
    );
};

export default PasswordCell;
