import { generatePath, NavigateOptions, useLocation, useNavigate } from "react-router-dom"
import { PathNames } from "./router";

export const useEditorNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const goToEditor = (clientId: string, reportSlug: string, viewSlug?: string | null, options?: NavigateOptions) => {
        navigate(generatePath(PathNames.EDITOR, {
            clientId,
            reportSlug,
            viewSlug: viewSlug ?? null
        }), { state: { from: location }, ...options });
    }
    return goToEditor;
}