import { Box } from '@chakra-ui/react';

const OutletLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Box m={0} px="8" py="6" minH="calc(100vh - 4rem)" bg="#F8F8F8">
            {children}
        </Box>
    );
};

export default OutletLayout;
