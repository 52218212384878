import { As, ButtonGroup, Icon, IconButton } from "@chakra-ui/react";

const activeStyle = { backgroundColor: 'white', border: '1px solid', borderColor: 'gray.200', borderRadius: '5px', margin: '2px' }

interface IconGroupProps {
    icons: {
        value: any;
        icon: As;
        label: string;
        onClick: () => void;
        isActive?: (() => boolean) | boolean;
    }[]
}

const IconsGroup: React.FC<IconGroupProps> = ({ icons }) => {
    return <ButtonGroup isAttached bg='gray.50'>
        {icons.map(({ icon, label, onClick, isActive }) => (
            <IconButton key={label} aria-label={label} variant='editor' onClick={onClick} icon={<Icon as={icon} />}
                isActive={typeof isActive === 'function' ? isActive() : isActive}
                _active={activeStyle} />
        ))}
    </ButtonGroup>
};

export default IconsGroup;