import { Box } from '@chakra-ui/react';

const OutletLayoutSettings: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Box px="8" py="6" w="100%">
            {children}
        </Box>
    );
};

export default OutletLayoutSettings;
