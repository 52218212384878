import { Flex, Icon, Text } from '@chakra-ui/react';
import { FaCirclePlus } from 'react-icons/fa6';

const CreatePickRow: React.FC<{ onOpen: () => void }> = ({ onOpen }) => {
    return (
        <Flex
            backgroundColor="#E5EEFC"
            height="2.5rem"
            alignItems={'center'}
            gap="0.5rem"
            px="1rem"
            py="0.2rem"
        >
            <Icon as={FaCirclePlus} color="#1C73E8" />
            <Text
                color="#1C73E8"
                _hover={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }}
                onClick={onOpen}
            >
                Create your own audience
            </Text>
        </Flex>
    );
};

export default CreatePickRow;