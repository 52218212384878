import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
    outline: 'none',
    border: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _hover: {
        border: 'none',
        _disabled: {
            opacity: 0.4
        }
    },
    _focus: {
        outline: 'none'
    },
    _focusVisible: {
        boxShadow: 'none'
    },
    _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        boxShadow: 'none'
    }
});

const variantOutlined = defineStyle((props) => {
    const { colorScheme: c } = props;
    return {
        bg: 'transparent',
        borderRadius: '4px',
        color: `${c}.100`,
        fontWeight: 'bold',
        fontSize: '14px',
        textAlign: 'center',
        borderColor: `${c}.50`,
        borderWidth: '2px',
        _hover: {
            color: `${c}.50`,
            bg: 'transparent',
            border: `2px`,
            borderColor: `${c}.50`
        }
    };
});

const variantSolid = defineStyle((props) => {
    const { colorScheme: c } = props;
    return {
        bg: `${c}.50`,
        borderRadius: '4px',
        color: `${c}.100`,
        fontWeight: 'bold',
        fontSize: '14px',
        textAlign: 'center',
        border: '2px solid transparent',
        _hover: {
            color: `${c}.100`,
            textDecoration: 'none',
            opacity: 0.9,
            backgroundColor: `${c}.50`,
            border: '2px solid transparent'
        }
    };
});

const variantUnstyled = defineStyle({
    bg: 'transparent',
    borderRadius: '4px',
    color: '#171D26',
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'center',
    border: '2px solid transparent',
    _hover: {
        color: '#171D26',
        textDecoration: 'none',
        opacity: 0.9,
        bg: '#ececec',
        border: '2px solid transparent'
    }
});

const variantUnderlined = defineStyle((props) => {
    return {
        bg: 'transparent',
        borderRadius: '0px',
        color: 'brand.primary',
        fontWeight: 'bold',
        fontSize: '14px',
        textAlign: 'center',
        borderBottom: `2px solid transparent`,
        _hover: {
            color: 'brand.primary',
            textDecoration: 'none',
            opacity: 0.9,
            bg: 'transparent',
            borderBottom: `2px solid ${props.theme.colors.brand.primary}`
        }
    };
});

const variantIcon = defineStyle({
    bg: 'transparent',
    borderRadius: '4px',
    fontWeight: 'bold',
    fontSize: '20px',
    textAlign: 'center',
    border: '2px solid transparent',
    _hover: {
        textDecoration: 'none',
        opacity: 0.9,
        bg: '#ececec',
        border: '2px solid transparent'
    }
});

const variantUnstyledIcon = defineStyle({
    bg: 'transparent',
    minWidth: 'auto',
    height: 'auto',
    borderRadius: '4px',
    fontWeight: 'bold',
    fontSize: '20px',
    textAlign: 'center',
    border: '2px solid transparent',
    _hover: {
        textDecoration: 'none',
        border: '2px solid transparent',
        backgroundColor: 'transparent',
        color: 'brand.primary'
    }
});

const variantDatePicker = defineStyle({
    transition: '.25s all ease',
    boxSizing: 'border-box',
    minH: '46px',
    borderRadius: '16px',
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    border: '1px solid #DFDFDF',
    borderColor: '#DFDFDF',
    boxShadow: '0 3px 14px rgba(0, 0, 0, 0.08)',
    _focusVisible: {
        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
    },

    _focus: {
        boxShadow: 'none'
    },
    _active: {
        boxShadow: 'none'
    },
    _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed'
    }
});

const variantPill = defineStyle((props) => {
    const { colorScheme: c } = props;
    return {
        bg: 'transparent',
        fontWeight: 'bold',
        fontSize: '14px',
        textAlign: 'center',
        borderWidth: '1px',
        border: '1px solid',
        borderRadius: 'full',
        borderColor: '#3c4043',
        background: '#303135',
        color: "#f1f3f4",
        _hover: {
            color: '#d2e3fc',
            bg: '#88aabb0a',
            border: `1px`,
            borderColor: '#3c4043'
        },
    };
});

const variants = {
    outline: variantOutlined,
    solid: variantSolid,
    unstyled: variantUnstyled,
    underlined: variantUnderlined,
    icon: variantIcon,
    unstyledIcon: variantUnstyledIcon,
    datepicker: variantDatePicker,
    pill: variantPill
};

const xs = defineStyle({
    height: '32px',
    p: '14px 32px'
});

const sm = defineStyle({
    height: '36px',
    p: '16px 36px'
});

const md = defineStyle({
    height: '40px',
    p: '20px 40px'
});

const lg = defineStyle({
    height: '48px',
    p: '24px 48px'
});

const xl = defineStyle({
    height: '52px',
    p: '26px 52px'
});

const sizes = {
    xs,
    sm,
    md,
    lg,
    xl
};

export const button = defineStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        size: 'md',
        variant: 'unstyled',
        colorScheme: 'default'
    }
});
