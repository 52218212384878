import { useGetUserMeQuery, useUpdateUserMutation } from '@api';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Text
} from '@chakra-ui/react';
import { UPDATE_PASSWORD_LINK } from '@constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { HappyModal } from '@tasklogy/zircon-ui-components';
import { userValidator } from 'common/validators';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import LoadingStatus from 'src/blueprint/components/LoadingStatus';
import { z } from 'zod';
import { useAuth } from 'src/auth/useAuth';

type FormData = z.infer<typeof userValidator.update>;

export const PersonalData = () => {
    const { data, isLoading } = useGetUserMeQuery({});
    const [updateUser, updateUserMutation] = useUpdateUserMutation();
    const { refetchUser } = useAuth();
    const {
        register,
        formState: { errors },
        getValues
    } = useForm<FormData>({
        resolver: zodResolver(userValidator.update),
        values: {
            id: data?.id,
            firstName: data?.firstName,
            lastName: data?.lastName,
            phoneNumber: data?.phoneNumber ?? '',
            role: data?.role
        },
        mode: 'onBlur'
    });

    const [isSettingsCompleteModalOpen, setIsSettingsCompleteModalOpen] = useState(false);

    const saveData = async () => {
        const values = getValues();
        try {
            await updateUser(values).unwrap();
            refetchUser?.();

            toast.success('Changes have been successfully saved.');
        } catch (error) {
            toast.error('An unexpected error occurred while saving changes.');
        }
        setIsSettingsCompleteModalOpen(false);
    };
    return (
        <div>
            <LoadingStatus isLoading={isLoading}>
                <Text variant="title" mb="40px">
                    Personal data
                </Text>
                <Box>
                    <form>
                        <Box>
                            <Grid
                                w="50%"
                                templateColumns="repeat(8, 1fr)"
                                rowGap="40px"
                                columnGap="30px"
                                mb="40px"
                            >
                                <GridItem colSpan={4}>
                                    <FormControl
                                        isRequired
                                        isInvalid={Boolean(errors.firstName)}
                                    >
                                        <FormLabel>First name</FormLabel>
                                        <Input
                                            type="text"
                                            placeholder=""
                                            {...register('firstName')}
                                        />
                                        <FormErrorMessage>
                                            {errors.firstName?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={4}>
                                    <FormControl
                                        isRequired
                                        isInvalid={Boolean(errors.lastName)}
                                    >
                                        <FormLabel>Last name</FormLabel>
                                        <Input
                                            type="text"
                                            placeholder=""
                                            {...register('lastName')}
                                        />
                                        <FormErrorMessage>
                                            {errors.lastName?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={4}>
                                    <FormControl isInvalid={Boolean(errors.phoneNumber)}>
                                        <FormLabel>Phone number</FormLabel>
                                        <Input
                                            type="text"
                                            placeholder="+421"
                                            {...register('phoneNumber')}
                                        />
                                        <FormErrorMessage>
                                            {errors.phoneNumber?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={4}>
                                    <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input
                                            type="text"
                                            placeholder="sample@sample.com"
                                            value={data?.email ?? ''}
                                            isReadOnly
                                            isDisabled
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={8}>
                                    <Flex justifyContent={'end'}>
                                        <Button
                                            variant={'outline'}
                                            onClick={() => {
                                                window.open(
                                                    UPDATE_PASSWORD_LINK,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            Change password
                                        </Button>
                                    </Flex>
                                </GridItem>
                            </Grid>

                            <hr />

                            <Flex justifyContent="start" mt="3rem" gap="20px">
                                <Button
                                    isDisabled={Object.keys(errors).length > 0}
                                    variant="solid"
                                    isLoading={updateUserMutation.isLoading}
                                    onClick={() => setIsSettingsCompleteModalOpen(true)}
                                >
                                    Save changes
                                </Button>
                            </Flex>
                        </Box>
                    </form>
                </Box>
                <HappyModal
                    isOpen={isSettingsCompleteModalOpen}
                    onClose={() => setIsSettingsCompleteModalOpen(false)}
                >
                    <HappyModal.Title>Attention</HappyModal.Title>
                    <HappyModal.Body>
                        Are you sure you want to save the changes?
                    </HappyModal.Body>
                    <HappyModal.Footer>
                        <Button
                            variant="outline"
                            onClick={() => setIsSettingsCompleteModalOpen(false)}
                        >
                            No
                        </Button>
                        <Button variant="solid" onClick={() => saveData()}>
                            Yes
                        </Button>
                    </HappyModal.Footer>
                </HappyModal>
            </LoadingStatus>
        </div>
    );
};
