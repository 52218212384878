import {
    deleteComponent,
    selectActiveReportPage,
    setComponentConfig
} from 'src/redux/features/blueprint/bluePrintSlice';
import { useAppDispatch, useAppSelector } from './redux';

import { useUpdatePageComponentsMutation } from '@api';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { useToast } from '@chakra-ui/react';
import { Component } from 'common/types';

export const useDeleteComponent = () => {
    const dispatch = useAppDispatch();

    const activeReportPage = useAppSelector(selectActiveReportPage);
    const [updateReportPage] = useUpdatePageComponentsMutation();

    const toast = useToast();

    return async (id: string) => {
        assertIsDefined(activeReportPage);

        const currentComponent = activeReportPage.components[id];

        dispatch(deleteComponent({ id }));

        const newComponents: Record<string, Component> = {};

        Object.keys(activeReportPage.components).forEach((key) => {
            if (key !== id) {
                newComponents[key] = activeReportPage.components[key];
            }
        });

        try {
            await updateReportPage({
                id: activeReportPage.id,
                components: {
                    ...newComponents
                }
            }).unwrap();
        } catch (e) {
            dispatch(
                setComponentConfig({
                    id,
                    component: currentComponent
                })
            );

            toast({
                title: 'There was an error with saving',
                description: 'An error occurred while saving',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
        }
    };
};
