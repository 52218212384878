import { Box, Text } from '@chakra-ui/react';
import { ComponentUI } from '@types';
import { useAppSelector } from 'src/hooks/redux';
import { selectComponentData } from 'src/redux/features/blueprint/bluePrintSlice';
import { GenericTooltip } from 'src/templates/blueprint/organisms/GenericTooltip';
import { useDefaultData } from '../defaultData';
import { ComponentType } from 'common/enums';
import { formatGenericValue } from 'src/utils';

const SingleValue = ({ properties, id }: ComponentUI) => {
    const componentData = useAppSelector((state) => selectComponentData(state, id));
    const defaultData = useDefaultData(ComponentType.SINGLE_VALUE);

    let data = componentData?.data;
    if (componentData?.error) {
        data = defaultData;
    }

    const fieldConfig = data?.fieldConfigs?.[data?.valueKey];

    const labelStyle = {
        width: '100%',
        ...properties.label,
    }

    const valueStyle = {
        width: '100%',
        ...properties.value,
    }

    return (
        <Box height="100%" style={properties.cardStyle} data-testid={`single-value-${id}`}>
            {data && (
                <>
                    <GenericTooltip label={data.tooltip ?? properties.tooltip}>
                        <Text style={labelStyle}>{data.label}</Text>
                    </GenericTooltip>
                    <Text style={valueStyle}>
                        {formatGenericValue(data.value, data.type, fieldConfig)}
                    </Text>
                </>
            )}
        </Box>
    );
};

export default SingleValue;
