import {
    Button,
    Checkbox,
    Fade,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { CreatePickRow, DualStackTable, FormGrid, HappyModal, OutletTitle, useStepList } from '@tasklogy/zircon-ui-components';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from 'src/blueprint/components/CustomReactSelect';

interface IForm {
    title: string;
    url: string;
    passwordCheckbox: boolean;
    password: string;
}

const mockDualStackData = {
    rows: [
        {
            id: 1,
            dataSourceType: 'Google Analytics',
            accountName: 'Test account',
            accountId: '353-287-2999'
        },
        {
            id: 2,
            dataSourceType: 'Google Analytics',
            accountName: 'Test account',
            accountId: '353-287-2999'
        },
        {
            id: 3,
            dataSourceType: 'Google Analytics',
            accountName: 'Test account',
            accountId: '353-287-2999'
        },
        {
            id: 4,
            dataSourceType: 'Google Analytics',
            accountName: 'Test account',
            accountId: '353-287-2999'
        },
        {
            id: 5,
            dataSourceType: 'Google Analytics',
            accountName: 'Test account',
            accountId: '353-287-2999'
        }
    ],
    cols: [
        {
            title: 'Typ zdroja',
            accessor: 'dataSourceType'
        },
        {
            title: 'Názov účtu',
            accessor: 'accountName'
        },
        {
            title: 'Identifikátor účtu',
            accessor: 'accountId'
        }
    ]
};

const Step1: React.FC = () => {
    const { goToNextStep } = useStepList();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { register, control, watch } = useForm();
    return (
        <>
            <OutletTitle>
            Creating a report
            </OutletTitle>
            <FormGrid>
                <FormGrid.Item colSpan={6}>
                    <FormControl>
                        <FormLabel>Report name</FormLabel>
                        <Input
                            type="text"
                            placeholder="Sample report 123"
                            {...register('title', { required: true })}
                        />
                    </FormControl>
                </FormGrid.Item>
                <FormGrid.Item colSpan={6}>
                    <FormControl>
                        <FormLabel>Report URL</FormLabel>
                        <Input
                            type="text"
                            placeholder="www.test.report.com"
                            {...register('url', { required: true })}
                        />
                    </FormControl>
                </FormGrid.Item>
                <FormGrid.Item colSpan={4}>
                    <FormControl variant={'row'}>
                        <Controller
                            name="passwordCheckbox"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Checkbox
                                    isChecked={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        <FormLabel margin={0}>Set password</FormLabel>
                    </FormControl>
                    <Fade in={Boolean(watch('passwordCheckbox'))}>
                        <FormControl>
                            <FormLabel>Enter your password</FormLabel>
                            <Input type="text" placeholder="1234" />
                        </FormControl>
                    </Fade>
                </FormGrid.Item>
                <FormGrid.Item colSpan={12}>
                    <DualStackTable
                        rows={mockDualStackData.rows}
                        cols={mockDualStackData.cols}
                    >
                        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                            <GridItem colSpan={12}>
                                <FormLabel>Select data source</FormLabel>
                            </GridItem>
                            <GridItem
                                colSpan={6}
                                gap="1rem"
                                display={'flex'}
                                flexDir={'column'}
                            >
                                <FormControl>
                                    <CustomSelect
                                        properties={{
                                            controlStyle: {
                                                borderRadius: '7px',
                                                minHeight: '2.75rem',
                                                cursor: 'pointer',
                                                fontSize: '0.875rem'
                                            }
                                        }}
                                    />
                                </FormControl>
                                <DualStackTable.PickTable>
                                    <CreatePickRow onOpen={onOpen} />
                                    <HappyModal isOpen={isOpen} onClose={onClose}>
                                        <HappyModal.Title> </HappyModal.Title>
                                        <HappyModal.Body>
                                            <Text>
                                            Do you wish to switch to client editing?{' '}
                                                <br />
                                                Your changes will be lost.
                                            </Text>
                                        </HappyModal.Body>
                                        <HappyModal.Footer>
                                            <Button variant="outline" onClick={onClose}>
                                                No
                                            </Button>
                                            <Button
                                                variant="solid"
                                                onClick={() => goToNextStep()}
                                            >
                                                Yes
                                            </Button>
                                        </HappyModal.Footer>
                                    </HappyModal>
                                </DualStackTable.PickTable>
                            </GridItem>
                            <GridItem colSpan={6}>
                                <DualStackTable.DropTable />
                            </GridItem>
                        </Grid>
                    </DualStackTable>
                </FormGrid.Item>
            </FormGrid>
        </>
    );
};

export default Step1;
