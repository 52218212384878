import { Box, Flex, FormControl, FormErrorMessage, Grid, GridItem, Switch, Text } from '@chakra-ui/react';
import { SourceIcon } from '@tasklogy/zircon-ui-components';
import { DataSourceIdentifier } from 'common/enums';
import { ReportPage } from 'common/types';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ClientConnectedSource } from 'src/redux/types/api';
import { PathNames } from 'src/router/router';
import { Report } from 'src/redux/types/api';
import { is } from 'date-fns/locale';

interface SidebarProps {
    pages: ReportPage[];
    clientConnectedSources: ClientConnectedSource[];
    clientId: number;
    template: Report;
}

const Sidebar: React.FC<SidebarProps> = ({ pages, clientId, template }) => {
    const { formState, control, setValue, trigger } = useFormContext();
    const { errors } = formState;
    const formConnectedSources = useWatch({
        control,
        name: 'connectedSources'
    });
    const navigate = useNavigate();
    const params = useParams();

    // Remove connected sources from form when page is removed, if they are not used in any other page
    const handleChangeSelectedDataSources = (formPages: number[]) => {
        // Extract unique data sources from all pages
        const allPagesDataSources = [
            ...new Set(pages.flatMap((page) => page.usedSources))
        ];

        // Filter pages that are in formPages
        const selectedPages = pages.filter((page) => formPages.includes(page.id));

        // Extract unique data sources from selected pages
        const unionDataSources = [
            ...new Set(selectedPages.flatMap((page) => page.usedSources))
        ];

        // Map all data sources to an object indicating if they are selected
        const dataSources = allPagesDataSources.map(
            (dataSource: DataSourceIdentifier) => ({
                dataSource,
                isSelected: unionDataSources.includes(dataSource)
            })
        );

        // Filter selected data sources
        const selectedConnectedSources = dataSources
            .filter((cs) => cs.isSelected)
            .map((cs) => cs.dataSource);

        // Reduce form connected sources to only include selected connected sources
        const newConnectedSources = Object.keys(formConnectedSources).reduce(
            (acc: Record<string, number>, key: string) => {
                if (selectedConnectedSources.includes(key as DataSourceIdentifier)) {
                    acc[key] = formConnectedSources[key];
                }
                return acc;
            },
            {}
        );

        setValue('connectedSources', newConnectedSources, {
            shouldValidate: true
        });
    };

    return (
        <Box mr="1rem">
            <Text fontSize="lg" fontWeight="bold" mb="1rem">
                Select pages:
            </Text>
            <Controller
                control={control}
                name="pages"
                render={({ field: { value, onChange } }) => (
                    <Flex dir='column'>
                        <FormControl isInvalid={Boolean(errors.pages)} >
                                {pages?.map((page) => {
                                    const url = generatePath(
                                        PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW,
                                        {
                                            clientId: clientId.toString(),
                                            templateId: template.id.toString(),
                                            reportSlug: template.slug,
                                            viewSlug: page.slug
                                        }
                                    );

                                    const isPageViewed = params?.viewSlug === page.slug;

                                    const textColor = value?.includes(page.id) ? isPageViewed ? 'blue.500' : 'gray.600' : 'gray.200';

                                    return (
                                        <React.Fragment key={page.id}>
                                            <Flex w='100%' mb='1rem' justifyContent='space-between' filter={
                                                value?.includes(page.id) ? 'none' : 'grayscale(1)'
                                            }>
                                                <Flex flexDir='column'>
                                                    <Text
                                                        fontSize={['11px', '14px', '18px']}
                                                        cursor="pointer"
                                                        onClick={() => navigate(url)}
                                                        color={textColor}
                                                        fontWeight={isPageViewed ? 700 : 500}
                                                        flexWrap='nowrap'
                                                        fontFamily={'DM Sans'}
                                                        transition={'color 0.3s'}
                                                    >
                                                        {page.displayName}
                                                    </Text>
                                                    <SourceIcon
                                                        selectedIcons={page.usedSources}
                                                        asStack
                                                    />
                                                </Flex>
                                                <Switch
                                                    isChecked={value?.includes(page.id)}
                                                    colorScheme="blue"
                                                    onChange={() => {
                                                        if (value?.includes(page.id)) {
                                                            onChange(
                                                                value.filter(
                                                                    (id: number) => id !== page.id
                                                                )
                                                            );
                                                            handleChangeSelectedDataSources(
                                                                value.filter(
                                                                    (id: number) => id !== page.id
                                                                )
                                                            );
                                                        } else {
                                                            onChange([...value, page.id]);
                                                            trigger();
                                                        }
                                                    }}
                                                />
                                            </Flex>
                                        </React.Fragment>
                                    );
                                })}
                            <FormErrorMessage>
                                {errors.pages?.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>
                    </Flex>
                )
                }
            />
        </Box >
    );
};

export default Sidebar;
