import { Flex, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form"
import { MdOutlineVerticalAlignBottom, MdOutlineVerticalAlignCenter, MdOutlineVerticalAlignTop } from 'react-icons/md'
import { RxAlignCenterHorizontally, RxAlignLeft, RxAlignRight } from "react-icons/rx"
import { TbLayoutDistributeHorizontal, TbLayoutDistributeVertical } from 'react-icons/tb'
import IconsGroup from '../../IconsGroup'
import FormPropertyRow from "../layout/FormPropertyRow"
import FormPropertyTitle from "../layout/FormPropertyTitle"

const DonutLegendForm = <T extends FieldValues>({ objPath }: { objPath: string }) => {
    const methods = useFormContext<T>();
    const [currentAlign, setCurrentAlign] = React.useState<'left' | 'center' | 'right'>('left');
    const [currentLayout, setCurrentLayout] = React.useState<'horizontal' | 'vertical'>('horizontal');
    const [currentItemsAlign, setCurrentItemsAlign] = React.useState<'left' | 'mirror' | 'right'>('left');
    const [currentVerticalAlign, setCurrentVerticalAlign] = React.useState<'top' | 'middle' | 'bottom'>('top');
    
    useEffect(() => {
        const values = methods.getValues(objPath as Path<T>);
        setCurrentAlign(values.align || 'left');
        setCurrentLayout(values.layout || 'horizontal');
        setCurrentItemsAlign(values.itemsAlign || 'left');
        setCurrentVerticalAlign(values.verticalAlign || 'top');
    }, [methods, objPath])

    const handleChangeAlign = (align: 'left' | 'center' | 'right') => {
        setCurrentAlign(align);
        methods.setValue(`${objPath}.align` as Path<T>, align as PathValue<T, Path<T>>, { shouldDirty: true });
    }

    const handleChangeLayout = (layout: 'horizontal' | 'vertical') => {
        setCurrentLayout(layout);
        methods.setValue(`${objPath}.layout` as Path<T>, layout as PathValue<T, Path<T>>, { shouldDirty: true });
    }

    const handleChangeItemsAlign = (align: 'left' | 'mirror' | 'right') => {
        setCurrentItemsAlign(align);
        methods.setValue(`${objPath}.itemsAlign` as Path<T>, align as PathValue<T, Path<T>>, { shouldDirty: true });
    }

    const handleChangeVerticalAlign = (align: 'top' | 'middle' | 'bottom') => {
        setCurrentVerticalAlign(align);
        methods.setValue(`${objPath}.verticalAlign` as Path<T>, align as PathValue<T, Path<T>>, { shouldDirty: true });
    }

    const verticalAlignIcons: any[] = [
        {
            value: 'top',
            icon: MdOutlineVerticalAlignTop,
            onClick: () => handleChangeVerticalAlign('top'),
            label: 'Top',
            isActive: () => currentVerticalAlign === 'top'
        },
        {
            value: 'middle',
            icon: MdOutlineVerticalAlignCenter,
            onClick: () => handleChangeVerticalAlign('middle'),
            label: 'Middle',
            isActive: () => currentVerticalAlign === 'middle'
        },
        {
            value: 'bottom',
            icon: MdOutlineVerticalAlignBottom,
            onClick: () => handleChangeVerticalAlign('bottom'),
            label: 'Bottom',
            isActive: () => currentVerticalAlign === 'bottom'
        }
    ]

    const itemsAlignIcons: any[] = [
        {
            value: 'left',
            icon: RxAlignLeft,
            onClick: () => handleChangeItemsAlign('left'),
            label: 'Left',
            isActive: () => currentItemsAlign === 'left'
        },
        {
            value: 'mirror',
            icon: RxAlignCenterHorizontally,
            onClick: () => handleChangeItemsAlign('mirror'),
            label: 'Center',
            isActive: () => currentItemsAlign === 'mirror'
        },
        {
            value: 'right',
            icon: RxAlignRight,
            onClick: () => handleChangeItemsAlign('right'),
            label: 'Right',
            isActive: () => currentItemsAlign === 'right'
        }
    ]

    const layoutAlign = [
        {
            value: 'horizontal',
            icon: TbLayoutDistributeHorizontal,
            onClick: () => handleChangeLayout('horizontal'),
            label: 'Horizontal',
            isActive: () => currentLayout === 'horizontal'
        },
        {
            value: 'vertical',
            icon: TbLayoutDistributeVertical,
            onClick: () => handleChangeLayout('vertical'),
            label: 'Vertical',
            isActive: () => currentLayout === 'vertical'
        }
    ]

    const alignIcons: any[] = [
        {
            value: 'left',
            icon: RxAlignLeft,
            onClick: () => handleChangeAlign('left'),
            label: 'None',
            isActive: () => currentAlign === 'left'
        },
        {
            value: 'center',
            icon: RxAlignCenterHorizontally,
            onClick: () => handleChangeAlign('center'),
            label: 'Center',
            isActive: () => currentAlign === 'center'
        },
        {
            value: 'right',
            icon: RxAlignRight,
            onClick: () => handleChangeAlign('right'),
            label: 'Right',
            isActive: () => currentAlign === 'right'
        }
    ]

    return <Flex flexDir='column'>
        <Flex my={4}>
            <Text textStyle='_h2'>Legend</Text>
        </Flex>
        <FormPropertyRow>
            <Flex justifyContent='space-between' alignItems='center' w='100%'>
                <FormPropertyTitle title='Align' />
                <IconsGroup icons={alignIcons} />
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex justifyContent='space-between' alignItems='center' w='100%'><FormPropertyTitle title='Layout' />
                <IconsGroup icons={layoutAlign} />
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex justifyContent='space-between' alignItems='center' w='100%'><FormPropertyTitle title='Items align' />
                <IconsGroup icons={itemsAlignIcons} />
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex justifyContent='space-between' alignItems='center' w='100%'>
                <FormPropertyTitle title='Vertical align' />
                <IconsGroup icons={verticalAlignIcons} />
            </Flex>
        </FormPropertyRow>

    </Flex>
}

export default DonutLegendForm;