import {
    Badge,
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { useEffect } from 'react';

interface ShortcutProps {
    children: React.ReactNode;
}

enum Keys {
    CMD = 'Cmd',
    CTRL = 'Ctrl',
    ALT = 'Alt',
    SHIFT = 'Shift',
    SLASH = '/',
    ESC = 'Esc'
}

const Shortcut: React.FC<ShortcutProps> = ({ children }) => {
    return (
        <Text
            as="span"
            fontSize={16}
            fontWeight={600}
            px={2}
            mx={1}
            py={1}
            color="black"
            backgroundColor="white"
            height={8}
            borderRadius={4}
        >
            {children}
        </Text>
    );
};

const ShortcutsWithDescription: React.FC<{
    shortcuts: string[];
    description: string;
}> = ({ shortcuts, description }) => {
    return (
        <Flex alignItems="center">
            {shortcuts.map((shortcut, index) => (
                <Shortcut key={index}>{shortcut}</Shortcut>
            ))}
            <Text color="white" fontSize={16} ml={2}>
                {description}
            </Text>
        </Flex>
    );
};

const KeyboardShortcutsModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === '/' && e.metaKey) {
                onOpen();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [onOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
            <ModalOverlay />
            <ModalContent style={{ backgroundColor: 'rgb(32 32 32 / 70%)' }}>
                <ModalBody>
                    <Flex justifyContent="center" alignItems="center">
                        <Text color="white" fontSize={32} fontWeight={600}>
                            Keyboard Shortcuts
                        </Text>
                        <Flex ml={4}>
                            <Shortcut>{Keys.CMD}</Shortcut>
                            <Shortcut>{Keys.SLASH}</Shortcut>
                        </Flex>
                    </Flex>
                    <Flex my={4} justifyContent="space-between">
                        <Flex mt={2} gap={4} flexDirection="column">
                            <Text color="white" fontSize={24} fontWeight={600}>
                                General
                            </Text>
                            <ShortcutsWithDescription
                                shortcuts={[Keys.CMD, Keys.SLASH]}
                                description="Show keyboard shortcuts"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.CMD, Keys.SHIFT, 'D']}
                                description="Duplicate"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.SHIFT, 'Drag']}
                                description="Drag horizontally"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.SHIFT, 'Resize']}
                                description="Resize with aspect ratio"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.CMD, 'Select']}
                                description="Select multiple components"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.ESC]}
                                description="Deselect all"
                            />
                        </Flex>
                        <Flex mt={2} gap={4} flexDirection="column">
                            <Text color="white" fontSize={24} fontWeight={600}>
                                Objects Alignment
                            </Text>
                            <ShortcutsWithDescription
                                shortcuts={[Keys.ALT, 'a']}
                                description="Align components left"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.ALT, 'd']}
                                description="Align components right"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.ALT, 'w']}
                                description="Align components top"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.ALT, 's']}
                                description="Align components bottom"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.ALT, 'v']}
                                description="Align components middle"
                            />
                            <ShortcutsWithDescription
                                shortcuts={[Keys.ALT, 'h']}
                                description="Align components center"
                            />
                        </Flex>
                        <Flex mt={2} gap={4} flexDirection="column">
                            <Text color="white" fontSize={24} fontWeight={600}></Text>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default KeyboardShortcutsModal;
