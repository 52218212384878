/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BlueprintMultiSelectProperties } from '@types';
import type { MutableRefObject, ReactElement, RefAttributes } from 'react';
import { forwardRef, useRef } from 'react';
import type {
    GroupBase,
    MenuListProps,
    NoticeProps,
    Props,
    SelectInstance
} from 'react-select';
import ReactSelect, { components } from 'react-select';
import { JSX } from 'react/jsx-runtime';

export type CustomSelectProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> &
    RefAttributes<SelectInstance<Option, IsMulti, Group>> & {
        isCreatable?: boolean;
        isWhithCheckbox?: boolean;
        properties?: BlueprintMultiSelectProperties;
    };

export type SelectComponent = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: CustomSelectProps<Option, IsMulti, Group>
) => ReactElement;

interface Option1 {
    value: string;
    label: string;
}

interface CustomMenuListProps extends MenuListProps<Option1, true> {
    onNewMachineClick: (searchValue: string) => void;
}

const NoOptionsMessage = (
    props: JSX.IntrinsicAttributes & NoticeProps<unknown, boolean, GroupBase<unknown>>
) => {
    return (
        <components.NoOptionsMessage {...props}>
            No results
        </components.NoOptionsMessage>
    );
};

const CustomSelect = forwardRef(
    <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
        props: CustomSelectProps<Option, IsMulti, Group>,
        ref:
            | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
            | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
            | null
    ) => {
        const ref2 = useRef<any>();

        const customComponents = {
            NoOptionsMessage
        };

        return (
            <ReactSelect
                ref={ref ?? ref2}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderRadius: '16px',
                        minHeight: '2.75rem',
                        cursor: 'pointer',
                        fontSize: '0.875rem',
                        ...props.properties?.controlStyle
                    }),
                    menuList: (provided) => ({
                        ...provided,
                        padding: '0',
                        ...props.properties?.menuListStyle
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        fontSize: '0.875rem',
                        ...props.properties?.placeholderStyle
                    })
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore - customComponents
                components={customComponents}
                {...props}
            />
        );
    }
) as SelectComponent;

// @ts-expect-error - displayName
CustomSelect.displayName = 'CustomSelect';

export default CustomSelect;
