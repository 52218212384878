import { Button, Flex } from '@chakra-ui/react';
import { ClientIdParams, ReportSlugParams, ViewSlugParams } from '@router';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/redux';
import {
    selectActiveReport,
    selectActiveReportPageId
} from 'src/redux/features/blueprint/bluePrintSlice';
import { useEditorNavigation } from 'src/router/hooks';

const ReportViewsNavigation = () => {
    const goToEditor = useEditorNavigation();

    const params = useParams<ReportSlugParams & ViewSlugParams & ClientIdParams>();
    const activeReportPageId = useAppSelector(selectActiveReportPageId);
    const activeReport = useAppSelector(selectActiveReport);

    const handleNavigate = (pageSlug: string) => {
        goToEditor(params.clientId!, params.reportSlug as string, pageSlug);
    };
    return (
        <Flex mb="1rem" gap='1rem'>
            {activeReport?.pages?.map((page, index: number) => {
                return (
                    <Button
                        key={index}
                        variant={activeReportPageId === page.id ? 'solid' : 'outline'}
                        size="sm"
                        onClick={() => handleNavigate(page.slug)}
                    >
                        {page.displayName}
                    </Button>
                );
            })}
        </Flex>
    );
};

export default ReportViewsNavigation;
