import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

import { zodResolver } from '@hookform/resolvers/zod';
import { DualStackTable, StyledSelect } from '@tasklogy/zircon-ui-components';
import { teamValidator } from 'common/validators';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useListClientsQuery, useUpdateMemberMutation } from '@api';
import { useParams } from 'react-router-dom';
import { TeamIdParams } from '@router';
import { toast } from 'react-toastify';
import { roleDisplayNames, RoleName, teamRoles } from 'common/roles';
import { useMemo, useState } from 'react';
import { User } from 'src/redux/types/api';
import { useAuth } from 'src/auth/useAuth';

interface CreateMemberModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const updateMemberValidator = teamValidator.updateMember.omit({ id: true });

type EditmemberForm = z.infer<typeof updateMemberValidator>;

const clientsCols = [
    {
        Header: 'Client',
        accessor: 'name',
        title: 'Client'
    }
];

interface EditFormContentProps {
    onClose: () => void;
    user: User;
}

const EditFormContent = ({ onClose, user }: EditFormContentProps) => {
    const { user: currentUser, hasScopePermissions } = useAuth();
    const params = useParams<TeamIdParams>();
    const [updateMemebr, { isLoading }] = useUpdateMemberMutation();

    const { data: clients } = useListClientsQuery(
        {
            teamId: params?.teamId ? parseInt(params.teamId) : currentUser?.teamId ?? null
        },
        {
            skip: !currentUser?.teamId && !params.teamId
        }
    );
    const {
        control,
        register,
        formState: { errors },
        watch,
        handleSubmit
    } = useForm<EditmemberForm>({
        resolver: zodResolver(updateMemberValidator),
        mode: 'onBlur',
        defaultValues: {
            role: user.role,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            password: undefined
        }
    });

    const selectedRole = watch('role');

    const rolesOptions = useMemo(() => {
        const teamRoleOptions = teamRoles.map((role) => {
            const roleDisplayName = roleDisplayNames[role];
            return { label: roleDisplayName, value: role };
        });

        if (
            hasScopePermissions({
                global: ['manage-developers']
            })
        ) {
            teamRoleOptions.push({
                label: roleDisplayNames.developer,
                value: 'developer'
            });
        }

        return teamRoleOptions;
    }, [hasScopePermissions]);

    const [selectedClients, setSelectedClients] = useState<
        { id: number; name: string }[]
    >(user.assignedClients);

    const clientRows = useMemo(() => {
        const extractedData = clients?.map((client) => ({
            id: client.id,
            name: client.name
        }));

        const data = extractedData?.filter((client) => {
            const isAssigned = selectedClients?.some(
                (selectedClient) => selectedClient.id === client.id
            );
            return !isAssigned;
        });

        return data;
    }, [clients, selectedClients]);

    const handleSubmitForm = handleSubmit(async (values) => {
        const dataToSend = {
            ...values,
            assignedClients: selectedClients.map((team) => team.id),
            id: user.id
        };

        try {
            await updateMemebr(dataToSend).unwrap();
            toast.success('Member has been successfully updated.');
            onClose();
        } catch (error) {
            toast.error('An error occurred while updating the member.');
            console.error(error);
        }
    });

    return (
        <>
            <ModalHeader color="#525A66" fontWeight="bold">
                Update Team Member
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody mb="0.5rem">
                <form>
                    <Grid
                        templateColumns="repeat(12, 1fr)"
                        templateRows="repeat(3, auto)"
                        gap="1rem"
                    >
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.firstName)}
                                isRequired
                            >
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="text"
                                    {...register('firstName', {
                                        required: true,
                                        maxLength: 20
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.firstName?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.lastName)}
                                isRequired
                            >
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="text"
                                    {...register('lastName')}
                                />
                                <FormErrorMessage>
                                    {errors.lastName?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.phoneNumber)}
                            >
                                <FormLabel>Phone Number</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="tel"
                                    {...register('phoneNumber')}
                                />
                                <FormErrorMessage>
                                    {errors.phoneNumber?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl color="#525A66" mb="20px" isReadOnly>
                                <FormLabel>E-mail</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    isReadOnly
                                    isDisabled
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    value={user.email}
                                    type="email"
                                />
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={6}>
                            <Controller
                                control={control}
                                name="role"
                                rules={{
                                    required: true
                                }}
                                render={({ field: { value, onChange } }) => {
                                    const roleDisplayName = value
                                        ? roleDisplayNames[value as RoleName]
                                        : '';
                                    return (
                                        <FormControl
                                            color="#525A66"
                                            mb="20px"
                                            sx={{
                                                '.select__value-container': {
                                                    height: '50px'
                                                }
                                            }}
                                            isRequired
                                        >
                                            <FormLabel>Role</FormLabel>
                                            <StyledSelect
                                                options={rolesOptions}
                                                value={{
                                                    label: roleDisplayName,
                                                    value: value
                                                }}
                                                onChange={(val: any) =>
                                                    onChange(val.value)
                                                }
                                                classNamePrefix="select"
                                            />
                                        </FormControl>
                                    );
                                }}
                            />
                        </GridItem>
                        {selectedRole === 'team-employee' && clientRows && (
                            <GridItem colSpan={12}>
                                <GridItem colSpan={12}>
                                    <FormLabel>Assign clients</FormLabel>
                                </GridItem>
                                <DualStackTable
                                    rows={clientRows}
                                    cols={clientsCols}
                                    selectedRows={selectedClients}
                                >
                                    <Grid templateColumns="repeat(12, 1fr)">
                                        <GridItem colSpan={6}>
                                            <DualStackTable.PickTable
                                                onActionCb={(value) =>
                                                    setSelectedClients([
                                                        ...selectedClients,
                                                        {
                                                            id: value.id as number,
                                                            name: value.name
                                                        }
                                                    ])
                                                }
                                            />
                                        </GridItem>
                                        <GridItem colSpan={6}>
                                            <DualStackTable.DropTable
                                                overideCols={clientsCols}
                                                onActionCb={(value) => {
                                                    setSelectedClients(
                                                        selectedClients.filter(
                                                            (team) => team.id !== value.id
                                                        )
                                                    );
                                                }}
                                            />
                                        </GridItem>
                                    </Grid>
                                </DualStackTable>
                            </GridItem>
                        )}
                    </Grid>
                </form>
            </ModalBody>

            <ModalFooter>
                <Button
                    variant="solid"
                    isLoading={isLoading}
                    onClick={() => {
                        handleSubmitForm();
                    }}
                >
                    Save
                </Button>
            </ModalFooter>
        </>
    );
};

interface CreateMemberModalProps {
    isOpen: boolean;
    onClose: () => void;
    user: User;
}

const EditMemberModal: React.FC<CreateMemberModalProps> = ({
    isOpen,
    onClose,
    user
}: CreateMemberModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay backdropFilter="blur(10px)" backgroundColor="transparent" />
            <ModalContent
                boxShadow="0px 5.56px 29.19px 1.39px rgba(36, 99, 235, 0.05);"
                minWidth="50rem"
            >
                <EditFormContent onClose={onClose} user={user} />
            </ModalContent>
        </Modal>
    );
};

export default EditMemberModal;
