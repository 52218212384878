import {
    Button,
    Flex,
    Text,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalCloseButton,
    Modal,
    ModalContent,
    ModalOverlay,
    useDisclosure,
    Box
} from '@chakra-ui/react';

import { SourceAuth } from 'src/redux/types/api';
import { BaseTable, SourceIcon } from '@tasklogy/zircon-ui-components';

import QuestionMessage from '../QuestionMessage';

import { useDeleteSourceAuthMutation, useRefreshSourceAuthMutation } from '@api';
import { toast } from 'react-toastify';
import { DataSourceIdentifier, SourceAuthStatus } from 'common/enums';
import { createColumnHelper } from '@tanstack/react-table';
import { SourceAuthAdvertiser } from 'common/types';
import { useMemo, useState } from 'react';
import SearchInput from 'src/components/SearchComponent';

interface SourceAuthDetailModalContentProps {
    onClose: () => void;
    onDelete?: () => void;
    onReauth?: () => void;
    sourceAuth: SourceAuth;
}

const columnHelper = createColumnHelper<SourceAuthAdvertiser>();

type Texts = {
    parentAccounts: string;
    accounts: string;
    account: string;
    accountId: string;
};

const textsByAdvertiserId: Record<string, Texts> = {
    [DataSourceIdentifier.DV360]: {
        parentAccounts: 'Partner accounts',
        accounts: 'Accounts',
        account: 'Account name',
        accountId: 'Account ID'
    },
    [DataSourceIdentifier.GA4]: {
        parentAccounts: 'Accounts',
        accounts: 'Properties',
        account: 'Property name',
        accountId: 'Property ID'
    },
    [DataSourceIdentifier.FACEBOOK_INSIGHTS]: {
        parentAccounts: '',
        accounts: 'Pages',
        account: 'Page name',
        accountId: 'Page ID'
    }
};

type ParentAcconut = {
    parentName: string;
    parentId: string;
};

export function SourceAuthDetailModalContent({
    onClose,
    onDelete,
    sourceAuth,
    onReauth
}: SourceAuthDetailModalContentProps) {
    const firstWithParent = sourceAuth.advertisers?.find(
        (advertiser) => advertiser.parentAccountId
    );

    const [search, setSearch] = useState<string>('');

    const [selectedParentId, setSelectedParentId] = useState<string | null>(
        firstWithParent ? firstWithParent.parentAccountId : '-'
    );
    const accountDetails = sourceAuth.accountDetails;

    const parentAccounts = useMemo(() => {
        if (
            ![
                DataSourceIdentifier.DV360,
                DataSourceIdentifier.GA4,
                DataSourceIdentifier.GOOGLE_ADS
            ].includes(sourceAuth.source.identifier)
        ) {
            return null;
        }
        const parentAccountsMap = new Map<string, ParentAcconut>();

        sourceAuth.advertisers?.forEach((advertiser) => {
            if (advertiser.parentAccountId) {
                parentAccountsMap.set(advertiser.parentAccountId, {
                    parentName: advertiser.parentAccountName
                        ? advertiser.parentAccountName
                        : '-',
                    parentId: advertiser.parentAccountId
                });
            } else {
                parentAccountsMap.set('-', {
                    parentName: 'Others',
                    parentId: '-'
                });
            }
        });

        return [...Array.from(parentAccountsMap.values())];
    }, [sourceAuth.advertisers, sourceAuth.source.identifier]);

    const advertisers = useMemo(() => {
        let advertisers = sourceAuth.advertisers;
        if (parentAccounts) {
            advertisers = sourceAuth.advertisers?.filter((advertiser) => {
                if (selectedParentId === '-') {
                    return advertiser.parentAccountId === null;
                }
                return advertiser.parentAccountId === selectedParentId;
            });
        }

        if (search) {
            advertisers = advertisers?.filter((advertiser) =>
                advertiser.advertiserName?.toLowerCase().includes(search.toLowerCase())
            );
        }

        return advertisers;
    }, [sourceAuth.advertisers, parentAccounts, selectedParentId, search]);

    const columns = [
        columnHelper.accessor('advertiserName', {
            id: 'advertiserName',
            cell: (info) => <Text maxW="16rem">{info.getValue()}</Text>,
            header: () =>
                textsByAdvertiserId[sourceAuth.source.identifier]?.account ??
                'Account name'
        }),
        columnHelper.accessor('advertiserId', {
            id: 'advertiserId',
            cell: (info) => info.getValue(),
            header: () =>
                textsByAdvertiserId[sourceAuth.source.identifier]?.accountId ??
                'Account ID'
        }),
        columnHelper.accessor('isUsed', {
            id: 'isUsed',
            cell: (info) => (
                <Text as="span" color={info.getValue() ? '#1BC30C' : 'auto'}>
                    {info.getValue() ? 'Yes' : 'No'}
                </Text>
            ),
            header: () => 'Used'
        })
    ];

    return (
        <>
            <ModalBody>
                <Flex flexDir="column" mb="0.5rem">
                    {sourceAuth.status === SourceAuthStatus.NO_ACCESS && (
                        <Flex
                            fontSize="0.875rem"
                            justifyContent="left"
                            alignItems="center"
                            mb="0.5rem"
                            gap="0.25rem"
                        >
                            <Box
                                as="span"
                                width="8px"
                                height="8px"
                                borderRadius="50%"
                                bg="red.500"
                            />
                            <Text color="red.500" lineHeight="1rem">
                                This connection seems to be inactive. Try to
                                re-authenticate.
                            </Text>
                        </Flex>
                    )}
                    <Text
                        color="text.lightGray"
                        as="span"
                        fontWeight="bold"
                        fontSize="1rem"
                        mb="0.875rem"
                    >
                        <Text fontWeight="normal" color="text.lightGray" as="span">
                            Email:
                        </Text>{' '}
                        {accountDetails?.userInfo.email}
                    </Text>
                    {parentAccounts && (
                        <>
                            <Text fontWeight="bold" fontSize="18px" mb="0.25rem">
                                {
                                    textsByAdvertiserId[sourceAuth.source.identifier]
                                        ?.parentAccounts
                                }
                            </Text>
                            <Flex gap="0.5rem" mb="1rem" flexWrap="wrap">
                                {parentAccounts.map((parent) => (
                                    <Flex
                                        key={parent.parentId}
                                        as="span"
                                        backgroundColor={
                                            selectedParentId === parent.parentId
                                                ? 'default.50'
                                                : 'transparent'
                                        }
                                        py="0.25rem"
                                        px="0.375rem"
                                        justifyContent="center"
                                        alignItems="center"
                                        border="2px solid"
                                        borderColor="default.50"
                                        borderRadius="0.125rem"
                                        onClick={() =>
                                            setSelectedParentId(parent.parentId)
                                        }
                                        cursor="pointer"
                                    >
                                        <Text as="span" fontSize="0.675rem">
                                            {parent.parentName}
                                        </Text>
                                    </Flex>
                                ))}
                            </Flex>
                        </>
                    )}
                    <Flex justifyContent="space-between" alignItems="center" mb="0.5rem">
                        <Text fontWeight="bold" fontSize="16px">
                            {textsByAdvertiserId[sourceAuth.source.identifier]
                                ?.accounts ?? 'Accounts'}
                        </Text>
                        <SearchInput search={search} size="sm" setSearch={setSearch} />
                    </Flex>

                    <Box minH="21rem" maxH="21rem" overflowY="auto">
                        <BaseTable
                            withPagination={false}
                            chakraTableProps={{
                                size: 'sm',
                                variant: 'striped'
                            }}
                            headerProps={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                bg: 'white'
                            }}
                            columns={columns}
                            data={advertisers}
                        />
                    </Box>
                </Flex>
            </ModalBody>
            <ModalFooter>
                <Flex gap="1rem">
                    {sourceAuth.status === SourceAuthStatus.NO_ACCESS && (
                        <Button variant="solid" onClick={onReauth}>
                            Re-authenticate
                        </Button>
                    )}
                    {onDelete && (
                        <Button variant="solid" onClick={onDelete} colorScheme="error">
                            Remove connection
                        </Button>
                    )}
                    <Button variant="outline" onClick={onClose}>
                        Close
                    </Button>
                </Flex>
            </ModalFooter>
        </>
    );
}

interface ConfirmDeleteModalContentProps {
    onClose: () => void;
    onConfirm: () => Promise<void> | void;
    sourceAuth: SourceAuth;
}

function ConfirmDeleteModalContent({
    onClose,
    onConfirm,
    sourceAuth
}: ConfirmDeleteModalContentProps) {
    const isUsed = sourceAuth.advertisers?.some((advertiser) =>
        advertiser.isUsed ? true : false
    );

    const text = (
        <Text>
            {isUsed
                ? 'Some clients are using this connection. Are you sure you want to delete it? By deleting this connection, all the data from sources connected to this connection will be lost.'
                : 'Are you sure you want to delete this connection? By deleting this connection, all the data from sources connected to this connection will be lost.'}
        </Text>
    );

    return (
        <>
            <ModalHeader></ModalHeader>
            <ModalCloseButton onClick={onClose} />
            <ModalBody>
                <Flex mb="1rem" w="100%" justifyContent="center">
                    <QuestionMessage
                        text1={text}
                        onApprove={onConfirm}
                        onReject={onClose}
                        buttonApprovedVariant="solid"
                        buttonRejectVariant="outline"
                        buttonApproved="Delete"
                        buttonReject="Back"
                    />
                </Flex>
            </ModalBody>
        </>
    );
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onReauth?: () => void;
    sourceAuth: SourceAuth;
}

export default function SourceAuthDetailModal({
    isOpen,
    onClose,
    sourceAuth,
    onReauth
}: Props) {
    const {
        isOpen: isDeleteModalOpen,
        onOpen: openDeleteModal,
        onClose: closeDeleteModal
    } = useDisclosure();

    const [deleteSourceAuth] = useDeleteSourceAuthMutation();
    const [refreshSourceAuth, { isLoading }] = useRefreshSourceAuthMutation();

    const handleDeleteConfirm = async () => {
        try {
            await deleteSourceAuth({ id: sourceAuth.id }).unwrap();
            toast.success('Connection has been deleted successfully.');
            onClose();
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while deleting the connection.');
        }
    };

    const handleDeleteClick = async () => {
        openDeleteModal();
    };

    const handleRefreshClick = async () => {
        try {
            await refreshSourceAuth({ sourceAuthId: sourceAuth.id }).unwrap();
            toast.success('Connection has been refreshed successfully.');
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while refreshing the connection.');
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay backdropFilter="blur(10px)" backgroundColor="transparent" />
            <ModalContent
                boxShadow="0px 5.56px 29.19px 1.39px rgba(36, 99, 235, 0.05);"
                width={isDeleteModalOpen ? '35rem' : '40rem'}
                maxWidth="40rem"
            >
                {isDeleteModalOpen ? (
                    <ConfirmDeleteModalContent
                        onClose={closeDeleteModal}
                        onConfirm={handleDeleteConfirm}
                        sourceAuth={sourceAuth}
                    />
                ) : (
                    <>
                        <ModalHeader pb="0.25rem">
                            <Flex alignItems="center" gap="0.5rem">
                                <Text>{sourceAuth.source.displayName}</Text>
                                <SourceIcon
                                    selectedIcons={[sourceAuth.source.identifier]}
                                />
                                <Button
                                    size="xs"
                                    variant="solid"
                                    ml="1rem"
                                    isLoading={isLoading}
                                    onClick={handleRefreshClick}
                                >
                                    Refresh
                                </Button>
                            </Flex>

                            <ModalCloseButton onClick={onClose} />
                        </ModalHeader>
                        <SourceAuthDetailModalContent
                            sourceAuth={sourceAuth}
                            onClose={onClose}
                            onDelete={handleDeleteClick}
                            onReauth={onReauth}
                        />
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}
