import {
    Text,
    Icon,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useTheme,
    TableProps as ChakraTableProps,
    TableBodyProps,
    TableHeadProps,
    Box,
    TableContainer
} from '@chakra-ui/react';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    useReactTable
} from '@tanstack/react-table';
import { useId, useState } from 'react';
import { FaSortDown, FaSortUp } from 'react-icons/fa6';
import React from 'react';
import { Pagination } from './Pagination/Pagination';

export interface TableProps<T> {
    columns: ColumnDef<T>[];
    data: T[];
    restTableProps?: any;
    chakraTableProps?: ChakraTableProps;
    chakraTableBodyProps?: TableBodyProps;
    headerProps?: TableHeadProps;
    variant?: string;
    withPagination?: boolean;
}
function BaseTable<T>({
    columns,
    data,
    restTableProps,
    chakraTableProps,
    chakraTableBodyProps,
    headerProps,
    withPagination = true,
    variant = 'simple'
}: TableProps<T>): React.ReactElement {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    });

    const table = useReactTable({
        data: data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: withPagination ? getPaginationRowModel() : undefined,
        onPaginationChange: setPagination,
        ...restTableProps,
        state: {
            pagination
        },
        autoResetPageIndex: false
    });

    const id = useId();
    const chakraTheme = useTheme();

    const rows = table.getRowModel().rows;

    return (
        <Box>
            <TableContainer
                sx={{
                    '::-webkit-scrollbar': {
                        '-webkit-appearance': 'none'
                    },
                    '::-webkit-scrollbar:vertical': {
                        width: '12px'
                    },
                    '::-webkit-scrollbar:horizontal': {
                        height: '11px'
                    },
                    '::-webkit-scrollbar-thumb': {
                        borderRadius: '8px',
                        border: '2px solid white',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    '::-webkit-scrollbar-track': {
                        backgroundColor: '#fff',
                        borderRadius: '8px'
                    }
                }}
            >
                <Table variant={variant} {...chakraTableProps}>
                    <Thead {...headerProps}>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={`${id}-${headerGroup.id}`}>
                                {headerGroup.headers.map((header) => (
                                    <Th
                                        key={`${id}-${header.id}`}
                                        borderBottom="2px solid #E2E8F0"
                                        cursor="pointer"
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div
                                                className={
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : ''
                                                }
                                                onClick={header.column.getToggleSortingHandler()}
                                                title={
                                                    header.column.getCanSort()
                                                        ? header.column.getNextSortingOrder() ===
                                                          'asc'
                                                            ? 'Sort ascending'
                                                            : header.column.getNextSortingOrder() ===
                                                                'desc'
                                                              ? 'Sort descending'
                                                              : 'Clear sort'
                                                        : undefined
                                                }
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: (
                                                        <Icon
                                                            as={FaSortUp}
                                                            color={
                                                                chakraTheme.colors.brand
                                                                    .primary
                                                            }
                                                        />
                                                    ),
                                                    desc: (
                                                        <Icon
                                                            as={FaSortDown}
                                                            color={
                                                                chakraTheme.colors.brand
                                                                    .primary
                                                            }
                                                        />
                                                    )
                                                }[
                                                    header.column.getIsSorted() as string
                                                ] ?? null}
                                            </div>
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...chakraTableBodyProps}>
                        {rows.length ? (
                            rows.map((row) => (
                                <Tr key={`${id}-${row.id}`}>
                                    {row.getVisibleCells().map((cell) => (
                                        <Td
                                            key={`${id}-${cell.id}`}
                                            borderBottom="1px solid #E2E8F0"
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </Td>
                                    ))}
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan={columns.length} w="100%">
                                    <Text
                                        fontSize="1rem"
                                        textAlign="center"
                                        mt="1rem"
                                        color="navy.200"
                                    >
                                        No rows to display
                                    </Text>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
            {withPagination && (
                <Box mt="1.5rem" mr="0.5rem">
                    <Pagination table={table} />
                </Box>
            )}
        </Box>
    );
}

export default BaseTable;
