import {
    Badge,
    Box,
    Button,
    Fade,
    Flex,
    Icon,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ClientIdParams, ReportSlugParams, ViewSlugParams } from '@router';
import { useEffect, useState } from 'react';
import { MdModeEdit, MdOutlineCloudUpload } from 'react-icons/md';
import {
    generatePath,
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import ModalSaveDraftReport from 'src/blueprint/pages/CreateReport/SelectedTemplate/components/ModalSaveDraftReport';
import {
    selectActiveReport,
    selectActiveReportPage
} from 'src/redux/features/blueprint/bluePrintSlice';
import { PathNames } from 'src/router/router';
import { PageForm } from './Sidebar/PagesPopover/PageForm';
import { findNonEditorPathname } from './Sidebar/utils';

const TopBar = () => {
    const navigate = useNavigate();
    const params = useParams<ReportSlugParams & ViewSlugParams & ClientIdParams>();
    const activeReport = useAppSelector(selectActiveReport);
    const activeReportIsDraft = activeReport?.isDraft;
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const [inlineEdit, setInlineEdit] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const location = useLocation();
    const isPageUpdating = useAppSelector((state) => state.blueprint.isPageUpdating);

    const previewUrl =
        window.location.origin +
        generatePath(PathNames.LIVE_PREVIEW, {
            clientId: params.clientId!,
            reportSlug: params.reportSlug!,
            viewSlug: params?.viewSlug ?? null
        });

    const handleClickLivePreview = () => {
        window.open(previewUrl, '_blank');
    };

    const handleNavigateBack = () => {
        const nonEditorPathname = findNonEditorPathname(location);
        if (nonEditorPathname) {
            navigate(nonEditorPathname);
        } else {
            navigate('/');
        }
    };

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (isPageUpdating) {
            setShow(true);
        } else if (!isPageUpdating && show) {
            // Delay the hiding of the spinner
            const timer = setTimeout(() => {
                setShow(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isPageUpdating, show]);

    return (
        <Flex
            height="48px"
            borderBottom="1px solid rgba(226, 227, 228, 1)"
            justifyContent="space-between"
            px="1rem"
            py="0.5rem"
            width="100vw"
            bg="white"
        >
            <Flex alignItems="center" gap="1rem" w='100%'>
                <Button size="xs" variant="link" onClick={() => handleNavigateBack()}>
                    Back
                </Button>
                {activeReportIsDraft && (
                    <Badge
                        size="lg"
                        variant="solid"
                        colorScheme="orange"
                        textTransform="unset"
                    >
                        Drafts
                    </Badge>
                )}
                {inlineEdit && activeReportPage ? (
                    <PageForm
                        page={{
                            displayName: activeReportPage.displayName,
                            slug: activeReportPage.slug,
                            id: activeReportPage.id
                        }}
                        onSaveCb={() => setInlineEdit(false)}
                        formStyles={{ maxWidth: '300px' }}
                    />
                ) : (
                    <Flex alignItems='center' gap='0.5rem' justifyContent='space-between'>
                        <Text
                            _hover={{ cursor: 'edit' }}
                        >
                            / {activeReport?.title} / {activeReportPage?.displayName}
                        </Text>
                        <Icon as={MdModeEdit} color='gray.600' _hover={{ cursor: 'pointer' }} onClick={() => setInlineEdit(true)} mr='1rem' />
                    </Flex>
                )}
                {
                    <Fade
                        in={show}
                        transition={{
                            enter: { duration: 0.4, ease: [0.4, 0, 0.2, 1] }, // Custom fade-in duration and easing
                            exit: { duration: 1, ease: [0.4, 0, 0.2, 1] } // Custom fade-out duration and easing
                        }}
                    >
                        <Flex alignItems="center">
                            <Box mt="0.35rem">
                                <Icon
                                    as={MdOutlineCloudUpload}
                                    w={7}
                                    h={7}
                                    color="green.500"
                                />
                            </Box>
                            <Text ml="0.5rem" fontSize="sm" color="green.500">
                                Saved
                            </Text>
                        </Flex>
                    </Fade>
                }
            </Flex>
            <Flex alignItems="center" gap="1rem">
                {activeReportIsDraft ? (
                    <Button size="xs" variant="solid" onClick={onOpen}>
                        Save
                    </Button>
                ) : (
                    <Button
                        size="xs"
                        variant="outline"
                        onClick={() => handleClickLivePreview()}
                    >
                        Live Preview
                    </Button>
                )}
                <ModalSaveDraftReport isOpen={isOpen} onClose={onClose} />
            </Flex>
        </Flex>
    );
};

export default TopBar;
