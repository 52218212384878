import { useUpdateReportMutation } from '@api';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { reportValidator } from 'common/validators';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Report } from 'src/redux/types/api';
import { z } from 'zod';

type UpdateFormFields = z.infer<typeof reportValidator.update>;

interface EditFormProps {
    onClose: () => void;
    editedReport?: Report;
}

const EditForm: React.FC<EditFormProps> = ({ onClose, editedReport }) => {
    const [updateReportTemplate] = useUpdateReportMutation();

    const { register, formState, handleSubmit, getValues } = useForm<UpdateFormFields>({
        resolver: zodResolver(reportValidator.update),
        mode: 'onBlur',
        reValidateMode: 'onSubmit',
        defaultValues: {
            id: editedReport?.id,
            isTemplate: false,
            title: editedReport?.title,
            slug: editedReport?.slug,
            password: editedReport?.password,
            isActive: editedReport?.isActive
        }
    });
    const { errors } = formState;

    const onSubmit = async (data: UpdateFormFields) => {
        if (editedReport) {
            try {
                await updateReportTemplate({
                    ...data,
                    id: editedReport.id
                }).unwrap();
                toast.success('Report updated successfully');
                onClose();
            } catch (error) {
                toast.error('An error occurred while updating the report');
            }

            return;
        }
    };

    return (
        <Box height="100%">
            <Box pb="1rem">
                <Flex flexDir="column" gap={4}>
                    <FormControl isRequired isInvalid={Boolean(errors.title)}>
                        <FormLabel>Name</FormLabel>
                        <Input {...register('title')} isRequired placeholder="Name" />
                        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={Boolean(errors.slug)}>
                        <FormLabel>Slug</FormLabel>
                        <Input {...register('slug')} isRequired placeholder="Slug" />
                        <FormErrorMessage>{errors?.slug?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={Boolean(errors.password)}>
                        <FormLabel>Password</FormLabel>
                        <Input
                            {...register('password')}
                            isRequired
                            placeholder="Password"
                        />
                        <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.isActive)}>
                        <Checkbox {...register('isActive')}>Active</Checkbox>
                        <FormErrorMessage>{errors?.isActive?.message}</FormErrorMessage>
                    </FormControl>

                    <Flex gap={4} mt="1rem">
                        <Button
                            variant="solid"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save
                        </Button>
                        <Button variant="outline" onClick={() => onClose()}>
                            Cancel
                        </Button>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
};

export default EditForm;
