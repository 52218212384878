import { Box, Button, Flex, FormControl, FormErrorMessage, Image, InputProps, Popover, PopoverAnchor, PopoverBody, PopoverContent, Portal, useOutsideClick } from "@chakra-ui/react";
import { useRef } from "react";
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import { FieldError } from "react-hook-form";
import { MdOutlineDeleteForever } from "react-icons/md";
import transparentIcon from "src/assets/editor/transparent.png";
import { usePopoverContext } from "src/editor/contexts/PopoverProvider";

interface FormNumberInputProps extends InputProps {
    error: FieldError | undefined;
    colorPickerProps?: any;
    onDeleteColor?: () => void;
    id: string; // Add an ID prop to differentiate instances
}

const SquareColorPickerInput = ({ id, error, value, onChange, onDeleteColor }: FormNumberInputProps) => {
    const { openPopover, setOpenPopover } = usePopoverContext();
    const popoverRef = useRef<HTMLDivElement>(null);
    const isOpen = openPopover === id;

    const handleToggle = () => {
        setOpenPopover(isOpen ? null : id);
    };

    // Close the popover when clicking outside of it
    useOutsideClick({
        ref: popoverRef,
        handler: () => {
            if (openPopover === id) {
                setOpenPopover(null);
            }
        },
    });

    const hangleChange = (e: any) => {
        if (onChange) {
            onChange(e);
        }
    }

    const { rgbaArr } = useColorPicker(value as string, (newColor: string) => {
        hangleChange({
            target: {
                value: newColor
            }
        })
    });

    return (<Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={() => setOpenPopover(null)}
        placement='left'
        closeOnBlur={true}
        closeOnEsc={true}
    >   <PopoverAnchor>
            <FormControl h='100%' isInvalid={!!error?.message}>
                <Box onClick={handleToggle} backgroundColor={value as string} w='100%' h='100%' minH={'15px'} >
                    {rgbaArr[3] === 0 ?
                        <Image src={transparentIcon} objectFit='fill' w='100%' h='100%' minH={'15px'} />
                        :

                        <Box backgroundColor={value as string} w='100%' h='100%' minH={'15px'} border='1px solid #DFDFDF'
                            cursor='pointer' />
                    }
                </Box>
                <Portal>
                    <PopoverContent>
                        <PopoverBody ref={popoverRef}>
                            {onDeleteColor && <Flex w='100%' justifyContent='flex-end' mb='1rem'>
                                <Button onClick={() => onDeleteColor && onDeleteColor()} leftIcon={<MdOutlineDeleteForever />} colorScheme='error' size='xs' variant='solid'>
                                    Delete color
                                </Button>
                            </Flex>}
                            <ColorPicker hideColorTypeBtns value={(value as string) ?? 'black'} onChange={(newColor: any) => {
                                hangleChange({
                                    target: {
                                        value: newColor
                                    }
                                })
                            }}
                            />
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
                {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
            </FormControl>
        </PopoverAnchor>
    </Popover>
    );
}

export default SquareColorPickerInput;