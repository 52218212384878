import { Box, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { defaultTheme } from "common/constants";
import { styleValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import CardForm from "../../forms/CardForm";
import DonutLegendForm from "../../forms/DonutLegendForm";
import GradientForm from "../../forms/GradientForm";
import PalletForm from "../../forms/PalletForm";
import PositionForm from "../../forms/PositionForm";
import TextForm from "../../forms/TextForm";
import StyleDivider from "../../StyleDivider";


type FormData = z.infer<typeof styleValidator.donutChart>

const defaultValues: Pick<FormData, 'properties'> = defaultTheme.components.DONUT_CHART;

const DonutStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultValues,
        values: componentToEdit as any,
        resolver: zodResolver(styleValidator.donutChart),
        mode: 'onBlur',
    })

    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <StyleDivider />
                <FormAccordion>
                    <FormAccordionItem title="Component styles">
                        <Flex flexDir='column'>
                            <GradientForm<FormData> name={"properties.component.linearGradients"} title="Gradients" withLegendSymbolTextColor />
                            <PalletForm<FormData> name={"properties.component.pallet"} title="Pallet" />
                            <TextForm<FormData> objPath={'properties.labelStyle'} textTitle="Label" textPath="properties.component.label" />
                            <DonutLegendForm<FormData> objPath={'properties.component.legend'} />
                        </Flex>
                    </FormAccordionItem>
                </FormAccordion>
                <StyleDivider />
                <CardForm<FormData> objPath={"properties.cardStyle"} />
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={defaultValues} />
            </form>
        </FormProvider>
    </Box>
}

export default DonutStyle;