import React, { createContext, useContext, useState, ReactNode } from "react";

interface PopoverContextType {
    openPopover: string | null;
    setOpenPopover: (id: string | null) => void;
}

const PopoverContext = createContext<PopoverContextType | undefined>(undefined);

export const PopoverProvider = ({ children }: { children: ReactNode }) => {
    const [openPopover, setOpenPopover] = useState<string | null>(null);

    return (
        <PopoverContext.Provider value={{ openPopover, setOpenPopover }}>
            {children}
        </PopoverContext.Provider>
    );
};

export const usePopoverContext = () => {
    const context = useContext(PopoverContext);
    if (!context) {
        throw new Error("usePopoverContext must be used within a PopoverProvider");
    }
    return context;
};
