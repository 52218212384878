import { FieldValues, Path } from "react-hook-form"
import BorderForm from "../BorderForm"
import DropShadowForm from "../DropShadow"
import ColorForm from "../ColorForm"
import FormPropertyRow from "../layout/FormPropertyRow"
import { Flex, Text } from "@chakra-ui/react"
import PaddingForm from "../PaddingForm"

const CardForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    return <>
    <Flex my={4} alignItems='center' justifyContent={'space-between'}>
            <Text textStyle='_h1'>Card styles</Text>
        </Flex>
        <FormPropertyRow>
            <ColorForm<FormData> name={`${objPath}.background`} withGradient />
        </FormPropertyRow>
        <FormPropertyRow>
            <DropShadowForm objPath={objPath} />
        </FormPropertyRow>
        <FormPropertyRow>
            <BorderForm objPath={objPath} />
        </FormPropertyRow>
        <FormPropertyRow>
            <PaddingForm objPath={objPath} />
        </FormPropertyRow>
    </>
}

export default CardForm;