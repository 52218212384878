import { useCreateReportMutation } from '@api';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ClientIdParams } from '@router';

import { reportValidator } from 'common/validators';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { z } from 'zod';

type CreateFormFields = z.infer<typeof reportValidator.create>;

interface CreateFormProps {
    onClose: () => void;
}

const CreateForm: React.FC<CreateFormProps> = ({ onClose }) => {
    const [createReport] = useCreateReportMutation();
    const params = useParams<ClientIdParams>();

    const { register, formState, handleSubmit } = useForm<CreateFormFields>({
        resolver: zodResolver(reportValidator.create),
        mode: 'onBlur',
        reValidateMode: 'onSubmit',
        defaultValues: {
            isTemplate: false,
            title: '',
            slug: '',
            password: '',
            clientId: params.clientId ? Number.parseInt(params.clientId) : -1,
            isActive: false
        }
    });
    const { errors } = formState;

    const onSubmit = async (data: CreateFormFields) => {
        try {
            await createReport({
                ...data
            }).unwrap();
            toast.success('Report created successfully');
            onClose();
        } catch (error) {
            toast.error('An error occurred while creating the report');
        }

        return;
    };

    console.log(errors);

    return (
        <Box height="100%">
            <Box pb="1rem">
                <Flex flexDir="column" gap={4}>
                    <FormControl isRequired isInvalid={Boolean(errors.title)}>
                        <FormLabel>Name</FormLabel>
                        <Input {...register('title')} isRequired placeholder="Name" />
                        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={Boolean(errors.slug)}>
                        <FormLabel>Slug</FormLabel>
                        <Input {...register('slug')} isRequired placeholder="Slug" />
                        <FormErrorMessage>{errors?.slug?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={Boolean(errors.password)}>
                        <FormLabel>Password</FormLabel>
                        <Input
                            {...register('password')}
                            isRequired
                            placeholder="Password"
                        />
                        <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.isActive)}>
                        <Checkbox {...register('isActive')}>Active</Checkbox>
                        <FormErrorMessage>{errors?.isActive?.message}</FormErrorMessage>
                    </FormControl>

                    <Flex gap={4} mt="1rem">
                        <Button
                            variant="solid"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save
                        </Button>
                        <Button variant="outline" onClick={() => onClose()}>
                            Cancel
                        </Button>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
};

export default CreateForm;
