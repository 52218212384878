import { deleteProperty } from "dot-prop";
const defaultText = {
    properties: {
        style: {
            background: 'transparent',
            color: 'black',
            fontSize: '16px',
            fontWeight: 800,
            fontFamily: 'Arial',
            padding: '5px'
        },
        text: 'lorem ipsum dolor sit amet'
    }
};
export const copyTextStyles = (properties) => {
    const _properties = { ...properties };
    deleteProperty(_properties, 'text');
    return _properties;
};
export default defaultText;
