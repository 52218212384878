import { IconButton } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";


interface AddIconButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const AddIconButton: React.FC<AddIconButtonProps> = ({ onClick }) => {
    return <IconButton variant='ghost' onClick={onClick} aria-label="Add" icon={<BsPlusLg />} />
}

export default AddIconButton;