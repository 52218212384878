import {
    Button,
    Flex,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    Text
} from '@chakra-ui/react';

import { useModalStepList } from '../ModalStepList/ModalStepList';

import SourceAuthCard from './SourceAuthCard';
import { SourceAuth } from 'src/redux/types/api';

interface Props {
    sourceAuths: SourceAuth[];
    onAddNewSourceAuth?: () => void;
}

export default function SourceAuthListStep({ sourceAuths, onAddNewSourceAuth }: Props) {
    const { onClose, setState, goToNextStep } = useModalStepList();

    return (
        <>
            <ModalHeader
                mb="0.275rem"
                pb="0"
                fontSize="22px"
                color="#525A66"
                fontWeight="bold"
                display={'flex'}
                justifyContent="space-between"
                flex="1"
                alignItems={'center'}
            >
                <Flex gap="1rem" alignItems="center" mb="1rem">
                    <Text>Choose connection</Text>
                    <Button
                        variant="solid"
                        onClick={() => {
                            onAddNewSourceAuth?.();
                        }}
                    >
                        Add new connection
                    </Button>
                </Flex>
                <ModalCloseButton onClick={onClose} />
            </ModalHeader>
            <ModalBody>
                <Flex
                    gap="1rem"
                    flexWrap="wrap"
                    maxH="35rem"
                    p="0.3rem"
                    overflowY="scroll"
                    sx={{
                        scrollbarGutter: 'stable'
                    }}
                >
                    {sourceAuths?.length ? (
                        sourceAuths?.map((sourceAuth) => (
                            <SourceAuthCard
                                key={sourceAuth.id}
                                sourceAuth={sourceAuth}
                                onClick={() => {
                                    setState({ sourceAuth });
                                    goToNextStep();
                                }}
                            />
                        ))
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                            <Text>You do not have any resources connected yet.</Text>
                        </Flex>
                    )}
                </Flex>
            </ModalBody>
            <ModalFooter>
                <Flex gap={'1rem'}>
                    <Button variant="outline" onClick={onClose}>
                        Close
                    </Button>
                </Flex>
            </ModalFooter>
        </>
    );
}
