import { Flex, Input } from '@chakra-ui/react'
import { getProperty } from 'dot-prop'
import { Controller, FieldError, FieldValues, useFormContext } from "react-hook-form"
import ColorPickerInput from "../inputs/ColorPickerInput"
import DashArrayInput from '../inputs/DashArrayInput'
import OpacityInput from '../inputs/OpacityInput'
import FormPropertyRow from "../layout/FormPropertyRow"
import FormPropertyTitle from "../layout/FormPropertyTitle"
import FormTitle from "../layout/FormTitle"
import ReactSelect from 'react-select'
import { useId } from 'react'
import { StyledSelect } from '@tasklogy/zircon-ui-components'

const BACKGROUND_SIZES = [
    { value: 'cover', label: 'Cover' },
    { value: 'contain', label: 'Contain' },
]

const DEFAULT_LINEAR_GRADIENT = 'linear-gradient(180deg, RGBA(255, 255, 255, 0.18) 0%, rgba(2,0,36,1) 100%)';


const backgroundImageCSSExtractor = (background: string) => {
    if (!background) {
        return {
            gradient: '',
            url: '',
        };
    }

    const urlValueMatch = background.match(/url\((.*?)\)/);
    const urlValue = urlValueMatch ? urlValueMatch[1] : '';

    // Extract the gradient part by removing the URL part
    const gradient = background.replace(/,?\s*url\((.*?)\)/, '').trim();
    const gradientValue = gradient ? gradient : DEFAULT_LINEAR_GRADIENT;
    return {
        gradient: gradientValue,
        url: urlValue,
    };
};

const handleChangeUrl = (newUrl: string, backgroundImageValue?: string) => {
    if (!backgroundImageValue) {
        return `url(${newUrl})`;
    }
    const { gradient } = backgroundImageCSSExtractor(backgroundImageValue);
    return `${gradient}${gradient ? ', ' : ''}url(${newUrl})`;
};


const handleChangeGradient = (backgroundImageValue: string, newGradient: string) => {
    const { url } = backgroundImageCSSExtractor(backgroundImageValue);
    return `${newGradient}${newGradient ? ', ' : ''}url(${url})`;
};


const BackgroundImageForm = <T extends FieldValues>({ objPath }: { objPath: string }) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;
    const id = useId();

    return <Flex flexDir='column'>
        <Flex mt={4}>
            <FormTitle title="Background Image" />
        </Flex>
        <FormPropertyRow>
            <Flex gap={'1rem'} alignItems={'center'} width={'100%'}>
                <FormPropertyTitle title='Image URL' />
                <Controller
                    name={`${objPath}.backgroundImage`}
                    render={({ field }) => (
                        <Input variant='editor' value={backgroundImageCSSExtractor(field.value).url} type="text" onChange={(e) => {
                            const newUrl = e.target.value;
                            field.onChange({ target: { value: handleChangeUrl(newUrl, field.value) } });
                        }} />
                    )}
                />
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex gap={'1rem'} alignItems={'center'} width={'100%'}>
                <FormPropertyTitle title='Gradient' />
                <Controller
                    name={`${objPath}.backgroundImage`}
                    render={({ field }) => (
                        <ColorPickerInput id={id} value={backgroundImageCSSExtractor(field.value).gradient} onChange={(e) => {
                            const newGradient = e.target.value;
                            field.onChange({ target: { value: handleChangeGradient(field.value, newGradient) } });
                        }} error={getProperty(errors, `${objPath}.color`) as FieldError | undefined} width='150px' type="text" />
                    )}
                />
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex gap={'1rem'} alignItems={'center'}>
                <FormPropertyTitle title='Size' />
                <Controller
                    name={`${objPath}.backgroundSize`}
                    defaultValue={BACKGROUND_SIZES[0].value}
                    render={({ field }) => (
                        <StyledSelect
                            variant='editor'
                            value={BACKGROUND_SIZES.find(size => size.value === field.value)}
                            options={BACKGROUND_SIZES}
                            onChange={(option: any) => field.onChange({ target: { value: option.value } })}
                        />
                    )}
                />
            </Flex>
        </FormPropertyRow>
    </Flex>
}

export default BackgroundImageForm;