import { Flex } from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { StyledSelect } from '@tasklogy/zircon-ui-components';
import { DataComponent } from 'common/types';
import { compatibilityChecker } from 'common/utils';
import { useCallback, useMemo } from 'react';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import {
    selectComponentById,
    selectConnectedSources
} from 'src/redux/features/blueprint/bluePrintSlice';
import FormTitle from '../../layout/FormTitle';
import Control from './Control';
import Option from './Option';
import SelectDimensions, { DimensionValue } from './SelectDimensions';
import SelectMetrics, { MetricValue } from './SelectMetrics';
import { getWholeConfigs } from './utils';

const SetupForm = ({ withoutDimensions = false }: { withoutDimensions?: boolean }) => {
    const {
        state: { activeSelectedComponentId }
    } = useCanvas();

    const componentToEdit = useAppSelector(
        selectComponentById(activeSelectedComponentId)
    ) as DataComponent;
    const connectedSources = useAppSelector(selectConnectedSources);

    const connectedSourceOptions = useMemo(() => {
        return connectedSources?.map((cs) => ({
            value: cs,
            label: cs?.advertiser?.advertiserName
        }));
    }, [connectedSources, componentToEdit]);

    const connectedSource = connectedSources?.find(
        (ds) => ds.id === componentToEdit.connectedSourceId
    );
    const dataSourceDiemnsions = connectedSource?.dataSource?.dimensions;
    const dataSourcemetrics = connectedSource?.dataSource?.metrics;

    const memoizedValues = useMemo(() => {
        // Calculate selected metrics
        const selectedMetrics = getWholeConfigs(
            componentToEdit.metrics,
            dataSourcemetrics
        );

        // Calculate selected dimensions
        const selectedDimensions = getWholeConfigs(
            componentToEdit.dimensions,
            dataSourceDiemnsions
        );

        // Filter out not-used dimensions
        const notUsedDimensions = dataSourceDiemnsions?.filter(
            (ds) => !selectedDimensions?.find((selected) => selected.id === ds.id)
        );

        // Filter out not-used metrics
        const notUsedMetrics = dataSourcemetrics?.filter(
            (ds) => !selectedMetrics?.find((selected) => selected.id === ds.id)
        );

        // Check compatibility for dimensions and metrics
        const { compatibleDimensions, compatibleMetrics } =
            compatibilityChecker.getCompatibleMetricsAndDimensions({
                allDimensions: notUsedDimensions ? notUsedDimensions : [],
                allMetrics: notUsedMetrics ? notUsedMetrics : [],
                selectedMetrics: selectedMetrics ? selectedMetrics : [],
                selectedDimensions: selectedDimensions ? selectedDimensions : []
            });

        return {
            selectedMetrics,
            selectedDimensions,
            dimensionOptions: compatibleDimensions,
            metricOptions: compatibleMetrics
        };
    }, [componentToEdit, dataSourcemetrics, dataSourceDiemnsions]);

    const { selectedMetrics, selectedDimensions, dimensionOptions, metricOptions } =
        memoizedValues;

    const setComponentConfig = useSetComponentConfig();

    const handleMetricsChange = useCallback(
        async (values: MetricValue[]) => {
            const metrics = values.map((option) => ({
                id: option.id,
                customDisplayName: option.customDisplayName ?? null,
                customDecimalPlaces: option.customDecimalPlaces ?? null
            }));

            setComponentConfig({
                ...componentToEdit,
                metrics
            });
        },
        [componentToEdit, setComponentConfig]
    );

    const handleDimensionsChange = useCallback(
        (values: DimensionValue[]) => {
            const dimensions = values.map((option) => ({
                id: option.id,
                customDisplayName: option.customDisplayName ?? null,
                customDecimalPlaces: option.customDecimalPlaces ?? null
            }));

            setComponentConfig({
                ...componentToEdit,
                dimensions
            });
        },
        [componentToEdit, setComponentConfig]
    );

    return (
        <Flex flexDir="column" gap={2}>
            <Flex my={4} flexDir="column">
                <FormTitle title="Select Advertiser Account" />
                <StyledSelect
                    variant="editor"
                    isMulti={false}
                    onChange={async (target: any) => {
                        const updatedComponent = {
                            ...componentToEdit,
                            dimensionIds: [],
                            metricIds: [],
                            connectedSourceId: target.value.id
                        };

                        setComponentConfig(updatedComponent);
                    }}
                    options={connectedSourceOptions}
                    value={
                        connectedSource?.id
                            ? connectedSourceOptions?.find(
                                  (option) => option.value.id === connectedSource?.id
                              )
                            : null
                    }
                    components={{ Option, Control }}
                />
            </Flex>
            {!withoutDimensions && (
                <SelectDimensions
                    selectedDimensions={selectedDimensions}
                    dimensionOptions={dimensionOptions}
                    onChange={handleDimensionsChange}
                />
            )}
            <SelectMetrics
                metricOptions={metricOptions}
                selectedMetrics={selectedMetrics}
                onChange={handleMetricsChange}
            />
        </Flex>
    );
};

export default SetupForm;
