import { Box, Button, Card, Flex, useDisclosure } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { generatePath, useNavigate } from 'react-router-dom';

import { WithPermissionsAction } from '@auth';
import { BaseTable, HappyModal, TitleCell } from '@tasklogy/zircon-ui-components';
import { PathNames } from 'src/router/router';
import LoadingStatus from '../../LoadingStatus';
import { useListTeamsQuery } from '@api';
import { Team } from 'src/redux/types/api';
import { DateTime } from 'luxon';
import ActionCell from './ActionCell';

const columnHelper = createColumnHelper<Team>();

interface TeamsTableProps {
    searchValue: string;
}

const columns: ColumnDef<Team, any>[] = [
    columnHelper.accessor('name', {
        id: 'name',
        cell: (info) => {
            const navigate = useNavigate();
            return (
                <TitleCell
                    weight="bold"
                    title={info.getValue()}
                    onClick={() =>
                        navigate(
                            generatePath(PathNames.TEAM_DETAIL, {
                                teamId: info.row.original.id?.toString()
                            })
                        )
                    }
                />
            );
        },
        header: () => 'Team'
    }),
    columnHelper.accessor('businessName', {
        id: 'bussinessName',
        cell: (info) => {
            return info.getValue() ?? '-';
        },
        header: () => 'Business Name'
    }),

    columnHelper.accessor('users', {
        id: 'usersCount',
        cell: (info) => {
            return info.getValue()?.length ?? '-';
        },
        header: () => 'Users'
    }),
    columnHelper.accessor('clients', {
        id: 'clientsCount',
        cell: (info) => {
            return info.getValue()?.length ?? '-';
        },
        header: () => 'Clients'
    }),
    columnHelper.accessor('createdAt', {
        id: 'createdAt',
        cell: (info) => {
            return info.getValue()
                ? DateTime.fromISO(info.getValue()).toFormat('dd.MM.yyyy')
                : '-';
        },
        header: () => 'Created At'
    }),

    columnHelper.accessor('street', {
        id: 'action',
        cell: (info) => {
            return <ActionCell team={info.row.original} />;
        },
        header: () => ''
    })
];

const TeamsTable: React.FC<TeamsTableProps> = ({ searchValue }) => {
    const { isOpen, onClose } = useDisclosure();
    const { data, isLoading } = useListTeamsQuery(undefined);

    const teams = data?.filter((team) =>
        team.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    return (
        <LoadingStatus isLoading={isLoading}>
            <Box>
                <Card padding="1rem">
                    <BaseTable
                        variant="withoutBcg"
                        columns={columns}
                        data={teams ?? []}
                    />
                </Card>
                <HappyModal isOpen={isOpen} onClose={onClose}>
                    <HappyModal.Title>Attention</HappyModal.Title>
                    <HappyModal.Body>
                        You really want to delete the team
                        <br /> XXX?
                    </HappyModal.Body>
                    <HappyModal.Footer>
                        <Button variant="outline" onClick={onClose}>
                            No
                        </Button>
                        <Button variant="solid" onClick={onClose}>
                            Yes
                        </Button>
                    </HappyModal.Footer>
                </HappyModal>
            </Box>
        </LoadingStatus>
    );
};
export default TeamsTable;
