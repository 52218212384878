import { cardStyle } from "./common.js";
const defaultTable = {
    properties: {
        style: {},
        cell: {
            color: 'black',
            background: 'white'
        },
        headerStyle: {
            tr: {
                background: '#f4f7fe'
            }
        },
        headerCell: {
            color: '#2b3674'
        },
        idCell: {
            color: 'black',
            background: 'white'
        },
        bodyStyle: {
            td: {
                padding: ''
            },
            tr: {
                _odd: {
                    background: '#fff'
                },
                _even: {
                    background: '#f4f7fe'
                },
                borderBottom: '1px solid',
                borderBottomColor: '#f4f7fe'
            }
        },
        footerStyle: {
            td: {
                color: 'black'
            },
            tr: {
                background: '#f4f7fe'
            },
            cell: {
                color: 'black'
            }
        },
        component: {
            paginationLayout: 'bottom'
        },
        paginationButtonsStyle: {
            stack: {
                gap: '4px',
                height: '1rem',
                margin: '0 0 10px'
            },
            rowsCount: {
                color: 'black'
            },
            backButton: {
                _hover: {
                    backgroundColor: 'transparent'
                },
                backgroundColor: 'transparent'
            },
            forwardButton: {
                _hover: {
                    backgroundColor: 'transparent'
                },
                backgroundColor: 'transparent'
            },
            iconBackButton: {
                _hover: {
                    color: 'black'
                },
                color: 'black'
            },
            iconForwardButton: {
                _hover: {
                    color: 'black'
                },
                color: 'black'
            }
        },
        paginationRowsPerPageStyle: {
            labelStyle: {
                color: 'black'
            }
        },
        cardStyle: cardStyle
    }
};
export const copyTableStyles = (properties) => {
    return properties;
};
export default defaultTable;
