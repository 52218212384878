export function deepMerge(target: any, source: any) {
    const output = Object.assign({}, target);

    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target)) Object.assign(output, { [key]: source[key] });
                else output[key] = deepMerge(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }

    return output;
}

function isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

export const idsListToKey = (idsList: string[]) => {
    return idsList.join('-');
};

export const jsonStyleToChakraProps = (style: { [x: string]: any; '&:hover': any }) => {
    const { '&:hover': hoverStyle, ...restStyle } = style;
    return {
        style: restStyle,
        _hover: hoverStyle
    };
};