import {
    Img,
    Modal,
    ModalBody,
    ModalBodyProps,
    ModalCloseButton,
    ModalContent,
    ModalContentProps,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import React from 'react';
// @ts-ignore:next-line
import IconGroup from '../../assets/svg/icons-group.svg';

export interface HappyModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    showCloseButton?: boolean;
    footerStyles?: React.CSSProperties;
    modalBodyProps?: ModalBodyProps;
}

interface FooterProps {
    children: React.ReactNode;
}
interface TitleProps {
    children: React.ReactNode;
}
interface BodyProps {
    children: React.ReactNode;
}

const HappyModal: React.FC<HappyModalProps> & {
    Footer: React.FC<FooterProps>;
    Title: React.FC<TitleProps>;
    Body: React.FC<BodyProps>;
} = ({
    isOpen,
    onClose,
    children,
    showCloseButton = true,
    footerStyles,
    modalBodyProps
}) => {
    const footerChildren = React.Children.toArray(children).filter(
        (child) => React.isValidElement(child) && child.type === Footer
    );

    const titleChildren = React.Children.toArray(children).filter(
        (child) => React.isValidElement(child) && child.type === Title
    );

    const bodyChildren = React.Children.toArray(children).filter(
        (child) => React.isValidElement(child) && child.type === Body
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay
                backdropBlur="8px"
                bg="blackAlpha.300"
                backdropFilter="blur(8px)"
                zIndex={99999}
            />
            <ModalContent pt="1rem" px="2rem" pb="1.5rem" containerProps={{ zIndex: 99999 }} {...modalBodyProps}>
                <ModalHeader
                    p="0"
                    mb="0.375rem"
                    fontSize="20px"
                    color="#525A66"
                    fontWeight="bold"
                >
                    {titleChildren}
                </ModalHeader>
                {showCloseButton && <ModalCloseButton top="0.75rem" />}
                <ModalBody
                    px="0"
                    pt="1rem"
                    pb="2rem"
                    textAlign="center"
                    {...modalBodyProps}
                >
                    <Img src={IconGroup} ml="auto" mr="auto" mb="30px" mt="20px" />
                    {bodyChildren}
                </ModalBody>
                <ModalFooter
                    p="0"
                    justifyContent="center"
                    gap={'1rem'}
                    style={footerStyles}
                >
                    {footerChildren}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const Title: React.FC<TitleProps> = ({ children }) => {
    return <>{children}</>;
};

const Body: React.FC<BodyProps> = ({ children }) => {
    return <>{children}</>;
};

const Footer: React.FC<FooterProps> = ({ children }) => {
    return <>{children}</>;
};
HappyModal.Title = Title;
HappyModal.Body = Body;
HappyModal.Footer = Footer;

export default HappyModal;
