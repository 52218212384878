import { Flex, FormControl, FormErrorMessage, FormHelperText } from '@chakra-ui/react';
import { DataSourceIdentifier } from 'common/enums';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import DataSource from './DataSource';
import { ClientConnectedSource } from 'src/redux/types/api';

interface TemplateDataSourcesProps {
    dataSources: { dataSource: DataSourceIdentifier; isSelected: boolean }[];
    clientConnectedSources: ClientConnectedSource[];
}

const TemplateDataSources: React.FC<TemplateDataSourcesProps> = ({
    dataSources,
    clientConnectedSources
}) => {
    const { formState } = useFormContext();
    const { errors } = formState;
    const [focused, setFocused] = React.useState<DataSourceIdentifier | undefined>();

    useEffect(() => {
        if (dataSources) {
            const selectedConnectedSources = dataSources
                .filter((cs) => cs.isSelected)
                .map((cs) => cs.dataSource);
            if (focused && !selectedConnectedSources.includes(focused)) {
                setFocused(undefined);
            }
        }
    }, [dataSources]);

    return (
        <Flex flexDir="column" pb="1.5rem">
            <Flex gap="1rem" justifyContent="flex-end">
                {dataSources.map((ds, i) => (
                    <DataSource
                        key={i}
                        ds={ds}
                        clientConnectedSources={
                            clientConnectedSources
                                ? clientConnectedSources?.filter(
                                      (cs) => ds.dataSource === cs.sourceIdentifier
                                  )
                                : []
                        }
                        focusedSource={focused}
                        setFocused={setFocused}
                    />
                ))}
            </Flex>
            <Flex>
                <FormControl
                    isInvalid={Object.keys(errors?.connectedSources ?? {}).length > 0}
                >
                    <FormErrorMessage>
                    If you don't connect all data sources, the report's data will be incomplete.
                    </FormErrorMessage>
                </FormControl>
            </Flex>
        </Flex>
    );
};

export default TemplateDataSources;
