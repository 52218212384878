import AceEditor from 'react-ace';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
    selectActiveReport,
    setReportTheme
} from 'src/redux/features/blueprint/bluePrintSlice';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import { isJsonValid } from './utils';
import { useState } from 'react';
import { Box, Button, useToast } from '@chakra-ui/react';
import { useUpdateReportMutation } from '@api';
import { assertIsDefined } from 'src/templates/blueprint/utils';

const UIThemeEditor = () => {
    const dispatch = useAppDispatch();
    const activeReport = useAppSelector(selectActiveReport);
    const [value, setValue] = useState(JSON.stringify(activeReport?.uiTheme, null, 2));
    const toast = useToast();
    const [updateReport] = useUpdateReportMutation();

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    const handleClick = async () => {
        if (!isJsonValid(value)) {
            toast({
                title: 'There was an error with saving',
                description: 'Content is not valid JSON',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
            return;
        }
        const parsed = JSON.parse(value);

        const currentTheme = activeReport?.uiTheme;

        assertIsDefined(currentTheme);

        dispatch(setReportTheme(parsed));

        try {
            await updateReport({
                ...activeReport,
                uiTheme: parsed,
            }).unwrap();
        } catch (error) {
            dispatch(setReportTheme(currentTheme));
            toast({
                title: 'There was an error with saving',
                description: 'Content is not valid JSON',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
        }
    };
    return (
        <>
            {activeReport && (
                <Box position="relative">
                    <Button
                        position="absolute"
                        bottom="1rem"
                        right="1rem"
                        zIndex={10}
                        onClick={handleClick}
                        variant='solid'
                    >
                        Save
                    </Button>
                    <AceEditor
                        enableBasicAutocompletion={true}
                        highlightActiveLine={true}
                        enableLiveAutocompletion={true}
                        mode="json"
                        theme="tomorrow"
                        onChange={handleChange}
                        name="UIThemeEditor"
                        editorProps={{ $blockScrolling: true }}
                        value={value}
                        width="100%"
                        setOptions={{
                            useWorker: false
                        }}
                    />
                </Box>
            )}
        </>
    );
};

export default UIThemeEditor;
