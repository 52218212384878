import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StorageKey } from 'src/constants';
import { ErrorResponse } from 'src/redux/api';

export interface AuthState {
    sharedAuth: {
        isAuthenticated: boolean;
        loading: boolean;
        error?: string;
        reportSlugToken?: string;
        reportSlug?: string;
        reportTokens?: Record<string, string>;
    };
    blueprintAuth: {
        isAuthenticated: boolean;
        loading: boolean;
        error?: ErrorResponse;
        token?: string;
        user?: {
            id: number;
            email: string;
        };
    };
    token?: string;
}

const initialState: AuthState = {
    sharedAuth: {
        isAuthenticated: false,
        loading: false,
        error: undefined,
        reportSlug: undefined,
        reportTokens: {}
    },
    blueprintAuth: {
        isAuthenticated: false,
        loading: false,
        error: undefined
    },
    token: undefined
};

const isSharedReport = window.location.pathname.includes('shared-report');

const lazyInitialState = () => {
    if (isSharedReport) {
        const reportSlug: string =
            window.location.pathname.split('/')[
                '/shared-report/client/:clientId/:reportSlug/:viewSlug?'
                    .split('/')
                    .indexOf(':reportSlug')
            ];
        const clientId =
            window.location.pathname.split('/')[
                '/shared-report/client/:clientId/:reportSlug/:viewSlug?'
                    .split('/')
                    .indexOf(':clientId')
            ];
        const reportTokensFromLocalStorage = localStorage.getItem(
            StorageKey.TOKEN_BY_REPORT_NAME
        );
        const reportTokens = JSON.parse(reportTokensFromLocalStorage ?? '{}');
        return {
            ...initialState,
            sharedAuth: {
                ...initialState.sharedAuth,
                isAuthenticated: !!reportTokens?.[`${clientId}::${reportSlug}`],
                reportTokens: reportTokens
            },
            token: reportTokens[`${clientId}::${reportSlug}`]
        };
    } else {
        const accessToken = localStorage.getItem(StorageKey.ACCESS_TOKEN) ?? undefined;
        return {
            ...initialState,
            token: accessToken,
            blueprintAuth: {
                ...initialState.blueprintAuth,
                isAuthenticated: !!accessToken
            }
        };
    }
};

const authSlice = createSlice({
    name: 'auth',
    initialState: lazyInitialState(),
    reducers: {
        logout: (state) => {
            state.sharedAuth.isAuthenticated = false;
            state.sharedAuth.reportSlug = undefined;
            state.blueprintAuth.isAuthenticated = false;
            state.token = undefined;
        },
        setSharedAuth: (state, action: PayloadAction<any>) => {
            state.sharedAuth = { ...state.sharedAuth, ...action.payload };
            state.token = action.payload.reportSlugToken ?? state.token;
        },

        // BLUEPRINT AUTH
        setBlueprintToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload ?? state.token;
            state.blueprintAuth.isAuthenticated = true;
            state.sharedAuth.isAuthenticated = true; // TODO check if token is valid
        }
    }
});

export const selectBlueprintAuth = (state: { auth: AuthState }) => {
    return state.auth.blueprintAuth;
};

export const selectSharedAuth = (state: { auth: AuthState }) => {
    return state.auth.sharedAuth;
};

export const selectToken = (state: { auth: AuthState }) => {
    return state.auth.token;
};

export const { setBlueprintToken, logout, setSharedAuth } = authSlice.actions;

export default authSlice.reducer;
