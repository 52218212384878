import { WithPermissionsAction } from '@auth';
import { Button, Flex, Text } from '@chakra-ui/react';
import { ActionDelete, HappyModal } from '@tasklogy/zircon-ui-components';
import { useModal } from 'src/hooks/useModal';
import EditMemberModal from 'src/pages/Settings/EditMemberModal';
import { User } from 'src/redux/types/api';
import { useDeleteMemberMutation } from '@api';
import { toast } from 'react-toastify';
import { useAuth } from 'src/auth/useAuth';

interface Props {
    user: User;
}
export default function ActionCell({ user }: Props) {
    const { isOpen, open, close } = useModal();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal
    } = useModal();
    const { user: currentUser } = useAuth();
    const [deleteMemebr] = useDeleteMemberMutation();

    const canDeleteAndEdit = currentUser?.id !== user.id;

    const handleDelete = async () => {
        try {
            await deleteMemebr({ id: user.id }).unwrap();
            toast.success('User deleted successfully.');
        } catch (e) {
            toast.error('An error occurred while deleting user.');
            console.error(e);
        } finally {
            closeDeleteModal();
        }
    };

    return (
        <Flex
            alignItems="center"
            justifyContent="left"
            visibility={canDeleteAndEdit ? 'visible' : 'hidden'}
        >
            <WithPermissionsAction.EditTeamMember onOpen={open}>
                <></>
            </WithPermissionsAction.EditTeamMember>
            <ActionDelete onClick={openDeleteModal} />
            <EditMemberModal isOpen={isOpen} onClose={close} user={user} />
            <HappyModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                <HappyModal.Title>Attention</HappyModal.Title>
                <HappyModal.Body>
                    Are you sure you want to delete user
                    <Text as="span" fontWeight="bold">
                        {' '}
                        {user?.fullName}
                    </Text>
                    ?
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={closeDeleteModal}>
                        No
                    </Button>
                    <Button variant="solid" onClick={handleDelete}>
                        Yes
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
        </Flex>
    );
}
