import { useAppSelector } from "@hooks";
import { ComponentType } from "common/enums";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import AreaChartStyle from "src/editor/components/style/AreaChart";
import BiaxialBarChartStyle from "src/editor/components/style/BiaxialBarChart";
import DateRangeStyle from "src/editor/components/style/DateRange";
import DonutStyle from "src/editor/components/style/Donut";
import DownloadButtonStyle from "src/editor/components/style/DownloadButton";
import ImageStyle from "src/editor/components/style/Image";
import LineChartStyle from "src/editor/components/style/LineChart";
import MultiSelectStyle from "src/editor/components/style/MultiSelect";
import NavbarStyle from "src/editor/components/style/Navbar";
import NavigationButtonStyle from "src/editor/components/style/NavigationButton";
import PageStyle from "src/editor/components/style/Page";
import RectangleStyle from "src/editor/components/style/Rectangle";
import SingleSelectStyle from "src/editor/components/style/SingleSelect";
import SingleValueStyle from "src/editor/components/style/SingleValue";
import StackedBarChartStyle from "src/editor/components/style/StackedBarChart";
import TableStyle from "src/editor/components/style/Table";
import TableProgressBarChartStyle from "src/editor/components/style/TableProgressBarChart";
import TextStyle from "src/editor/components/style/Text";
import TinyBarChartStyle from "src/editor/components/style/TinyBarChart";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";

const ComponentStyle = {
    [ComponentType.RECTANGLE]: RectangleStyle,
    [ComponentType.TEXT]: TextStyle,
    [ComponentType.TABLE]: TableStyle,
    [ComponentType.DONUT_CHART]: DonutStyle,
    [ComponentType.PIE_CHART]: DonutStyle,
    [ComponentType.AREA_CHART]: AreaChartStyle,
    [ComponentType.LINE_CHART]: LineChartStyle,
    [ComponentType.BIAXIAL_BAR_CHART]: BiaxialBarChartStyle,
    [ComponentType.STACKED_BAR_CHART]: StackedBarChartStyle,
    [ComponentType.TINY_BAR_CHART]: TinyBarChartStyle,
    [ComponentType.TABLE_PROGRESS_BAR_CHART]: TableProgressBarChartStyle,
    [ComponentType.SINGLE_VALUE]: SingleValueStyle,
    [ComponentType.SINGLE_SELECT]: SingleSelectStyle,
    [ComponentType.MULTI_SELECT]: MultiSelectStyle,
    [ComponentType.DATE_RANGE]: DateRangeStyle,
    [ComponentType.DOWNLOAD_BUTTON]: DownloadButtonStyle,
    [ComponentType.NAVIGATION_BUTTON]: NavigationButtonStyle,
    [ComponentType.IMAGE]: ImageStyle,
    [ComponentType.LOGO]: ImageStyle,
    [ComponentType.NAVBAR]: NavbarStyle
}

const Style = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    if (!componentToEdit) return <PageStyle />;
    const ComponentStyleToRender = ComponentStyle[componentToEdit.type] || null;
    return <ComponentStyleToRender />
}

export default Style;