import {
    useCreateReportMutation,
    useListTeamsQuery,
    useUpdateReportMutation
} from '@api';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { StyledSelect } from '@tasklogy/zircon-ui-components';

import { ApiError } from 'common/types';
import { reportValidator } from 'common/validators';

import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Report } from 'src/redux/types/api';
import { z } from 'zod';

type CreateFormFields = z.infer<typeof reportValidator.templateCreate>;

interface CreateFormProps {
    onClose: () => void;
    editedTemplate?: Report;
}

const CreateForm: React.FC<CreateFormProps> = ({ onClose, editedTemplate }) => {
    const [createReportTemplate] = useCreateReportMutation();
    const [updateReportTemplate] = useUpdateReportMutation();
    const { data: teams } = useListTeamsQuery(undefined);
    const [isPublic, setIsPublic] = useState<boolean>(
        editedTemplate ? !editedTemplate?.templateTeamId : false
    );

    const { register, formState, control, handleSubmit, setValue, watch, getValues } =
        useForm<CreateFormFields>({
            resolver: zodResolver(reportValidator.templateCreate),
            mode: 'onBlur',
            reValidateMode: 'onSubmit',
            defaultValues: {
                isTemplate: true,
                teamId: editedTemplate?.templateTeamId,
                templatePublished: editedTemplate?.templatePublished,
                title: editedTemplate?.title,
                slug: editedTemplate?.slug,
                password: editedTemplate?.password
            }
        });
    const { isValid, errors } = formState;

    const onSubmit = async (data: CreateFormFields) => {
        if (editedTemplate) {
            try {
                await updateReportTemplate({
                    id: editedTemplate.id,
                    ...data
                }).unwrap();
                toast.success('Template updated successfully');
                onClose();
            } catch (error) {
                toast.error('An error occurred while updating the template');
            }

            return;
        }

        try {
            await createReportTemplate(data).unwrap();
            toast.success('Template created successfully');
            onClose();
        } catch (error) {
            const message = (error as ApiError)?.data?.message?.includes(
                'duplicate key value violates unique constraint'
            )
                ? 'Tewmplate with this slug already exists'
                : 'An error occurred while creating the template';
            toast.error(message);
        }
    };

    const teamOptions = useMemo(() => {
        return (
            teams?.map((team) => ({
                label: team.name,
                value: team.id
            })) ?? []
        );
    }, [teams]);

    const selectedteamId = watch('teamId');

    const selectedTeam = selectedteamId
        ? teamOptions?.find((option) => option.value === selectedteamId)
        : null;

    return (
        <Box height="100%">
            <Box pb="1rem">
                <Flex flexDir="column" gap={4}>
                    <form id="template-create" onSubmit={handleSubmit(onSubmit)}></form>
                    <FormControl isInvalid={Boolean(errors.title)}>
                        <FormLabel>Name</FormLabel>
                        <Input {...register('title')} isRequired placeholder="Name" />
                        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.slug)}>
                        <FormLabel>Slug</FormLabel>
                        <Input {...register('slug')} isRequired placeholder="Slug" />
                        <FormErrorMessage>{errors?.slug?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.password)}>
                        <FormLabel>Password</FormLabel>
                        <Input
                            {...register('password')}
                            isRequired
                            placeholder="Password"
                        />
                        <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl>
                        <Checkbox {...register('templatePublished')}>Published</Checkbox>
                    </FormControl>

                    <Box>
                        <Checkbox
                            scale={1.5}
                            isChecked={isPublic}
                            onChange={({ target }) => {
                                setValue('teamId', null);
                                setIsPublic(target.checked);
                            }}
                        >
                            Available for all teams
                        </Checkbox>
                    </Box>

                    <Controller
                        control={control}
                        name="teamId"
                        render={({ field }) => {
                            return (
                                <StyledSelect
                                    placeholder="Select team"
                                    isDisabled={isPublic}
                                    onChange={(option: any) => {
                                        field.onChange(option.value);
                                    }}
                                    value={selectedTeam}
                                    options={teamOptions}
                                />
                            );
                        }}
                    />

                    <Flex gap={4} mt="1rem">
                        <Button
                            variant="solid"
                            type="submit"
                            isDisabled={!isValid}
                            form="template-create"
                        >
                            {editedTemplate ? 'Save' : 'Create'}
                        </Button>
                        <Button variant="outline" onClick={() => onClose()}>
                            Cancel
                        </Button>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
};

export default CreateForm;
