import { IconButton } from "@chakra-ui/react";
import { FiEdit3 } from "react-icons/fi";

export interface ActionEditProps {
  onClick: () => void;
}

const ActionEdit: React.FC<ActionEditProps> = ({ onClick }) => {
  return (
    <IconButton
      variant="icon"
      color="icons.edit"
      h={['1.5rem', '2rem', '2.5rem', '3rem', '3.5rem']}
      w={['1.5rem', '2rem', '2.5rem', '3rem', '3.5rem']}
      onClick={onClick}
      icon={<FiEdit3 />}
      aria-label={"edit"}
    />
  );
};

export default ActionEdit;
