import { useModalStepList } from '../../ModalStepList/ModalStepList';
import { SourceAuthAttemptStatus } from 'common/enums';
import { AuthInProgress } from '../AuthInProgress';
import { SourceAuth } from 'src/redux/types/api';
import { StepKeys } from '../enums';

interface Props {
    onSuccess?: () => void;
    withAccountSelection?: boolean;
}

export const AuthInProgressStep = ({ onSuccess, withAccountSelection }: Props) => {
    const { setStepByKey, goToPreviousStep, state, setState } = useModalStepList();

    const handleCancelAuth = () => {
        goToPreviousStep();
    };

    const handleOnPoll = (data: {
        status: SourceAuthAttemptStatus;
        sourceAuth: SourceAuth;
    }) => {
        setState({
            ...state,
            status: data.status,
            sourceAuth: data.sourceAuth
        });
    };

    return (
        <AuthInProgress
            onCancel={handleCancelAuth}
            onPoll={handleOnPoll}
            onSuccess={() => {
                onSuccess?.();
                if (withAccountSelection) {
                    setStepByKey(StepKeys.SelectAccount);
                } else {
                    setStepByKey(StepKeys.SourceAuthDetail);
                }
            }}
            attemptId={state.attemptId}
        />
    );
};
