import {
    selectActiveReportPage,
    selectPageThemeComponents
} from 'src/redux/features/blueprint/bluePrintSlice';
import { useAppSelector } from './redux';

import { ComponentType } from 'common/enums';
import { generateRandomId } from 'common/utils';
import { reportPageValidator } from 'common/validators';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { z } from 'zod';
import { deepMerge } from 'src/utils/theme';
import { useSetComponentConfig } from './useSetComponentConfig';
import { useCallback } from 'react';

export function createComponent(type: ComponentType) {
    const base = {
        id: generateRandomId(),
        type,
        x: 0,
        y: 0,
        w: 200,
        h: 200,
        zIndex: 1,
        properties: {
            style: {}
        }
    };

    if (type === ComponentType.TEXT) {
        return {
            ...base,
            w: 160,
            h: 40,
            properties: {
                style: {},
                text: 'Lorem ipsum dolor sit amet'
            }
        };
    }

    if (type === ComponentType.AREA_CHART) {
        return {
            ...base,
            w: 700,
            h: 200,
            properties: {
                style: {},
                embedded: {
                    title: null
                },
                formatType: 'PERCENTAGE',
                cartesianGrid: {
                    stroke: '#E7E7E7',
                    opacity: 1,
                    strokeDasharray: '3 3'
                }
            }
        };
    }

    if (type === ComponentType.BIAXIAL_BAR_CHART) {
        return {
            ...base,
            w: 700,
            h: 100,
            properties: {
                style: {},
                embedded: {
                    title: null
                },
                formatType: 'PERCENTAGE',
                cartesianGrid: {
                    stroke: '#E7E7E7',
                    opacity: 1,
                    strokeDasharray: '3 3'
                }
            }
        };
    }

    if (type === ComponentType.IMAGE || type === ComponentType.LOGO) {
        return {
            ...base,
            w: 200,
            h: 200,
            properties: {
                style: {},
                src: 'https://storage.googleapis.com/reportingo-dev/image_placeholder.png',
                alt: 'placeholder'
            }
        };
    }

    if (type === ComponentType.NAVIGATION_BUTTON) {
        return {
            ...base,
            w: 100,
            h: 40,
            properties: {
                style: {},
                text: 'Navigation text.'
            }
        };
    }

    if (type === ComponentType.NAVBAR) {
        return {
            ...base,
            w: 200,
            h: 300,
            properties: {
                style: {}
            }
        };
    }

    if (type === ComponentType.SINGLE_VALUE) {
        return {
            ...base,
            w: 180,
            h: 100,
            properties: {
                style: {},
                text: 'Navigation text.'
            }
        };
    }

    if (type === ComponentType.SINGLE_SELECT) {
        return {
            ...base,
            w: 180,
            h: 55,
            zIndex: 50, // Single select should be above other components
            properties: {
                style: {}
            },
            dimensionId: null,
            connectedSourceId: null,
            placeholder: 'Select'
        };
    }

    if (type === ComponentType.MULTI_SELECT) {
        return {
            ...base,
            w: 180,
            h: 55,
            zIndex: 50, // Multi select should be above other components
            properties: {
                style: {}
            },
            dimensionId: null,
            connectedSourceId: null,
            placeholder: 'Select'
        };
    }
    if (type === ComponentType.DATE_RANGE) {
        return {
            ...base,
            w: 180,
            h: 60,
            zIndex: 50 // DateRange select should be above other components
        };
    }

    return base;
}

export const useCreateComponent = () => {
    const setComponentConfig = useSetComponentConfig();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const pageThemeComponents = useAppSelector(selectPageThemeComponents);

    return useCallback(
        async (type: ComponentType, x?: number, y?: number) => {
            // @ts-expect-error - we know that theme comppnnet exists
            const pageThemeComponent = pageThemeComponents[type];

            assertIsDefined(activeReportPage);
            const highestZIndex = Object.keys(activeReportPage.components ?? []).length;

            // Create a new component of the specified type
            let component = createComponent(type);

            // Calculate the zIndex for the new component
            // Use the maximum value between the component's zIndex and the highest zIndex found
            const zIndex = Math.max(component.zIndex, highestZIndex);

            const xPosition = x ?? 0;
            const yPosition = y ?? 0;

            // Update the component with the calculated zIndex
            component = { ...component, zIndex, x: xPosition, y: yPosition };

            const properties = pageThemeComponent?.properties
                ? deepMerge(pageThemeComponent?.properties, component?.properties ?? {})
                : component?.properties;

            component = {
                ...component,
                properties: {
                    ...properties,
                    ...component.properties,
                    style: {
                        ...properties.style,
                        ...component.properties.style
                    }
                }
            };

            try {
                const validated = reportPageValidator.component.parse(component);
                await setComponentConfig(validated);
                return validated;
            } catch (err) {
                if (err instanceof z.ZodError) {
                    console.log(err.issues);
                }
            }
        },
        [setComponentConfig, activeReportPage, pageThemeComponents]
    );
};
