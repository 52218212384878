import { Collapse, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { getProperty } from "dot-prop";
import React, { ReactElement, useId } from "react";
import { Controller, FieldError, FieldValues, Path, PathValue, useFormContext } from "react-hook-form";
import { parsePx, toPx } from "src/templates/blueprint/utils";
import FormNumberInput from "../inputs/Number";
import FormPropertyRow from "../layout/FormPropertyRow";
import ColorPickerInput from "../inputs/ColorPickerInput";

enum FormType {
    ALL = 'all',
    MIXED = 'mixed'
}

interface CollapseFormConfig {
    title: string;
    typePath: string;
    all: {
        keyPath: string;
        defaultValue: any;
        icon: ReactElement<any, any>
        max?: number
    },
    mixed: {
        keyPath: string;
        defaultValue: any;
        icon: ReactElement<any, any>
        max?: number
    }[],
    mixedIcon: ReactElement<any, any>
}

interface CollapseFormProps<T> {
    objPath: Path<T>,
    config: CollapseFormConfig
    as?: typeof FormNumberInput | typeof ColorPickerInput
}

const CollapseForm = <T extends FieldValues>({ objPath, config, as: InputComponent = FormNumberInput }: CollapseFormProps<T>) => {
    const methods = useFormContext<T>();
    const [currentType, setCurrentType] = React.useState<FormType>(FormType.ALL);
    const { errors } = methods.formState;
    const id = useId();

    React.useEffect(() => {
        const formType = methods.getValues(`${objPath}.${config.typePath}` as Path<T>);
        if (formType) {
            setCurrentType(formType);
        } else {
            setCurrentType(FormType.ALL);
        }

    }, [methods])

    const handleSetMixedType = () => {
        const formType = currentType === FormType.ALL ? FormType.MIXED : FormType.ALL;
        const formValue = methods.getValues(`${objPath}.${config.mixed[0].keyPath}` as Path<T>);
        setCurrentType(formType);
        methods.setValue(`${objPath}.${config.typePath}` as Path<T>, formType as PathValue<T, Path<T>>);
        config.mixed.forEach(({ keyPath }) => {
            methods.setValue(`${objPath}.${keyPath}` as Path<T>, formValue, { shouldDirty: true });
        });
    }

    const handleAllChange = (value: PathValue<T, Path<T>>) => {
        config.mixed.forEach(({ keyPath }) => {
            methods.setValue(`${objPath}.${keyPath}` as Path<T>, value, { shouldDirty: true });
        })
    }


    return <Flex direction='column'>
        <Flex mt={4} alignItems='center' gap='1rem'>
            <Text textStyle='_h3'>{config.title}</Text>
        </Flex>
        <FormPropertyRow gap='1rem' justifyContent='space-between'>
            <Controller
                name={`${objPath}.${config.mixed[0].keyPath}` as Path<T>}
                control={methods.control}
                defaultValue={config.all.defaultValue}
                render={({ field }) =>
                    <>
                        {InputComponent === FormNumberInput ? (

                            <InputComponent id={`${id}.${objPath}.${config.all.keyPath}`} isDisabled={currentType === FormType.MIXED} min={0} max={config.all.max} icon={config.all.icon} value={parsePx(field.value)} onChange={(e) => {
                                const value = toPx(e.target.value) as PathValue<T, Path<T>>;
                                handleAllChange(value);
                            }} error={getProperty(errors, `${objPath}.${config.all.keyPath}`) as FieldError | undefined} type="number" />
                        ) :
                            (
                                <ColorPickerInput value={field.value} onChange={(e) => handleAllChange(e.target.value as PathValue<T, Path<T>>)} id={`${id}.${objPath}.${config.all.keyPath}`} isDisabled={currentType === FormType.MIXED} colorPickerProps={{ hideColorTypeBtns: true }} error={getProperty(errors, `${objPath}.${config.all.keyPath}`) as FieldError | undefined} type="text" />
                            )
                        }
                    </>


                }
            />
            <Tooltip label="Mixed" aria-label={`Set mixed ${config.title}`}>
                <IconButton variant='editor' onClick={() => handleSetMixedType()} color={currentType === 'all' ? 'gray' : 'blue'} aria-label={`Mixed ${config.title}`} icon={config.mixedIcon} />
            </Tooltip>
        </FormPropertyRow>
        <Collapse in={currentType === FormType.MIXED} animateOpacity>
            <FormPropertyRow display='grid' gridTemplateColumns='1fr 1fr' gap='1rem'>
                {config.mixed.map(({ keyPath, defaultValue, icon, max }) => (
                    <Controller
                        key={keyPath}
                        name={`${objPath}.${keyPath}` as Path<T>}
                        control={methods.control}
                        defaultValue={defaultValue}
                        render={({ field }) =>
                            <>
                                {InputComponent === FormNumberInput ? (
                                    <InputComponent id={`${id}.${objPath}.${keyPath}`} min={0} max={max} icon={icon} value={parsePx(field.value)} onChange={(e) => field.onChange({ target: { value: toPx(e.target.value) } })} error={getProperty(errors, `${objPath}.${keyPath}`) as FieldError | undefined} type="number" />
                                ) : (
                                    <ColorPickerInput id={`${id}.${objPath}.${keyPath}`} colorPickerProps={{ hideColorTypeBtns: true }} {...field} error={getProperty(errors, `${objPath}.${keyPath}`) as FieldError | undefined} type="text" />
                                )}
                            </>
                        }
                    />
                ))}
            </FormPropertyRow>
        </Collapse>

    </Flex>

}

export default CollapseForm;