import { Box, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';
import { MdQuestionMark } from 'react-icons/md';

export const GenericTooltip = ({
    children,
    label
}: {
    children: PropsWithChildren<ReactNode>;
    label?: string;
}) => {
    return (
        <Tooltip
            label={label}
            fontSize="md"
            placement="top"
            alignItems={'center'}
            hasArrow
            shouldWrapChildren
        >
            <Flex alignItems="center" width={'100%'}>
                {children}
                {label && (
                    <Box
                        as="span"
                        borderRadius="50%"
                        width={'12px'}
                        height={'16px'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        ml={1}
                    >
                        <Icon boxSize="10px" as={MdQuestionMark} />
                    </Box>
                )}
            </Flex>
        </Tooltip>
    );
};
