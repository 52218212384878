import { useUpdateClientMutation } from '@api';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { clientValidator } from 'common/validators';
import { useForm } from 'react-hook-form';

import { toast } from 'react-toastify';
import { Client } from 'src/redux/types/api';
import { z } from 'zod';

interface CreateClientModalProps {
    isOpen: boolean;
    onClose: () => void;
    client: Client;
}

const validator = clientValidator.update.omit({ id: true });

type FormData = z.infer<typeof validator>;

const UpdateClientModal: React.FC<CreateClientModalProps> = ({
    isOpen,
    onClose,
    client
}) => {
    const [updateClient, { isLoading }] = useUpdateClientMutation();

    const {
        register,
        formState: { errors, isValid },

        handleSubmit
    } = useForm<FormData>({
        resolver: zodResolver(validator),
        mode: 'onChange',
        defaultValues: {
            name: client.name
        }
    });

    const handleCreateClient = handleSubmit(async (values) => {
        try {
            await updateClient({
                id: client.id,
                name: values.name
            }).unwrap();

            toast.success('Client was updated successfully');
            onClose();
        } catch (error) {
            toast.error('Failed to update client');
        }
    });

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleCreateClient}>
                    <ModalHeader>Update client</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl isInvalid={Boolean(errors.name)}>
                            <FormLabel>Client name</FormLabel>
                            <Input
                                type="text"
                                placeholder="Client name"
                                {...register('name', { required: true })}
                            />
                            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant={'outline'} mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            isDisabled={!isValid}
                            isLoading={isLoading}
                            type="submit"
                            variant="solid"
                        >
                            Update client
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default UpdateClientModal;
