import { useUpdateReportPageMutation } from "@api";
import {
    Divider,
    IconButton,
    Input,
    InputGroup,
    InputRightElement
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ClientIdParams, ReportSlugParams, ViewSlugParams } from "@router";
import { reportPageValidator } from "common/validators";
import { CSSProperties } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { MdOutlineCancel, MdOutlineCheck } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEditorNavigation } from "src/router/hooks";
import { TPage } from "./types";
import { slugify } from "./utils";

export interface PageFormProps {
    page: TPage;
    onSaveCb: () => void;
    formStyles?: CSSProperties
}

const hoverStyle = { border: '1px solid', borderColor: 'transparent', borderRadius: '5px' }

export const PageForm = ({ page, onSaveCb, formStyles }: PageFormProps) => {
    const params = useParams<ReportSlugParams & ViewSlugParams & ClientIdParams>();
    const [updatePage] = useUpdateReportPageMutation();
    const goToEditor = useEditorNavigation();
    const methods = useForm({
        values: page,
        resolver: zodResolver(reportPageValidator.update),
        mode: 'onBlur',
    });

    const onSubmit = async () => {
        const formState = methods.formState;
        const { dirtyFields } = formState;
        if (!dirtyFields.displayName) {
            onSaveCb();
            return;
        }
        const values = methods.getValues();
        const slug = slugify(values.displayName) + '-' + page.id;
        try {
            await updatePage({
                displayName: values.displayName,
                slug: slug,
                id: page.id
            }).unwrap();
            goToEditor(params.clientId!, params.reportSlug as string, slug);
        } catch (error) {
            toast.error('Failed to update page');
        } finally {
            onSaveCb()
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%', ...formStyles }}>
                <Controller control={methods.control} name='displayName' render={({ field }) => (
                    <InputGroup size='xs' alignItems='center'>
                        <Input variant={'editor'} px='0.5rem' {...field} autoComplete="false" placeholder="" boxShadow='none' />
                        <InputRightElement width='80px' height='100%'>
                            <IconButton variant='editor' border='1px solid transparent' maxH='100%' px={0} _hover={{ ...hoverStyle, color: 'red.500' }} aria-label='Cancel' onClick={() => onSaveCb()} icon={<MdOutlineCancel />} />
                            <Divider orientation='vertical' />
                            <IconButton variant='editor' border='1px solid transparent' maxH='100%' _hover={{ ...hoverStyle, color: 'green.500' }} type='submit' aria-label='Save' icon={<MdOutlineCheck />} />
                        </InputRightElement>
                    </InputGroup>
                )} />
                <input hidden type="submit" />
            </form>
        </FormProvider>
    );
};