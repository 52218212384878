import { IWindowInfoContext } from '@faceless-ui/window-info/dist/WindowInfoProvider/context';
import { dataComponentTypes, filterComponentTypes } from 'common/enums';
import { ReportPage } from 'common/types';
import { addDays, endOfYesterday } from 'date-fns';

type GetInitDateRangeArgs = {
    minDateString: string | null;
    maxDateString: string | null;
    startDateFromUrl?: Date;
    endDateFromUrl?: Date;
};
export const getInitDateRange = ({
    minDateString,
    maxDateString,
    endDateFromUrl,
    startDateFromUrl
}: GetInitDateRangeArgs) => {
    const minDate = minDateString ? new Date(minDateString) : new Date('2010-01-01');
    let endDate = maxDateString ? new Date(maxDateString) : endOfYesterday();
    let startDate = addDays(endDate, -29);

    // Override with URL dates if they are within the valid range
    if (startDateFromUrl && endDateFromUrl) {
        if (!minDate || startDateFromUrl > minDate) {
            startDate = startDateFromUrl;
        }

        if (minDate && startDateFromUrl < minDate) {
            startDate = minDate;
        }

        if (endDateFromUrl < endDate) {
            endDate = endDateFromUrl;
        }
    }

    return { startDate, endDate };
};

export const linearGradientAngle2Coords = (inputAngle: number) => {
    function pointOfAngle(a: number) {
        return { x: Math.cos(a), y: Math.sin(a) };
    }

    function degreesToRadians(d: number) {
        return (d * Math.PI) / 180;
    }

    let eps = Math.pow(2, -52);
    let angle = inputAngle % 360;
    let startPoint: { x: number; y: number } = pointOfAngle(
        degreesToRadians(180 - angle)
    );
    let endPoint: { x: number; y: number } = pointOfAngle(degreesToRadians(360 - angle));

    // if you want negative values you can remove the following checks
    // but most likely it will produce undesired results
    if (startPoint.x <= 0 || Math.abs(startPoint.x) <= eps) startPoint.x = 0;

    if (startPoint.y <= 0 || Math.abs(startPoint.y) <= eps) startPoint.y = 0;

    if (endPoint.x <= 0 || Math.abs(endPoint.x) <= eps) endPoint.x = 0;

    if (endPoint.y <= 0 || Math.abs(endPoint.y) <= eps) endPoint.y = 0;

    return { startPoint, endPoint };
};

export function assertIsDefined<T>(
    value: T | undefined | null,
    errorMessage: string = 'Value must be defined'
): asserts value is T {
    if (value === undefined || value === null) {
        throw new Error(errorMessage);
    }
}

export function iterate(components: ReportPage['components']) {
    return Object.values(components);
}

export function getDataComponents(components: ReportPage['components']) {
    return iterate(components).filter((component) =>
        dataComponentTypes.includes(component.type)
    );
}

export function getFilterComponents(components: ReportPage['components']) {
    return iterate(components).filter((component) =>
        filterComponentTypes.includes(component.type)
    );
}

export const resizeHandleClasses = {
    bottom: 'handle long-handle-horizontal bottom-handle',
    bottomLeft: 'handle left-handle bottom-handle',
    bottomRight: 'handle right-handle bottom-handle',
    left: 'handle long-handle left-handle',
    right: 'handle long-handle right-handle',
    top: 'handle long-handle-horizontal top-handle',
    topLeft: 'handle left-handle top-handle',
    topRight: 'handle right-handle top-handle'
};

export const useDefalut = (value?: any, defaultValue?: any) => {
    return value !== undefined ? value : defaultValue;
};

export const parsePx = (value: any): number | string => {
    if (!value) {
        return '';
    }
    if (typeof value === 'number') {
        return value;
    }
    if (typeof value === 'string') {
        if (value.includes('px')) {
            return (parseFloat(value.replace('px', ''))).toFixed(0);
        } else if (value.includes('rem')) {
            return (parseFloat(value.replace('rem', '')) * 16).toFixed(0);
        } else if (value.includes('em')) {
            return (parseFloat(value.replace('em', '')) * 16).toFixed(0);
        }
    }
    return '';
};

export const toPx = (value: any): string => {
    if (!value) {
        return '';
    }
    if (typeof value === 'number') {
        return `${value}px`;
    }
    if (typeof value === 'string') {
        if (value.match(/^-?\d*\.?\d*px$/)) {
            return value;
        }
        if (value.match(/^-?\d*\.?\d*$/)) {
            return `${value}px`;
        }
    }
    return '0px';
};

export const parseViewportHeight = (windowInfo: IWindowInfoContext) => {
    return parseFloat(windowInfo['--vh'].replace('px', '')) * 100;
};

export const parseViewportWidth = (windowInfo: IWindowInfoContext) => {
    return parseFloat(windowInfo['--vw'].replace('px', '')) * 100;
};

export const isSfari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome');
};
