import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const editorVariant = defineStyle(() => {
    return {
        zIndex: 100000,
    }
  })

const variants = {
    editor: editorVariant
};

export const tooltipTheme = defineStyleConfig({
    variants
});
