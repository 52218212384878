import { Flex, VStack } from '@chakra-ui/react';
import { ColumnFormatType } from 'common/enums';
import { DimensionConfigWithId, MetricConfigWithId } from 'common/types';
import React, { memo, useMemo } from 'react';

import DnDElements from 'src/blueprint/components/DnDElements/DnDElements';
import FormTitle from '../../layout/FormTitle';

export interface MetricValue {
    id: string;
    value: string | number;
    label: string;
    formatType: ColumnFormatType;
    customDisplayName?: string | null;
    customDecimalPlaces?: number | null;
}

interface SelectMetricsProps {
    selectedMetrics: DimensionConfigWithId[];
    metricOptions: MetricConfigWithId[];
    onChange?: (values: MetricValue[]) => void;
}

const dndElementStyle = {
    style: {
        backgroundColor: '#BBDEFB'
    },
    hoverStyle: {
        backgroundColor: '#90CAF9'
    }
};

const SelectMetrics: React.FC<SelectMetricsProps> = memo(
    ({ metricOptions, selectedMetrics, onChange }) => {
        const values = useMemo(() => {
            return (
                selectedMetrics?.map((ds) => {
                    return {
                        id: ds.id,
                        label: ds.defaultDisplayName,
                        value: ds.id,
                        formatType: ds.formatType,
                        customDisplayName: ds.customDisplayName,
                        customDecimalPlaces: ds.customDecimalPlaces
                    };
                }) ?? []
            );
        }, [selectedMetrics]);

        const options = useMemo(() => {
            return (
                metricOptions?.map((ds: MetricConfigWithId) => ({
                    id: ds.id,
                    label: ds.defaultDisplayName,
                    value: ds.id,
                    formatType: ds.formatType,
                    customDisplayName: ds.customDisplayName,
                    customDecimalPlaces: ds.customDecimalPlaces
                })) ?? []
            );
        }, [metricOptions]);

        const memoizedChildren = useMemo(
            () => (
                <>
                    <Flex flexDir={'column'}>
                        <DnDElements.Values />
                    </Flex>
                    <DnDElements.Options triggerButtonText="Add metric" />
                </>
            ),
            []
        );

        return (
            <Flex w="100%" flexDir="column">
                <FormTitle title="Select metrics" />
                <DnDElements
                    values={values}
                    options={options}
                    onValuesChange={onChange}
                    elementStyle={dndElementStyle}
                >
                    {memoizedChildren}
                </DnDElements>
            </Flex>
        );
    }
);

export default SelectMetrics;
