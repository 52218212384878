import { Flex, ModalBody } from '@chakra-ui/react';
import { useModalStepList } from '../../ModalStepList/ModalStepList';
import { useGetSourceAuthUriMutation } from '@api';

import { toast } from 'react-toastify';
import { StepKeys } from '../enums';
import { useParams } from 'react-router-dom';
import { ClientIdParams } from 'src/router/types';

const buttonStyle = {
    backgroundColor: '#1877F2',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease'
};

export default function LoginWithFacebook() {
    const { state, setState, setStepByKey } = useModalStepList();
    const [getSourceAuthUri] = useGetSourceAuthUriMutation();
    const params = useParams<ClientIdParams>();

    const onClick = async () => {
        try {
            const response = await getSourceAuthUri({
                clientId: +params.clientId!,
                sourceIdentifier: state.sourceIdentifier
            }).unwrap();
            window.open(response.uri, '_blank');
            setState({
                ...state,
                clientId: +params.clientId!,
                attemptId: response.attemptId,
                uri: response.uri
            });
        } catch (error) {
            toast.error('Failed to pair data source');
            return;
        }
        setStepByKey(StepKeys.AuthInProgress);
    };

    return (
        <ModalBody>
            <Flex w="100%" justifyContent="center" mt="1rem">
                <button style={buttonStyle} onClick={onClick}>
                    Login with Facebook
                </button>
            </Flex>
        </ModalBody>
    );
}
