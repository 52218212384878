import { useGetClientQuery, useGetTeamQuery } from 'src/redux/api';
import { useAppSelector } from '@hooks';
import { Link } from 'react-router-dom';
import { selectActiveReportPageCrumbProps } from 'src/redux/features/blueprint/bluePrintSlice';
import { CrumbParams } from './types';

interface BlueprintReportProps {
    pathname: string;
    params: {
        reportSlug: string;
        viewSlug: string;
    };
}

export const CrumbBlueprintReport: React.FC<BlueprintReportProps> = ({ pathname }) => {
    const { title, displayName } = useAppSelector(selectActiveReportPageCrumbProps);
    return (
        <Link to={pathname}>
            {title} / {displayName}
        </Link>
    );
};

export const CrumbClientDetail: React.FC<CrumbParams> = ({ pathname, params }) => {
    const { data: clientDraftDocument, error } = useGetClientQuery(
        { id: +params!.clientId! },
        {
            skip: !params?.clientId
        }
    );

    return <Link to={pathname}>{clientDraftDocument?.name} </Link>;
};

export const TeamDetailScrumb: React.FC<CrumbParams> = ({ pathname, params }) => {
    const { data: teamData } = useGetTeamQuery(
        { teamId: parseInt(params?.teamId as string) },
        { skip: !params?.teamId }
    );
    return <Link to={pathname}>{teamData?.name}</Link>;
};

export const TeamClientDetailScrumb: React.FC<CrumbParams> = ({ pathname, params }) => {
    const { data: clientData } = useGetClientQuery(
        { id: parseInt(params?.clientId as string) },
        { skip: !params?.clientId }
    );
    return <Link to={pathname}>{clientData?.name}</Link>;
};
