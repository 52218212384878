import { Breadcrumb, BreadcrumbItem } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';
import { useMatches, useParams } from 'react-router-dom';

const AppBreadcrumbs: React.FC = () => {
    const params = useParams();
    let matches = useMatches();
    let crumbs = matches
        .filter((match: any) => Boolean(match?.handle?.crumb))
        .map((match: any) => match.handle.crumb({ pathname: match?.pathname, params }));
    return (
        <Breadcrumb separator={<FiChevronRight color="gray.500" />}>
            {crumbs.map((crumb: any, index: number) => (
                <BreadcrumbItem key={index}>{crumb}</BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
};

export default AppBreadcrumbs;
