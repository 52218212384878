import { borderAll } from "./common.js";
const defaultRectangle = {
    properties: {
        style: {
            background: 'white',
            boxShadow: '0px 0px 5px 0px black',
            backgroundSize: 'cover',
            ...borderAll
        }
    }
};
export const copyRectangleStyles = (properties) => {
    const _properties = { ...properties };
    return _properties;
};
export default defaultRectangle;
