import { Flex, IconButton } from '@chakra-ui/react';
import { FiTrash } from 'react-icons/fi';

export const ActionButton = ({
    actionType,
    onAction
}: {
    actionType: 'ADD' | 'REMOVE';
    onAction: () => void;
}) => {
    return (
        <Flex justifyContent="flex-end">
            {actionType === 'ADD' ? (
                <Flex
                    height="26px"
                    alignItems="center"
                    fontWeight="bold"
                    py="0"
                    color="brand.primary"
                    cursor="pointer"
                    mr="0.75rem"
                    _hover={{
                        textDecoration: 'underline'
                    }}
                    onClick={onAction}
                >
                    + Add
                </Flex>
            ) : (
                <IconButton
                    variant="icon"
                    color="icons.delete"
                    onClick={onAction}
                    height="26px"
                    icon={<FiTrash />}
                    aria-label={''}
                />
            )}
        </Flex>
    );
};
