import { Flex, Text } from '@chakra-ui/react'
import { getProperty } from 'dot-prop'
import { Controller, FieldError, FieldValues, useFormContext } from "react-hook-form"
import ColorPickerInput from "../inputs/ColorPickerInput"
import DashArrayInput from '../inputs/DashArrayInput'
import OpacityInput from '../inputs/OpacityInput'
import FormPropertyRow from "../layout/FormPropertyRow"
import FormPropertyTitle from "../layout/FormPropertyTitle"
import FormTitle from "../layout/FormTitle"


const CartesianGridForm = <T extends FieldValues>({ objPath }: { objPath: string }) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;

    return <Flex flexDir='column'>
        <Flex my={4}>
            <Text textStyle='_h2'>Cartesian Grid</Text>
        </Flex>
        <FormPropertyRow>
            <Flex gap={'1rem'} alignItems={'center'}>
                <FormPropertyTitle title='Stroke' />
                <Controller
                    name={`${objPath}.stroke`}
                    defaultValue={'#E7E7E7'}
                    render={({ field }) => (
                        <ColorPickerInput id={`CartesianGridForm-${objPath}.stroke`}  {...field} error={getProperty(errors, `${objPath}.color`) as FieldError | undefined} width='150px' type="text" />
                    )}
                />
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex gap={'1rem'} alignItems={'center'}>
                <FormPropertyTitle title='Opacity' />
                <Controller
                    name={`${objPath}.opacity`}
                    defaultValue={1}
                    render={({ field }) => (
                        <OpacityInput {...field} error={getProperty(errors, `${objPath}.opacity`) as FieldError | undefined} />
                    )}
                />
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex gap={'1rem'} alignItems={'center'}>
                <FormPropertyTitle title='Dashes' />
                <Controller
                    name={`${objPath}.strokeDasharray`}
                    defaultValue={1}
                    render={({ field }) => (
                        <DashArrayInput {...field} error={getProperty(errors, `${objPath}.strokeDasharray`) as FieldError | undefined} />
                    )}
                />
            </Flex>
        </FormPropertyRow>
    </Flex>
}

export default CartesianGridForm;