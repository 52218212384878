import { Box, Fade, Flex, Icon, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";

// @ts-ignore:next-line
import cornerWhite from "../../assets/svg/corner-white.svg";
import { useNavigationRoutes } from "../../hooks/useNavigationRoutes";
import { Route } from "../../types";
import ZirconLogo from "../ZirconLogo/ZirconLogo";
import "./Sidebar.css";

export interface CollapseSidebarProps {
  routes: Route[];
  isCollapsed?: boolean;
}

const CollapseSidebar: React.FC<CollapseSidebarProps> = ({
  routes,
  isCollapsed = false,
}) => {
  const navRoutes = useNavigationRoutes({ routes });
  const [collapsed, setCollapsed] = useState(false);
  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);

  return (
    <Box
      position={"sticky"}
      display={"flex"}
      height={"100vh"}
      top={0}
      bottom={0}
      zIndex={1000}
    >
      <Sidebar collapsed={collapsed} backgroundColor="rgba(82, 90, 102, 1)">
        <Flex
          h="20"
          alignItems="center"
          cursor={"pointer"}
          onClick={handleToggleSidebar}
        >
          <ZirconLogo collapsed={collapsed} />
        </Flex>
        <Menu
          style={{
            backgroundColor: "rgba(82, 90, 102, 1)",
            marginTop: "20px",
          }}
          menuItemStyles={{
            button: {
              [`&:hover`]: {
                backgroundColor: "transparent",
              },
            },
          }}
        >
          {navRoutes.map((route: any) => (
            <MenuItem
              key={route.path}
              icon={<Icon as={route.handle?.navItem?.icon} />}
              component={<Link to={route.path} />}
              active={route.isActive}
              style={{
                marginBottom: "10px",
              }}
            >
              {route.handle?.navItem?.name}
            </MenuItem>
          ))}
        </Menu>
        <Fade in={!collapsed}>
          <Image
            style={{
              position: "absolute",
              left: "30px",
              bottom: "30px",
              zIndex: -3,
              opacity: 0.7,
            }}
            src={cornerWhite}
            alt=""
          />
        </Fade>
      </Sidebar>
    </Box>
  );
};

export default CollapseSidebar;
