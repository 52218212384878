import { ComponentType } from 'react';
import { ScopePermissions } from '../router/types';
import { useHasScopePermissions } from './useHasScopePermissions';

const withScopePermissions = <P extends object>(
    WrappedComponent: ComponentType<P>,
    scopePermissions: ScopePermissions
) => {
    const WithScopePermissionsComponent: React.FC<P> = (props) => {
        const hasPermissions = useHasScopePermissions(scopePermissions);
        if (!hasPermissions) {
            return null;
        }
        return <WrappedComponent {...(props as P)} />;
    };
    return WithScopePermissionsComponent;
};

export default withScopePermissions;
