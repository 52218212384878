import { FieldValues, Path } from "react-hook-form";
import { BsChevronDoubleDown, BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronDoubleUp } from "react-icons/bs";
import { TbBorderSides, TbBoxPadding } from "react-icons/tb";
import CollapseForm from "../CollapseForm";

const config = {
    title: 'Width',
    typePath: '_borderWidthType',
    all: {
        keyPath: 'borderWidth',
        defaultValue: '0px',
        icon: <TbBorderSides />,
        max: 100
    },
    mixed: [
        { keyPath: 'borderLeftWidth', defaultValue: '0px', icon: < BsChevronDoubleLeft />, max: 100 },
        { keyPath: 'borderRightWidth', defaultValue: '0px', icon: <BsChevronDoubleRight />, max: 100 },
        { keyPath: 'borderTopWidth', defaultValue: '0px', icon: <BsChevronDoubleUp />, max: 100 },
        { keyPath: 'borderBottomWidth', defaultValue: '0px', icon: <BsChevronDoubleDown />, max: 100 }
    ],
    mixedIcon: <TbBoxPadding />
}


const WidthForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    return <CollapseForm<T> objPath={objPath} config={config} />

}

export default WidthForm;