import { Box } from "@chakra-ui/react";
import { SourceIcon } from "@tasklogy/zircon-ui-components";
import { components, ControlProps } from "react-select";

const Control = ({ children, ...props }: ControlProps<any>) => {
    const value = props?.selectProps?.value?.value?.dataSource?.identifier;
    return (
        <components.Control {...props}>
            <Box paddingLeft={2}>
                <SourceIcon selectedIcons={[value]} />
            </Box>
            {children}
        </components.Control>
    );
};

export default Control;