import { Image } from '@chakra-ui/react';
import { ComponentUI } from '@types';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';

const Logo = ({ properties }: ComponentUI) => {
    const { state } = useCanvas();
    const inEditor = state.useEditorReport
    return (
        <Image
            height={'100%'}
            width={'100%'}
            {...(inEditor ? { pointerEvents: 'none' } : {})}
            style={properties.style}
            src={properties?.src}
            alt={'logo'}
        />
    );
};

export default Logo;
