import { styleValidator } from "../../validators/index.js";
const defaultNavigationButton = {
    properties: {
        style: {
            background: 'white',
            color: 'black',
            verticalAlign: styleValidator.verticalAlign.Enum.middle,
            horizontalAlign: styleValidator.horizontalAlign.Enum.center,
            padding: '0px',
        },
        textStyle: {
            color: 'black',
            fontSize: '16px',
            fontWeight: 800,
            fontFamily: 'Arial',
            _active: {
                background: 'white',
                color: 'black',
                fontSize: '16px',
                fontWeight: 800,
                fontFamily: 'Arial',
            }
        }
    }
};
export const copyNavigationButtonStyles = (properties) => {
    const _properties = { ...properties };
    return _properties;
};
export default defaultNavigationButton;
