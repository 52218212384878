import { Box, Text } from '@chakra-ui/react';
import CustomSelect from '../CustomSelect';
import { FilterProps, useFilter } from './useFilter';

const SingleSelect = (props: FilterProps) => {
    const { componentData, selected, handleChange } = useFilter(props, false);

    return (
        <Box id={props.id} width="100%">
            {props.properties?.label && (
                <Text
                    color="white"
                    fontSize="medium"
                    marginBottom={2}
                    fontWeight={400}
                    style={props.properties.labelStyle}
                >
                    {props.properties?.label}
                </Text>
            )}
            <CustomSelect
                options={componentData}
                onChange={(value) => handleChange(value)}
                isMulti={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                placeholder={props.properties?.placeholder}
                value={selected}
                properties={props.properties}
            />
        </Box>
    );
};

export default SingleSelect;
