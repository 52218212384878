import { useUpdateReportPageMutation } from "@api";
import { Switch } from "@chakra-ui/react";
import { ReportPage } from "common/types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";


type TSwitchPublished = Pick<ReportPage, 'id' | 'isActive'>;

const SwitchPublished: React.FC<TSwitchPublished> = ({ isActive, id }) => {
    const [updatePage] = useUpdateReportPageMutation();
    const [pageIsActive, setPageIsActive] = useState(isActive);

    useEffect(() => {
        setPageIsActive(Boolean(isActive));
    }, [isActive]);
    
    const handleSwitchPublished = async () => {
        try {
            setPageIsActive(!Boolean(pageIsActive));
            await updatePage({ id: id, isActive: !Boolean(isActive) }).unwrap();
        } catch (error) {
            setPageIsActive(!Boolean(pageIsActive));
            toast.error('Failed to update page');
        }
    };
    
    return (
        <Switch isChecked={pageIsActive} onChange={() => handleSwitchPublished()} />
    );
}

export default SwitchPublished;