import { HStack, IconButton, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdKeyboardDoubleArrowLeft,
    MdKeyboardDoubleArrowRight
} from 'react-icons/md';
import PageButton from './PageButton';
import { usePagination } from 'ahooks';

interface PaginationProps {
    pagination: ReturnType<typeof usePagination>['pagination'];
}

const Pagination: React.FC<PaginationProps> = ({ pagination }) => {
    const isFirstPage = pagination.current === 1;
    const isLastPage = pagination.current === pagination.totalPage;
    const windowSize = pagination.totalPage >= 5 ? 5 : pagination.totalPage;

    const pageNumbers = useMemo(() => {
        if (pagination.current < windowSize) {
            let pages = Array.from({ length: windowSize }, (_, i) => (
                <PageButton
                    key={i}
                    page={i + 1}
                    isActive={pagination.current === i + 1}
                    onClick={() => pagination.changeCurrent(i + 1)}
                />
            ));
            pages.push(
                <IconButton
                    variant="icon"
                    aria-label="Previous"
                    bg="white"
                    isDisabled={isFirstPage}
                    icon={<Text>...</Text>}
                    onClick={() => pagination.changeCurrent(pagination.current + 1)}
                />
            );
            pages.push(
                <PageButton
                    key={pagination.totalPage}
                    page={pagination.totalPage}
                    isActive={pagination.current === pagination.totalPage}
                    onClick={() => pagination.changeCurrent(pagination.totalPage)}
                />
            );
            return pages;
        } else if (pagination.current >= windowSize) {
            let pages = [];
            const max =
                pagination.current + Math.floor(windowSize / 2) > pagination.totalPage
                    ? pagination.totalPage
                    : pagination.current + Math.floor(windowSize / 2);
            pages.push(
                <IconButton
                    variant="icon"
                    aria-label="Previous"
                    bg="white"
                    isDisabled={isFirstPage}
                    icon={<Text>...</Text>}
                    onClick={() => pagination.changeCurrent(1)}
                />
            );
            for (let i = pagination.current - Math.floor(windowSize / 2); i <= max; i++) {
                pages.push(
                    <PageButton
                        key={i}
                        page={i}
                        isActive={pagination.current === i}
                        onClick={() => pagination.changeCurrent(i)}
                    />
                );
            }
            pages.push(
                <IconButton
                    variant="icon"
                    aria-label="Previous"
                    bg="white"
                    isDisabled={isLastPage}
                    icon={<Text>...</Text>}
                    onClick={() => pagination.changeCurrent(pagination.totalPage)}
                />
            );
            return pages;
        }
    }, [pagination.totalPage, pagination.current]);

    return (
        <HStack my="2rem">
            <IconButton
                variant="icon"
                aria-label="Previous"
                bg="white"
                isDisabled={isFirstPage}
                icon={<MdKeyboardDoubleArrowLeft />}
                onClick={() => pagination.changeCurrent(1)}
            />
            <IconButton
                variant="icon"
                aria-label="Previous"
                bg="white"
                isDisabled={isFirstPage}
                icon={<MdKeyboardArrowLeft />}
                onClick={() => pagination.changeCurrent(pagination.current - 1)}
            />
            {pageNumbers}
            <IconButton
                variant="icon"
                aria-label="Previous"
                bg="white"
                isDisabled={isLastPage}
                icon={<MdKeyboardArrowRight />}
                onClick={() => pagination.changeCurrent(pagination.current + 1)}
            />
            <IconButton
                variant="icon"
                aria-label="Previous"
                bg="white"
                isDisabled={isLastPage}
                icon={<MdKeyboardDoubleArrowRight />}
                onClick={() => pagination.changeCurrent(pagination.total)}
            />
        </HStack>
    );
};

export default Pagination;
