import { useCreateTeamMutation, useGetTeamQuery, useUpdateTeamMutation } from '@api';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { teamValidator } from 'common/validators';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Team } from 'src/redux/types/api';

import { z } from 'zod';

interface CreateTeamModalProps {
    isOpen: boolean;
    onClose: () => void;
    teamToUpdate?: Team;
}

type FormData = z.infer<typeof teamValidator.create>;

const CreateOrUpdateTeamModal: React.FC<CreateTeamModalProps> = ({
    isOpen,
    onClose,
    teamToUpdate
}) => {
    const [createTeam] = useCreateTeamMutation();
    const [updateTeam] = useUpdateTeamMutation();

    console.log(teamToUpdate);

    const {
        register,
        formState: { errors, isValid },
        getValues
    } = useForm<FormData>({
        resolver: zodResolver(teamValidator.create),
        mode: 'onChange',
        defaultValues: {
            name: teamToUpdate?.name
        }
    });

    const handleSubmitTeam = async () => {
        const values = getValues();

        if (teamToUpdate) {
            try {
                await updateTeam({ id: teamToUpdate.id, ...values }).unwrap();
                onClose();
            } catch (error) {
                toast.error('Failed to update team');
            }

            return;
        }

        try {
            await createTeam(values).unwrap();
            onClose();
        } catch (error) {
            toast.error('Failed to create team');
        }
    };

    const text = teamToUpdate ? 'Update team' : 'Create team';

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{text}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isInvalid={Boolean(errors.name)}>
                        <Input
                            type="text"
                            placeholder="Team name"
                            {...register('name', { required: true })}
                        />
                        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button variant={'outline'} mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        isDisabled={!isValid}
                        onClick={() => handleSubmitTeam()}
                        variant="solid"
                    >
                        {text}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateOrUpdateTeamModal;
