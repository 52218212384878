import { Box, Flex, Text } from '@chakra-ui/react';

import { useParams } from 'react-router-dom';
import ReportsTable from '../components/ReportsTable';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { useGetClientQuery } from 'src/redux/api';

const ClientReports: React.FC = () => {
    const params = useParams<{ clientId: string }>();

    const idParam = params.clientId;
    assertIsDefined(idParam, 'Client ID is not defined');

    const { data: client } = useGetClientQuery({ id: Number.parseInt(idParam) });

    if (!client) {
        return null;
    }

    return (
        <div>
            <Flex gap={2} mb="2rem" alignItems="center">
                <Text variant="title">{client?.name}</Text>
            </Flex>
            <Box px="1rem">
                <ReportsTable client={client} />
            </Box>
        </div>
    );
};

export default ClientReports;
