
import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftAddon, InputLeftElement, Text } from "@chakra-ui/react";
import { useAppSelector } from "@hooks";
import { StyledSelect } from "@tasklogy/zircon-ui-components";
import { Controller, FieldError, FieldValues, Path, PathValue, useFormContext } from "react-hook-form";
import { selectActiveReport } from "src/redux/features/blueprint/bluePrintSlice";
import FormTitle from "../../layout/FormTitle";
import { PhoneIcon } from "@chakra-ui/icons";
import React, { useEffect } from "react";
import { getProperty } from "dot-prop";

enum Values {
    PAGE = 'page',
    URL = 'url',
    EMAIL = 'email',
    PHONE = 'phone'
}

interface Option {
    value: Values;
    label: string;
}

const OPTIONS = [
    { value: Values.PAGE, label: 'Navigate to report page' },
    { value: Values.URL, label: 'Use external URL' },
    { value: Values.EMAIL, label: 'Use email address' },
    { value: Values.PHONE, label: 'Use phone number' }
]

const SelectPage = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    const methods = useFormContext<T>();
    const values = methods.getValues();
    const activeReport = useAppSelector(selectActiveReport);

    const currentSelectedPage = activeReport?.pages?.find((page) => page.id === values.properties.pageId);

    const pages = activeReport?.pages?.filter((page) => page.isActive)?.map((page) => ({
        value: page.id,
        label: page.displayName
    }));
    return <Controller
        control={methods.control}
        name={`${objPath}.pageId` as Path<T>}
        render={({ field }) => (
            <FormControl>
                <FormLabel>
                    <Text textStyle='h2'>Select page</Text>
                </FormLabel>
                <StyledSelect
                    variant='editor'
                    options={pages}
                    value={{ value: currentSelectedPage?.slug, label: currentSelectedPage?.displayName }}
                    onChange={(option: any) => field.onChange({ target: { value: option.value } })}
                />
            </FormControl>
        )}
    />
}

const ExternalUrl = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;

    return <Controller
        control={methods.control}
        name={`${objPath}.url` as Path<T>}
        render={({ field }) => (
            <FormControl isInvalid={Boolean(getProperty(errors, `${objPath}.url`))}>
                <Input
                    variant='editor'
                    size="md"
                    type="text"
                    value={field.value ? field.value : ''}
                    onChange={field.onChange}
                />
                <FormErrorMessage>{(getProperty(errors, `${objPath}.url`) as FieldError | undefined)?.message}</FormErrorMessage>
            </FormControl>
        )}
    />
}

const extractMail = (value?: string) => {
    if (!value) return '';
    return value.replace('mailto:', '');
}

const Email = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;

    return <Controller
        control={methods.control}
        name={`${objPath}.url` as Path<T>}
        render={({ field }) => (
            <FormControl isInvalid={Boolean(getProperty(errors, `${objPath}.url`))}>
                <InputGroup>
                    <InputLeftAddon>@</InputLeftAddon>
                    <Input variant='editor' type='email' placeholder='Email address' value={extractMail(field.value)} onChange={(e) => field.onChange({ target: { value: `mailto:${e.target.value}` } })} />
                </InputGroup>
                <FormErrorMessage>{(getProperty(errors, `${objPath}.url`) as FieldError | undefined)?.message}</FormErrorMessage>
            </FormControl>
        )}
    />
}

const extractTel = (value?: string) => {
    if (!value) return '';
    return value.replace('tel:', '');
}

const Phone = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;

    return <Controller
        control={methods.control}
        name={`${objPath}.url` as Path<T>}
        render={({ field }) => (
            <FormControl isInvalid={Boolean(getProperty(errors, `${objPath}.url`))}>
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <PhoneIcon color='gray.300' />
                    </InputLeftElement>
                    <Input variant='editor'
                        size="md"
                        type="tel"
                        value={extractTel(field.value)}
                        onChange={(e) => field.onChange({ target: { value: `tel:${e.target.value}` } })} placeholder='Phone number' />
                </InputGroup>
                <FormErrorMessage>{(getProperty(errors, `${objPath}.url`) as FieldError | undefined)?.message}</FormErrorMessage>
            </FormControl>
        )}
    />
}

const NavigationButtonForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    const [selectedOption, setSelectedOption] = React.useState<Option | undefined>(undefined);
    const methods = useFormContext<T>();
    const values = methods.watch(objPath);

    const handleSelectChange = (option: Option) => {
        setSelectedOption(option);
        const values = methods.getValues(objPath);
        // reset values
        methods.setValue(objPath, {
            ...values,
            pageId: null,
            url: ''
        } as PathValue<T, Path<T>>, { shouldDirty: true });
        methods.setError(objPath, {});
    }

    useEffect(() => {
        if (!selectedOption) {
            const value = methods.getValues(objPath);
            if (value.pageId) {
                setSelectedOption(OPTIONS.find((option) => option.value === Values.PAGE));
            } else if (value.url) {
                const url = value.url as string;
                if (url.includes('mailto:')) {
                    setSelectedOption(OPTIONS.find((option) => option.value === Values.EMAIL));
                } else if (url.includes('tel:')) {
                    setSelectedOption(OPTIONS.find((option) => option.value === Values.PHONE));
                } else {
                    setSelectedOption(OPTIONS.find((option) => option.value === Values.URL));
                }
            }
        }
    }, [values]);

    return <Flex flexDir='column'>
        <FormTitle title='Navigation button properties' />
        <StyledSelect
            variant='editor'
            options={OPTIONS}
            value={selectedOption}
            onChange={(option: any) => handleSelectChange(option)}
        />
        <Flex mt={4} flexDir='column'>
            {selectedOption?.value === Values.PAGE && <SelectPage objPath={objPath} />}
            {selectedOption?.value === Values.URL && <ExternalUrl objPath={objPath} />}
            {selectedOption?.value === Values.EMAIL && <Email objPath={objPath} />}
            {selectedOption?.value === Values.PHONE && <Phone objPath={objPath} />}
        </Flex>
    </Flex>;
}

export default NavigationButtonForm;