import { Box } from '@chakra-ui/react';
import React from 'react';
import FilterForm from '../../forms/setup/FilterForm';

const FilterSetup: React.FC = () => {
    return (
        <Box>
            <FilterForm />
        </Box>
    );
};

export default FilterSetup;
