var CampaignStatus;
(function (CampaignStatus) {
    CampaignStatus["PENDING"] = "PENDING";
    CampaignStatus["APPROVED"] = "APPROVED";
    CampaignStatus["REJECTED"] = "REJECTED";
})(CampaignStatus || (CampaignStatus = {}));
var ComponentType;
(function (ComponentType) {
    ComponentType["PIE_CHART"] = "PIE_CHART";
    ComponentType["DONUT_CHART"] = "DONUT_CHART";
    ComponentType["LINE_CHART"] = "LINE_CHART";
    ComponentType["TABLE"] = "TABLE";
    ComponentType["SINGLE_VALUE"] = "SINGLE_VALUE";
    ComponentType["AREA_CHART"] = "AREA_CHART";
    ComponentType["BIAXIAL_BAR_CHART"] = "BIAXIAL_BAR_CHART";
    ComponentType["STACKED_BAR_CHART"] = "STACKED_BAR_CHART";
    ComponentType["TINY_BAR_CHART"] = "TINY_BAR_CHART";
    ComponentType["TABLE_PROGRESS_BAR_CHART"] = "TABLE_PROGRESS_BAR_CHART";
    ComponentType["DATE_RANGE"] = "DATE_RANGE";
    ComponentType["SINGLE_SELECT"] = "SINGLE_SELECT";
    ComponentType["MULTI_SELECT"] = "MULTI_SELECT";
    ComponentType["NAVIGATION_BUTTON"] = "NAVIGATION_BUTTON";
    ComponentType["DOWNLOAD_BUTTON"] = "DOWNLOAD_BUTTON";
    ComponentType["RECTANGLE"] = "RECTANGLE";
    ComponentType["TEXT"] = "TEXT";
    ComponentType["IMAGE"] = "IMAGE";
    ComponentType["LOGO"] = "LOGO";
    ComponentType["NAVBAR"] = "NAVBAR";
})(ComponentType || (ComponentType = {}));
const dataComponentTypes = [
    ComponentType.PIE_CHART,
    ComponentType.DONUT_CHART,
    ComponentType.LINE_CHART,
    ComponentType.TABLE,
    ComponentType.SINGLE_VALUE,
    ComponentType.AREA_CHART,
    ComponentType.BIAXIAL_BAR_CHART,
    ComponentType.STACKED_BAR_CHART,
    ComponentType.TINY_BAR_CHART,
    ComponentType.TABLE_PROGRESS_BAR_CHART
];
const filterComponentTypes = [
    ComponentType.SINGLE_SELECT,
    ComponentType.MULTI_SELECT,
    ComponentType.DATE_RANGE
];
const dimensionFilterComponentTypes = [ComponentType.SINGLE_SELECT, ComponentType.MULTI_SELECT];
const basicComponentTypes = [
    ComponentType.NAVIGATION_BUTTON,
    ComponentType.DOWNLOAD_BUTTON,
    ComponentType.RECTANGLE,
    ComponentType.TEXT,
    ComponentType.IMAGE,
    ComponentType.LOGO
];
var ColumnFormatType;
(function (ColumnFormatType) {
    ColumnFormatType["NUMBER"] = "NUMBER";
    ColumnFormatType["CURRENCY"] = "CURRENCY";
    ColumnFormatType["PERCENTAGE"] = "PERCENTAGE";
    ColumnFormatType["DATE"] = "DATE";
    ColumnFormatType["WEEK"] = "WEEK";
    ColumnFormatType["MONTH"] = "MONTH";
    ColumnFormatType["STRING"] = "STRING";
    ColumnFormatType["AD_PREVIEW"] = "AD_PREVIEW";
    ColumnFormatType["LINK"] = "LINK";
})(ColumnFormatType || (ColumnFormatType = {}));
var Language;
(function (Language) {
    Language["SK"] = "SK";
    Language["EN"] = "EN";
})(Language || (Language = {}));
var LayoutScale;
(function (LayoutScale) {
    LayoutScale["FIXED"] = "FIXED";
    LayoutScale["FILL"] = "FILL";
})(LayoutScale || (LayoutScale = {}));
var DataSourceIdentifier;
(function (DataSourceIdentifier) {
    DataSourceIdentifier["DV360"] = "DV360";
    DataSourceIdentifier["META"] = "META";
    DataSourceIdentifier["GOOGLE_ADS"] = "GOOGLE_ADS";
    DataSourceIdentifier["GA4"] = "GA4";
    DataSourceIdentifier["FACEBOOK_INSIGHTS"] = "FACEBOOK_INSIGHTS";
    DataSourceIdentifier["INSTAGRAM_INSIGHTS"] = "INSTAGRAM_INSIGHTS";
    DataSourceIdentifier["GEMIUS_GDE"] = "GEMIUS_GDE";
    DataSourceIdentifier["ADFORM"] = "ADFORM";
})(DataSourceIdentifier || (DataSourceIdentifier = {}));
export var ClientConnectedSourceStatus;
(function (ClientConnectedSourceStatus) {
    ClientConnectedSourceStatus["ACTIVE"] = "ACTIVE";
    ClientConnectedSourceStatus["SOURCE_AUTH_DELETED"] = "SOURCE_AUTH_DELETED";
    ClientConnectedSourceStatus["LOADING"] = "LOADING";
    ClientConnectedSourceStatus["LOADING_FAILED"] = "LOADING_FAILED";
    ClientConnectedSourceStatus["NO_ACCESS"] = "NO_ACCESS";
})(ClientConnectedSourceStatus || (ClientConnectedSourceStatus = {}));
export var InitialLoadingStatus;
(function (InitialLoadingStatus) {
    InitialLoadingStatus["PENDING"] = "PENDING";
    InitialLoadingStatus["IN_PROGRESS"] = "IN_PROGRESS";
    InitialLoadingStatus["COMPLETED"] = "COMPLETED";
    InitialLoadingStatus["FAILED"] = "FAILED";
})(InitialLoadingStatus || (InitialLoadingStatus = {}));
export var SourceAuthAttemptStatus;
(function (SourceAuthAttemptStatus) {
    SourceAuthAttemptStatus["STARTED"] = "STARTED";
    SourceAuthAttemptStatus["FINISHED"] = "FINISHED";
    SourceAuthAttemptStatus["FAILED"] = "FAILED";
    SourceAuthAttemptStatus["REAUTH_NOT_SAME"] = "REAUTH_NOT_SAME";
    SourceAuthAttemptStatus["EXPIRED"] = "EXPIRED";
    SourceAuthAttemptStatus["CANCELLED"] = "CANCELLED";
})(SourceAuthAttemptStatus || (SourceAuthAttemptStatus = {}));
export var SourceAuthStatus;
(function (SourceAuthStatus) {
    SourceAuthStatus["ACTIVE"] = "ACTIVE";
    SourceAuthStatus["NO_ACCESS"] = "NO_ACCESS";
    SourceAuthStatus["DELETED"] = "DELETED";
})(SourceAuthStatus || (SourceAuthStatus = {}));
export var SourceAuthAdvertiserStatus;
(function (SourceAuthAdvertiserStatus) {
    SourceAuthAdvertiserStatus["ACTIVE"] = "ACTIVE";
    SourceAuthAdvertiserStatus["SOURCE_AUTH_DELETED"] = "SOURCE_AUTH_DELETED";
    SourceAuthAdvertiserStatus["NO_ACCESS"] = "NO_ACCESS";
})(SourceAuthAdvertiserStatus || (SourceAuthAdvertiserStatus = {}));
export var NumericOperators;
(function (NumericOperators) {
    NumericOperators["EQ"] = "EQ";
    NumericOperators["GT"] = "GT";
    NumericOperators["LT"] = "LT";
    NumericOperators["GTE"] = "GTE";
    NumericOperators["LTE"] = "LTE";
    NumericOperators["IS_NULL"] = "IS_NULL";
})(NumericOperators || (NumericOperators = {}));
export var StringOperators;
(function (StringOperators) {
    StringOperators["CONTAINS"] = "CONTAINS";
    StringOperators["STARTS_WITH"] = "STARTS_WITH";
    StringOperators["IS_NULL"] = "IS_NULL";
})(StringOperators || (StringOperators = {}));
export var IncludeExcludeEnum;
(function (IncludeExcludeEnum) {
    IncludeExcludeEnum["INCLUDE"] = "include";
    IncludeExcludeEnum["EXCLUDE"] = "exclude";
})(IncludeExcludeEnum || (IncludeExcludeEnum = {}));
export var CountryCodes;
(function (CountryCodes) {
    CountryCodes["SK"] = "SK";
    CountryCodes["CZ"] = "CZ";
})(CountryCodes || (CountryCodes = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["CONNECTED_ACOUNT_LOADING_FINISHED"] = "CONNECTED_ACOUNT_LOADING_FINISHED";
})(NotificationType || (NotificationType = {}));
export var SortDirection;
(function (SortDirection) {
    SortDirection["ASC"] = "asc";
    SortDirection["DESC"] = "desc";
})(SortDirection || (SortDirection = {}));
export { CampaignStatus, ComponentType, ColumnFormatType, Language, LayoutScale, dataComponentTypes, filterComponentTypes, basicComponentTypes, dimensionFilterComponentTypes, DataSourceIdentifier };
