import { Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'src/hooks/redux';
import { useLazyGetReportBySlugQuery } from 'src/redux/api';
import { setActiveReportPageId } from 'src/redux/features/blueprint/bluePrintSlice';
import { useEditorNavigation } from 'src/router/hooks';

const ReportName = ({
    title,
    reportSlug,
    clientId
}: {
    title: string;
    reportSlug: string;
    clientId: number;
}) => {
    const dispatch = useAppDispatch();
    const [getSharedReportBySlug] = useLazyGetReportBySlugQuery();
    const goToEditor = useEditorNavigation();

    const prefetchReport = async () => {
        try {
            const response = await getSharedReportBySlug({
                clientId,
                slug: reportSlug,
                isSharedReport: false
            }).unwrap();
            dispatch(
                setActiveReportPageId(
                    response.pages.length > 0 ? response.pages[0].id : null
                )
            );
        } catch (error) {
            toast.error('Failed to load report');
        }
    };

    const handleClick = async () => {
        await prefetchReport();

        goToEditor(clientId?.toString(), reportSlug);
    };

    return (
        <Text
            onClick={handleClick}
            fontWeight="bold"
            _hover={{ textDecor: 'underline', cursor: 'pointer' }}
        >
            {title}
        </Text>
    );
};

export default ReportName;
