import { Flex } from '@chakra-ui/react';
import { WithPermissionsAction } from '@auth';
import SearchInput from 'src/components/SearchComponent';
import { OutletTitle } from '@tasklogy/zircon-ui-components';
import { useState } from 'react';

import TeamsTable from 'src/blueprint/components/Tables/TeamsTable/TeamsTable';
import CreateOrUpdateTeamModal from './CreateOrUpdateTeamModal';
import { useModal } from 'src/hooks/useModal';

export const TeamIndex = () => {
    const [search, setSearch] = useState<string>('');
    const { isOpen, open, close } = useModal();

    return (
        <>
            <Flex gap={2} mb="3rem" justifyContent="space-between" alignItems="center">
                <OutletTitle>Teams</OutletTitle>
                <Flex alignItems="center" gap="20px">
                    <SearchInput search={search} setSearch={setSearch} />
                    <WithPermissionsAction.CreateTeam onClick={open} />
                </Flex>
            </Flex>
            <CreateOrUpdateTeamModal isOpen={isOpen} onClose={close} />

            <TeamsTable searchValue={search} />
        </>
    );
};
