import { IconButton, Text } from '@chakra-ui/react';
import React from 'react';

const PageButton: React.FC<{ page: number; isActive: boolean; onClick: () => void }> = ({
    page,
    isActive,
    onClick
}) => {
    return (
        <IconButton
            aria-label="more pages"
            variant="icon"
            width={'fit-content'}
            bg="white"
            onClick={onClick}
            icon={<Text color={isActive ? 'brand.primary' : 'black'}>{page}</Text>}
        />
    );
};

export default PageButton;