import { Box } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { defaultTheme } from "common/constants";
import { styleValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { z } from "zod";
import BorderForm from "../../forms/BorderForm";
import ColorForm from "../../forms/ColorForm";
import PositionForm from "../../forms/PositionForm";
import TextForm from "../../forms/TextForm";
import StyleDivider from "../../StyleDivider";
import { FormAccordionItem } from "../../Accordion";
import PaddingForm from "../../forms/PaddingForm";

type FormData = z.infer<typeof styleValidator.text>

const TextStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultTheme.components.TEXT,
        values: componentToEdit as any,
        resolver: zodResolver(styleValidator.text),
        mode: 'onBlur',
    })
    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <StyleDivider />
                <FormAccordionItem title="Component styles">
                    <ColorForm<FormData> name={"properties.style.background"} />
                </FormAccordionItem>
                <BorderForm<FormData> objPath={'properties.style'} />
                <TextForm<FormData> objPath={'properties.style'} textPath="properties.text" asTextarea withTextAlign />
                <PaddingForm<FormData> objPath={'properties.style'} />
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={defaultTheme.components.TEXT} />
            </form>
        </FormProvider>
    </Box>
}

export default TextStyle;