import { Box, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { LuExternalLink } from 'react-icons/lu';
import { TbCopyMinus } from 'react-icons/tb';

export interface UrlCellProps {
    value: string;
}

const UrlCell: React.FC<UrlCellProps> = ({ value }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(value);
    };
    const openReport = () => {
        window.open(value, '_blank');
    };

    return (
        <Flex gap={2} alignItems="center">
            <Tooltip label="Open report" aria-label="Open report" placement="top">
                <Box as="span">
                    <Icon
                        as={LuExternalLink}
                        fontSize="1.125rem"
                        transition="color 0.2s"
                        _hover={{ color: 'brand.primary', cursor: 'pointer' }}
                        onClick={() => openReport()}
                        justifyContent="left"
                    />
                </Box>
            </Tooltip>
            <Tooltip label="Copy" aria-label="Copy" placement="top">
                <Box as="span">
                    <Icon
                        as={TbCopyMinus}
                        fontSize="1.125rem"
                        transition="color 0.2s"
                        _hover={{ color: 'brand.primary', cursor: 'pointer' }}
                        onClick={() => copyToClipboard()}
                        justifyContent="left"
                    />
                </Box>
            </Tooltip>
        </Flex>
    );
};

export default UrlCell;
