import { BlueprintTableProperties } from '@types';
import { useAppSelector } from 'src/hooks/redux';
import {
    selectComponent,
    selectComponentData
} from 'src/redux/features/blueprint/bluePrintSlice';
import { GenericTable } from 'src/templates/blueprint/organisms/table/GenericTable';
import GoCard from '../GoCard';
import { Flex, Text } from '@chakra-ui/react';
import { DataComponent } from 'common/types';

const Table = ({
    properties,
    id
}: {
    properties: BlueprintTableProperties;
    id: string;
}) => {
    const componentData = useAppSelector((state) => selectComponentData(state, id));
    const component = useAppSelector((state) =>
        selectComponent(state, id)
    ) as DataComponent;

    const renderContent = () => {
        if (!component.dimensions?.length && !component.metrics?.length) {
            return (
                <Flex justifyContent="center" alignItems="center" w="100%">
                    <Text color="inherit">
                        Select at least one dimension and one metric to display the table.
                    </Text>
                </Flex>
            );
        }

        if (componentData?.error) {
            return (
                <Flex justifyContent="center" alignItems="center" w="100%">
                    <Text>
                        There was an error fetching the data. Please try again later.
                    </Text>
                </Flex>
            );
        }

        if (componentData) {
            return (
                <GenericTable
                    data={componentData ?? []}
                    properties={properties}
                    id={id}
                    h={component.h}
                />
            );
        }
    };

    return (
        <GoCard
            cardStyle={properties?.cardStyle}
            w="100%"
            h="100%"
        >
            {renderContent()}
        </GoCard>
    );
};

export default Table;
