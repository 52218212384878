import { Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { useAppSelector } from "@hooks";
import { StyledSelect } from "@tasklogy/zircon-ui-components";
import { NavigationButton } from 'common/types';
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectActiveReport, selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { useCanvas } from "../../EditorContext";


export const NavigationConfig = () => {
    const { state: {activeSelectedComponentId} } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId)) as NavigationButton;
    const setComponentConfig = useSetComponentConfig();
    const activeReport = useAppSelector(selectActiveReport);

    const currentSelectedPage = activeReport?.pages?.find((page) => page.id === componentToEdit.properties.pageId);

    const pages = activeReport?.pages?.map((page) => ({
        value: page.id,
        label: page.displayName
    }));

    return <Flex flexDir={'column'} gap={'2rem'} my={'1rem'} px="1rem">

        <FormControl>
            <FormLabel>Select the page of the report</FormLabel>
            <StyledSelect
                options={pages}
                value={{value: currentSelectedPage?.slug, label: currentSelectedPage?.displayName}}
                onChange={(option: any) => {
                    const updated: NavigationButton = {
                        ...componentToEdit,
                        properties: {
                            ...componentToEdit.properties,
                            pageId: option.value
                        }
                    };
                    setComponentConfig(updated);
                }}
            />
        </FormControl>
    </Flex>
}