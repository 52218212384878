import { ComponentType, dataComponentTypes, dimensionFilterComponentTypes } from 'common/enums';
import { useAppSelector } from 'src/hooks/redux';
import { selectComponentById } from 'src/redux/features/blueprint/bluePrintSlice';
import { useCanvas } from '../../EditorContext';
import { ComponentConfig } from './ComponentConfig';
import { DateRangeConfig } from './DateRangeConfig';
import { ImageConfig } from './ImageConfig';
import { NavigationConfig } from './NavigationConfig';
import { FilterConfig } from './FilterConfig';

const DnDConfig = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const configuration = useAppSelector(selectComponentById(activeSelectedComponentId));

    if (!configuration) return null;

    if (configuration.type === ComponentType.DATE_RANGE) {
        return <DateRangeConfig />;
    }

    if (configuration.type === ComponentType.IMAGE || configuration.type === ComponentType.LOGO) {
        return <ImageConfig />;
    }

    if (configuration.type === ComponentType.NAVIGATION_BUTTON) {
        return <NavigationConfig />
    }

    if (dataComponentTypes.includes(configuration.type)) {
        return <ComponentConfig />;
    }

    if (dimensionFilterComponentTypes.includes(configuration.type)) {
        return <FilterConfig />;
    }

    return null;
};
export default DnDConfig;
