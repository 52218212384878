import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ActionDelete, ActionEdit, HappyModal } from '@tasklogy/zircon-ui-components';
import ModalWindow from 'src/components/ModalWindow';
import CreateForm from './CreateForm';
import { useModal } from 'src/hooks/useModal';
import { Report } from 'src/redux/types/api';
import { useDeleteReportMutation } from '@api';
import { toast } from 'react-toastify';

interface Props {
    template: Report;
}

export default function ActionCell({ template }: Props) {
    const { isOpen, open, close } = useModal();
    const [deleteTemplate] = useDeleteReportMutation();
    const {
        open: openDeleteModal,
        close: closeDeleteModal,
        isOpen: isDeleteModalOpen
    } = useModal();

    return (
        <Flex mr="1rem" justifyContent="end" minW={'5rem'}>
            <ActionEdit onClick={open} />
            <ActionDelete onClick={openDeleteModal} />
            <HappyModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                <HappyModal.Body>
                    Are you sure you want to delete template
                    <br /> {template.title}?
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={closeDeleteModal}>
                        No
                    </Button>
                    <Button
                        variant="solid"
                        onClick={async () => {
                            try {
                                await deleteTemplate({ id: template.id }).unwrap();
                                toast.success('Template deleted successfully.');
                                closeDeleteModal();
                            } catch (e) {
                                toast.error(
                                    'An error occurred while deleting the template.'
                                );
                            }
                        }}
                    >
                        Yes
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
            <ModalWindow
                minWidth="50rem"
                isOpen={isOpen}
                onClose={close}
                title="Edit Template"
            >
                <CreateForm onClose={close} editedTemplate={template} />
            </ModalWindow>
        </Flex>
    );
}
