import { Flex, FormControl, FormErrorMessage, InputProps, Radio, RadioGroup, RadioProps, Text, VStack } from "@chakra-ui/react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import FormTitle from "../../forms/layout/FormTitle";
import FormPropertyRow from "../../forms/layout/FormPropertyRow";
import { LayoutScale } from "common/enums";

import { forwardRef, LegacyRef } from "react";
import { FieldError } from "react-hook-form";
import { getProperty } from "dot-prop";

interface FormRadioProps extends RadioProps {
    error: FieldError | undefined;
    onChange?: (e: any) => void;
    value: any;
    ref: LegacyRef<HTMLDivElement> | undefined;
}

const FormRadioGroup = forwardRef<FormRadioProps, any>(({ error, onChange, value }, ref) => {
    return (
        <FormControl as="fieldset" isInvalid={!!error?.message}>
            <RadioGroup ref={ref as any} value={value} onChange={(nextValue: any) => onChange && onChange({
                target: {
                    value: nextValue
                }
            })}>
                <VStack
                    spacing="0.5rem"
                    alignItems="flex-start"
                    paddingLeft="1rem"
                >
                    <Radio value={LayoutScale.FILL}>
                        <Text textStyle='h1'>Adjust to width</Text>
                    </Radio>
                    <Radio value={LayoutScale.FIXED}>
                        <Text textStyle='h1'>Fixed size</Text>
                    </Radio>
                </VStack>
            </RadioGroup>
            {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}

        </FormControl>
    );
});

const ScaleForm = ({ name }: { name: string }) => {
    const methods = useFormContext();
    const { errors } = methods.formState;
    return <Flex flexDir='column'>
        <Flex my={4}>
            <FormTitle title="Page config" />
        </Flex>
        <FormPropertyRow>
            <Controller
                control={methods.control}
                name='scale'
                render={({ field }) => (
                    <FormRadioGroup error={getProperty(errors, name) as FieldError | undefined} {...field} />
                )}
            />
        </FormPropertyRow>
    </Flex>
}

export default ScaleForm;