import { useCallback } from 'react';
import { ActiveSelection, CanvasObjects, updateComponents } from './types';

interface useCanvasAlignmentProps {
    canvasObjects: CanvasObjects;
    activeSelection: ActiveSelection;
    updateComponents: updateComponents;
}

type alignmentFn = (selectedObjects: CanvasObjects) => CanvasObjects;

export const useCanvasAlignment = ({
    canvasObjects,
    activeSelection,
    updateComponents
}: useCanvasAlignmentProps) => {
    const alignObjects = useCallback(
        (alignmentFn: alignmentFn) => {
            const selectedObjects = canvasObjects.filter((obj) =>
                activeSelection.has(obj.id)
            ) as CanvasObjects;
            const updatedComponents = alignmentFn(selectedObjects);
            updateComponents(updatedComponents);
        },
        [canvasObjects, activeSelection, updateComponents]
    );

    const alignLeft = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            const leftMost = Math.min(...selectedObjects.map((obj) => obj.x));
            return selectedObjects.map((obj) => ({ ...obj, x: leftMost }));
        });

    const alignRight = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            const rightMost = Math.max(...selectedObjects.map((obj) => obj.x + obj.w));
            return selectedObjects.map((obj) => ({ ...obj, x: rightMost - obj.w }));
        });

    const alignCenter = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            const centerX = Math.min(...selectedObjects.map((obj) => obj.x + obj.w / 2));
            return selectedObjects.map((obj) => ({ ...obj, x: centerX - obj.w / 2 }));
        });

    const alignTop = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            const topMost = Math.min(...selectedObjects.map((obj) => obj.y));
            return selectedObjects.map((obj) => ({ ...obj, y: topMost }));
        });

    const alignMiddle = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            const middleY = Math.min(...selectedObjects.map((obj) => obj.y + obj.h / 2));
            return selectedObjects.map((obj) => ({ ...obj, y: middleY - obj.h / 2 }));
        });

    const alignBottom = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            const bottomMost = Math.max(...selectedObjects.map((obj) => obj.y + obj.h));
            return selectedObjects.map((obj) => ({ ...obj, y: bottomMost - obj.h }));
        });

    const distributeSpaceHorizontally = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            const sortedObjects = selectedObjects.sort((a, b) => a.x - b.x);
            const space = sortedObjects[sortedObjects.length - 1].x - sortedObjects[0].x;
            const spaceBetween = space / (sortedObjects.length - 1);
            return sortedObjects.map((obj, index) => ({
                ...obj,
                x: sortedObjects[0].x + index * spaceBetween
            }));
        });

    const distributeSpaceVertically = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            const sortedObjects = selectedObjects.sort((a, b) => a.y - b.y);
            const space = sortedObjects[sortedObjects.length - 1].y - sortedObjects[0].y;
            const spaceBetween = space / (sortedObjects.length - 1);
            return sortedObjects.map((obj, index) => ({
                ...obj,
                y: sortedObjects[0].y + index * spaceBetween
            }));
        });

    const tidyUpVertically = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            // Group objects by their horizontal overlap
            const groups: Array<CanvasObjects> = [];

            selectedObjects.forEach((obj) => {
                let addedToGroup = false;

                for (let group of groups) {
                    if (
                        group.some((gObj) => {
                            // Check if the objects overlap horizontally (x-axis)
                            const objRight = obj.x + obj.w;
                            const gObjRight = gObj.x + gObj.w;
                            return obj.x < gObjRight && objRight > gObj.x;
                        })
                    ) {
                        group.push(obj);
                        addedToGroup = true;
                        break;
                    }
                }

                if (!addedToGroup) {
                    groups.push([obj]);
                }
            });

            const updatedComponents: CanvasObjects = [];

            groups.forEach((group) => {
                // Sort each group by their vertical position (y value)
                const sortedGroup = group.sort((a, b) => a.y - b.y);

                // Adjust the y position for each object in the group
                sortedGroup.forEach((obj, index) => {
                    updatedComponents.push({
                        ...obj,
                        y: sortedGroup[0].y + index * (obj.h + 10) // Adjust 10px as spacing between objects
                    });
                });
            });

            return updatedComponents;
        });

    const tidyUpHorizontally = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            // Group objects by their vertical overlap
            const groups: Array<CanvasObjects> = [];

            selectedObjects.forEach((obj) => {
                let addedToGroup = false;

                for (let group of groups) {
                    if (
                        group.some((gObj) => {
                            // Check if the objects overlap vertically (y-axis)
                            const objBottom = obj.y + obj.h;
                            const gObjBottom = gObj.y + gObj.h;
                            return obj.y < gObjBottom && objBottom > gObj.y;
                        })
                    ) {
                        group.push(obj);
                        addedToGroup = true;
                        break;
                    }
                }

                if (!addedToGroup) {
                    groups.push([obj]);
                }
            });

            const updatedComponents: CanvasObjects = [];

            groups.forEach((group) => {
                // Sort each group by their horizontal position (x value)
                const sortedGroup = group.sort((a, b) => a.x - b.x);

                // Adjust the x position for each object in the group
                sortedGroup.forEach((obj, index) => {
                    updatedComponents.push({
                        ...obj,
                        x: sortedGroup[0].x + index * (obj.w + 10) // Adjust 10px as spacing between objects
                    });
                });
            });

            return updatedComponents;
        });

    const tidyUp = () =>
        alignObjects((selectedObjects: CanvasObjects) => {
            if (selectedObjects.length < 2) return [];

            // Calculate the number of columns and rows for the grid
            const numColumns = Math.ceil(Math.sqrt(selectedObjects.length));
            const numRows = Math.ceil(selectedObjects.length / numColumns);

            // Find the top-left corner of the selection
            const minX = Math.min(...selectedObjects.map((obj) => obj.x));
            const minY = Math.min(...selectedObjects.map((obj) => obj.y));

            // Calculate the horizontal and vertical spacing
            const horizontalSpacing =
                (Math.max(...selectedObjects.map((obj) => obj.x)) - minX) /
                (numColumns - 1);
            const verticalSpacing =
                (Math.max(...selectedObjects.map((obj) => obj.y)) - minY) / (numRows - 1);

            // Sort objects based on their position in the original selection
            const sortedObjects = [...selectedObjects].sort((a, b) => {
                if (a.y === b.y) {
                    return a.x - b.x;
                }
                return a.y - b.y;
            });

            const updatedComponents = sortedObjects.map((obj, index) => {
                const row = Math.floor(index / numColumns);
                const col = index % numColumns;

                return {
                    ...obj,
                    x: minX + col * (obj.w + horizontalSpacing),
                    y: minY + row * (obj.h + verticalSpacing)
                };
            });

            return updatedComponents;
        });

    return {
        left: alignLeft,
        right: alignRight,
        center: alignCenter,
        top: alignTop,
        middle: alignMiddle,
        bottom: alignBottom,
        distributeSpaceHorizontally,
        distributeSpaceVertically,
        tidyUpVertically,
        tidyUpHorizontally,
        tidyUp
    };
};
