import { useListUserSourceAuthsQuery } from '@api';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import SourceAuthCard from 'src/blueprint/components/SourceAuths/SourceAuthCard';

import { OutletTitle } from '@tasklogy/zircon-ui-components';
import LoadingStatus from 'src/blueprint/components/LoadingStatus';
import PairDataSourceWizard from 'src/blueprint/components/PairDataSources/PairDataSourceWizard';

export default function SourceAuthIndex() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data, refetch, isLoading } = useListUserSourceAuthsQuery(undefined);

    return (
        <>
            <Flex gap={2} mb="3rem" justifyContent="center" flexDir="column">
                <Flex alignItems="center" gap="30px">
                    <OutletTitle>My Connections</OutletTitle>
                    <Button variant="solid" onClick={onOpen} mb="1rem">
                        Add new connection
                    </Button>
                </Flex>

                <LoadingStatus isLoading={isLoading}>
                    <Flex gap="1rem" flexWrap="wrap">
                        {data?.map((sourceAuth) => (
                            <SourceAuthCard key={sourceAuth.id} sourceAuth={sourceAuth} />
                        ))}
                    </Flex>
                </LoadingStatus>

                <PairDataSourceWizard
                    isOpen={isOpen}
                    onClose={onClose}
                    onSuccess={refetch}
                />
            </Flex>
        </>
    );
}
