import { HamburgerIcon } from '@chakra-ui/icons';
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure
} from '@chakra-ui/react';
import { FiCopy, FiTrash } from 'react-icons/fi';
import { toast } from 'react-toastify';
import {
    useDeleteReportPageMutation,
    useDuplicateReportPageMutation,
    useUpdateReportPageMutation
} from 'src/redux/api';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import DeleteCardAlert from './DeleteCardAlert';
import { PageForm } from './PageForm';
import { Page } from './PagesTab';

export interface PageWithId extends Page {
    id: number;
}

export interface PageCardProps {
    item: PageWithId;
}

export const PageCard = ({ item }: PageCardProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [deletePage] = useDeleteReportPageMutation();
    const [updatePage] = useUpdateReportPageMutation();
    const [duplicatePage] = useDuplicateReportPageMutation();
    const onDelete = async () => {
        try {
            await deletePage({ id: item.id }).unwrap();
            toast.success('Page deleted');
        } catch (error) {
            toast.error('Failed to delete page');
        }
    };
    const onUpdate = async (values: Page) => {
        try {
            await updatePage({
                displayName: values.displayName,
                slug: values.slug,
                id: item.id
            }).unwrap();
            toast.success('Page updated');
        } catch (error) {
            toast.error('Failed to update page');
        }
    };

    const onDuplicate = async () => {
        try {
            assertIsDefined(item);
            await duplicatePage({ id: item.id }).unwrap();
            toast.success('Page duplicated');
        } catch (error) {
            toast.error('Failed to duplicate page');
        }
    };
    return (
        <>
            <DeleteCardAlert
                text={item.slug}
                isOpen={isOpen}
                onClose={onClose}
                onDelete={onDelete}
            />
            <AccordionItem>
                {({ isExpanded }) => (
                    <>
                        <Flex
                            border="1px solid #4FD1C5"
                            borderRadius={5}
                            style={
                                isExpanded
                                    ? {
                                          borderRadius: '5px 5px 0px 0px',
                                          borderBottomColor: 'transparent',
                                          borderBottomWidth: 0,
                                          outline: 'none'
                                      }
                                    : {}
                            }
                            outline={'none'}
                            _focus={{ outline: 'none' }}
                        >
                            <AccordionButton
                                _hover={{
                                    borderColor: 'transparent'
                                }}
                                _focus={{ outline: 'none' }}
                                width={'100%'}
                            >
                                <Box marginRight={'1rem'}>
                                    <AccordionIcon />
                                </Box>
                                <Box
                                    as="span"
                                    flex="1"
                                    textAlign="left"
                                    color="black"
                                    fontWeight={600}
                                >
                                    {item.displayName}
                                </Box>
                            </AccordionButton>
                            <Box borderLeft="1px solid #4FD1C5">
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        aria-label="Page options"
                                        icon={<HamburgerIcon />}
                                        variant='unstyled'
                                        _hover={{
                                            borderColor: 'transparent'
                                        }}
                                        _focus={{
                                            outline: 'none'
                                        }}
                                    />
                                    <MenuList>
                                        <MenuItem
                                            _hover={{
                                                borderColor: 'transparent',
                                                backgroundColor: 'gray.200'
                                            }}
                                            _focus={{
                                                outline: 'none'
                                            }}
                                            icon={<FiCopy />}
                                            onClick={() => onDuplicate()}
                                        >
                                            Duplicate
                                        </MenuItem>
                                        <MenuItem
                                            icon={<FiTrash />}
                                            onClick={() => onOpen()}
                                            _hover={{
                                                backgroundColor: 'gray.200',
                                                borderColor: 'transparent'
                                            }}
                                            _focus={{
                                                outline: 'none'
                                            }}
                                        >
                                            Delete
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </Box>
                        </Flex>
                        <AccordionPanel
                            borderColor="#4FD1C5"
                            borderWidth={2}
                            borderRadius="0px 0px 5px 5px"
                            pb={4}
                        >
                            <PageForm page={item} onSave={(values) => onUpdate(values)} />
                        </AccordionPanel>
                    </>
                )}
            </AccordionItem>
        </>
    );
};
