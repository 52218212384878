import { Box, Checkbox, Flex, Input, Text } from '@chakra-ui/react';
import { DateRangeFilter } from 'common/types';
import { DateTime } from 'luxon';
import { ChangeEvent } from 'react';
import { useAppSelector } from 'src/hooks/redux';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import { selectComponentById } from 'src/redux/features/blueprint/bluePrintSlice';
import { useCanvas } from '../../EditorContext';

export const DateRangeConfig = () => {
    const { state: {activeSelectedComponentId} } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId)) as DateRangeFilter;
    const setComponentConfig = useSetComponentConfig();

    const onCheckboxChange = async (
        { target }: ChangeEvent<HTMLInputElement>,
        key: 'minDate' | 'maxDate'
    ) => {
        if (key === 'minDate') {
            if (target.checked) {
                const updated: DateRangeFilter = {
                    ...componentToEdit,
                    minDate: '2000-01-01'
                };
                await setComponentConfig(updated);
            } else {
                const updated: DateRangeFilter = {
                    ...componentToEdit,
                    minDate: null
                };
                await setComponentConfig(updated);
            }
        }

        if (key === 'maxDate') {
            if (target.checked) {
                const updated: DateRangeFilter = {
                    ...componentToEdit,
                    maxDate: DateTime.now().toFormat('yyyy-MM-dd')
                };
                setComponentConfig(updated);
            } else {
                const updated: DateRangeFilter = {
                    ...componentToEdit,
                    maxDate: null
                };
                setComponentConfig(updated);
            }
        }
    };

    return (
        <Flex flexDir={'column'} gap={'2rem'} my={'1rem'} px="1rem">
            {componentToEdit?.type === 'DATE_RANGE' && (
                <Box>
                    <Flex gap={4}>
                        <Checkbox
                            onChange={async (e) => onCheckboxChange(e, 'minDate')}
                            isChecked={Boolean(componentToEdit.minDate)}
                        />
                        <Text>Choose a minimum date</Text>
                    </Flex>
                    <Input
                        size="md"
                        maxW="14rem"
                        type="date"
                        isDisabled={!componentToEdit.minDate}
                        value={componentToEdit.minDate ? componentToEdit.minDate : ''}
                        onChange={({ target }) => {
                            const updated: DateRangeFilter = {
                                ...componentToEdit,
                                minDate: target.value
                            };
                            setComponentConfig(updated);
                        }}
                    />
                </Box>
            )}
            {componentToEdit?.type === 'DATE_RANGE' && (
                <Box>
                    <Flex gap={4}>
                        <Checkbox
                            onChange={(e) => onCheckboxChange(e, 'maxDate')}
                            isChecked={Boolean(componentToEdit.maxDate)}
                        />
                        <Text>Choose the maximum date</Text>
                    </Flex>
                    <Input
                        size="md"
                        type="date"
                        maxW="14rem"
                        isDisabled={!componentToEdit.maxDate}
                        value={componentToEdit.maxDate ? componentToEdit.maxDate : ''}
                        onChange={({ target }) => {
                            const updated: DateRangeFilter = {
                                ...componentToEdit,
                                maxDate: target.value
                            };
                            setComponentConfig(updated);
                        }}
                    />
                </Box>
            )}
        </Flex>
    );
};
