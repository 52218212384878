import { Button, Fade } from "@chakra-ui/react";
import React, { CSSProperties } from "react";
import { useStepList } from "./StepList";

export const NextButton: React.FC<{
  children: React.ReactNode;
  onClick?: () => void;
  display?: boolean;
  styles?: CSSProperties;
}> = ({ children, onClick, display = false, styles }) => {
  const { currentStep, totalSteps, goToNextStep } = useStepList();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      goToNextStep();
    }
  };

  return (
    <Fade in={currentStep !== totalSteps - 1 || display}>
      <Button variant={"solid"} onClick={() => handleOnClick()} style={styles}>
        {children}
      </Button>
    </Fade>
  );
};
