import { Box, Flex } from "@chakra-ui/react";
import React, { FC } from "react";
import { NextButton } from "./NextButton";
import { PreviousButton } from "./PreviousButton";
import { useStepList } from "./StepList";
import { StepProps } from "./StepList.types";

export const Step: FC<StepProps> = ({
  children,
  component,
  isActive,
  displayPreviousButton = true,
  displayNextButton = true,
  previousButtonText,
  nextButtonText,
  onClickPrevious,
  onClickNext,
  nextButtonStyles,
  previousButtonStyles,
}) => {
  const { goToNextStep, goToPreviousStep } = useStepList();
  if (isActive === false) return null;
  return (
    <Flex flexDir="column" justifyContent={"space-between"}>
      <Box>
        {component
          ? React.createElement(component)
          : React.Children.map(children, (child) => {
              return React.cloneElement(child as React.ReactElement, {
                goToNextStep,
                goToPreviousStep,
              });
            })}
      </Box>
      <Flex justifyContent={"space-between"} my="2rem">
        {displayPreviousButton && (
          <PreviousButton onClick={onClickPrevious} styles={previousButtonStyles}>
            {previousButtonText ? previousButtonText : "Previous"}
          </PreviousButton>
        )}
        <NextButton onClick={onClickNext} display={displayNextButton} styles={nextButtonStyles}>
          {nextButtonText ? nextButtonText : "Next"}
        </NextButton>
      </Flex>
    </Flex>
  );
};
