import {
    Box,
    Flex,
    ScaleFade,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from '@chakra-ui/react';


import { useCanvas } from '../EditorContext';
import DefaultFiltersTab from './DefaultFilters/DefaultFiltersTab';
import DnDConfig from './DnDConfig/DnDConfig';
import UIComponentEditor from './JSONEditors/UIComponentEditor';
import UIReportEditor from './JSONEditors/UIReportPageEditor';
import UIThemeEditor from './JSONEditors/UIThemeEditor';
import UIReport from './UIReport';

const EditorTab = () => {
    const { state } = useCanvas();
    return (
        <Box gap={10} display={'flex'} flexDir="column" id="canvas-toolbar">
            <Box>
                <Tabs variant="enclosed">
                    <TabList justifyContent={'space-between'}>
                        <Flex>
                            <Tab>UI report</Tab>
                            <Tab>UI report Config</Tab>
                            <Tab>UI theme</Tab>
                        </Flex>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <UIReportEditor />
                        </TabPanel>
                        <TabPanel>
                            <UIReport />
                        </TabPanel>
                        <TabPanel>
                            <UIThemeEditor />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
            <ScaleFade in={Boolean(state?.activeSelection.size === 1)} unmountOnExit>
                <Box>
                    <Tabs variant="enclosed">
                        <TabList justifyContent={'space-between'}>
                            <Tab style={{ borderRadius: '5px 5px 0px 0px' }}>
                                Component
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Tabs position="relative" variant="unstyled">
                                    <TabList>
                                        <Tab>JSON</Tab>
                                        <Tab>Data Config</Tab>
                                        <Tab>DefaultFilters</Tab>
                                    </TabList>
                                    <TabIndicator
                                        mt="-1.5px"
                                        height="2px"
                                        bg="blue.500"
                                        borderRadius="1px"
                                    />
                                    <TabPanels>
                                        <TabPanel padding={0}>
                                            <UIComponentEditor />
                                        </TabPanel>

                                        <TabPanel padding={0}>
                                            <DnDConfig />
                                        </TabPanel>
                                        <TabPanel padding={0}>
                                            <DefaultFiltersTab />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </ScaleFade>
        </Box>
    );
};

export default EditorTab;
