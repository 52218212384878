import React, { useMemo, useState } from 'react';
import { Box, Button, Flex, Select, Input, VStack, Text } from '@chakra-ui/react';
import { ActionDelete, SourceIcon } from '@tasklogy/zircon-ui-components';
import {
    DefaultFilterConfig,
    DefaultFilterExpression,
    DimensionConfigWithId,
    MetricConfigWithId
} from 'common/types';
import {
    ColumnFormatType,
    IncludeExcludeEnum,
    NumericOperators,
    StringOperators
} from 'common/enums';
import { DataSource, DefaultFilter } from 'src/redux/types/api';
import { defaultFilterValidator, reportValidator } from 'common/validators';
import {
    useCreateReportDefaultFilterMutation,
    useUpdateReportDefaultFilterMutation
} from '@api';

import { toast } from 'react-toastify';

type Field = MetricConfigWithId | DimensionConfigWithId;

interface Props {
    metrics: MetricConfigWithId[];
    dimensions: DimensionConfigWithId[];
    editedFilter?: DefaultFilter | null;
    reportId: number;
    dataSource: DataSource;
    onCanceled: () => void;
    onSave?: () => void;
}

const example = [
    [
        {
            field: '',
            operator: NumericOperators.EQ,
            value: '',
            filterType: IncludeExcludeEnum.INCLUDE
        }
    ]
];

type Operator = NumericOperators | StringOperators;

const operatorDisplayNames: Record<Operator, string> = {
    EQ: 'Equals',
    GT: 'Greater than',
    LT: 'Less than',
    GTE: 'Greater than or equal',
    LTE: 'Less than or equal',
    IS_NULL: 'Is null',
    CONTAINS: 'Contains',
    STARTS_WITH: 'Starts with'
};

// This function returns the correct operators based on the selected field's formatType
const getOperators = (field: Field) => {
    if (
        [
            ColumnFormatType.CURRENCY,
            ColumnFormatType.NUMBER,
            ColumnFormatType.PERCENTAGE
        ].includes(field.formatType)
    ) {
        return Object.values(NumericOperators);
    }

    if ([ColumnFormatType.STRING].includes(field.formatType)) {
        return Object.values(StringOperators);
    }

    return [];
};

// This function returns the correct input type based on the selected field's formatType
const getInputType = (field: Field) => {
    if (
        [
            ColumnFormatType.CURRENCY,
            ColumnFormatType.NUMBER,
            ColumnFormatType.PERCENTAGE
        ].includes(field.formatType)
    ) {
        return 'number';
    }

    return 'text';
};

const deepCopyFilterConfig = (config: DefaultFilterConfig) => {
    return config.map((andGroup) => {
        return andGroup.map((orGroup) => ({ ...orGroup }));
    });
};

const DefaultFiltersBuilder = ({
    dimensions,
    metrics,
    editedFilter,
    onCanceled,
    reportId,
    dataSource,
    onSave
}: Props) => {
    const [createDefaultFilter] = useCreateReportDefaultFilterMutation();
    const [updateDefaultFilter] = useUpdateReportDefaultFilterMutation();
    const [name, setName] = useState(editedFilter ? editedFilter.name : '');
    const [filterConfig, setFilterConfig] = useState<DefaultFilterConfig>(
        editedFilter ? editedFilter.config : example
    );

    const addFilter = (parentIndex?: number) => {
        const newFilter: DefaultFilterExpression = {
            field: '',
            operator: NumericOperators.EQ,
            value: '',
            filterType: IncludeExcludeEnum.INCLUDE
        };

        if (parentIndex !== undefined) {
            const newFilters = deepCopyFilterConfig(filterConfig);

            // Check if the existing OR group contains either metrics or dimensions
            const containsMetric = newFilters[parentIndex].some((f) =>
                metrics.some((m) => m.id === f.field)
            );
            const containsDimension = newFilters[parentIndex].some((f) =>
                dimensions.some((d) => d.id === f.field)
            );

            // Set the type of the new filter based on the existing fields in the OR group
            if (containsMetric) {
                newFilter.field = metrics[0]?.id || ''; // Default to the first metric
            } else if (containsDimension) {
                newFilter.field = dimensions[0]?.id || ''; // Default to the first dimension
            } else {
                // If the group is empty, allow adding any type
                newFilter.field = metrics[0]?.id || dimensions[0]?.id || '';
            }

            newFilters[parentIndex].push(newFilter);
            setFilterConfig(newFilters);
        } else {
            setFilterConfig([...filterConfig, [newFilter]]);
        }
    };

    const isValid = useMemo(() => {
        const result = defaultFilterValidator.defaultFilterConfig.safeParse(filterConfig);
        return result.success;
    }, [filterConfig]);

    const updateFilter = (
        parentIndex: number,
        index: number,
        key: keyof DefaultFilterExpression,
        value: any
    ) => {
        const newFilters = deepCopyFilterConfig(filterConfig);
        const filter = newFilters[parentIndex][index];

        if (key === 'field') {
            const selectedField =
                metrics.find((f) => f.id === value) ??
                dimensions.find((f) => f.id === value);

            if (selectedField) {
                // Determine if the selected field is a metric or a dimension
                const isNewFieldMetric = metrics.some((m) => m.id === value);
                const isNewFieldDimension = dimensions.some((d) => d.id === value);

                // Determine if the OR group already contains fields of a specific type
                const containsMetric = newFilters[parentIndex].some((f) =>
                    metrics.some((m) => m.id === f.field)
                );

                const containsDimension = newFilters[parentIndex].some((f) =>
                    dimensions.some((d) => d.id === f.field)
                );

                const groupSize = newFilters[parentIndex]?.length;

                // If the group already contains fields of the opposite type, prevent the update
                if (
                    (isNewFieldMetric && containsDimension && groupSize > 1) ||
                    (isNewFieldDimension && containsMetric && groupSize > 1)
                ) {
                    toast.error(
                        'You can only have either metrics or dimensions in an OR group.'
                    );
                    return; // Prevent the update
                }

                // If allowed, update the operator and reset the value
                filter.operator = getOperators(selectedField)[0]; // Default to the first operator
                filter.value = '';
            }
        }

        if (key === 'operator') {
            if (value === StringOperators.IS_NULL || value === NumericOperators.IS_NULL) {
                filter.value = null;
            }
        }

        // @ts-expect-error - This is a dynamic key
        filter[key] = value;

        setFilterConfig(newFilters);
    };

    const removeFilter = (parentIndex: number, index: number) => {
        const newFilters = deepCopyFilterConfig(filterConfig);
        newFilters[parentIndex].splice(index, 1);
        if (newFilters[parentIndex].length === 0) {
            newFilters.splice(parentIndex, 1);
        }
        setFilterConfig(newFilters);
    };

    const handleSave = async () => {
        const result = defaultFilterValidator.defaultFilterConfig.safeParse(filterConfig);

        const body = {
            reportId,
            dataSourceId: dataSource.id,
            name,
            config: filterConfig
        };

        reportValidator.createDefaultFilter.parse(body);

        if (result.success) {
            try {
                if (editedFilter) {
                    await updateDefaultFilter({
                        id: editedFilter.id,
                        name,
                        config: filterConfig
                    }).unwrap();
                } else {
                    await createDefaultFilter({
                        reportId,
                        dataSourceId: dataSource.id,
                        name,
                        config: filterConfig
                    }).unwrap();
                }
                toast.success('Default filter saved successfully.');
                onSave?.();
                onCanceled();
            } catch (error) {
                toast.error('An error occurred while saving the default filter');
                console.error(error);
            }
        } else {
            toast.error('Invalid filter configuration');
        }
    };

    return (
        <Flex flexDir="column">
            <Flex flexDir="row" alignItems="center" mb="1rem" gap="1rem">
                <Box w="max-content">
                    <Input
                        w="20rem"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        placeholder="Filter name"
                    />
                </Box>
                <SourceIcon withText selectedIcons={[dataSource.identifier]} />
            </Flex>

            <VStack spacing={2} align="stretch">
                {filterConfig.map((orGroup, parentIndex) => (
                    <React.Fragment key={parentIndex}>
                        <Box p={4} borderWidth="1px" borderRadius="md" shadow="md">
                            {orGroup.map((filter, index) => {
                                const foundMetric = metrics.find(
                                    (m) => m.id === filter.field
                                );

                                const foundDimension = dimensions.find(
                                    (d) => d.id === filter.field
                                );

                                const selectedField = foundMetric ?? foundDimension;

                                return (
                                    <React.Fragment key={index}>
                                        <Flex align="center" mb={2}>
                                            <Select
                                                value={filter.field}
                                                onChange={(e) =>
                                                    updateFilter(
                                                        parentIndex,
                                                        index,
                                                        'field',
                                                        e.target.value
                                                    )
                                                }
                                                mr={2}
                                            >
                                                <option value="">Select Field</option>
                                                {filterConfig[parentIndex].length <= 1
                                                    ? // If the group has one or no items, show both dimensions and metrics
                                                      [...metrics, ...dimensions].map(
                                                          (field) => (
                                                              <option
                                                                  key={field.id}
                                                                  value={field.id}
                                                              >
                                                                  {
                                                                      field.defaultDisplayName
                                                                  }
                                                              </option>
                                                          )
                                                      )
                                                    : // If the group has more than one item, filter based on the existing type
                                                      filterConfig[parentIndex].some(
                                                            (f) =>
                                                                metrics.some(
                                                                    (m) =>
                                                                        m.id === f.field
                                                                )
                                                        )
                                                      ? // Show only metrics if there's already a metric in the group
                                                        metrics.map((metric) => (
                                                            <option
                                                                key={metric.id}
                                                                value={metric.id}
                                                            >
                                                                {
                                                                    metric.defaultDisplayName
                                                                }
                                                            </option>
                                                        ))
                                                      : // Show only dimensions if there's already a dimension in the group
                                                        dimensions.map((dimension) => (
                                                            <option
                                                                key={dimension.id}
                                                                value={dimension.id}
                                                            >
                                                                {
                                                                    dimension.defaultDisplayName
                                                                }
                                                            </option>
                                                        ))}
                                            </Select>

                                            <Select
                                                placeholder="Select Operator"
                                                value={filter.operator}
                                                onChange={(e) =>
                                                    updateFilter(
                                                        parentIndex,
                                                        index,
                                                        'operator',
                                                        e.target.value as
                                                            | NumericOperators
                                                            | StringOperators
                                                    )
                                                }
                                                mr={2}
                                            >
                                                {selectedField &&
                                                    getOperators(selectedField).map(
                                                        (operator) => (
                                                            <option
                                                                key={operator}
                                                                value={operator}
                                                            >
                                                                {
                                                                    operatorDisplayNames[
                                                                        operator
                                                                    ]
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                            </Select>

                                            <Input
                                                placeholder="Value"
                                                value={filter.value as string}
                                                onChange={(e) =>
                                                    updateFilter(
                                                        parentIndex,
                                                        index,
                                                        'value',
                                                        e.target.value
                                                    )
                                                }
                                                mr={2}
                                                type={
                                                    selectedField
                                                        ? getInputType(selectedField)
                                                        : 'text'
                                                }
                                                isDisabled={
                                                    filter.operator ===
                                                        StringOperators.IS_NULL ||
                                                    filter.operator ===
                                                        NumericOperators.IS_NULL
                                                }
                                            />

                                            <Select
                                                value={filter.filterType}
                                                onChange={(e) =>
                                                    updateFilter(
                                                        parentIndex,
                                                        index,
                                                        'filterType',
                                                        e.target
                                                            .value as IncludeExcludeEnum
                                                    )
                                                }
                                                mr={2}
                                            >
                                                <option
                                                    value={IncludeExcludeEnum.INCLUDE}
                                                >
                                                    Include
                                                </option>
                                                <option
                                                    value={IncludeExcludeEnum.EXCLUDE}
                                                >
                                                    Exclude
                                                </option>
                                            </Select>

                                            <ActionDelete
                                                onClick={() =>
                                                    removeFilter(parentIndex, index)
                                                }
                                            />
                                        </Flex>
                                        {index < orGroup.length - 1 && (
                                            <Text ml="0.5rem" my="0.25rem">
                                                OR
                                            </Text>
                                        )}
                                    </React.Fragment>
                                );
                            })}

                            <Button
                                size="xs"
                                mt="1rem"
                                w="112px"
                                variant="solid"
                                onClick={() => addFilter(parentIndex)}
                            >
                                + OR
                            </Button>
                        </Box>
                        {parentIndex < filterConfig.length - 1 && (
                            <Text ml="0.5rem">AND</Text>
                        )}
                    </React.Fragment>
                ))}

                <Box mb="1rem" mt="0.5rem" ml="1rem">
                    <Button
                        size="xs"
                        w="112px"
                        variant="solid"
                        onClick={() => addFilter()}
                    >
                        + AND
                    </Button>
                </Box>
            </VStack>
            <Flex gap="0.5rem" justifyContent="end">
                <Button w="8rem" variant="outline" onClick={onCanceled}>
                    Close
                </Button>
                <Button
                    w="8rem"
                    isDisabled={!isValid || !name}
                    _disabled={{
                        cursor: 'not-allowed',
                        backgroundColor: 'gray.300',
                        _hover: {
                            backgroundColor: 'gray.300'
                        }
                    }}
                    variant="solid"
                    onClick={handleSave}
                >
                    {editedFilter ? 'Update' : 'Create'}
                </Button>
            </Flex>
        </Flex>
    );
};

export default DefaultFiltersBuilder;
