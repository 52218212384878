import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ZirconOnlyLogo from '../../assets/svg/zircon-logo.svg';
import { Box, Flex, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import ZirconTreeText from '../../assets/svg/zirconTreeText.svg';

const ZirconLogo: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
    const [hidden, setHidden] = useState(collapsed);
    return (
        <React.Fragment>
            <Flex gap={2} height={'30px'} alignItems={'center'}>
                <Box backgroundColor={'rgba(82, 90, 102, 1)'}>
                    <Image src={ZirconOnlyLogo} height={'30px'} pl={'25px'} />
                </Box>
                <Flex
                    style={{
                        height: '25px',
                        position: 'absolute',
                        left: '60px',
                        zIndex: -1,
                        gap: '20px',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <motion.img
                        hidden={hidden}
                        initial={false}
                        onAnimationStart={() => setHidden(false)}
                        onAnimationComplete={() => setHidden(collapsed)}
                        animate={{
                            left: collapsed ? -100 : 60,
                            opacity: collapsed ? 0 : 1
                        }}
                        src={ZirconTreeText}
                        transition={{ duration: 0.3 }}
                    ></motion.img>
                    {collapsed ? (
                        <ChevronRightIcon
                            color="white"
                            fontSize="30px"
                            position="relative"
                            left="-11px"
                        ></ChevronRightIcon>
                    ) : (
                        <ChevronLeftIcon
                            color="white"
                            fontSize="30px"
                            position="relative"
                            left="0"
                        ></ChevronLeftIcon>
                    )}
                </Flex>
            </Flex>
        </React.Fragment>
    );
};
export default ZirconLogo;
