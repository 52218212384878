import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { getColor, mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    inputAnatomy.keys
);

export function getDefaults(props: Record<string, any>) {
    const { focusBorderColor: fc, errorBorderColor: ec } = props
    return {
        focusBorderColor: fc || mode("blue.500", "blue.300")(props),
        errorBorderColor: ec || mode("red.500", "red.300")(props),
    }
}

const baseStyle = definePartsStyle(() => {
    return {
        field: {
            height: '52px',
            width: '100%',
            minWidth: 0,
            outline: 0,
            color: 'black',
            position: 'relative',
            appearance: 'none',
            transitionProperty: 'common',
            transitionDuration: 'normal',
            _disabled: {
                opacity: 0.4,
                cursor: 'not-allowed'
            }
        },
        element: {
            height: '100%',
            bg: 'transparent'
        }
    };
});

const variantOutline = definePartsStyle({
    field: {
        border: '1px solid #DFDFDF',
        borderColor: '#DFDFDF',
        borderRadius: '7px',
        boxShadow: '0 3px 14px rgba(0, 0, 0, 0.08)',
        _focusVisible: {
            boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
        },
        bg: 'white'
    },
    element: {
        '> svg': {
            color: '#878790',
            bg: 'transparent'
        }
    }
});

const variantUnstyled = definePartsStyle({
    field: {
        border: '1px solid #DFDFDF',
        borderColor: '#DFDFDF',
        borderRadius: '7px',
        boxShadow: '0 3px 14px rgba(0, 0, 0, 0.08)',
        _focusVisible: {
            boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
        }
    },
    element: {
        bg: 'white'
    }
});

const variantEditor = definePartsStyle((props) => {
    const { errorBorderColor: ec } = getDefaults(props)
    return {
        field: {
            border: '1px solid #F2F3F4',
            fontSize: '12px',
            borderRadius: '6px',
            boxShadow: 'none',
            backgroundColor: '#F2F3F4',
            color: '#242424',
            _focusVisible: {
                border: '1px solid #DFDFDF',
                boxShadow: 'none'
            },
            _invalid: {
                borderColor: ec,
            }
        },
        addon: {
            border: "0px solid",
            borderColor: "transparent",
            bg: 'white',
            color: "white",
        },
        element: {
            bg: 'white',
            border: "0px solid"
        }
    }
});


const pill = definePartsStyle({
    field: {
      border: '1px solid',
      borderRadius: 'full',
      borderColor: '#3c4043',
      background: '#303135',
      color: "#f1f3f4"
    }
  })

const variants = {
    outline: variantOutline,
    unstyled: variantUnstyled,
    editor: variantEditor,
    pill: pill
};

const xs = defineStyle({
    height: '32px'
});

const sm = defineStyle({
    height: '36px'
});

const md = defineStyle({
    height: '40px'
});

const lg = defineStyle({
    height: '48px'
});

const xl = defineStyle({
    height: '52px'
});

const sizes = {
    xs: definePartsStyle({ field: xs, addon: xs, element: xs }),
    sm: definePartsStyle({ field: sm, addon: sm, element: sm }),
    md: definePartsStyle({ field: md, addon: md, element: md }),
    lg: definePartsStyle({ field: lg, addon: lg, element: lg }),
    xl: definePartsStyle({ field: xl, addon: xl, element: xl })
};

export const inputTheme = defineMultiStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        size: 'md',
        variant: 'outline'
    }
});
