import { Box } from '@chakra-ui/react';

import React from 'react';

import SetupForm from '../../forms/setup/SetupForm';

const CommonSetup: React.FC = () => {
    return (
        <Box>
            <SetupForm />
        </Box>
    );
};

export default CommonSetup;
