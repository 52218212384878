import { useDeleteClientMutation } from '@api';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { ActionDelete, ActionEdit, HappyModal } from '@tasklogy/zircon-ui-components';
import { useModal } from 'src/hooks/useModal';
import { Client } from 'src/redux/types/api';
import { toast } from 'react-toastify';
import AuthorizedWithScopePermissions from 'src/auth/AuthorizedWithScopePermissions';
import UpdateClientModal from 'src/blueprint/pages/Clients/UpdateClientModal';

interface ActionDeleteClientProps {
    client: Client;
}

const ActionDeleteClient = ({ client }: ActionDeleteClientProps) => {
    const [deleteClient] = useDeleteClientMutation();

    const {
        open: openDeleteModal,
        close: closeDeleteModal,
        isOpen: isDeleteModalOpen,
        data: deleteModalData
    } = useModal<Client>();

    return (
        <>
            <ActionDelete
                onClick={() => {
                    openDeleteModal(client);
                }}
            />
            <HappyModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                <HappyModal.Title>Attention</HappyModal.Title>
                <HappyModal.Body>
                    <Box>
                        Are you sure you want to delete client
                        <Text as="span" ml="0.125rem" fontWeight="bold">
                            {deleteModalData?.name}
                        </Text>
                        ? By deleting this client, you will also delete client's reports
                        and connected accounts.
                    </Box>
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={closeDeleteModal}>
                        No
                    </Button>
                    <Button
                        variant="solid"
                        colorScheme="error"
                        onClick={async () => {
                            try {
                                assertIsDefined(deleteModalData);
                                await deleteClient({ id: deleteModalData?.id });
                                closeDeleteModal();
                                toast.success('Client deleted');
                            } catch (e) {
                                console.error(e);
                                toast.error('Failed to delete client');
                            }
                        }}
                    >
                        Yes
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
        </>
    );
};

interface Props {
    client: Client;
}

export default function ActionCell({ client }: Props) {
    const { open, close, isOpen } = useModal();
    return (
        <Flex ml="-1rem" alignItems="center" justifyContent="right">
            <AuthorizedWithScopePermissions
                scopePermissions={{
                    team: ['delete-client']
                }}
            >
                <ActionDeleteClient client={client} />
            </AuthorizedWithScopePermissions>
            <AuthorizedWithScopePermissions
                scopePermissions={{
                    team: ['edit-client-name']
                }}
            >
                <ActionEdit onClick={open} />
                <UpdateClientModal onClose={close} isOpen={isOpen} client={client} />
            </AuthorizedWithScopePermissions>
        </Flex>
    );
}
