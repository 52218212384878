import { Box } from '@chakra-ui/react';
import { CSSProperties } from 'react';

interface Props {
    properties: {
        style?: CSSProperties;
    };
}
export default function Rectangle({ properties }: Props) {
    const {backgroundImage, ...restStyle} = properties.style || {};
    const backgroundImageStyle = backgroundImage?.includes('url(http') ? {backgroundImage} : {};
    return <Box w="100%" h="100%" pointerEvents='none' style={{...restStyle, ...backgroundImageStyle}} />;
}
