import { Flex, Text } from "@chakra-ui/react"
import { FieldValues, Path } from "react-hook-form"
import ColorForm from "./ColorForm"
import RadiusForm from "./RadiusForm"
import WidthForm from "./WidthForm"


const BorderForm = <T extends FieldValues>({ objPath }: { objPath: Path<T>, type?: any }) => {
    return <Flex flexDir='column'>
        <Flex my={4}>
            <Text textStyle='_h2'>Border</Text>
        </Flex>
        <ColorForm<T> objPath={objPath} />
        <WidthForm<T> objPath={objPath} />
        <RadiusForm<T> objPath={objPath} />
    </Flex>
}

export default BorderForm;