import z from 'zod';
import { DataSourceIdentifier } from '../enums.js';
export const createFromCredentials = z.object({
    sourceIdentifier: z.nativeEnum(DataSourceIdentifier),
    password: z
        .string({ message: 'Password is required.' })
        .min(1, { message: 'Password is required.' }),
    username: z
        .string({ message: 'Username is required.' })
        .min(1, { message: 'Username is required.' })
});
export const refresh = z.object({
    sourceAuthId: z.number()
});
