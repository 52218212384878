import { Box } from '@chakra-ui/react';
import { ClientIdParams, ReportSlugParams, ViewSlugParams } from '@router';
import { useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import CanvasProvider from 'src/blueprint/pages/editor/EditorContext';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useLazyGetReportBySlugQuery } from 'src/redux/api';
import {
    selectActiveReport,
    selectActiveReportPage,
    setActiveReportPageId
} from 'src/redux/features/blueprint/bluePrintSlice';
import { PathNames } from 'src/router/router';
import { Root } from './Root';

const LivePreview = () => {
    const activeReport = useAppSelector(selectActiveReport);
    const activePage = useAppSelector(selectActiveReportPage);
    const params = useParams<ReportSlugParams & ViewSlugParams & ClientIdParams>();
    const [fetchReport] = useLazyGetReportBySlugQuery();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!activeReport?.pages) {
            fetchReport({ slug: params.reportSlug, clientId: +params.clientId! });
        }
    }, [fetchReport, params.reportSlug, activeReport?.pages]);

    useEffect(() => {
        if (params.viewSlug && activeReport?.pages) {
            const page = activeReport?.pages?.find((p: any) => p.slug === params.viewSlug);

            if (page) {
                dispatch(setActiveReportPageId(page.id));
            } else {
                dispatch(setActiveReportPageId(activeReport.pages[0].id));
            }
        }
    }, [activeReport?.pages, dispatch, params.viewSlug]);

    if (!params.clientId) {
        return null;
    }

    if (!activeReport) {
        return null;
    }

    return (
        <Box overflowX="auto">
            <CanvasProvider
                useEditorReport={false}
                useSharedReportAPI={false}
                reportBasePathName={generatePath(PathNames.BASE_LIVE_PREVIEW, {
                    clientId: params.clientId!
                })}

            height={activePage?.layout?.pageHeight}
            width={activePage?.layout?.pageWidth}
            >
                <Root />
            </CanvasProvider>
        </Box>
    );
};

export default LivePreview;
