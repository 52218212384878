import { Box, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { defaultTheme } from "common/constants";
import { styleValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import ColorForm from "../../forms/ColorForm";
import PositionForm from "../../forms/PositionForm";
import TextForm from "../../forms/TextForm";
import StyleDivider from "../../StyleDivider";

type FormData = z.infer<typeof styleValidator.multiSelect>

const MultiSelectStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultTheme.components.MULTI_SELECT,
        values: componentToEdit as any,
        resolver: zodResolver(styleValidator.multiSelect),
        mode: 'onBlur',
    })

    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <StyleDivider />
                <FormAccordion>
                    <FormAccordionItem title="Component styles">
                        <TextForm<FormData> objPath={'properties.labelStyle'} textTitle="Label" textPath="properties.label" />
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Control">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Color' name={"properties.placeholderStyle.color"} />
                            <ColorForm<FormData> title='Background Color' name={"properties.controlStyle.backgroundColor"} />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Dropdown Indicator">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.dropdownIndicatorStyle.color'} />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Indicator Separator">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.indicatorSeparatorStyle.backgroundColor'} />
                        </Flex>
                    </FormAccordionItem>
                </FormAccordion>
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={defaultTheme.components.MULTI_SELECT} />
            </form>
        </FormProvider>
    </Box>
}

export default MultiSelectStyle;