import defaultTable, { copyTableStyles } from './table.js';
import defaultDonutChart, { copyDonutChartStyles } from './donut_chart.js';
import defaultMultiSelect, { copyMultiSelectStyles } from './multi_select.js';
import defaultSingleValue, { copySingleValueStyles } from './single_value.js';
import defaultDateRange, { copyDateRangeStyles } from './date_range.js';
import defaultPieChart, { copyPieChartStyles } from './pie_chart.js';
import defaultLineChart, { copyLineChartStyles } from './line_chart.js';
import defaultAreaChart, { copyAreaChartStyles } from './area_chart.js';
import { ComponentType } from '../../enums.js';
import defaultRectangle, { copyRectangleStyles } from './rectangle.js';
import defaultText, { copyTextStyles } from './text.js';
import defaultNavigationButton, { copyNavigationButtonStyles } from './navigation_button.js';
import defaultImage from './image.js';
import { copyTinyBarChartStyles, defaultTinyBarChart } from './tiny_bar_chart.js';
import { copyBiaxialBarChartStyles, defalutBiaxialBarChart } from './biaxialBarChart.js';
import { copyStackedBarChartStyles, defalutStackedBarChart } from './stackedBarChart.js';
import { copySingleSelectStyles, defaultSingleSelect } from './singleSelect.js';
import { copyDownloadButtonStyles, defaultDownloadButton } from './downloadButton.js';
import { copyNavbarStyles, defaultNavbar } from './navbar.js';
const componentsStyles = {
    [ComponentType.TABLE]: copyTableStyles,
    [ComponentType.DONUT_CHART]: copyDonutChartStyles,
    [ComponentType.MULTI_SELECT]: copyMultiSelectStyles,
    [ComponentType.SINGLE_VALUE]: copySingleValueStyles,
    [ComponentType.DATE_RANGE]: copyDateRangeStyles,
    [ComponentType.RECTANGLE]: copyRectangleStyles,
    [ComponentType.TEXT]: copyTextStyles,
    [ComponentType.NAVIGATION_BUTTON]: copyNavigationButtonStyles,
    [ComponentType.PIE_CHART]: copyPieChartStyles,
    [ComponentType.LINE_CHART]: copyLineChartStyles,
    [ComponentType.AREA_CHART]: copyAreaChartStyles,
    [ComponentType.TINY_BAR_CHART]: copyTinyBarChartStyles,
    [ComponentType.BIAXIAL_BAR_CHART]: copyBiaxialBarChartStyles,
    [ComponentType.STACKED_BAR_CHART]: copyStackedBarChartStyles,
    [ComponentType.SINGLE_SELECT]: copySingleSelectStyles,
    [ComponentType.DOWNLOAD_BUTTON]: copyDownloadButtonStyles,
    [ComponentType.NAVBAR]: copyNavbarStyles
};
const defaultTheme = {
    components: {
        [ComponentType.TABLE]: defaultTable,
        [ComponentType.DONUT_CHART]: defaultDonutChart,
        [ComponentType.MULTI_SELECT]: defaultMultiSelect,
        [ComponentType.SINGLE_VALUE]: defaultSingleValue,
        [ComponentType.DATE_RANGE]: defaultDateRange,
        [ComponentType.RECTANGLE]: defaultRectangle,
        [ComponentType.TEXT]: defaultText,
        [ComponentType.NAVIGATION_BUTTON]: defaultNavigationButton,
        [ComponentType.PIE_CHART]: defaultPieChart,
        [ComponentType.LINE_CHART]: defaultLineChart,
        [ComponentType.AREA_CHART]: defaultAreaChart,
        [ComponentType.IMAGE]: defaultImage,
        [ComponentType.TINY_BAR_CHART]: defaultTinyBarChart,
        [ComponentType.BIAXIAL_BAR_CHART]: defalutBiaxialBarChart,
        [ComponentType.STACKED_BAR_CHART]: defalutStackedBarChart,
        [ComponentType.SINGLE_SELECT]: defaultSingleSelect,
        [ComponentType.DOWNLOAD_BUTTON]: defaultDownloadButton,
        [ComponentType.NAVBAR]: defaultNavbar
    },
    copyProperties: (componentType, properties) => {
        if (!componentsStyles[componentType]) {
            return properties;
        }
        return componentsStyles[componentType](properties);
    }
};
export default defaultTheme;
