import z from 'zod';

export const createClientValidation = z.object({
    name: z
        .string()
        .min(3, { message: 'The name must have a minimum length of 3 characters' })
        .max(255),
    customDomain: z.string().url({ message: 'Invalid URL address' }).optional()
});

export const createConnectedSourceValidation = z.object({
    parentAccount: z
        .object({
            parentAccountId: z.string(),
            parentAccountName: z.string()
        })
        .optional(),
    advertiser: z.object(
        {
            id: z.number(),
            advertiserId: z.string(),
            advertiserName: z.string()
        },
        { message: 'You must select an advertiser.' }
    )
});
