import { Text, Textarea } from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ComponentUI } from '@types';
import { Component } from 'common/types';
import React, { useEffect, useState } from 'react';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import { selectComponentById } from 'src/redux/features/blueprint/bluePrintSlice';
import { assertIsDefined } from '../utils';

const GoText = ({ properties, id }: ComponentUI) => {
    const componentToEdit = useAppSelector(selectComponentById(id));
    const textValue = componentToEdit?.properties?.text ?? '';
    const [text, setText] = useState(textValue);
    const { state, eventEmitter, actions } = useCanvas();
    const setComponentConfig = useSetComponentConfig();

    useEffect(() => {
        setText(textValue);
    }, [textValue]);

    const handleSave = React.useCallback(async () => {
        assertIsDefined(componentToEdit);
        const component = {
            ...componentToEdit,
            properties: {
                ...componentToEdit.properties,
                text: text
            }
        } as Component;
        await setComponentConfig(component);
        actions.setActiveSelection(new Set());
    }, [componentToEdit, text, setComponentConfig, actions]);

    eventEmitter.useSubscription((val) => {
        if (val === 'save-inline-edit') {
            handleSave();
        }
    });

    if (state.enabledInlineEditor && state.activeSelectedComponentId === id) {
        return (
            <Textarea
                variant={'unstyled'}
                style={properties.style}
                value={text}
                maxW='none'
                onChange={(e) => setText(e.target.value)}
            />
        );
    }
    // handle \n in text
    const textWithLineBreak = text?.includes('\n')
        ? text.split('\n').map((str: string, index: number) => (
              <React.Fragment key={index}>
                  {str}
                  <br />
              </React.Fragment>
          ))
        : text;
    return <Text style={properties.style}>{textWithLineBreak}</Text>;
};

export default GoText;
