import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import { useModalStepList } from './ModalStepList';
import { ModalStepProps } from './ModalStepList.types';

const MotionModalContent = motion(ModalContent);

export const ModalStep: FC<ModalStepProps> = ({
    children,
    isActive,
    modalProps,
    header
}) => {
    const { setModalProps, onClose } = useModalStepList();
    useEffect(() => {
        if (isActive && modalProps) {
            setModalProps(modalProps);
        }
    }, [isActive, modalProps]);
    if (isActive === false) return null;
    return (
        <>
            <ModalOverlay
                backdropBlur="8px"
                bg="blackAlpha.300"
                backdropFilter="blur(8px)"
            />
            <MotionModalContent pt="1rem" px="1rem" pb="1.5rem">
                <ModalHeader
                    p="0"
                    mb="0.375rem"
                    fontSize="20px"
                    color="#525A66"
                    fontWeight="bold"
                    display={'flex'}
                    justifyContent="space-between"
                    flex="1"
                    alignItems={'center'}
                >
                    {header}
                    <ModalCloseButton onClick={onClose} />
                </ModalHeader>
                <ModalCloseButton onClick={onClose} />
                {children}
            </MotionModalContent>
        </>
    );
};
