import ContentLoader from 'react-content-loader';

const DonutChartContentLoader = (props: any) => {
    return (
        <ContentLoader
            speed={1}
            width={2000}
            height={500}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%', padding: '50px' }}
            {...props}
        >
            <rect x="46" y="135" rx="3" ry="3" width="52" height="6" />
            <rect x="100" y="135" rx="3" ry="3" width="52" height="6" />
            <circle cx="100" cy="60" r="55" />
        </ContentLoader>
    );
};

export default DonutChartContentLoader;
