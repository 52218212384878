import { defineStyleConfig } from "@chakra-ui/react";

export const text = defineStyleConfig({
    // Styles for the base style
    baseStyle: ({ colorScheme: c }) => ({
        color: c ? `${c}.500` : '#525A66',
    }),
    // Styles for the size variations
    sizes: {
        small: {},
        md: {},
    },
    // Styles for the visual style variations
    variants: {
        title: {
            fontSize: "33px",
            fontWeight: "bold",
        },
        subtitle: {
            fontSize: "27px",
            fontWeight: "bold",
        },
    },
    // The default `size` or `variant` values
    defaultProps: {},
});
