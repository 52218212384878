import { ButtonGroup, Flex, IconButton, Text } from "@chakra-ui/react";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { GrTextAlignCenter, GrTextAlignLeft, GrTextAlignRight } from "react-icons/gr";
import { MdOutlineVerticalAlignBottom, MdOutlineVerticalAlignCenter, MdOutlineVerticalAlignTop } from "react-icons/md";
import FormTitle from "../layout/FormTitle";

const activeStyle = { backgroundColor: 'white', border: '1px solid', borderColor: 'gray.200', borderRadius: '5px', margin: '2px' }

const AlignmentForm = <T extends FieldValues>({ horizontalAlignPath, verticalAlignPath, name }: { horizontalAlignPath: Path<T>, verticalAlignPath: Path<T>, name: string }) => {
    const methods = useFormContext<T>();

    return <Flex flexDir='column'>
        <Flex my={4}>
            <Text textStyle='_h2'>{name ?? 'Text'}</Text>
        </Flex>
        <Flex gap={'1rem'} alignItems={'center'}>
            <Controller
                name={horizontalAlignPath}
                control={methods.control}
                render={({ field }) => (
                    <ButtonGroup isAttached bg='gray.50'>
                        <IconButton aria-label="left" variant='editor' onClick={() => field.onChange('left')} isActive={field.value === 'left'} _active={activeStyle} icon={<GrTextAlignLeft />} />
                        <IconButton aria-label="center" variant='editor' onClick={() => field.onChange('center')} isActive={field.value === 'center'} _active={activeStyle} icon={<GrTextAlignCenter />} />
                        <IconButton aria-label="right" variant='editor' onClick={() => field.onChange('right')} isActive={field.value === 'right'} _active={activeStyle} icon={<GrTextAlignRight />} />
                    </ButtonGroup>
                )}
            />
            <Controller
                name={verticalAlignPath}
                control={methods.control}
                render={({ field }) => (
                    <ButtonGroup isAttached bg='gray.50'>
                        <IconButton aria-label="top" variant='editor' onClick={() => field.onChange('top')} isActive={field.value === 'top'} _active={activeStyle} icon={<MdOutlineVerticalAlignTop />} />
                        <IconButton aria-label="middle" variant='editor' onClick={() => field.onChange('middle')} isActive={field.value === 'middle'} _active={activeStyle} icon={<MdOutlineVerticalAlignCenter />} />
                        <IconButton aria-label="bottom" variant='editor' onClick={() => field.onChange('bottom')} isActive={field.value === 'bottom'} _active={activeStyle} icon={<MdOutlineVerticalAlignBottom />} />
                    </ButtonGroup>
                )}
            />
        </Flex>
    </Flex>

}

export default AlignmentForm;