import { ComponentType } from '../enums.js';
export const componentTypeToName = {
    [ComponentType.TABLE]: 'Table',
    [ComponentType.DONUT_CHART]: 'Donut Chart',
    [ComponentType.LINE_CHART]: 'Line Chart',
    [ComponentType.AREA_CHART]: 'Area Chart',
    [ComponentType.SINGLE_VALUE]: 'Single Value',
    [ComponentType.PIE_CHART]: 'Pie Chart',
    [ComponentType.SINGLE_SELECT]: 'Single Select',
    [ComponentType.RECTANGLE]: 'Rectangle',
    [ComponentType.TEXT]: 'Text',
    [ComponentType.DOWNLOAD_BUTTON]: 'Download Button',
    [ComponentType.NAVIGATION_BUTTON]: 'Navigation Button',
    [ComponentType.DATE_RANGE]: 'Date Range',
    [ComponentType.MULTI_SELECT]: 'Multi Select',
    [ComponentType.IMAGE]: 'Image',
    [ComponentType.LOGO]: 'Logo',
    [ComponentType.BIAXIAL_BAR_CHART]: 'Biaxial Bar Chart',
    [ComponentType.STACKED_BAR_CHART]: 'Stacked Bar Chart',
    [ComponentType.TINY_BAR_CHART]: 'Tiny Bar Chart',
    [ComponentType.TABLE_PROGRESS_BAR_CHART]: 'Table Progress Bar Chart',
    [ComponentType.NAVBAR]: 'Navbar'
};
