import { DimensionConfigWithId, MetricConfigWithId } from 'common/types';

type SelectedMetric = {
    id: string;
    customDisplayName?: string | null;
    customDecimalPlaces?: number | null;
};

export const getWholeConfigs = (
    selectedMetrics?: SelectedMetric[] | null,
    dataSourceMetricCOnfigs?: MetricConfigWithId[]
) => {
    if (!selectedMetrics) {
        return [];
    }

    return selectedMetrics
        ?.map((dim) => ({
            ...dataSourceMetricCOnfigs?.find((d) => d.id === dim.id),
            customDisplayName: dim.customDisplayName,
            customDecimalPlaces: dim.customDecimalPlaces
        }))
        .filter((d) => d.id) as DimensionConfigWithId[];
};
