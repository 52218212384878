import { debounce } from 'lodash';
import { memo, useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDeepCompareEffect } from 'use-deep-compare';

type Props = {
    defaultValues: any;
    onSubmit: any;
    debounceTime?: number;
    debug?: boolean;
};

const AutoSave = memo(({ defaultValues, onSubmit, debounceTime = 500, debug = false }: Props) => {
    const methods = useFormContext();
    const {errors} = methods.formState;
    if (debug) {
        console.log('Errors', errors);
    }
    const { dirtyFields } = methods.formState;
    const hasDirtyFields = Object.keys(dirtyFields).length > 0;

    // eslint-disable-next-line
    const debouncedSave = useCallback(
        debounce(() => {
            const valuesBeforeSave = methods.getValues();
            if (debug) {
                console.log('AutoSave', valuesBeforeSave);
            }
            methods.handleSubmit(onSubmit)();
        }, debounceTime),
        []
    );

    const watchedData = useWatch({
        control: methods.control,
        defaultValue: defaultValues
    });

    useDeepCompareEffect(() => {
        console.log('Triggered');
        if (hasDirtyFields) {
            console.log('Saving');
            debouncedSave();
        }
    }, [watchedData]);

    return <></>;
});

AutoSave.displayName = 'AutoSave';

export default AutoSave;
