import { IconButton } from '@chakra-ui/react';
import { FiTrash } from 'react-icons/fi';

export interface ActionDeleteProps {
    onClick: () => void;
}

const ActionDelete: React.FC<ActionDeleteProps> = ({ onClick }) => {
    return (
        <IconButton
            variant="icon"
            color="icons.delete"
            h={['1.5rem', '2rem', '2.5rem', '3rem', '3.5rem']}
            w={['1.5rem', '2rem', '2.5rem', '3rem', '3.5rem']}
            onClick={onClick}
            icon={<FiTrash />}
            aria-label={'delete'}
        />
    );
};

export default ActionDelete;
