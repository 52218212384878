// src/auth/AuthProvider.tsx
import React, {
    createContext,
    useState,
    ReactNode,
    useEffect,
    useCallback,
    useMemo
} from 'react';
import { useLazyGetUserMeQuery } from '@api';
import * as authService from 'src/auth/authService';
import { toast } from 'react-toastify';

type Permission = {
    scope: string;
    name: string;
};

export type User = {
    permissions: Permission[];
    scopePermissions?: Record<string, string[]>;
    createdAt: string;
    email: string;
    firstName: string;
    lastName: string;
    id: number;
    phoneNumber?: string;
    role: RoleName;
    roleDisplayname: string;
    updatedAt: string;
    isReportingoAdmin?: boolean;
    teamId?: number;
    team?: Team;
};

import {
    ScopePermissions,
    WithAnyPermissionName,
    WithAnyPermissionScope
} from 'src/router/types';
import { RoleName } from 'common/roles';
import { Team } from 'src/redux/types/api';

interface AuthContextProps {
    user: User | null;
    isAuthenticated: boolean;
    handleCallback: (code: string) => Promise<void>;
    refetchUser?: () => Promise<void>;
    hasScopePermissions: (scopePermissions: ScopePermissions) => boolean;
    hasRoles: (roles: string[]) => boolean;
}

export const AuthContext = createContext<AuthContextProps>({
    isAuthenticated: false,
    user: null,
    handleCallback: async () => {},
    hasScopePermissions: () => false,
    hasRoles: () => false
});

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(authService.getUserFromStorage());

    const [fetchMe] = useLazyGetUserMeQuery(undefined);

    const handleCallback = async (code: string) => {
        try {
            await authService.handleCallback(code);
            const userInfo = await fetchMe({}).unwrap();
            setUser(userInfo);
            authService.saveUserToStorage(userInfo);
        } catch (error) {
            toast.error('An error occurred while logging in.');
            console.error('Error during token exchange', error);
        }
    };

    const refetchUser = useCallback(async () => {
        const userInfo = await fetchMe({}).unwrap();
        setUser(userInfo);
        authService.saveUserToStorage(userInfo);
    }, [fetchMe]);

    useEffect(() => {
        if (user && authService.getTokenResponseFromStorage()) {
            refetchUser();
        }
    }, [user, refetchUser]);

    const userScopePermissions = useMemo(() => {
        const userScopePermissions: Record<string, string[]> = {};

        user?.permissions.forEach(({ scope, name }) => {
            if (!userScopePermissions[scope]) {
                userScopePermissions[scope] = [name];
            } else {
                userScopePermissions[scope].push(name);
            }
        });

        return userScopePermissions;
    }, [user]);

    const hasScopePermissions = useCallback(
        (scopePermissions: ScopePermissions) => {
            const scopes = Object.keys(scopePermissions) as WithAnyPermissionScope[];

            if (!user || !user.permissions) {
                return false;
            }

            // scopes has 'any' as a key, so we need to check if the user has any of the scopes permissions
            if (scopes.includes('any')) {
                const permissions = scopePermissions['any'];
                if (!permissions) {
                    return false;
                }

                return permissions.some(
                    (permission: WithAnyPermissionName) =>
                        permission === 'any' ||
                        user?.permissions.some(
                            (userPermission) => userPermission.name === permission
                        )
                );
            } else {
                return scopes.some((scope) => {
                    const permissions = scopePermissions[scope];
                    const userPermissions = userScopePermissions?.[scope];
                    if (!userPermissions || !permissions) {
                        return false;
                    }

                    return permissions.some(
                        (permission) =>
                            permission === 'any' || userPermissions.includes(permission)
                    );
                });
            }
        },
        [user, userScopePermissions]
    );

    const hasRoles = useCallback(
        (roles: string[]) => {
            const userRole = user?.role;

            if (!user || !userRole) {
                return false;
            }

            return roles.some((role) => userRole === role);
        },
        [user]
    );

    return (
        <AuthContext.Provider
            value={{
                user,
                handleCallback,
                isAuthenticated: !!user,
                refetchUser,
                hasScopePermissions,
                hasRoles
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
