import { Flex, FormControl, FormErrorMessage, IconButton, Text } from '@chakra-ui/react'
import { StyledSelect } from '@tasklogy/zircon-ui-components'
import { getProperty } from 'dot-prop'
import { useId } from 'react'
import { Controller, FieldError, FieldValues, Path, PathValue, useFormContext } from "react-hook-form"
import { GrTextAlignCenter, GrTextAlignLeft, GrTextAlignRight } from 'react-icons/gr'
import { fonts } from 'src/fonts'
import ColorPickerInput from "../inputs/ColorPickerInput"
import FormTextInput from '../inputs/Text'
import FormTextAreaInput from '../inputs/TextArea'
import FormPropertyRow from "../layout/FormPropertyRow"
import FormPropertyTitle from "../layout/FormPropertyTitle"

const FONTS_HELMET = Object.keys(fonts).map((font: string) => ({ value: fonts[font as keyof typeof fonts].name, label: fonts[font as keyof typeof fonts].label, fontWeights: fonts[font as keyof typeof fonts].fontWeights }))

const FONTS: { value: string, label: string, fontWeights: { value: number, label: string }[] }[] = [
    ...FONTS_HELMET.map(font => ({ value: font.value, label: font.label, fontWeights: font.fontWeights })),
    {
        value: 'Arial', label: 'Arial', fontWeights: [{ value: 400, label: 'Regular' },
        { value: 700, label: 'Bold' }]
    }
]

const FONT_SIZES = [
    { value: '12px', label: '12px' },
    { value: '14px', label: '14px' },
    { value: '16px', label: '16px' },
    { value: '18px', label: '18px' },
    { value: '20px', label: '20px' },
    { value: '24px', label: '24px' },
    { value: '32px', label: '32px' },
    { value: '48px', label: '48px' },
    { value: '64px', label: '64px' },
    { value: '72px', label: '72px' },
]

interface TextFormProps {
    objPath: string
    textPath?: string
    textTitle?: string
    asTextarea?: boolean
    withTextAlign?: boolean
    withoutFontFamily?: boolean
    withoutFontSize?: boolean
    withoutFontWeight?: boolean
    withoutColor?: boolean
}

const TextForm = <T extends FieldValues>({ objPath, withTextAlign = false, textPath, textTitle, asTextarea = false, withoutFontFamily = false, withoutFontSize = false, withoutFontWeight = false, withoutColor = false }: TextFormProps) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;
    const id = useId();

    const values = methods.getValues(objPath as Path<T>);
    const fontFamily = values?.fontFamily ?? 'Arial' as string;

    const FONTS_WEIGHTS = FONTS.find(font => font.value === fontFamily)?.fontWeights ?? [{ value: 400, label: 'Regular' }]

    const handleFontFamilyChange = (value: string) => {
        const font = FONTS.find(font => font.value === value)
        if (font) {
            const previousFontWeight = methods.getValues(`${objPath}.fontWeight` as Path<T>);
            let fontWeight = previousFontWeight as number;
            if (!font.fontWeights.find(fontWeightLabelValue => fontWeightLabelValue.value === previousFontWeight)) {
                fontWeight = font.fontWeights[0].value;
            }
            methods.setValue(`${objPath}.fontWeight` as Path<T>, fontWeight as PathValue<T, Path<T>>, { shouldDirty: true });
        }
        methods.setValue(`${objPath}.fontFamily` as Path<T>, value as PathValue<T, Path<T>>, { shouldDirty: true });
    }



    return <Flex flexDir='column'>
        <Flex my={4}>
            <Text textStyle='_h2'>{textTitle ?? 'Text'}</Text>
        </Flex>
        {textPath && (
            <>
                {asTextarea ? <FormTextAreaInput name={textPath} title='Text' error={getProperty(errors, textPath) as FieldError | undefined} /> : <FormTextInput name={textPath} title='Text' error={getProperty(errors, textPath) as FieldError | undefined} />}
            </>
        )}
        {withoutColor === false && <FormPropertyRow>
            <Flex gap={'1rem'} alignItems={'center'}>
                <FormPropertyTitle title='Fill' />
                <Controller
                    name={`${objPath}.color`}
                    defaultValue={'black'}
                    render={({ field }) => (
                        <ColorPickerInput id={id} {...field} error={getProperty(errors, `${objPath}.color`) as FieldError | undefined} width='150px' type="text" />
                    )}
                />
            </Flex>
        </FormPropertyRow>}
        {withoutFontFamily === false && <FormPropertyRow>
            <Controller
                name={`${objPath}.fontFamily`}
                defaultValue={FONTS[0].value}
                render={({ field }) => (
                    <FormControl isInvalid={Boolean((getProperty(errors, `${objPath}.fontFamily`) as FieldError | undefined)?.message)}>
                        <StyledSelect
                            variant='editor'
                            value={FONTS.find(font => font.value === field.value)}
                            options={FONTS}
                            onChange={(option: any) => handleFontFamilyChange(option.value)}
                        />
                        <FormErrorMessage>{(getProperty(errors, `${objPath}.fontFamily`) as FieldError | undefined)?.message}</FormErrorMessage>
                    </FormControl>
                )}
            />
        </FormPropertyRow>}
        <FormPropertyRow>
            <Flex alignItems={'center'} justifyContent='space-between' width='100%'>
                {withoutFontSize === false && <Controller
                    name={`${objPath}.fontSize`}
                    defaultValue={FONT_SIZES[0].value}
                    render={({ field }) => (
                        <FormControl style={{ width: '150px' }} isInvalid={Boolean((getProperty(errors, `${objPath}.fontSize`) as FieldError | undefined)?.message)}>
                            <StyledSelect
                                variant='editor'
                                value={FONT_SIZES.find(font => font.value === field.value)}
                                options={FONT_SIZES}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                            <FormErrorMessage>{(getProperty(errors, `${objPath}.fontSize`) as FieldError | undefined)?.message}</FormErrorMessage>
                        </FormControl>
                    )}
                />}
                {withoutFontWeight === false && <Controller
                    name={`${objPath}.fontWeight`}
                    render={({ field }) => (
                        <FormControl style={{ width: '150px' }} isInvalid={Boolean((getProperty(errors, `${objPath}.fontWeight`) as FieldError | undefined)?.message)}>
                            <StyledSelect
                                variant='editor'
                                value={FONTS_WEIGHTS.find(font => font.value === field.value)}
                                options={FONTS_WEIGHTS}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                            <FormErrorMessage>{(getProperty(errors, `${objPath}.fontWeight`) as FieldError | undefined)?.message}</FormErrorMessage>
                        </FormControl>
                    )}
                />}
            </Flex>
        </FormPropertyRow>
        {withTextAlign && <FormPropertyRow>
            <FormPropertyTitle title='Text Align' />
            <Flex>
                <Controller
                    name={`${objPath}.textAlign`}
                    defaultValue={'left'}
                    render={({ field }) => (
                        <IconButton variant='ghost' backgroundColor={field.value !== 'center' && field.value !== 'right' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                            target: { value: 'left' }
                        })} aria-label="Align Left" icon={<GrTextAlignLeft />} />
                    )}
                />
                <Controller
                    name={`${objPath}.textAlign`}
                    defaultValue={'center'}
                    render={({ field }) => (
                        <IconButton variant='ghost' backgroundColor={field.value === 'center' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                            target: { value: 'center' }
                        })} aria-label="Align Center" icon={<GrTextAlignCenter />} />
                    )}
                />
                <Controller
                    name={`${objPath}.textAlign`}
                    defaultValue={'right'}
                    render={({ field }) => (
                        <IconButton variant='ghost' backgroundColor={field.value === 'right' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                            target: { value: 'right' }
                        })} aria-label="Align Right" icon={<GrTextAlignRight />} />
                    )}
                />
            </Flex>
        </FormPropertyRow>}

    </Flex>
}

export default TextForm;