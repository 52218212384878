import { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { isIframe } from 'src/utils';

interface RealSizeCreativeIframeProps {
    src: string;
    width: number;
    height: number;
}

export default function RealSizeCreativeIframe({
    src,
    width,
    height
}: RealSizeCreativeIframeProps) {
    const [isAdblockDetected, setIsAdblockDetected] = useState(false);

    useEffect(() => {
        if (isIframe(new URL(src) as URL)) {
            fetch(src)
                .then((response) => response.text())
                .then(() => {
                    setIsAdblockDetected(false);
                })
                .catch(() => {
                    setIsAdblockDetected(true);
                });
        }
    }, [src]);

    return (
        <>
            {isAdblockDetected ? (
                <Box display={'flex'} flexDir={'column'} textAlign={'center'}>
                    <Text fontSize={24}>Allow ads on the web</Text>
                    <Text>It looks like you are using an ad blocker.</Text>
                </Box>
            ) : (
                <iframe src={src} width={width} height={height} allowFullScreen />
            )}
        </>
    );
}
