import { useUpdateReportPageMutation } from '@api';
import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Icon,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    useToast,
    VStack
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppDispatch, useAppSelector } from '@hooks';
import { defaultLayout } from 'common/constants';
import { LayoutScale } from 'common/enums';
import { reportPageValidator } from 'common/validators';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MdRestartAlt } from 'react-icons/md';
import { z } from 'zod';
import AutoSave from '../AutoSave';
import { selectActiveReportPage, setActiveReportPageLayout } from 'src/redux/features/blueprint/bluePrintSlice';
import { assertIsDefined } from 'src/templates/blueprint/utils';

type FormData = z.infer<typeof reportPageValidator.layout>;

const UIReport = () => {
    const [submittedData, setSubmittedData] = React.useState({});
    const dispatch = useAppDispatch();
    const toast = useToast();
    const [updateReportPage] = useUpdateReportPageMutation();
    const page = useAppSelector(selectActiveReportPage);
    const methods = useForm<FormData>({
        defaultValues: { ...defaultLayout, ...page?.layout },
        values: page?.layout,
        mode: 'onBlur',
        resolver: zodResolver(reportPageValidator.layout)
    });

    const {
        control,
        handleSubmit,
        reset,
        formState,
        formState: { isSubmitSuccessful }
    } = methods;

    const { isDirty, dirtyFields, errors } = formState;

    const onSubmit = async (data: FormData) => {
        const currentLayout = page?.layout;

        assertIsDefined(page, 'Page is not defined');
        assertIsDefined(currentLayout, 'Layout is not defined');
        dispatch(setActiveReportPageLayout(data));

        try {
            await updateReportPage({
                id: page.id,
                layout: data
            }).unwrap();
            toast({
                title: 'Changes has been successfully saved',
                status: 'success',
                position: 'top-right'
            });
        } catch (error) {
            dispatch(setActiveReportPageLayout(currentLayout));
            toast({
                title: 'There was an error with saving',
                description: 'Failed to save changes',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
        }
        setSubmittedData(data);
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({ ...submittedData });
        }
    }, [isSubmitSuccessful, submittedData, reset]);

    return (
        <Flex flexDir={'column'} gap={'2rem'} my={'1rem'} px="1rem">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box>
                        <FormControl as="fieldset">
                            <FormLabel as="legend">Display mode</FormLabel>
                            <Controller
                                control={control}
                                name="scale"
                                render={({ field: { value, onChange } }) => (
                                    <RadioGroup value={value} onChange={onChange}>
                                        <VStack
                                            spacing="0.5rem"
                                            alignItems="flex-start"
                                            paddingLeft="1rem"
                                        >
                                            <Radio value={LayoutScale.FILL}>
                                                Adjust to width
                                            </Radio>
                                            <Radio value={LayoutScale.FIXED}>
                                                Fixed size
                                            </Radio>
                                        </VStack>
                                    </RadioGroup>
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <Flex>
                            <FormLabel>Canvas size</FormLabel>
                            {isDirty &&
                                (dirtyFields.pageWidth || dirtyFields.pageHeight) && (
                                    <Icon
                                        onClick={() =>
                                            reset({
                                                pageHeight: defaultLayout.pageHeight,
                                                pageWidth: defaultLayout.pageWidth
                                            })
                                        }
                                        style={{ cursor: 'pointer' }}
                                        as={MdRestartAlt}
                                    />
                                )}
                        </Flex>
                        <HStack spacing="24px">
                            <FormControl isInvalid={Boolean(errors.pageWidth)}>
                                <Controller
                                    control={control}
                                    name="pageWidth"
                                    render={({ field: { value, onChange } }) => (
                                        <>
                                            <NumberInput
                                                value={value}
                                                onChange={(nextValue) =>
                                                    onChange(+nextValue)
                                                }
                                            >
                                                <NumberInputField />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                            {errors.pageWidth ? (
                                                <FormErrorMessage>
                                                    {errors.pageWidth?.message}
                                                </FormErrorMessage>
                                            ) : (
                                                <FormHelperText>
                                                    Width (px)
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </FormControl>
                            <Controller
                                control={control}
                                name="pageHeight"
                                render={({ field: { value, onChange } }) => (
                                    <FormControl isInvalid={Boolean(errors.pageHeight)}>
                                        <NumberInput
                                            value={value}
                                            onChange={(nextValue) => onChange(+nextValue)}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        {errors.pageHeight ? (
                                            <FormErrorMessage>
                                                {errors.pageHeight?.message}
                                            </FormErrorMessage>
                                        ) : (
                                            <FormHelperText>Height (px)</FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </HStack>
                    </Box>
                    <input hidden type="submit" />
                    <AutoSave
                        onSubmit={onSubmit}
                        defaultValues={{ ...defaultLayout, ...page?.layout }}
                    />
                </form>
            </FormProvider>
        </Flex>
    );
};

export default UIReport;
