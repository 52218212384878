import AceEditor from 'react-ace';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
    selectActiveReportPage,
    setActiveReportPageLayout
} from 'src/redux/features/blueprint/bluePrintSlice';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import { isJsonValid } from './utils';
import { reportPageValidator } from 'common/validators';
import { Box, Button, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useUpdateReportPageMutation } from '@api';
import { assertIsDefined } from 'src/templates/blueprint/utils';

const UIReportPageEditor = () => {
    const page = useAppSelector(selectActiveReportPage);
    const dispatch = useAppDispatch();
    const toast = useToast();
    const [updateReportPage] = useUpdateReportPageMutation();

    const [value, setValue] = useState(JSON.stringify(page?.layout, null, 2));

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    const handleClick = async () => {
        if (!isJsonValid(value)) {
            toast({
                title: 'There was an error with saving',
                description: 'Content is not valid JSON',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
            return;
        }
        const parsed = JSON.parse(value);

        const validated = reportPageValidator.layout.strict().safeParse(parsed);

        if (validated.success === false) {
            toast({
                title: 'There was an error with saving',
                description: validated.error.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
            return;
        }

        const currentLayout = page?.layout;

        assertIsDefined(page, 'Page is not defined');
        assertIsDefined(currentLayout, 'Layout is not defined');

        dispatch(setActiveReportPageLayout(validated.data));

        try {
            await updateReportPage({
                id: page.id,
                layout: validated.data
            }).unwrap();
        } catch (error) {
            dispatch(setActiveReportPageLayout(currentLayout));
            toast({
                title: 'Chyba pri ukladaní',
                description: 'Nepodarilo sa uložiť zmeny',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
        }
    };

    return (
        <>
            {page && (
                <Box position="relative">
                    <Button
                        position="absolute"
                        bottom="1rem"
                        right="1rem"
                        zIndex={10}
                        onClick={handleClick}
                        variant={'solid'}
                    >
                        Uložiť
                    </Button>
                    <AceEditor
                        enableBasicAutocompletion={true}
                        highlightActiveLine={true}
                        enableLiveAutocompletion={true}
                        mode="json"
                        theme="tomorrow"
                        onChange={handleChange}
                        name="UIReportEditor"
                        editorProps={{ $blockScrolling: true }}
                        value={value}
                        height={'30rem'}
                        width="100%"
                    />
                </Box>
            )}
        </>
    );
};

export default UIReportPageEditor;
