import { Box, Divider, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { defaultTheme } from "common/constants";
import { styleValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import AlignmentForm from "../../forms/Alignment";
import BorderForm from "../../forms/BorderForm";
import ColorForm from "../../forms/ColorForm";
import PositionForm from "../../forms/PositionForm";
import TextForm from "../../forms/TextForm";
import StyleDivider from "../../StyleDivider";
import PaddingForm from "../../forms/PaddingForm";

type FormData = z.infer<typeof styleValidator.navbar>

const NavbarStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultTheme.components.NAVBAR,
        values: componentToEdit as any,
        resolver: zodResolver(styleValidator.navbar),
        mode: 'onBlur',
    })
    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <StyleDivider />
                <FormAccordion>
                    <FormAccordionItem title="Button Styles">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Background' name={"properties.buttonContainer.background"} withGradient />
                            <BorderForm<FormData> objPath={"properties.buttonContainer"} />
                            <TextForm objPath="properties.buttonStyle" />
                            <AlignmentForm horizontalAlignPath="properties.buttonContainer.horizontalAlign" verticalAlignPath="properties.buttonContainer.verticalAlign" name="Alignment" />
                            <PaddingForm<FormData> objPath='properties.buttonStyle' />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Active Button Styles">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Background' name={"properties.buttonContainer._active.background"} withGradient />
                            <BorderForm<FormData> objPath={"properties.buttonContainer._active"} />
                            <TextForm objPath="properties.buttonStyle._active" withoutFontFamily withoutFontSize withoutFontWeight />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Hover Button Styles">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Background' name={"properties.buttonContainer._hover.background"} withGradient />
                            <BorderForm<FormData> objPath={"properties.buttonContainer._hover"} />
                            <TextForm objPath="properties.buttonStyle._hover" withoutFontFamily withoutFontSize withoutFontWeight />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                </FormAccordion>
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={defaultTheme.components.NAVBAR} />
            </form>
        </FormProvider>
    </Box>
}

export default NavbarStyle;