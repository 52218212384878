export const DASHBOARD_PATH = '';
export const DASHBOARD_REPORTS_PATH = 'reporty';
export const DASHBOARD_DATA_SOURCES_PATH = 'datove-zdroje';
export const SHARED_REPORT_PATH = 'shared-report';
export const DASHBOARD_CLIENTS_PATH = 'clients';

export enum DraggableTypes {
    PAGE = 'PAGE',
    METRIC = 'METRIC',
    DIMENSION = 'DIMENSION'
}

export enum StorageKey {
    USER = 'user',
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
    FILE_ACCESS_TOKEN = 'fileAccessToken',
    TOKEN_BY_REPORT_NAME = 'tokenByReportName'
}

export const CREATIVE_PLACEHOLDER_URL =
    'https://placehold.co/100x50?text=Creative&font=roboto';
export const VIDEO_PLACEHOLDER_URL = 'https://placehold.co/100x50?text=Video&font=roboto';

const redirectUri = window.location.origin + '/cb';
export const UPDATE_PASSWORD_LINK = `${import.meta.env.VITE_AUTHORITY}/realms/${import.meta.env.VITE_REALM}/protocol/openid-connect/auth?client_id=${import.meta.env.VITE_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;

export const breakpoints = {
    xs: 480,
    s: 600,
    m: 850,
    l: 1280,
    xl: 1680,
    xxl: 1920
};

export const windowInfoBreakpoints = {
    xs: '(max-width: 480px)',
    s: '(max-width: 600px)',
    m: '(max-width: 850px)',
    l: '(max-width: 1280px)',
    xl: '(max-width: 1680px)',
    xxl: '(max-width: 1920px)'
}

export const HEADER_HEIGHT = 130;
