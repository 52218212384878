function generateRandomId(length = 12) {
    return Math.random().toString(36).substring(2, length);
}
class CompatibilityChecker {
    getCompatibleTables(selectedMetrics, selectedDimensions) {
        const dimensionTables = selectedDimensions
            .filter((dimension) => dimension.dmTables)
            .map((dimension) => dimension.dmTables);
        const metricTables = selectedMetrics
            .filter((metric) => metric.dmTables)
            .map((metric) => metric.dmTables);
        const allTables = [...new Set([...dimensionTables.flat(), ...metricTables.flat()])];
        return allTables.filter((table) => selectedDimensions.every(({ dmTables }) => dmTables.includes(table)) &&
            selectedMetrics.every(({ dmTables }) => dmTables.includes(table)));
    }
    // Function to filter metrics and dimensions based on compatible tables
    filterCompatibleMetricsAndDimensions(compatibleTables, allMetrics, allDimensions) {
        const compatibleMetrics = allMetrics.filter((metric) => metric.dmTables && metric.dmTables.some((table) => compatibleTables.includes(table)));
        const compatibleDimensions = allDimensions.filter((dimension) => dimension.dmTables &&
            dimension.dmTables.some((table) => compatibleTables.includes(table)));
        return {
            compatibleMetrics,
            compatibleDimensions
        };
    }
    getCompatibleMetricsAndDimensions(args) {
        const { selectedMetrics, selectedDimensions, allMetrics, allDimensions } = args;
        if (!selectedMetrics.length && !selectedDimensions.length) {
            return {
                compatibleMetrics: allMetrics,
                compatibleDimensions: allDimensions
            };
        }
        const compatibleTables = this.getCompatibleTables(selectedMetrics, selectedDimensions);
        const { compatibleMetrics, compatibleDimensions } = this.filterCompatibleMetricsAndDimensions(compatibleTables, allMetrics, allDimensions);
        return {
            compatibleMetrics,
            compatibleDimensions
        };
    }
}
const compatibilityChecker = new CompatibilityChecker();
function findCompatibleTable(metrics, dimensions) {
    let potentialTables = [];
    dimensions.forEach((dimension) => {
        if (dimension.dmTables) {
            const compatibleTables = dimension.dmTables;
            potentialTables.push(...compatibleTables);
        }
    });
    metrics.forEach(({ dmTables }) => {
        if (dmTables) {
            const compatibleTables = dmTables;
            potentialTables.push(...compatibleTables);
        }
    });
    return potentialTables.filter((table, index, self) => self.indexOf(table) === index &&
        dimensions.every(({ dmTables }) => dmTables.includes(table)) &&
        metrics.every(({ dmTables }) => dmTables.includes(table)))[0];
}
export { generateRandomId, compatibilityChecker, findCompatibleTable };
