import { Flex, Text } from '@chakra-ui/react';
import { PaginationState } from '@tanstack/react-table';
import PaginationButtons from './PaginationButtons';
import { StyledSelect } from '@tasklogy/zircon-ui-components';

const PAGINATION_OPTIONS = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' }
];

interface Table {
    getState: () => {
        pagination: PaginationState;
    };
    setPageSize: (size: number) => void;
    getPageCount: () => number;
    getRowModel: () => {
        rows: any[];
    };
    nextPage: () => void;
    previousPage: () => void;
    setPageIndex: (index: number) => void;
    getRowCount: () => number;
}

interface PaginationProps {
    table: Table;
}

export const Pagination = ({ table }: PaginationProps) => {
    const {
        pagination: { pageSize, pageIndex }
    } = table.getState();

    return (
        <Flex justifyContent="end" alignItems="center" gap="1rem">
            <Flex
                alignItems="center"
                gap="0.5rem"
                sx={{
                    '.pagination-select__value-container': {
                        padding: '0',
                        paddingLeft: '0.5rem',
                        minHeight: '1.5rem',
                        height: '1.75rem',
                        fontSize: '0.85rem'
                    },
                    '.pagination-select__control': {
                        padding: '0',
                        minHeight: '1.75rem',
                        height: '1.75rem'
                    },
                    '.pagination-select__indicator': {
                        padding: '0.25rem'
                    }
                }}
            >
                <Text fontWeight={500} fontSize={12}>
                    {'Rows per page: '}
                </Text>
                <StyledSelect
                    options={PAGINATION_OPTIONS}
                    classNamePrefix="pagination-select"
                    value={{ value: pageSize, label: pageSize.toString() }}
                    onChange={(newValue: any) => {
                        table.setPageSize(Number(newValue?.value));
                    }}
                />
            </Flex>
            <PaginationButtons
                pageCount={table.getPageCount()}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRowsCount={table.getRowCount()}
                onNextPage={table.nextPage}
                onPreviousPage={table.previousPage}
                onSetPageIndex={table.setPageIndex}
            />
        </Flex>
    );
};
