import { useListReportsQuery } from '@api';
import { Button, Card, CardBody, Flex, useDisclosure } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import ModalWindow from 'src/components/ModalWindow';
import {
    BaseTable,
    DateTimeCell,
    PasswordCell,
    UrlCell
} from '@tasklogy/zircon-ui-components';
import ReportName from '../../ReportsTable/ReportName';
import CreateForm from './CreateForm';
import { generatePath } from 'react-router-dom';
import { PathNames } from 'src/router/router';
import { Report } from 'src/redux/types/api';
import ActionCell from './ActionCell';

const columnHelper = createColumnHelper<Report>();

const CREATE_TEMPLATE_LABEL = 'Create template';

const columns: ColumnDef<Report, any>[] = [
    columnHelper.accessor('id', {
        id: 'id',
        cell: (info) => info.getValue(),
        header: () => 'ID'
    }),
    columnHelper.accessor('title', {
        cell: (info) => {
            return (
                <ReportName
                    title={info.getValue()}
                    reportSlug={info.row.original.slug}
                    clientId={info.row.original.clientId}
                />
            );
        },
        footer: (info) => info.column.id,
        header: () => 'Title'
    }),
    columnHelper.accessor('templateTeam', {
        id: 'templateTeam',
        cell: (info) => (info.getValue() ? info.getValue().name : '-'),
        header: () => 'Team'
    }),
    columnHelper.accessor('templatePublished', {
        id: 'templatePublished',
        cell: (info) => (info.getValue() ? 'YES' : 'NO'),
        header: () => 'Published'
    }),
    columnHelper.accessor('updatedAt', {
        id: 'updatedAt',
        cell: (info) => <DateTimeCell value={new Date(info.getValue())} />,
        header: () => 'Last edit'
    }),
    columnHelper.accessor('slug', {
        id: 'previewUrl',
        cell: (info) => {
            const previewUrl =
                window.location.origin +
                generatePath(PathNames.SHARED_REPORT, {
                    clientId: info.row.original.clientId.toString(),
                    reportSlug: info.getValue(),
                    viewSlug: null
                });
            return <UrlCell value={previewUrl} />;
        },
        header: () => 'Report URL',
        enableSorting: false
    }),
    columnHelper.accessor('password', {
        id: 'password',
        cell: (info) => <PasswordCell password={info.getValue()} />,
        header: () => 'Password',
        enableSorting: false
    }),
    columnHelper.accessor('id', {
        id: 'id',
        cell: (info) => <ActionCell template={info.row.original} />,
        header: () => null
    })
];

const TemplatesTable = () => {
    const {
        isOpen: isOpenCreateTemplateReport,
        onOpen: onOpenCreateTemplateReport,
        onClose: onCloseCreateTemplateReport
    } = useDisclosure();
    const { data: templates } = useListReportsQuery({ isTemplate: true });

    const tableData: Report[] = templates ?? [];

    return (
        <>
            <Card>
                <CardBody>
                    <Flex w="100%" justifyContent="right">
                        <Button variant="solid" onClick={onOpenCreateTemplateReport}>
                            {CREATE_TEMPLATE_LABEL}
                        </Button>
                    </Flex>
                    <BaseTable variant="reports" columns={columns} data={tableData} />
                </CardBody>
            </Card>
            <ModalWindow
                minWidth="50rem"
                isOpen={isOpenCreateTemplateReport}
                onClose={onCloseCreateTemplateReport}
                title={CREATE_TEMPLATE_LABEL}
            >
                <CreateForm onClose={onCloseCreateTemplateReport} />
            </ModalWindow>
        </>
    );
};

export default TemplatesTable;
