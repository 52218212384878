import { Fade, Spinner } from '@chakra-ui/react';

const LoadingStatus: React.FC<{ isLoading: boolean; children: React.ReactNode }> = ({
    isLoading,
    children
}) => {
    return (
        <>
            {isLoading && (
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                />
            )}
            <Fade in={!isLoading}>{children}</Fade>
        </>
    );
};

export default LoadingStatus;
