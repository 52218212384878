import { FieldValues, Path } from "react-hook-form";
import { BsChevronDoubleDown, BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronDoubleUp } from "react-icons/bs";
import { TbBorderOuter, TbBoxPadding } from "react-icons/tb";
import CollapseForm from "../CollapseForm";

const config = {
    title: 'Padding',
    typePath: '_paddingType',
    all: {
        keyPath: 'padding',
        defaultValue: '0px',
        icon: <TbBorderOuter />
    },
    mixed: [
        { keyPath: 'paddingLeft', defaultValue: '0px', icon: < BsChevronDoubleLeft /> },
        { keyPath: 'paddingRight', defaultValue: '0px', icon: <BsChevronDoubleRight /> },
        { keyPath: 'paddingTop', defaultValue: '0px', icon: <BsChevronDoubleUp /> },
        { keyPath: 'paddingBottom', defaultValue: '0px', icon: <BsChevronDoubleDown /> }
    ],
    mixedIcon: <TbBoxPadding />
}

const PaddingForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    return <CollapseForm objPath={objPath} config={config} />

}

export default PaddingForm;