import { useGetTeamQuery } from '@api';
import { Box, Card, CardBody, Flex, Icon, Link, useDisclosure } from '@chakra-ui/react';
import { TeamIdParams } from '@router';
import { createColumnHelper } from '@tanstack/react-table';
import SearchInput from 'src/components/SearchComponent';
import { BaseTable } from '@tasklogy/zircon-ui-components';
import { useMemo, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import CreateMemberModal from 'src/pages/Settings/CreateMemberModal';

import LoadingStatus from '../../LoadingStatus';
import { User } from 'src/redux/types/api';
import ActionCell from './ActionCell';
import { roleDisplayNames } from 'common/roles';
import { useAuth } from 'src/auth/useAuth';

const columnHelper = createColumnHelper<User>();

const columns = [
    columnHelper.accessor('fullName', {
        cell: (info) => <strong>{info.getValue()}</strong>,
        header: () => 'Name'
    }),
    columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: () => 'E-mail'
    }),
    columnHelper.accessor('role', {
        cell: (info) => {
            const role = info.getValue();
            const displayName = roleDisplayNames[role];
            return displayName;
        },
        header: () => 'Role'
    }),
    columnHelper.accessor('assignedClients', {
        cell: (info) =>
            info
                .getValue()
                .map((client) => client.name)
                .join(', '),
        header: () => 'Assigned Clients'
    }),
    columnHelper.accessor('id', {
        id: 'action',
        cell: (info) => <ActionCell user={info.row.original} />,
        header: () => 'Action'
    })
];

const AddTeamMemberAction = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Link variant="defaultSmall" onClick={onOpen}>
                <Box display="flex" alignItems="center" gap="5px">
                    <Icon as={FiPlus}></Icon>
                    Create Member
                </Box>
            </Link>
            <CreateMemberModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};

const TeamMembersTable = () => {
    const [search, setSearch] = useState<string>('');
    const params = useParams<TeamIdParams>();
    const { user } = useAuth();
    const { data: teamData, isLoading } = useGetTeamQuery(
        { teamId: user?.teamId ?? (params?.teamId ? parseInt(params.teamId, 10) : 0) },
        { skip: !(user?.teamId ?? (params?.teamId ? parseInt(params.teamId, 10) : 0)) }
    );

    const data = useMemo(() => {
        return teamData?.users
            ?.filter((member) =>
                JSON.stringify({
                    fullname: `${member.firstName} ${member.lastName}`,
                    email: member.email
                }).includes(search)
            )
            .sort(
                (a, b) =>
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
    }, [search, teamData]);

    return (
        <Box textAlign="center">
            <LoadingStatus isLoading={isLoading}>
                <Card>
                    <CardBody>
                        <Flex
                            justifyContent={'end'}
                            alignItems="center"
                            mb="1.5rem"
                            gap="20px"
                        >
                            <SearchInput search={search} setSearch={setSearch} />
                            <AddTeamMemberAction />
                        </Flex>

                        {data && <BaseTable columns={columns} data={data} />}
                    </CardBody>
                </Card>
            </LoadingStatus>
        </Box>
    );
};
export default TeamMembersTable;
