import { Flex, FormControl, InputProps, Textarea } from "@chakra-ui/react";
import { Controller, FieldError, Path } from "react-hook-form";
import FormPropertyRow from "../layout/FormPropertyRow";
import FormPropertyTitle from "../layout/FormPropertyTitle";

interface FormNumberInputProps extends InputProps {
    name: Path<any>;
    title?: string;
    error?: FieldError | undefined;
    [key: string]: any;
}

const FormTextAreaInput = ({ name, title, error }: FormNumberInputProps) => {
    return (
        <FormPropertyRow>
            <Flex alignItems={'center'} justifyContent='space-between' gap={'0.5rem'} width={'100%'}>
                <FormPropertyTitle title={title ?? 'Background'} />
                <Controller
                    name={name}
                    render={({ field }) => (
                        <FormControl isInvalid={Boolean(error?.message)}>
                            <Textarea variant='editor' onChange={field.onChange} value={field.value} />
                        </FormControl>)}
                />
            </Flex>
        </FormPropertyRow>
    );
};

export default FormTextAreaInput;