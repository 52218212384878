import { OutletTitle } from '@tasklogy/zircon-ui-components';
import ReportsTable from '../components/ReportsTable';
const Reports = () => {
    return (
        <>
            <OutletTitle>Reports</OutletTitle>
            <ReportsTable withClientFilter />
        </>
    );
};
export default Reports;
