import {
    Box,
    Flex,
    HStack,
    Icon,
    IconButton,
    Image,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Text,
    Tooltip
} from '@chakra-ui/react';
import { ComponentType } from 'common/enums';
import { MdOutlineAddChart } from 'react-icons/md';
import AreaChartIcon from 'src/assets/editor/areaChart.svg';
import BarChartIcon from 'src/assets/editor/barChart.svg';
import DonutChartIcon from 'src/assets/editor/donutChart.svg';
import HorizontalBarChartIcon from 'src/assets/editor/horizontalBarChart.svg';
import LineChartIcon from 'src/assets/editor/lineChart.svg';
import PieChartIcon from 'src/assets/editor/piechart.svg';
import StackedBarChartIcon from 'src/assets/editor/stackedBarChart.svg';
import TableChartIcon from 'src/assets/editor/tableChart.svg';
import StackedChartIcon from 'src/assets/editor/stackedChart.svg';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useCanvasScroll } from './utils';

const chartIcons: any = {
    [ComponentType.PIE_CHART]: PieChartIcon,
    [ComponentType.DONUT_CHART]: DonutChartIcon,
    [ComponentType.TABLE]: TableChartIcon,
    [ComponentType.TINY_BAR_CHART]: BarChartIcon,
    [ComponentType.BIAXIAL_BAR_CHART]: StackedBarChartIcon,
    [ComponentType.TABLE_PROGRESS_BAR_CHART]: HorizontalBarChartIcon,
    [ComponentType.STACKED_BAR_CHART]: StackedChartIcon,
    [ComponentType.LINE_CHART]: LineChartIcon,
    [ComponentType.AREA_CHART]: AreaChartIcon
};

interface AddComponentButtonProps {
    ariaLabel: string;
    type: ComponentType;
    onClickCb: () => void;
}

const ChartIconButton: React.FC<AddComponentButtonProps> = ({
    ariaLabel,
    type,
    onClickCb
}) => {
    const { actions, state } = useCanvas();
    const { scrollX, scrollY, canvasHeight } = useCanvasScroll();
    const x = state.width ? state.width / 2 : scrollX;
    const y = scrollY + canvasHeight / 2;
    const ChartIcon = chartIcons[type] ?? null;
    return (
        <Tooltip
            variant="editor"
            label={ariaLabel}
            aria-label={ariaLabel}
            placement="top"
            hasArrow
        >
            <IconButton
                variant="icon"
                onClick={() => {
                    actions.addComponent(type, x, y);
                    onClickCb();
                }}
                aria-label={ariaLabel}
                icon={<Image src={ChartIcon} />}
            />
        </Tooltip>
    );
};

interface AddChartPopoverProps {
    isOpen: boolean;
    onToggle: () => void;
}

const AddChartPopover: React.FC<AddChartPopoverProps> = ({ isOpen, onToggle }) => {
    const handleCallback = () => {
        onToggle();
    };
    return (
        <Popover placement="right-start" closeOnBlur isOpen={isOpen}>
            <PopoverTrigger>
                <Box>
                    <Tooltip
                        label="Add a chart"
                        aria-label="Add a chart"
                        placement="right"
                    >
                        <IconButton
                            variant="icon"
                            aria-label="Add new component"
                            onClick={onToggle}
                            icon={<Icon h="60px" as={MdOutlineAddChart} />}
                        />
                    </Tooltip>
                </Box>
            </PopoverTrigger>

            <Portal>
                <Box zIndex={99999} position="relative">
                    <PopoverContent className="editor-sidebar-content">
                        <PopoverHeader border="none" fontSize="14px" fontWeight={600}>
                            Add chart
                        </PopoverHeader>
                        <PopoverBody display="flex" flexDir="column" gap="1rem">
                            <Flex flexDir="column">
                                <Text>Pie</Text>
                                <HStack>
                                    <ChartIconButton
                                        ariaLabel="Donut chart"
                                        type={ComponentType.DONUT_CHART}
                                        onClickCb={handleCallback}
                                    />
                                    <ChartIconButton
                                        ariaLabel="Pie chart"
                                        type={ComponentType.PIE_CHART}
                                        onClickCb={handleCallback}
                                    />
                                </HStack>
                            </Flex>
                            <Flex flexDir="column">
                                <Text>Table</Text>
                                <HStack>
                                    <ChartIconButton
                                        ariaLabel="Table"
                                        type={ComponentType.TABLE}
                                        onClickCb={handleCallback}
                                    />
                                </HStack>
                            </Flex>
                            <Flex flexDir="column">
                                <Text>Bar</Text>
                                <HStack>
                                    <ChartIconButton
                                        ariaLabel="Bar chart"
                                        type={ComponentType.TINY_BAR_CHART}
                                        onClickCb={handleCallback}
                                    />
                                    <ChartIconButton
                                        ariaLabel="Biaxial bar chart"
                                        type={ComponentType.BIAXIAL_BAR_CHART}
                                        onClickCb={handleCallback}
                                    />
                                    <ChartIconButton
                                        ariaLabel="Stacked bar chart"
                                        type={ComponentType.STACKED_BAR_CHART}
                                        onClickCb={handleCallback}
                                    />
                                </HStack>
                            </Flex>
                            <Flex flexDir="column">
                                <Text>Line</Text>
                                <HStack>
                                    <ChartIconButton
                                        ariaLabel="Line chart"
                                        type={ComponentType.LINE_CHART}
                                        onClickCb={handleCallback}
                                    />
                                </HStack>
                            </Flex>
                            <Flex flexDir="column">
                                <Text>Area</Text>
                                <HStack>
                                    <ChartIconButton
                                        ariaLabel="Area chart"
                                        type={ComponentType.AREA_CHART}
                                        onClickCb={handleCallback}
                                    />
                                </HStack>
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </Box>
            </Portal>
        </Popover>
    );
};

export default AddChartPopover;
