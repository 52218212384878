import { z } from 'zod';
import { parse, isValid } from 'date-fns';
const dateSchema = z.preprocess((input) => {
    if (typeof input === 'string') {
        const parsedDate = parse(input, 'yyyy-MM-dd', new Date());
        return isValid(parsedDate) ? parsedDate : null;
    }
    return input;
}, z.date());
// Define dateRangeValue schema
const dateRangeValue = z.object({
    start: dateSchema,
    end: dateSchema
});
// Define singleValue schema
const singleValue = z.object({
    equals: z.string().optional(),
    contains: z.string().optional()
});
// Define multiValue schema
const multiValue = z.object({
    equals: z.array(z.string()).optional(),
    contains: z.array(z.string()).optional()
});
// Define genericValue schema using conditional types
const genericValue = z.union([dateRangeValue, singleValue, multiValue]).refine((value) => {
    if ('start' in value)
        return true;
    if ('equals' in value && typeof value.equals === 'string')
        return true;
    if ('equals' in value && Array.isArray(value.equals))
        return true;
    if ('contains' in value && typeof value.contains === 'string')
        return true;
    if ('contains' in value && Array.isArray(value.contains))
        return true;
    return false;
});
const filterValue = z.object({
    id: z.string(),
    value: genericValue
});
export const getData = z.object({
    reportPageId: z.number(),
    componentIds: z.array(z.string()),
    filterValues: z.array(filterValue).optional()
});
export const getFilterOptions = z.object({
    reportPageId: z.number(),
    filterIds: z.array(z.string()),
    dateRange: dateRangeValue.optional()
});
export default {
    filterValue,
    multiValue,
    dateRangeValue,
    singleValue,
    getData,
    getFilterOptions
};
