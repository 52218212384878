import { Divider, Flex, IconButton } from '@chakra-ui/react';
import {
    MdAlignHorizontalLeft,
    MdAlignHorizontalRight,
    MdAlignVerticalBottom,
    MdAlignVerticalCenter,
    MdAlignVerticalTop,
    MdHorizontalDistribute,
    MdOutlineAlignHorizontalCenter,
    MdVerticalDistribute
} from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';
import { CgMenuGridR } from 'react-icons/cg';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useEffect } from 'react';

const AlignToolbar = ({layout}: {layout?: 'vertical' | 'horizontal'}) => {
    const { actions, state } = useCanvas();

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.altKey && event.code === 'KeyA') {
            actions.align.left();
        } else if (event.altKey && event.code === 'KeyD') {
            actions.align.right();
        } else if (event.altKey && event.code === 'KeyW') {
            actions.align.top();
        } else if (event.altKey && event.code === 'KeyS') {
            actions.align.bottom();
        } else if (event.altKey && event.code === 'KeyV') {
            actions.align.middle();
        } else if (event.altKey && event.code === 'KeyH') {
            actions.align.center();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // If there are less than 2 components selected, don't show the toolbar
    if (state.activeSelection.size < 2) return null;
    return (
        <Flex id="canvas-align-toolbar" flexDir={layout === 'vertical' ? 'column' : 'row'}>
            <IconButton
                aria-label="Align Horizontal Left"
                icon={<MdAlignHorizontalLeft />}
                variant="icon"
                onClick={() => actions.align.left()}
            />
            <IconButton
                aria-label="Align Horizontal Center"
                icon={<MdOutlineAlignHorizontalCenter />}
                variant="icon"
                onClick={() => actions.align.center()}
            />
            <IconButton
                aria-label="Align Horizontal Right"
                icon={<MdAlignHorizontalRight />}
                variant="icon"
                onClick={() => actions.align.right()}
            />
            <IconButton
                aria-label="Align Vertical Top"
                icon={<MdAlignVerticalTop />}
                variant="icon"
                onClick={() => actions.align.top()}
            />
            <IconButton
                aria-label="Align Vertical Center"
                icon={<MdAlignVerticalCenter />}
                variant="icon"
                onClick={() => actions.align.middle()}
            />
            <IconButton
                aria-label="Align Vertical Bottom"
                icon={<MdAlignVerticalBottom />}
                variant="icon"
                onClick={() => actions.align.bottom()}
            />
            <Divider orientation={layout === 'vertical' ? 'horizontal' : 'vertical'} />
            <IconButton
                aria-label="Distribute Horizontal"
                icon={<MdHorizontalDistribute />}
                variant="icon"
                onClick={() => actions.align.distributeSpaceHorizontally()}
            />
            <IconButton
                aria-label="Distribute Vertical"
                icon={<MdVerticalDistribute />}
                variant="icon"
                onClick={() => actions.align.distributeSpaceVertically()}
            />
            <IconButton
                aria-label="Tidy Up Horizontally"
                icon={<GiHamburgerMenu />}
                variant="icon"
                onClick={() => actions.align.tidyUpHorizontally()}
            />
            <IconButton
                aria-label="Tidy Up Vertically"
                icon={<GiHamburgerMenu style={{ transform: 'rotate(90deg)' }} />}
                variant="icon"
                onClick={() => actions.align.tidyUpVertically()}
            />
            <IconButton
                aria-label="Tidy Up"
                icon={<CgMenuGridR />}
                variant="icon"
                onClick={() => actions.align.tidyUp()}
            />
        </Flex>
    );
};

export default AlignToolbar;
