import { useCreateReportMutation } from '@api';
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Heading,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import { ClientTemplateParams } from '@router';
import { SourceIcon } from '@tasklogy/zircon-ui-components';
import { DataSourceIdentifier } from 'common/enums';
import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BlankReportImage from 'src/assets/editor/blank.jpg';
import { useEditorNavigation } from 'src/router/hooks';
import { PathNames } from 'src/router/router';
import MissingImage from './MissingImage';

interface CardTemplateProps {
    template: {
        id: number;
        title: string;
        usedDataSources: DataSourceIdentifier[];
        previewImageUrl?: string;
        slug: string;
        isBlank?: boolean;
    };
}

const CardTemplate: React.FC<CardTemplateProps> = ({ template }) => {
    const goToEditor = useEditorNavigation();
    const [createReport] = useCreateReportMutation();
    const params = useParams<ClientTemplateParams>();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleCreateReport = async () => {
        try {
            const response = await createReport({
                title: template.title,
                slug: 'draft',
                password: null,
                clientId: params.clientId ? Number.parseInt(params.clientId) : -1,
                isActive: false,
                isTemplate: false,
                isDraft: true,
            }).unwrap();
            goToEditor(params.clientId!, response.slug);
        } catch (error) {
            toast.error('An error occurred while creating the report');
        }
    }
    return (
        <>
            <Card maxW="sm">
                <CardBody pb="0">
                    {template.previewImageUrl ? (
                        <Image
                            src={template.isBlank ? BlankReportImage : template.previewImageUrl}
                            alt="Template thumbnail"
                            borderRadius="13.67px 13.67px 0px 0px"
                            objectFit="cover"
                            width="100%"
                            height={{ 'sm': '150px', 'md': '150px', 'xl': '150px', '2xl': '200px' }}
                            objectPosition="top"
                        />
                    ) : (
                        <MissingImage />
                    )}
                    <VStack
                        mt="15px"
                        spacing="3"
                        display="flex"
                        justifyContent="left"
                        alignItems="left"
                    >
                        <Heading fontSize="17px">{template.title}</Heading>
                        <SourceIcon selectedIcons={template.usedDataSources} />
                    </VStack>
                </CardBody>
                <CardFooter>
                    <ButtonGroup
                        spacing="2"
                        display="flex"
                        justifyContent='space-between'
                        width="100%"
                    >
                        {!template.isBlank && <Button isDisabled={!Boolean(template.previewImageUrl)} variant="outline" onClick={onOpen}>
                            Preview
                        </Button>}
                        <Button
                            variant="solid"
                            width='100%'
                            onClick={() => {
                                if (template.isBlank) {
                                    handleCreateReport()
                                } else {
                                    navigate(
                                        generatePath(
                                            PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW,
                                            {
                                                clientId: params.clientId!,
                                                templateId: template.id.toString(),
                                                reportSlug: template.slug,
                                                viewSlug: null
                                            }
                                        )
                                    )
                                }
                            }
                            }
                        >
                            Use template
                        </Button>
                    </ButtonGroup>
                </CardFooter>
            </Card>
            <Modal isOpen={isOpen} onClose={onClose} size="5xl">
                <ModalOverlay />
                <ModalContent border="none">
                    <ModalHeader>{template.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody px={0}>
                        <Box height="450px" overflowY="scroll">
                            <Image
                                src={template.previewImageUrl}
                                alt="Template thumbnail"
                            />
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            variant="solid"
                            onClick={() =>
                                navigate(
                                    generatePath(
                                        PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW,
                                        {
                                            clientId: params.clientId!,
                                            templateId: template.id.toString(),
                                            reportSlug: template.slug,
                                            viewSlug: null
                                        }
                                    )
                                )
                            }
                        >
                            Use template
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CardTemplate;
