
import { FieldValues, Path } from "react-hook-form";
import { BsChevronDoubleDown, BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronDoubleUp } from "react-icons/bs";
import { TbBorderSides, TbBoxPadding } from "react-icons/tb";
import CollapseForm from "../CollapseForm";
import ColorPickerInput from "../inputs/ColorPickerInput";

const config = {
    title: 'Color',
    typePath: '_borderColorType',
    all: {
        keyPath: 'borderColor',
        defaultValue: 'transparent',
        icon: <TbBorderSides />,
    },
    mixed: [
        {
            keyPath: 'borderTopColor',
            defaultValue: 'transparent',
            icon: <BsChevronDoubleUp />
        },
        {
            keyPath: 'borderRightColor',
            defaultValue: 'transparent',
            icon: <BsChevronDoubleRight />
        },
        {
            keyPath: 'borderBottomColor',
            defaultValue: 'transparent',
            icon: <BsChevronDoubleDown />
        },
        {
            keyPath: 'borderLeftColor',
            defaultValue: 'transparent',
            icon: <BsChevronDoubleLeft />
        }
    ],
    mixedIcon: <TbBoxPadding />
}


const BorderColorForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    return <CollapseForm<T> objPath={objPath} config={config} as={ColorPickerInput} />

}

export default BorderColorForm;