import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ImageComponent } from 'common/types';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import {
    selectComponentById
} from 'src/redux/features/blueprint/bluePrintSlice';
import { useCanvas } from '../../EditorContext';

export const ImageConfig = () => {
    const { state: {activeSelectedComponentId} } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId)) as ImageComponent;
    const setComponentConfig = useSetComponentConfig();

    return (
        <Flex flexDir={'column'} gap={'2rem'} my={'1rem'} px="1rem">
            <FormControl>
                <FormLabel>Image URL:</FormLabel>
                <Input
                    value={componentToEdit.properties.src}
                    onChange={(e) => {
                        const updated: ImageComponent = {
                            ...componentToEdit,
                            properties: {
                                ...componentToEdit.properties,
                                src: e.target.value
                            }
                        };
                        setComponentConfig(updated);
                    }}
                />
            </FormControl>
            <FormControl>
                <FormLabel>Image placeholder:</FormLabel>
                <Input
                    value={componentToEdit.properties.alt}
                    onChange={(e) => {
                        const updated: ImageComponent = {
                            ...componentToEdit,
                            properties: {
                                ...componentToEdit.properties,
                                src: e.target.value
                            }
                        };
                        setComponentConfig(updated);
                    }}
                />
            </FormControl>
        </Flex>
    );
};
