import { StepList } from '@tasklogy/zircon-ui-components';
import FinalStep from './FinalStep';
import Step1 from './Step1';

const ClientCreateReport: React.FC = () => {
    return (
        <StepList>
            <StepList.Step nextButtonText="Create report">
                <Step1 />
            </StepList.Step>
            <StepList.Step displayPreviousButton={false} displayNextButton={false}>
                <FinalStep />
            </StepList.Step>
        </StepList>
    );
};

export default ClientCreateReport;
