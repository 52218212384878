import { Helmet } from 'react-helmet';

export const fonts = {
    ['manrope']: {
        name: 'Manrope, serif',
        label: 'Manrope',
        renderHelmet: () => {
            return (
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link
                        rel="preconnect"
                        href="https://fonts.gstatic.com"
                        crossOrigin="anonymous"
                    />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>
            );
        },
        fontWeights: [
            { value: 200, label: 'Extra Light' },
            { value: 300, label: 'Light' },
            { value: 400, label: 'Regular' },
            { value: 500, label: 'Medium' },
            { value: 600, label: 'Semi Bold' },
            { value: 700, label: 'Bold' },
            { value: 800, label: 'Extra Bold' },
        ]
    },
    ['noto-sans']: {
        name: 'Noto Sans, sans-serif',
        label: 'Noto Sans',
        renderHelmet: () => {
            return (
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link
                        rel="preconnect"
                        href="https://fonts.gstatic.com"
                        crossOrigin="anonymous"
                    />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100..900&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>
            );
        },
        fontWeights: [
            { value: 100, label: 'Thin' },
            { value: 200, label: 'Extra Light' },
            { value: 300, label: 'Light' },
            { value: 400, label: 'Regular' },
            { value: 500, label: 'Medium' },
            { value: 600, label: 'Semi Bold' },
            { value: 700, label: 'Bold' },
            { value: 800, label: 'Extra Bold' },
            { value: 900, label: 'Black' },
        ]
    },
    ['roboto']: {
        name: 'Roboto, sans-serif',
        label: 'Roboto',
        renderHelmet: () => {
            return <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin="anonymous"
                />
                <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet" />
            </Helmet>
        },
        fontWeights: [
            { value: 100, label: 'Thin' },
            { value: 200, label: 'Extra Light' },
            { value: 300, label: 'Light' },
            { value: 400, label: 'Regular' },
            { value: 500, label: 'Medium' },
            { value: 600, label: 'Semi Bold' },
            { value: 700, label: 'Bold' },
            { value: 800, label: 'Extra Bold' },
            { value: 900, label: 'Black' },
        ]
    },
    ['lato']: {
        name: 'Lato, sans-serif',
        label: 'Lato',
        renderHelmet: () => {
            return <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin="anonymous"
                />
                <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />
            </Helmet>
        },
        fontWeights: [
            { value: 100, label: 'Thin' },
            { value: 200, label: 'Extra Light' },
            { value: 300, label: 'Light' },
            { value: 400, label: 'Regular' },
            { value: 500, label: 'Medium' },
            { value: 600, label: 'Semi Bold' },
            { value: 700, label: 'Bold' },
            { value: 800, label: 'Extra Bold' },
            { value: 900, label: 'Black' },
        ]
    },
    ['dm-sans']: {
        name: 'DM Sans, sans-serif',
        label: 'DM Sans',
        renderHelmet: () => {
            return (
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link
                        rel="preconnect"
                        href="https://fonts.gstatic.com"
                        crossOrigin="anonymous"
                    />
                    <link href="https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100..1000&display=swap" rel="stylesheet" />
                </Helmet>
            );
        },
        fontWeights: [
            { value: 100, label: 'Thin' },
            { value: 200, label: 'Extra Light' },
            { value: 300, label: 'Light' },
            { value: 400, label: 'Regular' },
            { value: 500, label: 'Medium' },
            { value: 600, label: 'Semi Bold' },
            { value: 700, label: 'Bold' },
            { value: 800, label: 'Extra Bold' },
            { value: 900, label: 'Black' },
        ]
    },
    ['baloo-2']: {
        name: '"Baloo 2", sans-serif',
        label: 'Baloo 2',
        renderHelmet: () => {
            return (
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link
                        rel="preconnect"
                        href="https://fonts.gstatic.com"
                        crossOrigin="anonymous"
                    />
                    <link href="https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap" rel="stylesheet" />
                </Helmet>
            );
        },
        fontWeights: [
            { value: 400, label: 'Regular' },
            { value: 500, label: 'Medium' },
            { value: 600, label: 'Semi Bold' },
            { value: 700, label: 'Bold' },
            { value: 800, label: 'Extra Bold' },
        ]
    }
};
