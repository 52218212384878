import { Box, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { defaultTheme } from "common/constants";
import { styleValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import ColorForm from "../../forms/ColorForm";
import PositionForm from "../../forms/PositionForm";
import TextForm from "../../forms/TextForm";
import StyleDivider from "../../StyleDivider";

type FormData = z.infer<typeof styleValidator.downloadButton>

const DownloadButtonStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultTheme.components.DOWNLOAD_BUTTON,
        values: componentToEdit as any,
        resolver: zodResolver(styleValidator.downloadButton),
        mode: 'onBlur',
    })
    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <StyleDivider />
                <FormAccordion>
                    <FormAccordionItem title="Component styles">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Background' name={"properties.style.background"} withGradient />
                            <ColorForm<FormData> title='Icon Color' name={"properties.downloadIconStyle.color"} />
                            <TextForm<FormData> textTitle="Label" objPath="properties.style" textPath={"properties.component.label"} />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Loading styles">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Background' name={"properties.loadingStyle.background"} withGradient />
                            <ColorForm<FormData> title='Color' name={"properties.loadingStyle.color"} />
                        </Flex>
                    </FormAccordionItem>
                </FormAccordion>
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={defaultTheme.components.DOWNLOAD_BUTTON} />
            </form>
        </FormProvider>
    </Box>
}

export default DownloadButtonStyle;