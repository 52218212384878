import { Box, Button, Flex, FormLabel, Input } from '@chakra-ui/react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Page } from './PagesTab';

export interface PageFormProps {
    page: Page;
    onSave: (values: Page) => void;
}

export const PageForm = ({ page, onSave }: PageFormProps) => {
    const { register, handleSubmit, getValues } = useForm({
        defaultValues: page
    });

    const onSubmit: SubmitHandler<FieldValues> = (data) => {
        onSave(data as unknown as Page);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDir="column" gap={4}>
                <Box>
                    <FormLabel>Name</FormLabel>
                    <Input {...register('displayName')} placeholder="Meta" />
                </Box>
                <Box>
                    <FormLabel>Slug</FormLabel>
                    <Input {...register('slug')} placeholder="Slug" />
                </Box>
                <Flex gap={4}>
                    <Button variant={'solid'} type="submit">
                        Save
                    </Button>
                </Flex>
            </Flex>
        </form>
    );
};
