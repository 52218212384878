import { BlueprintMultiSelectProperties, ComponentUI } from '@types';
import { useState } from 'react';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';

import {
    selectActiveReportPage,
    selectAppliedFilters,
    selectReportPageFilters,
    setAppliedFilter
} from 'src/redux/features/blueprint/bluePrintSlice';
import { assertIsDefined } from '../../utils';
import { useFetchReportPageData } from 'src/hooks/useFetchReportPageData';

export interface FilterProps extends Omit<ComponentUI, 'properties'> {
    properties: BlueprintMultiSelectProperties;
    id: string;
}

export const useFilter = ({ id }: FilterProps, isMulti: boolean) => {
    const { state } = useCanvas();
    const dispatch = useAppDispatch();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const pageFilters = useAppSelector(selectReportPageFilters);
    const fetchPageData = useFetchReportPageData();
    const componentData = pageFilters[id];
    const appliedFilters = useAppSelector(selectAppliedFilters);
    const filters = appliedFilters.find((f) => f.id === id);
    const filterValues = filters?.value?.equals?.map((v: string) => ({
        value: v,
        label: v
    }));
    const [selected, setSelected] = useState<any>(
        isMulti ? filterValues ?? [] : filterValues?.[0] ?? ''
    );

    assertIsDefined(activeReportPage);

    const handleChange = (values: any) => {
        if (isMulti) {
            const equalsValues = values
                .filter((v: any) => !v.value.includes('contains:'))
                .map((v: any) => v.value);

            const containsValues = values
                .filter((v: any) => v.value.includes('contains:'))
                .map((v: any) => v.value.replace('contains:', ''));
            setSelected(Array.from(values));

            updateFilters(id, equalsValues, containsValues);
        } else {
            setSelected(values);
            updateFilters(id, [values.value], []);
        }
    };

    const updateFilters = (
        id: string,
        equalsValues: string[],
        containsValues: string[]
    ) => {
        let filters = appliedFilters;
        const filterIndex = appliedFilters.findIndex((f: any) => f.id === id);

        if (filterIndex === -1) {
            filters.push({
                id,
                value: {
                    equals: equalsValues,
                    contains: containsValues
                }
            });
        } else {
            filters[filterIndex] = {
                id,
                value: {
                    equals: equalsValues,
                    contains: containsValues
                }
            };
        }
        dispatch(
            setAppliedFilter({
                id,
                value: {
                    equals: equalsValues,
                    contains: containsValues
                }
            })
        );
        fetchPageData({
            filterValues: filters,
            isSharedReport: state.useSharedReportAPI
        });
    };

    return {
        componentData,
        selected,
        handleChange
    };
};
