import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalContentProps,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { HSeparator } from './Separator';

interface Props extends PropsWithChildren, ModalContentProps {
    title: string;
    isOpen: boolean;
    withSeparator?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
}

export default function ModalWindow({
    children,
    title,
    isOpen,
    withSeparator = true,
    onClose,
    ...restProps
}: Props) {
    return (
        <Modal
            onClose={onClose ? onClose : () => {}}
            isOpen={isOpen}
            closeOnOverlayClick={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent pt="1.5rem" px="2rem" pb="2rem" {...restProps}>
                <ModalHeader p="0" mb="0.375rem">
                    {title}
                </ModalHeader>
                {withSeparator && <HSeparator mb="1.5rem" />}
                {onClose && <ModalCloseButton top="0.75rem" />}
                <ModalBody p="0">{children}</ModalBody>
            </ModalContent>
        </Modal>
    );
}
