import { Box, Fade, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table';
import React, { useEffect, useMemo } from 'react';
import { ActionButton } from './ActionButton';
import {
    DropTableProps,
    DualStackProps,
    DualStackTableContextProps,
    PickTableProps
} from './types';

const DualStackTableContext = React.createContext<DualStackTableContextProps>({
    rows: [],
    cols: [],
    pickedRows: [],
    addToPickedRows: () => {},
    removeFromPickedRows: () => {}
});

export const useDualStackTable = () => {
    return React.useContext(DualStackTableContext);
};

const DualStackTable: React.FC<DualStackProps> & {
    PickTable: React.FC<PickTableProps>;
    DropTable: React.FC<DropTableProps>;
} = ({ rows, cols, selectedRows, children }) => {
    const [rowsToPick, setRowsToPick] = React.useState<any>(rows || []);
    const [pickedRows, setPickedRows] = React.useState<any>(selectedRows || []);
    const addToPickedRows = (row: any) => {
        setPickedRows([...pickedRows, row]);
        setRowsToPick(rowsToPick.filter((r: any) => r.id !== row.id));
    };

    const removeFromPickedRows = (row: any) => {
        setPickedRows(pickedRows.filter((r: any) => r.id !== row.id));
        setRowsToPick([...rowsToPick, row]);
    };

    useEffect(() => {
        setRowsToPick(rows || []);
    }, [rows]);

    useEffect(() => {
        setPickedRows(selectedRows || []);
    }, [selectedRows]);

    return (
        <DualStackTableContext.Provider
            value={{
                rows: rowsToPick,
                cols,
                pickedRows: pickedRows,
                addToPickedRows,
                removeFromPickedRows
            }}
        >
            {children}
        </DualStackTableContext.Provider>
    );
};

const PickTable: React.FC<PickTableProps> = ({
    children,
    style,
    overideCols,
    onActionCb,
    cellProps
}) => {
    const { rows, cols, addToPickedRows } = React.useContext(DualStackTableContext);
    const data = useMemo(
        () =>
            rows.map((item) => ({
                ...item,
                action: undefined
            })),
        [rows]
    );

    const columnHelper = createColumnHelper<any>();

    const tableCols = overideCols || cols;

    let columns = tableCols.map((col) =>
        columnHelper.accessor(col.accessor, {
            cell: (info) => info.getValue(),
            header: () => col.title
        })
    );
    columns.push(
        columnHelper.accessor('action', {
            cell: (info) => (
                <ActionButton
                    onAction={() => {
                        addToPickedRows(info.row.original);
                        if (onActionCb) {
                            onActionCb(info.row.original);
                        }
                    }}
                    actionType={'ADD'}
                />
            ),
            header: () => ''
        })
    );
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel()
    });
    return (
        <Box>
            <Box px={'1rem'} backgroundColor={'white'}>
                <Table variant="dualStackTable" style={style}>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, idx) => (
                                    <Th
                                        key={idx}
                                        borderBottom="2px solid #E2E8F0"
                                        textAlign={
                                            idx === headerGroup.headers.length - 1
                                                ? 'right'
                                                : 'left'
                                        }
                                        _last={{ textAlign: 'right' }}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext()
                                              )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row, idx) => (
                            <Tr key={idx}>
                                {row.getVisibleCells().map((cell, idx2) => (
                                    <Td key={idx2} style={{ border: 'none' }}>
                                        {flexRender(cell.column.columnDef.cell, {
                                            ...cell.getContext(),
                                            ...cellProps
                                        })}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            {children}
        </Box>
    );
};

const DropTable: React.FC<DropTableProps> = ({
    style,
    overideCols,
    onActionCb,
    cellProps
}) => {
    const { pickedRows, cols, removeFromPickedRows } =
        React.useContext(DualStackTableContext);

    const data = useMemo(
        () =>
            pickedRows.map((item) => ({
                ...item,
                action: undefined
            })),
        [pickedRows]
    );

    const columnHelper = createColumnHelper<any>();

    const tableCols = overideCols || cols;

    let tableColumns = tableCols.map((col) => {
        return columnHelper.accessor(col.accessor, {
            cell: (info) => (col.render ? col.render(info) : info.getValue()),
            header: () => col.title
        });
    });

    tableColumns.push(
        columnHelper.accessor('action', {
            cell: (info) => (
                <ActionButton
                    onAction={() => {
                        removeFromPickedRows(info.row.original);
                        if (onActionCb) {
                            onActionCb(info.row.original);
                        }
                    }}
                    actionType={'REMOVE'}
                />
            ),
            header: () => ''
        })
    );

    const table = useReactTable({
        data: data,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel()
    });

    return (
        <Fade in={data.length > 0}>
            <Box px={'1rem'} backgroundColor={'white'}>
                <Table variant="dualStackTable" style={style}>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, idx) => (
                                    <Th
                                        key={idx}
                                        borderBottom="2px solid #E2E8F0"
                                        textAlign={
                                            idx === headerGroup.headers.length - 1
                                                ? 'right'
                                                : 'left'
                                        }
                                        _last={{ textAlign: 'right' }}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext()
                                              )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row, idx) => (
                            <Tr key={idx}>
                                {row.getVisibleCells().map((cell, idx2) => (
                                    <Td key={idx2} py="0" style={{ border: 'none' }}>
                                        {flexRender(cell.column.columnDef.cell, {
                                            ...cell.getContext(),
                                            ...cellProps,
                                            rowIndex: idx
                                        })}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Fade>
    );
};

DualStackTable.PickTable = PickTable;
DualStackTable.DropTable = DropTable;

export default DualStackTable;
