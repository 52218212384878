import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

import { useCreateMemberMutation, useListClientsQuery } from '@api';

import { zodResolver } from '@hookform/resolvers/zod';
import { TeamIdParams } from '@router';
import { DualStackTable, StyledSelect } from '@tasklogy/zircon-ui-components';
import { roleDisplayNames, RoleName, teamRoles } from 'common/roles';
import { teamValidator } from 'common/validators';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { useAuth } from 'src/auth/useAuth';

interface CreateMemberModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const createMemebr = teamValidator.createMember.omit({ teamId: true });

type CreateMemberForm = z.infer<typeof createMemebr>;

const clientsCols = [
    {
        Header: 'Client',
        accessor: 'name',
        title: 'Client'
    }
];

const dropTableCols = [
    {
        Header: 'Client',
        accessor: 'name',
        title: 'Client',
        render: (info: any) => {
            return <Flex>{info.getValue()}</Flex>;
        }
    }
];

const CreateFormContent: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const { user, hasScopePermissions } = useAuth();
    const params = useParams<TeamIdParams>();
    const [createMember, { isLoading }] = useCreateMemberMutation();
    const { data: clients } = useListClientsQuery(
        {
            teamId: params?.teamId ? parseInt(params.teamId) : user?.teamId ?? null
        },
        {
            skip: !user?.teamId && !params.teamId
        }
    );

    const {
        control,
        register,
        formState: { errors },
        watch,
        handleSubmit
    } = useForm<CreateMemberForm>({
        resolver: zodResolver(createMemebr),
        mode: 'onBlur',
        defaultValues: {
            role: 'team-employee'
        }
    });

    const selectedRole = watch('role');

    const rolesOptions = useMemo(() => {
        const teamRoleOptions = teamRoles.map((role) => {
            const roleDisplayName = roleDisplayNames[role];
            return { label: roleDisplayName, value: role };
        });

        if (
            hasScopePermissions({
                global: ['manage-developers']
            })
        ) {
            teamRoleOptions.push({
                label: roleDisplayNames.developer,
                value: 'developer'
            });
        }

        return teamRoleOptions;
    }, [hasScopePermissions]);

    const clientRows = useMemo(() => {
        return clients?.map((client) => ({
            id: client.id,
            name: client.name
        }));
    }, [clients]);

    const [selectedTeams, setSelectedTeams] = useState<{ id: number; name: string }[]>(
        []
    );

    const handleSubmitForm = handleSubmit(async (values) => {
        const dataToSend = {
            ...values,
            teamId: user?.teamId ?? (params?.teamId ? parseInt(params.teamId, 10) : 0),
            assignedClients: selectedTeams.map((team) => team.id)
        };
        try {
            await createMember(dataToSend).unwrap();
            toast.success('Member has been successfully created.');
            onClose();
        } catch (error) {
            if ((error as any)?.status === 409) {
                toast.error('Member with this email already exists.');
                return;
            }
            toast.error('An unexpected error occurred while creating a member.');
            console.error(error);
        }
    });

    return (
        <>
            <ModalHeader color="#525A66" fontWeight="bold">
                Create Member
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <form>
                    <Grid
                        templateColumns="repeat(12, 1fr)"
                        templateRows="repeat(3, auto)"
                        gap="1rem"
                    >
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.firstName)}
                                isRequired
                            >
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="text"
                                    {...register('firstName', {
                                        required: true,
                                        maxLength: 20
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.firstName?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.lastName)}
                                isRequired
                            >
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="text"
                                    {...register('lastName')}
                                />
                                <FormErrorMessage>
                                    {errors.lastName?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.phoneNumber)}
                            >
                                <FormLabel>Phone Number</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="tel"
                                    {...register('phoneNumber')}
                                />
                                <FormErrorMessage>
                                    {errors.phoneNumber?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.email)}
                                isRequired
                            >
                                <FormLabel>E-mail</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    type="email"
                                    {...register('email')}
                                />
                                <FormErrorMessage>
                                    {errors.email?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.password)}
                                isRequired
                            >
                                <FormLabel>Password</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="password"
                                    {...register('password')}
                                />
                                <FormErrorMessage>
                                    {errors.password?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={6}>
                            <Controller
                                control={control}
                                name="role"
                                rules={{
                                    required: true
                                }}
                                render={({ field: { value, onChange } }) => {
                                    const roleDisplayName = value
                                        ? roleDisplayNames[value as RoleName]
                                        : '';
                                    return (
                                        <FormControl
                                            color="#525A66"
                                            mb="20px"
                                            sx={{
                                                '.select__value-container': {
                                                    height: '50px'
                                                }
                                            }}
                                            isRequired
                                        >
                                            <FormLabel>Role</FormLabel>
                                            <StyledSelect
                                                options={rolesOptions}
                                                value={{
                                                    label: roleDisplayName,
                                                    value: value
                                                }}
                                                onChange={(value: any) =>
                                                    onChange(value.value)
                                                }
                                                classNamePrefix="select"
                                            />
                                        </FormControl>
                                    );
                                }}
                            />
                        </GridItem>
                        {selectedRole === 'team-employee' && (
                            <GridItem colSpan={12}>
                                <GridItem colSpan={12}>
                                    <FormLabel>Assign clients</FormLabel>
                                </GridItem>
                                <DualStackTable
                                    rows={clientRows as any}
                                    cols={clientsCols as any}
                                    selectedRows={selectedTeams}
                                >
                                    <Grid templateColumns="repeat(12, 1fr)">
                                        <GridItem colSpan={6}>
                                            <DualStackTable.PickTable
                                                onActionCb={(value: any) =>
                                                    setSelectedTeams([
                                                        ...selectedTeams,
                                                        { id: value.id, name: value.name }
                                                    ])
                                                }
                                            />
                                        </GridItem>
                                        <GridItem colSpan={6}>
                                            <DualStackTable.DropTable
                                                overideCols={dropTableCols}
                                                onActionCb={(value: any) => {
                                                    setSelectedTeams(
                                                        selectedTeams.filter(
                                                            (team) => team.id !== value.id
                                                        )
                                                    );
                                                }}
                                            />
                                        </GridItem>
                                    </Grid>
                                </DualStackTable>
                            </GridItem>
                        )}
                    </Grid>
                </form>
            </ModalBody>

            <ModalFooter>
                <Button
                    variant="solid"
                    isLoading={isLoading}
                    onClick={() => {
                        handleSubmitForm();
                    }}
                >
                    Create Member
                </Button>
            </ModalFooter>
        </>
    );
};

const CreateMemberModal: React.FC<CreateMemberModalProps> = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay backdropFilter="blur(10px)" backgroundColor="transparent" />
            <ModalContent
                boxShadow="0px 5.56px 29.19px 1.39px rgba(36, 99, 235, 0.05);"
                minWidth="50rem"
            >
                <CreateFormContent onClose={onClose} />
            </ModalContent>
        </Modal>
    );
};

export default CreateMemberModal;
