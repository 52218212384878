import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import ClientsTable from '../../components/Tables/ClientsTable/ClientsTable.tsx';
import CreateClientModal from './CreateClientModal';

const Clients: React.FC = () => {
    const { isOpen, onClose } = useDisclosure();
    return (
        <div>
            <Flex gap={2} mb="20px" justifyContent="left" alignItems="center">
                <Text variant="title">Clients</Text>
            </Flex>
            <Box>
                <ClientsTable />
            </Box>
            <CreateClientModal isOpen={isOpen} onClose={onClose} />
        </div>
    );
};

export default Clients;
