import { ModalContent, ModalOverlay } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import { useModalStepList } from './ModalStepList';
import { ModalStepProps } from './ModalStepList.types';

const MotionModalContent = motion(ModalContent);

export const ModalStep: FC<ModalStepProps> = ({ children, isActive, modalProps }) => {
    const { setModalProps } = useModalStepList();
    useEffect(() => {
        if (isActive && modalProps) {
            setModalProps(modalProps);
        }
    }, [isActive, modalProps]);
    if (isActive === false) return null;
    return (
        <>
            <ModalOverlay
                backdropBlur="8px"
                bg="blackAlpha.300"
                backdropFilter="blur(8px)"
            />
            <MotionModalContent
                pt="1rem"
                px="1rem"
                pb="1.5rem"
            >
                {children}
            </MotionModalContent>
        </>
    );
};
