export default function ErrorFallback() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                gap: '40px'
            }}
        >
            <h1>Ooops! Something went wrong.</h1>
            <a
                href="/"
                style={{
                    textDecoration: 'underline',
                    backgroundColor: `#23E1EF`,
                    borderRadius: '4px',
                    color: `#525A66`,
                    fontWeight: 'bold',

                    textAlign: 'center',
                    border: '2px solid transparent',
                    fontSize: '16px',
                    padding: '20px 40px'
                }}
            >
                Get Back to home page.
            </a>
        </div>
    );
}
