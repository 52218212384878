import { useState } from 'react';

export function useModal<T>() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [data, setData] = useState<T | undefined>();

    const open = (data?: T) => {
        setData(data);
        setIsOpen(true);
    };
    const close = () => {
        setIsOpen(false);
        setData(undefined);
    };

    return { isOpen, open, close, data, setData };
}
