import {
    Button,
    Flex,
    FormControl,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text
} from '@chakra-ui/react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

interface Props {
    onSubmit: (password: string) => void;
    errorMessage?: string;
    isLoading?: boolean;
}

export default function AuthForm({ onSubmit, errorMessage, isLoading }: Props) {
    const [showPassword, setShowPassword] = useState(false);
    const {
        control,
        watch,
        handleSubmit: _handleSubmit
    } = useForm<{ password: string }>({ defaultValues: { password: '' } });
    const password = watch('password');

    const handleSubmit = _handleSubmit((data) => {
        onSubmit(data.password);
    });

    return (
        <form onSubmit={handleSubmit}>
            <FormControl mt="0.5rem" isRequired>
                <Controller
                    control={control}
                    name="password"
                    render={({ field: {value, onChange, ...rest} }) => (
                        <InputGroup size="md">
                            <Input
                                {...rest}
                                value={value}
                                onChange={(e) => onChange(e.target.value.trim())}
                                fontSize="sm"
                                ms={{ base: '0px', md: '4px' }}
                                placeholder="Password"
                                size="lg"
                                type={showPassword ? 'text' : 'password'}
                            />{' '}
                            <InputRightElement
                                display="flex"
                                h="100%"
                                mr="0.5rem"
                                alignItems="center"
                            >
                                <Icon
                                    color="navy.700"
                                    boxSize="20px"
                                    _hover={{ cursor: 'pointer' }}
                                    as={
                                        showPassword
                                            ? RiEyeCloseLine
                                            : MdOutlineRemoveRedEye
                                    }
                                    onClick={() => setShowPassword((prev) => !prev)}
                                />
                            </InputRightElement>
                        </InputGroup>
                    )}
                />
                {errorMessage && (
                    <Text
                        ml="1rem"
                        mt="0.25rem"
                        as="span"
                        color="red.300"
                        fontSize="12px"
                    >
                        {errorMessage}
                    </Text>
                )}
            </FormControl>
            <Flex justifyContent="right">
                <Button
                    variant="solid"
                    mt="1rem"
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!password}
                >
                    Submit
                </Button>
            </Flex>
        </form>
    );
}
