import { Text } from "@chakra-ui/react";

interface Props {
    title: string;
}

const FormPropertyTitle: React.FC<Props> = ({ title }) => {
    return <Text textStyle='h2'  whiteSpace='nowrap'>{title}</Text>
}

export default FormPropertyTitle;