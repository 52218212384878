import { Flex, FormControl, Input, InputGroup, InputLeftElement, InputProps, Text, Tooltip } from "@chakra-ui/react";
import { forwardRef } from "react";
import { Controller, FieldError, Path } from "react-hook-form";
import FormPropertyRow from "../layout/FormPropertyRow";
import FormPropertyTitle from "../layout/FormPropertyTitle";

interface FormNumberInputProps extends InputProps {
    name: Path<any>;
    title: string;
    error?: FieldError | undefined;
}

const FormTextInput = forwardRef<HTMLInputElement, FormNumberInputProps>(({ name, title, error, ...props }, ref) => {
    return (
        <FormPropertyRow>
            <Flex alignItems={'center'} justifyContent='space-between' gap={'0.5rem'} width={'100%'}>
                <FormPropertyTitle title={title ?? 'Background'} />
                <Controller
                    name={name}
                    render={({ field }) => (
                        <FormControl isInvalid={Boolean(error?.message)}>
                            <Input variant='editor' type='text' {...field} {...props} ref={ref} value={field.value ?? ''} />
                        </FormControl>)}
                />
            </Flex>
        </FormPropertyRow>
    );
});

export default FormTextInput;