
import { Box, Checkbox, Flex, Input, Text } from "@chakra-ui/react";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import FormTitle from "../layout/FormTitle";

interface DateRangeFormProps<T> {
    title: string;
    name: Path<T>;
    defaultValue: string | null;
}

const MinMaxDate = <T extends FieldValues>({ title, name, defaultValue }: DateRangeFormProps<T>) => {
    const methods = useFormContext<T>();
    return <Controller
        control={methods.control}
        name={name}
        render={({ field }) => (
            <Box>
                <Flex gap={4}>
                    <Checkbox
                        onChange={(e) => {
                            if (e.target.checked) {
                                field.onChange({ target: { value: defaultValue } });
                            } else {
                                field.onChange({ target: { value: null } });
                            }
                        }}
                        isChecked={Boolean(field.value)}
                    />
                    <Text>{title}</Text>
                </Flex>
                <Input
                    size="md"
                    maxW="14rem"
                    type="date"
                    isDisabled={!field.value}
                    value={field.value ? field.value : ''}
                    onChange={field.onChange}
                />
            </Box>
        )}
    />
}

export default MinMaxDate;