import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    useDisclosure,
    Input,
    FormControl,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
    Badge
} from '@chakra-ui/react';
import React from 'react';

interface DeleteCardAlertProps {
    text: string;
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteCardAlert = ({ text, isOpen, onClose, onDelete }: DeleteCardAlertProps) => {
    const cancelRef = React.useRef(null);
    const [value, setValue] = React.useState('');
    const handleDelete = () => {
        if (value === text) {
            onDelete();
            onClose();
        }
    };

    const isError = value !== text;
    return (
        <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Delete report
                    </AlertDialogHeader>

                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                    If you want to delete the report, overwrite it{' '}
                        <Badge colorScheme="red" textTransform="none">
                            {text}
                        </Badge>{' '}
                        into the text field.
                        <FormControl isInvalid={Boolean(isError && value)}>
                            <FormLabel>Slug</FormLabel>
                            <Input
                                type="text"
                                value={value}
                                onChange={(event) => setValue(event.target.value)}
                            />
                            {isError && value && (
                                <FormErrorMessage>
                                    Value "{value}" is not equal to "{text}"
                                </FormErrorMessage>
                            )}
                        </FormControl>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} variant='outline' onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant="solid" colorScheme='error' onClick={() => handleDelete()} ml={3}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default DeleteCardAlert;
