import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
    DASHBOARD_DATA_SOURCES_PATH,
    DASHBOARD_PATH,
    DASHBOARD_REPORTS_PATH,
    SHARED_REPORT_PATH
} from '../constants';
import privateRoutes from './private';
import publicRoutes from './public';

export enum PathNames {
    BASE_SHARED_REPORT = `/${SHARED_REPORT_PATH}/client/:clientId`,
    SHARED_REPORT = `/${SHARED_REPORT_PATH}/client/:clientId/:reportSlug/:viewSlug?`,
    DASHBOARD_LOGIN = `${DASHBOARD_PATH}/auth/login`,
    DASHBOARD_SOURCE_AUTHS = `${DASHBOARD_PATH}/connected-sources`,
    DASHBOARD = `${DASHBOARD_PATH}`,
    DASHBOARD_DOCUMENTATION = `${DASHBOARD_PATH}/documentation`,
    DASHBOARD_REPORTS = `${DASHBOARD_PATH}/${DASHBOARD_REPORTS_PATH}`,
    DASHBOARD_REPORT = `${DASHBOARD_PATH}/${DASHBOARD_REPORTS_PATH}/:clientId/:reportSlug/:viewSlug?`,
    DASHBOARD_DATA_SOURCES = `${DASHBOARD_PATH}/${DASHBOARD_DATA_SOURCES_PATH}`,
    DASHBOARD_DATA_SOURCE_DETAIL = `${DASHBOARD_PATH}/${DASHBOARD_DATA_SOURCES_PATH}/:id`,
    DASHBOARD_CLIENTS = `${DASHBOARD_PATH}/clients`,
    DASHBOARD_CLIENT_REPORTS = `${DASHBOARD_CLIENTS}/:clientId/reports`,
    CLIENT_DETAIL = `${DASHBOARD_PATH}/clients/:clientId`,
    CLIENT_CREATE_REPORT = `${DASHBOARD_PATH}/clients/:clientId/reports/create`,
    CLIENT_CREATE_REPORT_SIMPLE = `${DASHBOARD_PATH}/clients/:clientId/reports/create-simple`,
    CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE = `${DASHBOARD_PATH}/clients/:clientId/reports/create-simple/:templateId`,
    CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW = `${DASHBOARD_PATH}/clients/:clientId/reports/create-simple/:templateId/:reportSlug/:viewSlug?`,
    CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_CREATED = `${DASHBOARD_PATH}/clients/:clientId/reports/create-simple/:templateId/created/:reportSlug/:viewSlug?`,
    CREATE_CLIENT = `${DASHBOARD_PATH}/clients/create`,
    USER_SETTINGS = `${DASHBOARD_PATH}/settings`,
    TEAMS = `${DASHBOARD_PATH}/teams`,
    TEAM_CREATE_UPDATE = `${DASHBOARD_PATH}/teams/detail/:teamId?`,
    TEAM_DETAIL = `${DASHBOARD_PATH}/teams/:teamId/clients`,
    TEAM_CREATE_CLIENT = `${DASHBOARD_PATH}/teams/:teamId/clients/create`,
    TEAM_CLIENT_DETAIL = `${DASHBOARD_PATH}/teams/:teamId/clients/:clientId`,
    USER_SETTINGS_PERSONAL_DATA = `${USER_SETTINGS}/personal-data`,
    USER_SETTINGS_PASSWORD = `${USER_SETTINGS}/password`,
    USER_SETTINGS_COMPANY_DATA = `${USER_SETTINGS}/company-data`,
    USER_SETTINGS_TEAM_MEMBERS = `${USER_SETTINGS}/team-members`,
    // TEMPLATE ROUTES
    TEMPLATES = `${DASHBOARD_PATH}/templates`,

    // EDITOR ROUTES
    EDITOR = '/editor/:clientId/:reportSlug/:viewSlug?',
    BASE_LIVE_PREVIEW = '/live-preview/:clientId',
    LIVE_PREVIEW = '/live-preview/:clientId/:reportSlug/:viewSlug?',
}

const RootRouter = () => {
    const router = createBrowserRouter([...privateRoutes(), ...publicRoutes()]);
    return <RouterProvider router={router} />;
};

export default RootRouter;
