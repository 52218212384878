import { Box, Text } from '@chakra-ui/react';
import { memo, useEffect, useState } from 'react';
import { isIframe } from 'src/utils';

interface SmallPreviewProps {
    src: string;
    height: number;
    width: number;
    onClick: () => void;
}

const MAX_HEIGHT = 90;
const FIXED_WIDTH = 100;

const SmallPreview = memo(({ src, height, width, onClick }: SmallPreviewProps) => {
    const [isAdblockDetected, setIsAdblockDetected] = useState(false);

    useEffect(() => {
        if (isIframe(new URL(src) as URL)) {
            fetch(src)
                .then((response) => response.text())
                .then(() => {
                    setIsAdblockDetected(false);
                })
                .catch(() => {
                    setIsAdblockDetected(true);
                });
        }
    }, [src]);

    // Calculate the aspect ratio scaling
    const widthRatio = FIXED_WIDTH / width;
    const heightRatio = MAX_HEIGHT / height;

    // Choose the smaller ratio to maintain the aspect ratio
    const scaleFactor = Math.min(widthRatio, heightRatio);

    const scaledWidth = width * scaleFactor;
    const scaledHeight = height * scaleFactor;

    const translateY = (MAX_HEIGHT - scaledHeight) / 2;

    if (isAdblockDetected) {
        return (
            <Box display={'flex'} flexDir={'column'} textAlign={'left'}>
                <Text fontSize={8}>Allow ads on the web</Text>
                <Text>It looks like you are using an ad blocker.</Text>
            </Box>
        );
    }

    return (
        <Box
            position="relative"
            width={`${FIXED_WIDTH}px`}
            height={`${MAX_HEIGHT}px`}
            overflow="hidden"
        >
            <Box
                position="absolute"
                cursor="pointer"
                onClick={onClick}
                top={0}
                left={0}
                width={FIXED_WIDTH}
                height={MAX_HEIGHT}
                zIndex={20}
            />
            <Box
                position="absolute"
                top={translateY}
                width={`${scaledWidth}px`}
                height={`${scaledHeight}px`}
            >
                <iframe
                    src={src}
                    width={width}
                    height={height}
                    style={{
                        transform: `scale(${scaleFactor})`,
                        transformOrigin: 'top left',
                        border: 'none'
                    }}
                    allowFullScreen
                />
            </Box>
        </Box>
    );
});

export default SmallPreview;
