import { Box, Image } from '@chakra-ui/react';
import { ComponentUI } from '@types';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';

const isSVG = (url: string) => {
    return url?.endsWith('.svg');
}

const GoImage = ({ properties }: ComponentUI) => {
    const { state } = useCanvas();
    const inEditor = state.useEditorReport

    const isSVGImage = isSVG(properties?.src);

    if (isSVGImage) {
        return <Box height='100%' width='100%' {...(inEditor ? { pointerEvents: 'none' } : {})} style={{ mask: `url(${properties?.src}) no-repeat 50% 50%`, ...properties.style }} />;
    }

    return (
        <Image
            height={'100%'}
            width={'100%'}
            {...(inEditor ? { pointerEvents: 'none' } : {})}
            style={properties.style}
            src={properties?.src}
            alt={properties?.alt}
        />
    );
};

export default GoImage;
