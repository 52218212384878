import { Input, InputProps } from "@chakra-ui/react";
import { forwardRef, useState } from "react";
import { FieldError } from "react-hook-form";

interface OpacityInputProps extends InputProps {
    error: FieldError | undefined;
    value: number;
}

const OpacityInput = forwardRef<HTMLInputElement, OpacityInputProps>(({ value, onChange }, ref) => {
    const [focused, setFocused] = useState(false);
    return (
        <Input ref={ref} variant='editor' size='xs'
            value={focused ? value * 100 : `${(value * 100).toFixed(0)}%`}
            onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}
            onChange={(e) => onChange && onChange({ target: { value: e.target.value ? Number(e.target.value) / 100 : 0 } } as any)}
            type="text" width={'50px'} />
    );
});

export default OpacityInput;