import { Button, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { ActionDelete, ActionEdit, HappyModal } from '@tasklogy/zircon-ui-components';
import ModalWindow from 'src/components/ModalWindow';

import { useModal } from 'src/hooks/useModal';
import { Report } from 'src/redux/types/api';
import { useDeleteReportMutation, useListClientsQuery } from '@api';
import { toast } from 'react-toastify';
import EditForm from './EditForm';
import { IoDuplicate } from 'react-icons/io5';
import SelectClientModal from './SelectClientModal';
import { generatePath, useNavigate } from 'react-router-dom';
import { PathNames } from 'src/router/router';
import { useAuth } from 'src/auth/useAuth';

interface Props {
    report: Report;
}

export default function ActionCell({ report: report }: Props) {
    const {
        open: openEditModal,
        isOpen: isEditModalOpen,
        close: closeEditModal
    } = useModal();
    const {
        open: openDuplicateModal,
        isOpen: isDuplicateModalOpen,
        close: closeDuplicateModal
    } = useModal();
    const [deleteTemplate] = useDeleteReportMutation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { data: clients } = useListClientsQuery(
        {
            teamId: user?.teamId ?? 0
        },
        {
            skip: !user?.teamId
        }
    );
    const {
        open: openDeleteModal,
        close: closeDeleteModal,
        isOpen: isDeleteModalOpen
    } = useModal();

    return (
        <Flex mr="1rem" justifyContent="end" minW={'5rem'}>
            <ActionEdit onClick={openEditModal} />
            <ActionDelete onClick={openDeleteModal} />
            <Tooltip label="Duplicate" aria-label="Duplicate" placement="top">
                <IconButton
                    variant="icon"
                    color="gray.500"
                    h={['1.5rem', '2rem', '2.5rem', '3rem', '3.5rem']}
                    w={['1.5rem', '2rem', '2.5rem', '3rem', '3.5rem']}
                    p={0}
                    onClick={openDuplicateModal}
                    icon={<IoDuplicate />}
                    aria-label={'duplicate'}
                />
            </Tooltip>
            <HappyModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                <HappyModal.Body>
                    Are you sure you want to delete report
                    <br />{' '}
                    <Text as="span" fontWeight="bold">
                        {report.title}
                    </Text>{' '}
                    ?
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={closeDeleteModal}>
                        No
                    </Button>
                    <Button
                        variant="solid"
                        onClick={async () => {
                            try {
                                await deleteTemplate({ id: report.id });
                                toast.success('Template deleted successfully.');
                                closeDeleteModal();
                            } catch (e) {
                                toast.error(
                                    'An error occurred while deleting the template.'
                                );
                            }
                        }}
                    >
                        Yes
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
            <ModalWindow
                minWidth="50rem"
                isOpen={isEditModalOpen}
                onClose={closeEditModal}
                title="Edit Report"
            >
                <EditForm onClose={closeEditModal} editedReport={report} />
            </ModalWindow>
            <SelectClientModal
                clients={clients ?? []}
                isOpen={isDuplicateModalOpen}
                onClose={closeDuplicateModal}
                onClientSelect={(selectedClientId) => {
                    if (selectedClientId) {
                        navigate(
                            generatePath(
                                PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW,
                                {
                                    clientId: selectedClientId.toString(),
                                    templateId: report.id.toString(),
                                    reportSlug: report.slug,
                                    viewSlug: ''
                                }
                            )
                        );
                    }
                }}
            />
        </Flex>
    );
}
