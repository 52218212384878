/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    Flex,
    FlexProps,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Text,
    TextProps,
    Th,
    Thead,
    Tr,
    useDisclosure
} from '@chakra-ui/react';
import {
    PaginationState,
    SortingState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { BlueprintTableProperties, TableData, valueIsNumeric } from '@types';
import { ColumnFormatType } from 'common/enums';
import { PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { formatGenericValue } from 'src/utils';
import RealSizeCreativeIframe from '../creativeIframe/RealSizeCreativeiframe';
import PlayerIcon from './PlayerIcon';
import SmallCreativeIframe from '../creativeIframe/SmallCreativeIframe';
import { GenericTooltip } from '../GenericTooltip';
import { Pagination } from './Pagination';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { isSfari } from 'src/templates/blueprint/utils';

interface Props {
    data: TableData;
    properties: BlueprintTableProperties;
    id: string;
    h: number;
}

function Header({
    properties,
    children
}: {
    children: ReactNode;
    properties: BlueprintTableProperties;
}) {
    return (
        <Text
            fontSize="13px"
            fontWeight="600"
            textTransform="none"
            as="span"
            style={properties?.headerCell}
        >
            {children}
        </Text>
    );
}

interface CellProps extends PropsWithChildren, FlexProps {
    textProps?: TextProps;
    properties: BlueprintTableProperties;
}

function Cell({ children, properties, textProps, style, ...rest }: CellProps) {
    return (
        <Flex align="center" {...rest}>
            <Text
                fontSize="12px"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                as="span"
                style={{ ...properties?.cell, ...style }}
                {...textProps}
            >
                {children}
            </Text>
        </Flex>
    );
}

export const GenericTable = ({ data, properties, id, h }: Props) => {
    const [modalSourceUri, setmodalSourceUri] = useState<{
        sourceType: 'HTML' | 'VIDEO' | 'IMAGE';
        url: string;
        width: number;
        height: number;
    }>();
    const {
        isOpen: isModalOpen,
        onOpen: onModalOpen,
        onClose: onModalClose
    } = useDisclosure();
    const columnHelper = createColumnHelper<any>();
    const tableColumns = data?.columns ?? [];
    const { state } = useCanvas();

    const withRowNumber = properties?.component?.rowNumbers;

    let columns = withRowNumber
        ? [
              columnHelper.accessor('id', {
                  id: 'id',
                  header: () => <></>,
                  cell: (info) => {
                      const rowNr =
                          (info.table
                              .getSortedRowModel()
                              ?.flatRows?.findIndex(
                                  (flatRow) => flatRow.id === info.row.id
                              ) || 0) + 1;

                      return (
                          <Text pl="1rem" color="black" style={properties.idCell}>
                              {withRowNumber ? rowNr : ''}
                          </Text>
                      );
                  }
              })
          ]
        : [];

    columns = columns.concat(
        tableColumns.map((column) => {
            return columnHelper.accessor(column.accessor, {
                id: column.accessor,
                header: () => <Header properties={properties}>{column.title}</Header>,
                cell: (info) => {
                    const columnDefinition = data.columns.find(
                        (column) => column.accessor === info.column.id
                    );
                    if (
                        (columnDefinition?.type as ColumnFormatType) ===
                        ColumnFormatType.AD_PREVIEW
                    ) {
                        const value = info.renderValue();
                        if (value?.sourceType === 'IMAGE') {
                            return (
                                <Image
                                    src={value.url}
                                    height="90px"
                                    data-testid="image-thumbnail"
                                    my="0.25rem"
                                    borderRadius="0.25rem"
                                    _hover={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setmodalSourceUri(value);
                                        onModalOpen();
                                    }}
                                />
                            );
                        }
                        if (value?.sourceType === 'VIDEO') {
                            return (
                                <Box
                                    position={'relative'}
                                    width="max-content"
                                    height="90px"
                                    _hover={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setmodalSourceUri(info.renderValue());
                                        onModalOpen();
                                    }}
                                >
                                    <Image
                                        src={info.renderValue().thumbnail}
                                        height="90px"
                                        data-testid="video-thumbnail"
                                        my="0.25rem"
                                        borderRadius="0.25rem"
                                    />
                                    <PlayerIcon url={info.renderValue().url} />
                                </Box>
                            );
                        } else if (value?.sourceType === 'HTML') {
                            return (
                                <SmallCreativeIframe
                                    src={value.url}
                                    height={value.height}
                                    data-testid="html-thumbnail"
                                    width={value.width}
                                    onClick={() => {
                                        setmodalSourceUri(value);
                                        onModalOpen();
                                    }}
                                />
                            );
                        } else {
                            return <>-</>;
                        }
                    }

                    if (columnDefinition?.type === ColumnFormatType.LINK) {
                        return (
                            <Cell properties={properties}>
                                <a
                                    href={info.renderValue()}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {info.renderValue()}
                                </a>
                            </Cell>
                        );
                    }

                    const isNumericMetric =
                        valueIsNumeric[columnDefinition?.type as ColumnFormatType] &&
                        columnDefinition?.isMetric;

                    const formattedValue = formatGenericValue(
                        info.renderValue(),
                        column.type,
                        {
                            ...columnDefinition
                        }
                    );

                    return (
                        <Cell
                            properties={properties}
                            maxW="24rem"
                            justifyContent={isNumericMetric ? 'end' : 'unset'}
                        >
                            {formattedValue}
                        </Cell>
                    );
                }
            });
        })
    );
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: properties?.component?.pageSize ?? 10
    });

    const table = useReactTable({
        data: data?.rows ?? [],
        columns,
        state: {
            sorting,
            pagination,
            globalFilter
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        debugTable: false
    });

    const rows = table.getRowModel().rows;

    // Calculate the number of text columns
    const numberOfDimensionColumns = tableColumns.filter(
        (column) => !column.isMetric
    ).length;

    const renderSummary = () => {
        return (
            <Tr
                style={properties?.footerStyle?.tr}
                pr="1rem"
                fontWeight="bold"
                position="sticky"
                bottom={summaryRowOffset}
                background="white"
            >
                {columns.map((column: any, index) => {
                    const summaryColumn = data.summary?.find(
                        (summaryColumn) => summaryColumn.accessor === column.accessorKey
                    );
                    const tableColumn = tableColumns.find(
                        (column) => column.accessor === summaryColumn?.accessor
                    );

                    let text = '';

                    if (index === 0) {
                        text = 'Total';
                    } else if (summaryColumn?.value !== undefined && tableColumn) {
                        text = formatGenericValue(
                            summaryColumn?.value,
                            tableColumn?.type,
                            tableColumn
                        );
                    }

                    return (
                        <Td
                            key={index}
                            fontSize={{ sm: '12px' }}
                            border="0"
                            px="12px"
                            py="0.5rem"
                            color="black"
                            w={index === 0 ? '3rem' : 'max-content'}
                            fontWeight="700"
                            style={properties?.footerStyle?.td}
                        >
                            <Cell
                                properties={properties}
                                justifyContent={index === 0 ? 'left' : 'right'}
                                style={properties?.footerStyle?.cell}
                            >
                                {text}
                            </Cell>
                        </Td>
                    );
                })}
            </Tr>
        );
    };

    const renderPreview = () => {
        if (modalSourceUri && modalSourceUri.sourceType === 'HTML') {
            return (
                <RealSizeCreativeIframe
                    src={modalSourceUri.url}
                    width={modalSourceUri?.width}
                    height={modalSourceUri?.height}
                />
            );
        } else if (modalSourceUri?.sourceType === 'VIDEO') {
            return <ReactPlayer url={modalSourceUri?.url} controls />;
        } else if (modalSourceUri?.sourceType === 'IMAGE') {
            return <Image src={modalSourceUri?.url} />;
        }

        return null;
    };

    const summaryRowOffset = useMemo(() => {
        // Safari has a bug where the sticky footer is not not actually at the bottom when using scale
        if (isSfari() && state.scale) {
            const offset = -1 * (1 - state.scale) * h;
            return `${offset}px`;
        }

        return '-1px';
    }, [state.scale, h]);

    return (
        <Flex w="100%" flexDir="column" h="100%">
            {rows.length > 0 && properties?.component?.paginationLayout === 'top' && (
                <Pagination properties={properties} table={table} />
            )}
            <Box w="100%" maxH="100%" overflow="auto">
                <Table
                    color="gray.500"
                    data-testid={`table-${id}`}
                    __css={{
                        width: '100%',
                        tableLayout: 'auto'
                    }}
                >
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr
                                key={headerGroup.id}
                                style={properties?.headerStyle?.tr}
                                position="sticky"
                                top="-1px"
                                zIndex={100}
                            >
                                {headerGroup.headers.map((header) => {
                                    const column = data?.columns?.find(
                                        (column) => column?.accessor === header.column.id
                                    );

                                    const isMetric =
                                        column?.isMetric || header.column?.id === 'id';

                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            cursor="pointer"
                                            px="12px"
                                            _first={{
                                                pl: withRowNumber ? '0' : '1rem',
                                                pr: '0'
                                            }}
                                            py="0.375rem"
                                            onClick={header.column.getToggleSortingHandler()}
                                            w={
                                                isMetric
                                                    ? 'fit-content'
                                                    : `${100 / numberOfDimensionColumns}%`
                                            }
                                            minW={isMetric ? 'fit-content' : 'auto'}
                                            whiteSpace="nowrap"
                                            style={properties?.headerStyle?.th}
                                        >
                                            <Flex
                                                align="center"
                                                textAlign={'right'}
                                                justifyContent={
                                                    isMetric ? 'right' : 'unset'
                                                }
                                                fontSize={{ sm: '10px', lg: '12px' }}
                                                gap={1}
                                            >
                                                <GenericTooltip label={column?.tooltip}>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}

                                                    {{
                                                        asc: (
                                                            <Text
                                                                ml="0.125rem"
                                                                style={{
                                                                    color: properties
                                                                        .headerCell?.color
                                                                }}
                                                            >
                                                                &#x25B2;
                                                            </Text>
                                                        ),
                                                        desc: (
                                                            <Text
                                                                ml="0.125rem"
                                                                style={{
                                                                    color: properties
                                                                        .headerCell?.color
                                                                }}
                                                            >
                                                                &#x25BC;
                                                            </Text>
                                                        )
                                                    }[
                                                        header.column.getIsSorted() as string
                                                    ] ?? null}
                                                </GenericTooltip>
                                            </Flex>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>

                    {rows.length > 0 ? (
                        <Tbody>
                            {rows.map((row) => {
                                return (
                                    <Tr
                                        key={row.id}
                                        style={properties?.bodyStyle?.tr}
                                        _even={properties?.bodyStyle?.tr?._even}
                                        _odd={properties?.bodyStyle?.tr?._odd}
                                        data-testid={`row-${row.id}`}
                                        pr="1rem"
                                    >
                                        {row.getVisibleCells().map((cell, index) => {
                                            return (
                                                <Td
                                                    key={cell.id}
                                                    fontSize={{ sm: '12px' }}
                                                    border="0"
                                                    px="12px"
                                                    py="0.35rem"
                                                    _first={{
                                                        pl: withRowNumber ? '0' : '1rem',
                                                        pr: '0'
                                                    }}
                                                    w={
                                                        index === 0
                                                            ? '2rem'
                                                            : 'max-content'
                                                    }
                                                    style={properties?.bodyStyle?.td}
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            );
                                        })}
                                    </Tr>
                                );
                            })}
                            {renderSummary()}
                        </Tbody>
                    ) : (
                        <Tbody>
                            <Tr
                                style={properties?.emptyTableStyle?.tr}
                                data-testid="empty-row"
                            >
                                <Td
                                    style={properties?.emptyTableStyle?.td}
                                    colSpan={columns.length}
                                    w="100%"
                                    py="1rem"
                                >
                                    <Text
                                        style={properties?.emptyTableStyle?.text}
                                        fontSize="1rem"
                                        textAlign="center"
                                        color="black"
                                    >
                                        There are none for the selected period and
                                        campaigns data
                                    </Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    )}
                </Table>
            </Box>

            {rows.length > 0 && properties?.component?.paginationLayout === 'bottom' && (
                <Pagination properties={properties} table={table} />
            )}
            <Modal isOpen={isModalOpen} onClose={onModalClose} closeOnEsc>
                <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px)"
                    style={properties?.modalOverlayStyle}
                />
                <ModalContent
                    width="100%"
                    padding="3rem"
                    backgroundColor="transparent"
                    boxShadow="none"
                    maxW="none"
                    style={properties?.modalContentStyle}
                >
                    <ModalBody
                        p={0}
                        display="flex"
                        justifyContent="center"
                        style={properties?.modalBodyStyle}
                        data-testid="ad-preview-modal"
                    >
                        <Flex position="relative" p={0}>
                            {renderPreview()}
                        </Flex>
                        <Box position="relative">
                            <ModalCloseButton
                                position="absolute"
                                top="-40px"
                                left="10px"
                                style={{ outline: 'none' }}
                                _hover={{ borderColor: 'white' }}
                                _focus={{ borderColor: 'transparent' }}
                                color="white"
                                backgroundColor="gray"
                                outline={'none'}
                                data-testid="close-modal-button"
                            />
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
};
