import { useAppSelector } from "@hooks";
import { ComponentType } from "common/enums";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import CommonSetup from "src/editor/components/setup/Common";
import DateRangeSetup from "src/editor/components/setup/DateRange";
import FilterSetup from "src/editor/components/setup/Filter";
import ImageSetup from "src/editor/components/setup/Image";
import NavigationButtonSetup from "src/editor/components/setup/NavigationButton";
import SingleValueSetup from "src/editor/components/setup/SingleValue";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";

const NoneSetup = () => null;

const ComponentStyle = {
    [ComponentType.RECTANGLE]: NoneSetup,
    [ComponentType.TEXT]: NoneSetup,
    [ComponentType.TABLE]: CommonSetup,
    [ComponentType.DONUT_CHART]: CommonSetup,
    [ComponentType.PIE_CHART]: CommonSetup,
    [ComponentType.AREA_CHART]: CommonSetup,
    [ComponentType.LINE_CHART]: CommonSetup,
    [ComponentType.BIAXIAL_BAR_CHART]: CommonSetup,
    [ComponentType.STACKED_BAR_CHART]: CommonSetup,
    [ComponentType.TINY_BAR_CHART]: CommonSetup,
    [ComponentType.TABLE_PROGRESS_BAR_CHART]: CommonSetup,
    [ComponentType.SINGLE_VALUE]: SingleValueSetup,
    [ComponentType.SINGLE_SELECT]: FilterSetup,
    [ComponentType.MULTI_SELECT]: FilterSetup,
    [ComponentType.DATE_RANGE]: DateRangeSetup,
    [ComponentType.DOWNLOAD_BUTTON]: NoneSetup,
    [ComponentType.NAVIGATION_BUTTON]: NavigationButtonSetup,
    [ComponentType.IMAGE]: ImageSetup,
    [ComponentType.LOGO]: ImageSetup,
    [ComponentType.NAVBAR]: NoneSetup,
}

const Setup = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    if (!componentToEdit) return null;
    const ComponentStyleToRender = ComponentStyle[componentToEdit.type] || null;
    return <ComponentStyleToRender />
}

export default Setup;