import { Text } from "@chakra-ui/react";

export interface ITitleCell {
  title: string;
  weight?: string;
  onClick?: () => void;
}

const TitleCell: React.FC<ITitleCell> = ({
  title,
  onClick,
  weight = "medium",
}) => {
  return (
    <Text
      fontWeight={weight}
      _hover={{
        cursor: "pointer",
        textDecoration: "underline 0.15em #535A66",
        textUnderlineOffset: "0.4em",
      }}
      transition="text-decoration-color 300ms, text-underline-offset 300ms"
      textDecoration="underline 0.15em rgba(0, 0, 0, 0)"
      textUnderlineOffset="0.15em"
      onClick={onClick}
    >
      {title}
    </Text>
  );
};

export default TitleCell;
