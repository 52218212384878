import { Box, Flex, Text } from '@chakra-ui/react';
import { SourceIcon, StyledSelect } from '@tasklogy/zircon-ui-components';
import { ColumnFormatType } from 'common/enums';
import { DataComponent, DimensionConfigWithId, MetricConfigWithId } from 'common/types';
import { compatibilityChecker } from 'common/utils';
import { useMemo } from 'react';
import { components, ControlProps, OptionProps } from 'react-select';
import DnDElements from 'src/blueprint/components/DnDElements/DnDElements';
import { useAppSelector } from 'src/hooks/redux';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import {
    selectComponentById,
    selectConnectedSources
} from 'src/redux/features/blueprint/bluePrintSlice';
import { useCanvas } from '../../EditorContext';

type HandleChangeArgs = {
    key: 'dimensions' | 'metrics';
    selectedOption: {
        id: string;
        value: string | number;
        label: string;
        formatType: ColumnFormatType;
        customDisplayName?: string | null;
        customDecimalPlaces?: number | null;
    }[];
};

const Control = ({ children, ...props }: ControlProps<any>) => {
    const value = props?.selectProps?.value?.value?.dataSource?.identifier;
    return (
        <components.Control {...props}>
            <Box paddingLeft={2}>
                <SourceIcon selectedIcons={[value]} />
            </Box>
            {children}
        </components.Control>
    );
};

const Option = ({ children, ...props }: OptionProps<any>) => {
    const value = props.data?.value?.dataSource?.identifier;
    return (
        <components.Option {...props}>
            <Flex role="group" gap={2}>
                <Box
                    filter={'grayscale(0.7)'}
                    _groupHover={{ filter: 'none' }}
                    transition={'filter 0.3s ease'}
                >
                    <SourceIcon selectedIcons={[value]} />
                </Box>
                {children}
            </Flex>
        </components.Option>
    );
};

const getWholeConfigs = (
    selectedMetrics?:
        | {
              id: string;
              customDisplayName?: string | null;
              customDecimalPlaces?: number | null;
          }[]
        | null,
    dataSourceMetricCOnfigs?: MetricConfigWithId[]
) => {
    if (!selectedMetrics) {
        return [];
    }

    return selectedMetrics
        ?.map((dim) => ({
            ...dataSourceMetricCOnfigs?.find((d) => d.id === dim.id),
            customDisplayName: dim.customDisplayName,
            customDecimalPlaces: dim.customDecimalPlaces
        }))
        .filter((d) => d.id) as DimensionConfigWithId[];
};

export const ComponentConfig = () => {
    const {
        state: { activeSelectedComponentId }
    } = useCanvas();
    const componentToEdit = useAppSelector(
        selectComponentById(activeSelectedComponentId)
    ) as DataComponent;
    const connectedSources = useAppSelector(selectConnectedSources);

    const connectedSourceId = componentToEdit.connectedSourceId;

    const setComponentConfig = useSetComponentConfig();

    const connectedSource = connectedSources?.find((ds) => ds.id === connectedSourceId);

    const connectedSourceOptions = useMemo(() => {
        return connectedSources?.map((cs) => ({
            value: cs,
            label: cs?.advertiser?.advertiserName
        }));
    }, [connectedSources]);

    const dataSourceDiemnsions = connectedSource?.dataSource?.dimensions;
    const dataSourcemetrics = connectedSource?.dataSource?.metrics;

    const selectedDimensions = getWholeConfigs(
        componentToEdit.dimensions,
        dataSourceDiemnsions
    );

    const selectedMetrics = getWholeConfigs(componentToEdit.metrics, dataSourcemetrics);

    const dimensionOptions = useMemo(() => {
        // filter out options that are already selected
        const notUsedDimensions = dataSourceDiemnsions?.filter(
            (ds) => !selectedDimensions?.find((selected) => selected.id === ds.id)
        );

        const { compatibleDimensions } =
            compatibilityChecker.getCompatibleMetricsAndDimensions({
                allDimensions: notUsedDimensions ? notUsedDimensions : [],
                allMetrics: dataSourcemetrics ? dataSourcemetrics : [],
                selectedMetrics: selectedMetrics ? selectedMetrics : [],
                selectedDimensions: selectedDimensions ? selectedDimensions : []
            });

        return compatibleDimensions;
    }, [dataSourceDiemnsions, dataSourcemetrics, selectedMetrics, selectedDimensions]);

    const metricOptions = useMemo(() => {
        const notUsedMetrics = dataSourcemetrics?.filter(
            (ds) => !selectedMetrics?.find((selected) => selected.id === ds.id)
        );

        const { compatibleMetrics } =
            compatibilityChecker.getCompatibleMetricsAndDimensions({
                allDimensions: dataSourceDiemnsions ? dataSourceDiemnsions : [],
                allMetrics: notUsedMetrics ? notUsedMetrics : [],
                selectedMetrics: selectedMetrics ? selectedMetrics : [],
                selectedDimensions: selectedDimensions ? selectedDimensions : []
            });

        return compatibleMetrics;
    }, [dataSourcemetrics, dataSourceDiemnsions, selectedMetrics, selectedDimensions]);

    const handleChange = async ({ key, selectedOption }: HandleChangeArgs) => {
        if (key === 'dimensions') {
            const dimensions = selectedOption.map((option) => ({
                id: option.id,
                customDisplayName: option.customDisplayName ?? null,
                customDecimalPlaces: option.customDecimalPlaces ?? null
            }));

            await setComponentConfig({
                ...componentToEdit,
                dimensions
            });

            return;
        }

        if (key === 'metrics') {
            const metrics = selectedOption.map((option) => ({
                id: option.id,
                customDisplayName: option.customDisplayName ?? null,
                customDecimalPlaces: option.customDecimalPlaces ?? null
            }));

            await setComponentConfig({
                ...componentToEdit,
                metrics
            });

            return;
        }

        throw new Error('Invalid key');
    };

    return (
        <Flex flexDir={'column'} gap={'2rem'} my={'1rem'} px="1rem">
            <Box>
                <Text>Select Advertiser Account</Text>
                <StyledSelect
                    isMulti={false}
                    onChange={async (target: any) => {
                        const updatedComponent = {
                            ...componentToEdit,
                            dimensionIds: [],
                            metricIds: [],
                            connectedSourceId: target.value.id
                        };

                        await setComponentConfig(updatedComponent);
                    }}
                    options={connectedSourceOptions}
                    value={connectedSourceOptions?.find(
                        (option) => option.value.id === connectedSourceId
                    )}
                    components={{ Option, Control }}
                />
            </Box>
            <Box>
                <Text>Select dimensions</Text>
                <DnDElements
                    values={
                        selectedDimensions?.map((ds) => {
                            return {
                                id: ds?.id,
                                label: ds?.defaultDisplayName,
                                value: ds.id,
                                formatType: ds?.formatType,
                                customDisplayName: ds?.customDisplayName,
                                customDecimalPlaces: ds?.customDecimalPlaces
                            };
                        }) ?? []
                    }
                    options={
                        dimensionOptions?.map((ds: DimensionConfigWithId) => ({
                            id: ds.id,
                            label: ds.defaultDisplayName,
                            value: ds.id,
                            formatType: ds.formatType,
                            customDisplayName: ds.customDisplayName,
                            customDecimalPlaces: ds.customDecimalPlaces
                        })) ?? []
                    }
                    onValuesChange={(values) =>
                        handleChange({ key: 'dimensions', selectedOption: values })
                    }
                    elementStyle={{
                        style: {
                            backgroundColor: '#C8E6C9'
                        },
                        hoverStyle: {
                            backgroundColor: '#A5D6A7'
                        }
                    }}
                >
                    <Flex flexDir={'column'}>
                        <DnDElements.Values />
                    </Flex>
                    <DnDElements.Options triggerButtonText="Pridať dimenziu" />
                </DnDElements>
            </Box>
            <Box>
                <Text>Select metrics</Text>
                <DnDElements
                    values={
                        selectedMetrics?.map((ds) => {
                            return {
                                id: ds.id,
                                label: ds.defaultDisplayName,
                                value: ds.id,
                                formatType: ds.formatType,
                                customDisplayName: ds.customDisplayName,
                                customDecimalPlaces: ds.customDecimalPlaces
                            };
                        }) ?? []
                    }
                    options={
                        metricOptions?.map((ds: MetricConfigWithId) => ({
                            id: ds.id,
                            label: ds.defaultDisplayName,
                            value: ds.id,
                            formatType: ds.formatType,
                            customDisplayName: ds.customDisplayName,
                            decimalPlaces: ds.decimalPlaces
                        })) ?? []
                    }
                    onValuesChange={(values) =>
                        handleChange({ key: 'metrics', selectedOption: values })
                    }
                    elementStyle={{
                        style: {
                            backgroundColor: '#BBDEFB'
                        },
                        hoverStyle: {
                            backgroundColor: '#90CAF9'
                        }
                    }}
                >
                    <Flex flexDir={'column'}>
                        <DnDElements.Values />
                    </Flex>
                    <DnDElements.Options triggerButtonText="Pridať metriku" />
                </DnDElements>
            </Box>
        </Flex>
    );
};
