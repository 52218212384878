import { chakra, Flex, FlexProps } from "@chakra-ui/react";

interface Props extends FlexProps {
    children: React.ReactNode;
}

const _FormPropertyRow: React.FC<Props> = ({ children, ...rest }) => {
    return <Flex alignItems='center' my={1} justifyContent='space-between' {...rest}>
        {children}
    </Flex>
}

const FormPropertyRow = chakra(_FormPropertyRow);

export default FormPropertyRow;