import AceEditor from 'react-ace';
// Import the required ace-builds for JSON mode
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import { useAppSelector } from 'src/hooks/redux';
import { selectComponentById } from 'src/redux/features/blueprint/bluePrintSlice';

import { Box, Button, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import { useCanvas } from '../../EditorContext';
import { isJsonValid } from './utils';

const UIComponentEditor = () => {
    const {
        state: { activeSelectedComponentId }
    } = useCanvas();
    const componentToEdit = useAppSelector(
        selectComponentById(activeSelectedComponentId)
    );
    const setComponentConfig = useSetComponentConfig();
    const toast = useToast();

    const [value, setValue] = useState(JSON.stringify(componentToEdit, null, 2));

    useEffect(() => {
        setValue(JSON.stringify(componentToEdit, null, 2));
    }, [componentToEdit]);

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    const handleSave = async () => {
        if (!isJsonValid(value)) {
            toast({
                title: 'There was an error with saving',
                description: 'Content is not valid JSON',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
            return;
        }
        const parsed = JSON.parse(value);

        await setComponentConfig(parsed);
    };

    return (
        <>
            <Box position="relative">
                <Button
                    position="absolute"
                    bottom="1rem"
                    right="1rem"
                    zIndex={10}
                    onClick={handleSave}
                    variant="solid"
                >
                    Save
                </Button>
                <AceEditor
                    enableBasicAutocompletion={true}
                    highlightActiveLine={true}
                    enableLiveAutocompletion={true}
                    mode="json"
                    theme="tomorrow"
                    onChange={handleChange}
                    name="UIComponentEditor"
                    editorProps={{ $blockScrolling: true }}
                    value={value}
                    height="40rem"
                    width="100%"
                    setOptions={{
                        useWorker: false
                    }}
                />
            </Box>
        </>
    );
};

export default UIComponentEditor;
