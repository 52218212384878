import { Flex, Icon, Link } from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';

interface ExternalLinkProps {
    to: string;
    label: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ to, label }) => {
    return (
        <Flex
            alignItems="center"
            gap="0.5rem"
            color="#0F91D2"
            _hover={{ color: '#0c78ad' }}
        >
            <Icon as={FiExternalLink} />
            <Link
                href={to}
                cursor='pointer'
                textDecoration='underline'
                color='#0F91D2'
                _hover={{ color: '#0c78ad' }}
                target="_blank"
            >
                {label}
            </Link>
        </Flex>
    );
};

export default ExternalLink;
