import { Link, LinkProps } from '@chakra-ui/react';
import { NavigateProps, To, useNavigate } from 'react-router-dom';

export interface NavigateLinkProps extends LinkProps {
    to: To;
    options?: Omit<NavigateProps, 'to'>;
    children: React.ReactNode;
    [key: string]: any;
}

const NavigateLink: React.FC<NavigateLinkProps> = ({ to, children, ...rest }) => {
    const navigate = useNavigate();
    return (
        <Link
            variant="tableReportButton"
            onClick={() => navigate(to, rest.options)}
            {...rest}
        >
            {children}
        </Link>
    );
};

export default NavigateLink;
