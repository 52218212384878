import { z } from 'zod';
import validator from 'validator';
const create = z.object({
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string().nullable(),
    phoneNumber: z
        .string()
        .refine(validator.isMobilePhone, { message: 'Nesprávny formát telefónneho čísla' })
        .optional()
        .nullable(),
    password: z.string().optional(),
    role: z.string().optional()
});
const update = z.object({
    id: z.number(),
    firstName: z.string().min(1),
    lastName: z.string().min(1).nullable(),
    phoneNumber: z
        .string()
        .refine(validator.isMobilePhone, { message: 'Nesprávny formát telefónneho čísla' })
        .optional()
        .nullable(),
    role: z.string().optional()
});
const remove = z.object({
    id: z.number()
});
export default {
    create,
    update,
    remove
};
