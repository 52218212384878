import { Box, Button, Flex, Img } from "@chakra-ui/react";
import {
  ContentProps,
  MessageButtonProps,
  MessageProps,
} from "./Message.types";

// @ts-ignore:next-line
import IconGroup from "../../assets/svg/icons-group.svg";
import React from "react";

const Message: React.FC<MessageProps> & {
  RejectButton: React.FC<MessageButtonProps>;
  ApproveButton: React.FC<MessageButtonProps>;
  Content: React.FC<ContentProps>;
} = ({ children, style }) => {
  const buttonReject = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === RejectButton
  );
  const buttonApproved = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === ApproveButton
  );

  const content = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === Content
  );
  return (
    <Flex
      textAlign="center"
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      style={style}
    >
      <Flex flexDir="column">
        <Img src={IconGroup} alt="" ml="auto" mr="auto" mb="30px" mt="20px" />
        <Box fontSize="lg" fontWeight={400} color="rgba(82, 90, 102, 1)">
          {content}
        </Box>
        <Flex gap="20px" justifyContent="center" alignItems="center" mt="2rem">
          {buttonReject}
          {buttonApproved}
        </Flex>
      </Flex>
    </Flex>
  );
};

const Content: React.FC<ContentProps> = ({ children }) => {
  return <>{children}</>;
};

const RejectButton: React.FC<MessageButtonProps> = ({ children, ...props }) => {
  return (
    <Button variant="outline" {...props}>
      {children}
    </Button>
  );
};

const ApproveButton: React.FC<MessageButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button variant="solid" {...props}>
      {children}
    </Button>
  );
};

Message.Content = Content;
Message.RejectButton = RejectButton;
Message.ApproveButton = ApproveButton;

export default Message;
