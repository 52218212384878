import { string, z } from 'zod';
import validator from 'validator';
import { CountryCodes } from '../enums.js';
const ICO_REGEX = /^\d{8}$/;
const DIC_REGEX = /^\d{10}$/;
const ZIP_CODE_REGEX = /^\d{3}\s?\d{2}$/;
const create = z.object({
    name: z.string().min(3, { message: 'Name needs to be at least 3 characters long' }),
    businessName: z.string().optional().nullable(),
    ico: z
        .string()
        .refine((value) => (value ? ICO_REGEX.test(value) : true), { message: 'Invalid format' })
        .optional()
        .nullable(),
    dic: z
        .string()
        .refine((value) => (value ? DIC_REGEX.test(value) : true), { message: 'Invalid format' })
        .optional()
        .nullable(),
    street: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    streetNumber: z.string().optional().nullable(),
    zipCode: z
        .string()
        .refine((value) => (value ? ZIP_CODE_REGEX.test(value) : true), {
        message: 'Invalid format'
    })
        .optional()
        .nullable()
});
const get = z.object({
    id: z.number()
});
const update = z
    .object({
    id: z.number(),
    name: z.string().min(3).optional(),
    businessName: z.string().optional().nullable(),
    ico: z
        .string()
        .refine((value) => (value ? ICO_REGEX.test(value) : true), {
        message: 'Invalid format.'
    })
        .optional()
        .nullable(),
    dic: z
        .string()
        .refine((value) => (value ? DIC_REGEX.test(value) : true), {
        message: 'Invalid format.'
    })
        .optional()
        .nullable(),
    street: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    streetNumber: z.string().optional().nullable(),
    zipCode: z
        .string()
        .refine((value) => (value ? ZIP_CODE_REGEX.test(value) : true), {
        message: 'Invalid format.'
    })
        .optional()
        .nullable(),
    countryCode: z.nativeEnum(CountryCodes).optional().nullable()
})
    .strict();
const createMember = z.object({
    email: z.string().min(1, 'Email is required.').email({ message: 'Invalid email format.' }),
    teamId: z.number(),
    firstName: z.string().min(1, { message: 'First name is required.' }),
    lastName: z.string().min(1, { message: 'Last name is required.' }),
    phoneNumber: z
        .string()
        .refine((value) => (!value ? true : validator.isMobilePhone(value)), {
        message: 'Nesprávny formát telefónneho čísla'
    })
        .optional()
        .nullable(),
    role: z.string(),
    password: z.string().min(6, { message: 'Password must be at least 6 characters long.' }),
    assignedClients: z.array(z.number()).optional()
});
const updateMember = z.object({
    id: z.number(),
    firstName: z.string().min(1, { message: 'First name is required.' }),
    lastName: z.string().min(1, { message: 'Last name is required.' }),
    role: z.string().optional(),
    password: string()
        .min(6, { message: 'Password must be at least 6 characters long.' })
        .optional(),
    phoneNumber: z
        .string()
        .refine((value) => (!value ? true : validator.isMobilePhone(value)), {
        message: 'Invalid phone number format.'
    })
        .optional()
        .nullable(),
    assignedClients: z.array(z.number()).optional()
});
const listClients = z.object({
    teamId: z.number()
});
const remove = z.object({
    id: z.number(),
    passPhrase: z.string().min(1, { message: 'Passphrase is required.' })
});
const removeMember = z.object({
    id: z.number()
});
export default {
    create,
    createMember,
    updateMember,
    update,
    get,
    listClients,
    remove,
    removeMember
};
