import { Box, Button, Flex, Img, Text } from '@chakra-ui/react';
import React, { ReactNode, useState } from 'react';
import IconGroup from '@assets/svg/icons-group.svg';

type GenericComponentProps = {
    text1: ReactNode;
    buttonRejectVariant?: string;
    buttonApprovedVariant?: string;
    buttonReject: string;
    buttonApproved: string;
    onReject: () => Promise<void> | void;
    onApprove: () => Promise<void> | void;
};

const QuestionMessage: React.FC<GenericComponentProps> = ({
    text1,
    buttonReject,
    buttonApproved,
    buttonApprovedVariant,
    buttonRejectVariant,
    onReject,
    onApprove
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleApprove = async () => {
        setIsLoading(true);
        try {
            await onApprove();
        } catch (e) {
            setIsLoading(false);
            throw e;
        }

        setIsLoading(false);
    };

    return (
        <Box textAlign="center">
            <Img src={IconGroup} alt="" ml="auto" mr="auto" mb="30px" mt="20px" />
            <Text fontSize="lg" fontWeight={400} color="rgba(82, 90, 102, 1)">
                {text1}
            </Text>
            <Flex gap="20px" justifyContent="center" alignItems="center" mt="2rem">
                <Button
                    variant={buttonRejectVariant ?? 'outline'}
                    onClick={onReject}
                >
                    {buttonReject}
                </Button>
                <Button
                    onClick={handleApprove}
                    isLoading={isLoading}
                    variant={buttonApprovedVariant ?? 'solid'}
                >
                    {buttonApproved}
                </Button>
            </Flex>
        </Box>
    );
};

export default QuestionMessage;
