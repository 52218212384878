import { Box, Button, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { StyledSelect } from '@tasklogy/zircon-ui-components';
import ModalWindow from 'src/components/ModalWindow';
import { Client } from 'src/redux/types/api';

import { FiExternalLink } from 'react-icons/fi';
import { components, OptionProps } from 'react-select';
import { useState } from 'react';

interface Props {
    clients: Client[];
    onClientSelect: (clientId: number | null) => void;
    isOpen: boolean;
    onClose: () => void;
}

const Option = ({ children, ...props }: OptionProps<any>) => {
    const isDisabled = props.isDisabled;
    return (
        <components.Option {...props}>
            <Tooltip
                w="100%"
                isDisabled={!isDisabled}
                label="Client needs to have at least one connected account to create a report."
            >
                <Box>{children}</Box>
            </Tooltip>
        </components.Option>
    );
};

export default function SelectClientModal({
    clients,
    isOpen,
    onClientSelect,
    onClose
}: Props) {
    const [selectedClientId, setSelectedClientId] = useState<number | null>(null);
    const clientsOptions = clients?.map((client) => ({
        label: client.name,
        value: client.id,
        isDisabled: client.connectedSources?.length === 0
    }));

    return (
        <ModalWindow
            minWidth="30rem"
            isOpen={isOpen}
            onClose={() => {
                onClose();
                onClientSelect(null);
            }}
            title="Select client"
        >
            <StyledSelect
                placeholder="Select client"
                options={clientsOptions}
                onChange={(option: any) => setSelectedClientId(option.value)}
                value={clientsOptions?.find(
                    (option) => option.value === selectedClientId
                )}
                components={{ Option }}
            />
            <Flex justifyContent="right" mt="1rem">
                <Button
                    variant="solid"
                    isDisabled={!selectedClientId}
                    onClick={() => onClientSelect(selectedClientId)}
                >
                    Select
                    <Icon ml="0.5rem" as={FiExternalLink} />
                </Button>
            </Flex>
        </ModalWindow>
    );
}
