import { Box, Button, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { SourceAuth } from 'src/redux/types/api';

import { SourceIcon } from '@tasklogy/zircon-ui-components';
import SourceAuthDetailModal from './SourceAuthDetailModal';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { SourceAuthStatus } from 'common/enums';
import ModalStepList from '../ModalStepList/ModalStepList';

import { AuthInProgress } from '../PairDataSources/AuthInProgress';
import { api, useGetSourceAuthUriMutation } from 'src/redux/api';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@hooks';
import PingStatus from '../Ping';

interface SourceAuthCardProps {
    sourceAuth: SourceAuth;
    onClick?: () => void;
    onReauth?: () => void;
}

export default function SourceAuthCard({ sourceAuth, onClick }: SourceAuthCardProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useAppDispatch();
    const {
        isOpen: isAuthenticationFlowOpen,
        onOpen: openAuthenticationFlow,
        onClose: closeAuthenticationFlow
    } = useDisclosure();

    const [getSourceAuthUri] = useGetSourceAuthUriMutation();

    const [attemotId, setAttemptId] = useState<string | null>(null);

    const advertisers = sourceAuth?.advertisers;

    const isUsed = useMemo(() => {
        return advertisers?.some((advertiser) => advertiser.isUsed);
    }, [advertisers]);

    const handleRauth = async () => {
        const response = await getSourceAuthUri({
            sourceIdentifier: sourceAuth.source.identifier,
            reauthId: sourceAuth.id
        }).unwrap();

        window.open(response.uri, '_blank');
        setAttemptId(response.attemptId);
        onClose();
        openAuthenticationFlow();
    };

    const handleReauthSuccess = () => {
        dispatch(api.util.invalidateTags(['SourceAuths']));
        toast.success('Re-authentication successful.');
        closeAuthenticationFlow();
        onClose();
    };

    return (
        <>
            <Flex
                flexDir="column"
                padding="1rem"
                background="white"
                borderRadius="7px"
                border="1px solid #DFDFDF"
                minW="28rem"
                maxW="28rem"
                minH="180px"
                cursor="pointer"
                transition="transform 0.2s"
                _hover={{
                    transform: 'scale(1.02)'
                }}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    } else {
                        onOpen();
                    }
                }}
            >
                <Flex
                    fontWeight="bold"
                    fontSize="1rem"
                    alignItems="center"
                    justifyContent="space-between"
                    pb="0.75rem"
                    borderBottom="1px solid #DFDFDF"
                >
                    <Text color="#525A66">{sourceAuth.source.displayName}</Text>
                    <HStack spacing="1rem">
                        <SourceIcon selectedIcons={[sourceAuth.source.identifier]} />
                    </HStack>
                </Flex>
                <Flex mt="0.5rem" fontSize="0.875rem" justifyContent="space-between">
                    <Text fontSize='sm' color="text.lightGray">Email:</Text>
                    <PingStatus size='sm' justifyContent='end' colorScheme={isUsed ? 'green' : 'red'}>
                        {isUsed ? 'Used' : 'Unused'}
                    </PingStatus>
                </Flex>
                <Flex fontSize="0.875rem" justifyContent="space-between">
                    <Text fontSize='sm' color="text.lightGray" fontWeight="bold">
                        {sourceAuth.accountDetails?.userInfo?.email}
                    </Text>
                    <Flex alignItems='center' gap='0.3rem'>
                        <Text fontSize='xs' textAlign="right" as="span" color="text.lightGray">
                            Updated:
                        </Text>
                        <Text
                            fontSize='xs'
                            textAlign="right"
                            as="span"
                            color="text.lightGray"
                        >
                            {DateTime.fromISO(sourceAuth.createdAt).toFormat(
                                'dd.MM.yyyy'
                            )}
                        </Text>
                    </Flex>
                </Flex>
                <Flex
                    mt="0.5rem" fontSize="0.875rem" justifyContent="space-between">
                    <Flex flexDir="column">
                        {sourceAuth.accountDetails?.userInfo?.name && (
                            <>
                                <Text color="text.lightGray">Name:</Text>
                                <Text color="text.lightGray" fontWeight="bold">
                                    {sourceAuth.accountDetails?.userInfo?.name}
                                </Text>
                            </>
                        )}
                    </Flex>
                </Flex>

                {sourceAuth.status === SourceAuthStatus.NO_ACCESS && (
                    <PingStatus mt="0.5rem" size='sm' colorScheme='red' statusStyles={{ fontWeight: 500 }}>
                        This connection seems to be inactive. Try to re-authenticate.
                    </PingStatus>
                )}

                <Flex gap="0.25rem" flexWrap="wrap" mt="1rem" alignItems="end" flex="1">
                    {advertisers?.slice(0, 6)
                        .sort((a) => a.isUsed ? -1 : 1)
                        .map((advertiser) => (
                            <Flex
                                key={advertiser.advertiserId}
                                as="span"
                                backgroundColor={advertiser.isUsed ? '#1BC30C' : '#8C8C8C'}
                                py="0.25rem"
                                px="0.375rem"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="0.125rem"
                            >
                                <Text as="span" fontSize="0.675rem" color="white">
                                    {advertiser.advertiserName}
                                </Text>
                            </Flex>
                        ))}
                    {advertisers?.length > 6 && (
                        <Flex
                            as="span"
                            backgroundColor="#8C8C8C"
                            py="0.25rem"
                            px="0.375rem"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="0.125rem"
                        >
                            <Text as="span" fontSize="0.675rem" color="white">
                                + more
                            </Text>
                        </Flex>
                    )}
                </Flex>
            </Flex>
            <ModalStepList
                isOpen={isAuthenticationFlowOpen}
                onClose={closeAuthenticationFlow}
            >
                <ModalStepList.Step modalProps={{ size: 'xl', isCentered: true }}>
                    <AuthInProgress
                        onCancel={closeAuthenticationFlow}
                        onSuccess={handleReauthSuccess}
                        attemptId={attemotId ? attemotId : undefined}
                        onPoll={() => { }}
                    />
                </ModalStepList.Step>
            </ModalStepList>
            <SourceAuthDetailModal
                isOpen={isOpen}
                onClose={onClose}
                sourceAuth={sourceAuth}
                onReauth={handleRauth}
            />
        </>
    );
}
