import { Text } from '@chakra-ui/react';
import React from 'react';

export interface OutletTitleProps {
    children: React.ReactNode | string;
}

const OutletTitle: React.FC<OutletTitleProps> = ({ children }) => {
    return (
        <Text variant={'title'} mb="20px">
            {children}
        </Text>
    );
};

export default OutletTitle;
