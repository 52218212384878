import { Box, Flex, Text } from '@chakra-ui/react';
import { useGetTeamQuery } from '@api';
import { TeamIdParams } from '@router';
import { OutletTitle } from '@tasklogy/zircon-ui-components';
import { useParams } from 'react-router-dom';
import ClientsTable from 'src/blueprint/components/Tables/ClientsTable/ClientsTable.tsx';
import TeamMembersTable from 'src/blueprint/components/Tables/TeamMembersTable/TeamMembersTable';

export const TeamDetail = () => {
    const params = useParams<TeamIdParams>();
    const { data: teamData } = useGetTeamQuery(
        { teamId: parseInt(params?.teamId as string) },
        { skip: !params?.teamId }
    );
    return (
        <>
            <Flex gap={2} justifyContent="space-between" alignItems="center">
                <OutletTitle>{teamData?.name}</OutletTitle>
            </Flex>
            <Flex flexDir={'column'} gap={'2rem'}>
                <Box>
                    <Text variant={'subtitle'}>Clients</Text>
                    <ClientsTable />
                </Box>
                <Box mb="3rem">
                    <Text variant={'subtitle'}>Team members</Text>
                    <TeamMembersTable />
                </Box>
            </Flex>
        </>
    );
};
