import { Flex } from "@chakra-ui/react"
import { getProperty } from 'dot-prop'
import { Controller, FieldError, FieldValues, useFormContext } from "react-hook-form"
import ColorPickerInput from "../inputs/ColorPickerInput"
import FormPropertyRow from "../layout/FormPropertyRow"
import FormPropertyTitle from "../layout/FormPropertyTitle"
import FormTitle from "../layout/FormTitle"
import { useId } from "react"

const ColorForm = <T extends FieldValues>({ name, title, withGradient=false }: { name: string, title?: string, withGradient?: boolean }) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;
    const id = useId();

    return <FormPropertyRow>
        <Flex alignItems={'center'} justifyContent='space-between' gap={'0.5rem'} width={'100%'}>
            <FormPropertyTitle title={title ?? 'Background'} />
            <Controller
                name={name}
                render={({ field }) => (
                    <ColorPickerInput id={id} colorPickerProps={{hideColorTypeBtns: !withGradient}} {...field} error={getProperty(errors, name) as FieldError | undefined} type="text" />
                )}
            />
        </Flex>
    </FormPropertyRow>
}

export default ColorForm;