import { Grid, GridItem, GridProps } from "@chakra-ui/react";

export interface IFormGridProps extends GridProps {
  children: React.ReactNode;
  templateColumns?: string;
  gap?: number;
  rowGap?: number;
  colGap?: number;
  autoRows?: string;
  autoFlow?: string;
  autoColumns?: string;
  templateRows?: string;
  templateAreas?: string;
  template?: string;
  area?: string;
  column?: string;
  row?: string;
  columnStart?: string;
  columnEnd?: string;
  rowStart?: string;
  rowEnd?: string;
}

export interface IItemProps {
  children: React.ReactNode;
  colSpan?: number;
  rowSpan?: number;
  colStart?: number;
  colEnd?: number;
  rowStart?: number;
  rowEnd?: number;
}

const FormGrid: React.FC<IFormGridProps> & { Item: React.FC<IItemProps> } = ({
  children,
  ...props
}) => {
    const defaultProps = {
        templateColumns: 'repeat(12, 1fr)',
        gap: 5,
        rowGap: '40px',
        columnGap: '30px',
        width: '50%'
    };
    const newProps = { ...defaultProps, ...props };
    return <Grid {...newProps}>{children}</Grid>;
};

const Item: React.FC<IItemProps> = ({ children, ...props }) => {
  const defaultProps = {
    colSpan: 6,
  };
  const newProps = { ...defaultProps, ...props };
  return <GridItem {...newProps}>{children}</GridItem>;
};

FormGrid.Item = Item;

export default FormGrid;
