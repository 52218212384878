import {
    Box,
    Flex,
    Text
} from '@chakra-ui/react'
import FormTitle from '../forms/layout/FormTitle'
import StyleDivider from '../StyleDivider'

interface FormAccordionProps {
    children: React.ReactNode
}

const FormAccordion: React.FC<FormAccordionProps> = ({ children }) => {
    return <Flex direction='column' gap='0.5rem'>
        {children}
    </Flex>
}

interface FormAccordionItemProps {
    title: string
    children: React.ReactNode
}

const FormAccordionItem: React.FC<FormAccordionItemProps> = ({ title, children }) => {
    return <Flex direction='column' gap='10px'>
        <FormTitle title={title} />
        <Box>
            {children}
        </Box>
    </Flex>
}

export { FormAccordion, FormAccordionItem }
