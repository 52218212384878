import { Button, Checkbox, Flex, Text } from '@chakra-ui/react';
import DefaultFiltersBuilder from './DefaultFIlterBuilder';
import ModalWindow from 'src/components/ModalWindow';
import { useModal } from 'src/hooks/useModal';
import { useAppSelector } from '@hooks';
import {
    selectActiveReport,
    selectComponentById,
    selectConnectedSources
} from 'src/redux/features/blueprint/bluePrintSlice';

import { DataComponent } from 'common/types';
import { useMemo } from 'react';
import { ActionDelete, SourceIcon } from '@tasklogy/zircon-ui-components';
import { DefaultFilter } from 'src/redux/types/api';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import {
    useDeleteReportDefaultFilterMutation,
    useListReportDefaultFiltersQuery
} from '@api';
import { toast } from 'react-toastify';

export default function DefaultFiltersTab() {
    const { close, isOpen, open, data } = useModal<DefaultFilter | null>();
    const {
        close: closeDeleteModal,
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        data: deleteModalData
    } = useModal<DefaultFilter | null>();
    const activeReport = useAppSelector(selectActiveReport);
    const [deleteDefaultFilter] = useDeleteReportDefaultFilterMutation();
    const { data: defaultFilters } = useListReportDefaultFiltersQuery(
        {
            reportId: activeReport?.id as number
        },
        {
            skip: !activeReport
        }
    );

    const {
        state: { activeSelectedComponentId }
    } = useCanvas();
    const componentToEdit = useAppSelector(
        selectComponentById(activeSelectedComponentId)
    ) as DataComponent;
    const connectedSources = useAppSelector(selectConnectedSources);

    const setComponentConfig = useSetComponentConfig();

    const connectedSource = connectedSources?.find(
        (ds) => ds.id === componentToEdit?.connectedSourceId
    );

    const availableFilters = useMemo(() => {
        if (defaultFilters && connectedSource) {
            return defaultFilters?.filter(
                (filter) => filter.dataSourceId === connectedSource?.dataSourceId
            );
        }

        return [];
    }, [connectedSource, defaultFilters]);

    const dataSource = connectedSource?.dataSource;

    const handleDefaultFilterChecked = async (filterId: number, checked: boolean) => {
        if (checked) {
            const newIds = componentToEdit.defaultFilterIds
                ? componentToEdit.defaultFilterIds
                : [];

            if (newIds.includes(filterId)) {
                return;
            }

            await setComponentConfig({
                ...componentToEdit,
                defaultFilterIds: [...newIds, filterId]
            });

            return;
        }

        if (!componentToEdit.defaultFilterIds) {
            return;
        }

        const newIds = componentToEdit.defaultFilterIds.filter((id) => id !== filterId);

        await setComponentConfig({
            ...componentToEdit,
            defaultFilterIds: newIds
        });
    };

    const handleDeleteFilter = async (filter: DefaultFilter) => {
        try {
            await deleteDefaultFilter({ id: filter.id }).unwrap();
            toast.success('Filter has been deleted');
            closeDeleteModal();
        } catch (error) {
            toast.error('An error occurred while deleting the filter');
            console.error(error);
        }
    };

    return (
        <Flex minH="10rem" flexDir="column" p="1rem">
            <Flex gap={2} justifyContent="start" alignItems="center" mb="1rem">
                <Text fontSize="1.125rem" color="black">
                    Default filters
                </Text>
                <Button
                    variant="solid"
                    p="16px 30px"
                    w="max-content"
                    onClick={() => open(undefined)}
                >
                    + Create filter
                </Button>
            </Flex>

            <Flex flexDir="column">
                {availableFilters?.map((filter, index) => (
                    <Flex
                        key={filter.id}
                        gap="0.75rem"
                        alignItems="center"
                        justifyContent="space-between"
                        borderTop="1px solid"
                        py="0.5rem"
                        px="0.5rem"
                        borderBottom={
                            index === availableFilters.length - 1 ? '1px solid' : 'none'
                        }
                    >
                        <Flex>
                            <Checkbox
                                key={filter.id}
                                isChecked={
                                    componentToEdit.defaultFilterIds?.includes(
                                        filter.id
                                    ) ?? false
                                }
                                onChange={(e) =>
                                    handleDefaultFilterChecked(
                                        filter.id,
                                        e.target.checked
                                    )
                                }
                                mr="0.5rem"
                            >
                                <Text
                                    _hover={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                    fontSize="1rem"
                                >
                                    {filter.name}
                                </Text>
                            </Checkbox>
                            {filter.dataSource?.identifier && (
                                <SourceIcon
                                    selectedIcons={[filter.dataSource.identifier]}
                                />
                            )}
                        </Flex>

                        <Flex alignItems="center" gap="0.5rem">
                            <Button
                                size="xs"
                                variant="solid"
                                onClick={() => open(filter)}
                            >
                                Open
                            </Button>
                            <ActionDelete onClick={() => openDeleteModal(filter)} />
                        </Flex>
                    </Flex>
                ))}
            </Flex>

            {isDeleteModalOpen && deleteModalData && (
                <ModalWindow
                    isOpen={isDeleteModalOpen}
                    onClose={closeDeleteModal}
                    title="Delete filter"
                >
                    <Flex flexDir="column">
                        <Text as="span" textAlign={'center'}>
                            Are you sure you want to delete the filter <br />
                            <Text as="span" fontWeight="bold">
                                {deleteModalData.name}
                            </Text>
                            ?
                        </Text>
                        <Flex justifyContent="flex-end" mt="1rem" gap="0.5rem">
                            <Button
                                variant="outline"
                                onClick={() => {
                                    closeDeleteModal();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="solid"
                                colorScheme="error"
                                onClick={() => {
                                    handleDeleteFilter(deleteModalData);
                                }}
                            >
                                Delete
                            </Button>
                        </Flex>
                    </Flex>
                </ModalWindow>
            )}

            {isOpen && connectedSource && activeReport && dataSource && (
                <ModalWindow
                    isOpen={isOpen}
                    onClose={close}
                    title="Filter builder"
                    minW="65rem"
                >
                    <Flex flexDir="column">
                        <DefaultFiltersBuilder
                            onCanceled={close}
                            metrics={dataSource.metrics}
                            dimensions={dataSource.dimensions}
                            reportId={activeReport.id}
                            dataSource={dataSource}
                            editedFilter={data}
                        />
                    </Flex>
                </ModalWindow>
            )}
        </Flex>
    );
}
