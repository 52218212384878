import { Box, Link as ChakraLink, Text, Textarea } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from '@hooks';
import { ComponentUI } from '@types';
import { Component, HorizontalAlign, VerticalAlign } from 'common/types';
import React, { useEffect, useState } from 'react';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import {
    selectActiveReport,
    selectComponentById
} from 'src/redux/features/blueprint/bluePrintSlice';
import { jsonStyleToChakraProps } from 'src/utils/theme';
import { assertIsDefined } from '../utils';

export default function NavigationButton({ properties, id }: ComponentUI) {
    const componentToEdit = useAppSelector(selectComponentById(id)) as Component;
    const textValue = componentToEdit?.properties?.text ?? '';
    const [inputText, setInputText] = useState(textValue);
    const { url, text, style, textStyle, pageId } = properties;
    const activeReport = useAppSelector(selectActiveReport);
    const { state, eventEmitter, actions } = useCanvas();
    const isSharedReport = !state.useEditorReport;
    const setComponentConfig = useSetComponentConfig();

    useEffect(() => {
        setInputText(textValue);
    }, [textValue]);

    const handleSave = React.useCallback(async () => {
        assertIsDefined(componentToEdit);
        const component = {
            ...componentToEdit,
            properties: {
                ...componentToEdit.properties,
                text: inputText
            }
        } as Component;
        await setComponentConfig(component);
        actions.setActiveSelection(new Set());
    }, [componentToEdit, inputText, setComponentConfig, actions]);

    eventEmitter.useSubscription((val) => {
        if (val === 'save-inline-edit') {
            handleSave();
        }
    });

    if (state.enabledInlineEditor && state.activeSelectedComponentId === id) {
        return (
            <Textarea
                variant={'unstyled'}
                style={{
                    ...properties.style,
                    height: componentToEdit.h,
                    width: componentToEdit.w
                }}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
            />
        );
    }

    const justifyContentMap: Record<HorizontalAlign, string> = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end'
    }

    const justifyContent = justifyContentMap?.[style.horizontalAlign as HorizontalAlign] ?? 'center';

    const alignItemsMap: Record<VerticalAlign, string> = {
        top: 'flex-start',
        middle: 'center',
        bottom: 'flex-end'
    }

    const alignItems = alignItemsMap?.[style.verticalAlign as VerticalAlign] ?? 'center';


    const buttonStyle = {
        ...textStyle,
        textAlign: style.horizontalAlign ?? 'center'
    };
    const { _active: activeStyle, ...restButtonStyle } = buttonStyle;

    const activeButtonStyle = {
        ...restButtonStyle,
        ...activeStyle
    };

    const componentStyleProps = jsonStyleToChakraProps(buttonStyle);

    if (!isSharedReport) {
        return (
            <Box style={{ ...style, display: 'flex', justifyContent: justifyContent, alignItems: alignItems }}>
                <Text as="a" {...componentStyleProps}>
                    {text}
                </Text>
            </Box>
        );
    }

    if (Boolean(pageId)) {
        const page = activeReport?.pages.find((page) => {
            return page.id === pageId;
        });
        const to = `${state.reportBasePathName}/${activeReport?.slug}/${page?.slug}`;
        return (
            <Box style={{
                ...style, display: 'flex', justifyContent: justifyContent, alignItems: alignItems,
            }}>
                <NavLink
                    to={to}
                    style={(props) => (props.isActive ? activeButtonStyle : restButtonStyle)}
                >
                    {text}
                </NavLink>
            </Box>
        );
    }

    if (url) {
        return (
            <Box style={{
                ...style, display: 'flex', justifyContent: justifyContent, alignItems: alignItems,
            }}>
                <ChakraLink href={url} target="_blank" {...componentStyleProps}>
                    {text}
                </ChakraLink>
            </Box>
        );
    }

    return (
        <Box style={{
            ...style, display: 'flex', justifyContent: justifyContent, alignItems: alignItems,
        }}>
            <Text as="a" {...componentStyleProps}>
                {text}
            </Text>
        </Box>
    );
}
