import { Flex, SimpleGrid, Text } from "@chakra-ui/react"
import { useId } from "react"
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form"
import FormNumberInput from "../inputs/Number"
import SquareColorPickerInput from "../inputs/SquareColorPickerInput"
import FormPropertyTitle from "../layout/FormPropertyTitle"


const parsePxAsInt = (value: string) => {
    if (!value) return 0;
    if (value.includes('px')) {
        return parseInt(value.replace('px', ''));
    }
    return 0;
}


const DropShadowForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    const methods = useFormContext<T>();
    const id = useId();

    const extractDropShadow = (value: string | undefined) => {
        if (!value) return { x: 0, y: 0, blur: 0, spread: 0, color: '#000000' };
        let dropShadow = {
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
            color: '#000000'
        }
        const shadowValues = value.match(
            /(-?\d+px)\s(-?\d+px)\s?(\d*px)?\s?(\d*px)?\s?(rgba?\([^\)]+\)|#[0-9a-fA-F]+|[a-zA-Z]+)?/
        );

        if (!shadowValues) {
            return dropShadow;
        }

        dropShadow = {
            x: parsePxAsInt(shadowValues[1]),
            y: parsePxAsInt(shadowValues[2]),
            blur: parsePxAsInt(shadowValues[3]),
            spread: parsePxAsInt(shadowValues[4]),
            color: shadowValues[5] || '#000000'
        }

        return dropShadow;
    }

    const handleChange = (key: string, keyValue: string, value: string) => {
        const newDropShadow = { ...extractDropShadow(value), [key]: keyValue };
        return `${newDropShadow.x}px ${newDropShadow.y}px ${newDropShadow.blur}px ${newDropShadow.spread}px ${newDropShadow.color}`;
    }

    return <Flex direction='column'>
        <Flex my={4} alignItems='center' gap='1rem'>
            <Text textStyle='_h2'>Drop shadow</Text>
        </Flex>
        <Flex gap='0.5rem' justifyContent='space-between'>
            <SimpleGrid columns={2} spacing='0.2rem'>
                <Flex maxW='4rem'>
                    <Controller
                        name={`${objPath}.boxShadow` as Path<T>}
                        control={methods.control}
                        render={({ field }) => <FormNumberInput label="X" error={undefined} variant='editor' value={extractDropShadow(field.value).x} onChange={(e) => {
                            const newValue = handleChange('x', e.target.value, field.value);
                            field.onChange({ target: { value: newValue } });
                        }}
                        />}
                    />
                </Flex>
                <Flex maxW='4rem'>
                    <Controller
                        name={`${objPath}.boxShadow` as Path<T>}
                        control={methods.control}
                        render={({ field }) => <FormNumberInput label="Y" error={undefined} variant='editor' value={extractDropShadow(field.value).y} onChange={(e) => {
                            const newValue = handleChange('y', e.target.value, field.value);
                            field.onChange({ target: { value: newValue } });
                        }}
                        />}
                    />
                </Flex>
                <Flex maxW='5rem'>
                    <Controller
                        name={`${objPath}.boxShadow` as Path<T>}
                        control={methods.control}
                        render={({ field }) => <FormNumberInput min={0}  label="Blur" error={undefined} variant='editor' value={extractDropShadow(field.value).blur} onChange={(e) => {
                            const newValue = handleChange('blur', e.target.value, field.value);
                            field.onChange({ target: { value: newValue } });
                        }}
                        />}
                    />
                </Flex>
                <Flex maxW='7rem'>
                    <Controller
                        name={`${objPath}.boxShadow` as Path<T>}
                        control={methods.control}
                        render={({ field }) => <FormNumberInput min={0}  label="Spread" error={undefined} variant='editor' value={extractDropShadow(field.value).spread} onChange={(e) => {
                            const newValue = handleChange('spread', e.target.value, field.value);
                            field.onChange({ target: { value: newValue } });
                        }}
                        />}
                    />
                </Flex>
            </SimpleGrid>
            <Controller
                control={methods.control}
                name={`${objPath}.boxShadow` as Path<T>}
                render={({ field: { value, onChange } }) => (
                    <Flex width='70px' h='70px'>
                        <SquareColorPickerInput id={`dropShadow-${id}`} colorPickerProps={{ hideColorTypeBtns: true }} value={extractDropShadow(value).color} onChange={(e: any) => {
                            const newValue = handleChange('color', e.target.value, value);
                            onChange({ target: { value: newValue } });
                        }}
                            error={undefined} type="text" />
                    </Flex>
                )}
            />
        </Flex>
    </Flex>
}

export default DropShadowForm;