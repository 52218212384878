import { Box, Flex, Text } from '@chakra-ui/react';

import TeamMembersTable from 'src/blueprint/components/Tables/TeamMembersTable/TeamMembersTable';
export const TeamMembers = () => {
    return (
        <div>
            <Flex gap={2} mb="3rem" justifyContent="space-between" alignItems="center">
                <Text variant="title">Team Members</Text>
            </Flex>
            <Box px="1rem">
                <TeamMembersTable />
            </Box>
        </div>
    );
};
