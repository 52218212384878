import { useUpdateReportImageMutation } from '@api';
import { Box, Button, Flex, Img, Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { toast } from 'react-toastify';
import { selectActiveReport } from 'src/redux/features/blueprint/bluePrintSlice';
import { assertIsDefined } from 'src/templates/blueprint/utils';

const ReportScreenshot = () => {
    const activeReport = useAppSelector(selectActiveReport);
    const [updateImage, data] = useUpdateReportImageMutation();
    const handleUpdateImage = async () => {
        try {
            assertIsDefined(activeReport);
            await updateImage({ reportId: activeReport.id }).unwrap();
            toast.success('Report image updated successfully');
        } catch (error) {
            toast.error('Failed to update report image');
        }
    };
    return (
        <Flex flexDir="column">
            <Flex justifyContent="space-between" alignItems="baseline" mb={4}>
                <Text textStyle='h1'>Report Screenshot:</Text>
                <Button onClick={() => handleUpdateImage()} variant="outline">
                    {data?.isLoading ? 'Updating...' : 'Update Report Image'}
                </Button>
            </Flex>
            {!activeReport?.previewImageUrl && (
                <Text fontSize="sm" color="gray.500">
                    No screenshot available
                </Text>
            )}
            {!data?.isLoading && (
                <Box>
                    <Img src={activeReport?.previewImageUrl} />
                </Box>
            )}
        </Flex>
    );
};

export default ReportScreenshot;
