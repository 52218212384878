import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ResponsiveValue,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { SlOptionsVertical } from "react-icons/sl";

export interface ActionMenuItem {
  icon: IconType;
  label: string;
  onClick: () => void;
}

export interface ActionMenuProps {
  items: ActionMenuItem[];
  menuButtonVariant?: ResponsiveValue<string> | undefined;
  size: string;
}

const ActionMenu: React.FC<ActionMenuProps> = ({
  items,
  menuButtonVariant,
  size,
}) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        fontSize={size ? size : "20px"}
        variant={menuButtonVariant ? menuButtonVariant : "icon"}
        color="icons.options"
        icon={<Icon as={SlOptionsVertical}></Icon>}
      />
      <MenuList>
        {items.map((item: ActionMenuItem, index: number) => (
          <MenuItem
            key={index}
            icon={<Icon as={item.icon} onClick={item.onClick} />}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default ActionMenu;
