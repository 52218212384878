import { Button, Fade } from "@chakra-ui/react";
import React, { CSSProperties } from "react";
import { useStepList } from "./StepList";

export const PreviousButton: React.FC<{
  children: React.ReactNode;
  onClick?: () => void;
  styles?: CSSProperties;
}> = ({ children, onClick, styles }) => {
  const { currentStep, goToPreviousStep } = useStepList();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      goToPreviousStep();
    }
  };

  return (
    <Fade in={currentStep !== 0}>
      <Button variant={"outline"} onClick={() => handleOnClick()} style={styles}>
        {children}
      </Button>
    </Fade>
  );
};
