import TemplatesTable from 'src/blueprint/components/Tables/TemplatesTable';
import { OutletTitle } from '@tasklogy/zircon-ui-components';

const TemplatesIndex = () => {
    return (
        <>
            <OutletTitle>Templates</OutletTitle>
            <TemplatesTable />
        </>
    );
};

export default TemplatesIndex;
