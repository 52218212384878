import { Input, InputProps } from "@chakra-ui/react";
import { forwardRef, useState } from "react";
import { FieldError } from "react-hook-form";

interface DashArrayInputProps extends InputProps {
    error: FieldError | undefined;
    value: string;
}

const DashArrayInput = forwardRef<HTMLInputElement, DashArrayInputProps>(({ value, onChange }, ref) => {
    const [focused, setFocused] = useState(false);
    const renderValue = (value: string) => {
        if (focused) {
            return value;
        }
        const v = value.split(' ').map(v => `${v.trim()}px`).join(', ');
        if (v === 'px') {
            return '';
        }
        return v;
    }
    return (
        <Input 
            ref={ref} 
            variant='editor' 
            size='xs' 
            value={renderValue(value)} 
            onFocus={() => setFocused(true)} 
            onBlur={() => setFocused(false)} 
            onChange={onChange} 
            type="text" 
            width={'100px'} 
        />
    );
});

export default DashArrayInput;