import { Flex, Text } from '@chakra-ui/react';
import { Message } from '@tasklogy/zircon-ui-components';

const FinalStep = () => {
    return (
        <Flex justifyContent={'center'} alignItems={'center'} height={'40rem'}>
            <Message>
                <Message.Content>
                    <Text>
                        Congratulations, your report has been created successfully. <br />
                        Now you can edit your report or go back to the page
                        reports.
                    </Text>
                </Message.Content>
                <Message.ApproveButton>Edit report</Message.ApproveButton>
            </Message>
        </Flex>
    );
};

export default FinalStep;
