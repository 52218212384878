import {
    Box,
    Button,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement
} from '@chakra-ui/react';
import { debounce, set } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { MdClear } from 'react-icons/md';

interface SearchInputProps {
    search: string;
    setSearch: (search: string) => void;
    size?: 'sm' | 'md' | 'lg';
}

const SearchInput: React.FC<SearchInputProps> = ({ setSearch, size }) => {
    const [query, setQuery] = useState<string>('');
    const debouncedSearchChange = useCallback(
        debounce((value: string) => {
            setSearch(value);
        }, 300),
        []
    );

    useEffect(() => {
        debouncedSearchChange(query);
    }, [query, debouncedSearchChange]);

    const handleClear = () => {
        setQuery('');
        setSearch('');
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };
    return (
        <Box>
            <InputGroup>
                <InputLeftElement>
                    <Icon as={FiSearch}></Icon>
                </InputLeftElement>
                <Input
                    type="text"
                    placeholder="Search"
                    size={size ?? 'md'}
                    onChange={handleInputChange}
                    value={query}
                />
                <InputRightElement>
                    <Icon
                        style={{
                            cursor: 'pointer',
                            opacity: query ? 1 : 0,
                            transition: 'opacity 0.2s ease'
                        }}
                        onClick={handleClear}
                        as={MdClear}
                    ></Icon>
                </InputRightElement>
            </InputGroup>
        </Box>
    );
};

export default SearchInput;
